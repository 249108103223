import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Card,
  SearchBar,
  DatePicker,
  Table,
  NotFound,
} from '@outlier-spa/component';
import { LoadingKiri } from 'components';
import { DefaultCell } from 'components/table';
import { useApi } from '@outlier-spa/fetch';
import { useNavigate } from 'react-router-dom';

interface IClassTutor {
  tutor: string;
  tutorId: string;
  total: number;
  classes: { week: number; total: number }[];
}

export const ClassesMonth: React.FC = () => {
  const [getUrl, loadingRef] = useApi();
  const [filter, setFilter] = useState('');
  const [date, setDate] = useState<moment.Moment>();
  const [limits, setLimits] = useState<{
    minimum: moment.Moment;
    maximum: moment.Moment;
  }>();
  const navigate = useNavigate();
  const [tutors, setTutors] = useState<IClassTutor[]>([]);
  const [data, headers] = useMemo(() => {
    const result: any[] = [];
    let maxWeek = 1;
    tutors.forEach((tutor) => {
      const row: any = { ...tutor };
      let total = 0;
      tutor.classes.forEach((item) => {
        row[`week${item.week}`] = item.total;
        total += item.total;
        maxWeek = Math.max(maxWeek, item.week);
      });
      row.total = total;
      result.push(row);
    });
    const headers: any[] = [
      {
        key: 'tutor',
        value: 'Monitores',
        sortable: true,
        cellComponent: DefaultCell,
      },
      ...Array.from({ length: maxWeek }).map((_, week) => ({
        key: `week${week + 1}`,
        value: `Semana 0${week + 1}`,
        sortable: true,
        cellComponent: DefaultCell,
      })),
      {
        key: 'total',
        width: 130,
        value: 'Total clases',
        sortable: true,
        cellComponent: ({ value }: any) => (
          <div className="bold pdr-10 w100 text-right">{value || '-'}</div>
        ),
      },
    ];
    return [result, headers];
  }, [tutors]);

  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      const filterStr = filter.toLowerCase();
      return data.filter((d) => {
        const tutor = d[0].tutor?.value?.toLowerCase();
        return tutor?.includes(filterStr);
      });
    },
    [filter]
  );

  function onChangeDate(value: moment.Moment | null) {
    if (value) setDate(value);
  }

  function onRowClick(data: any) {
    if (!date) return;
    navigate(
      `/team/user/${data.tutorId.value}/classes/${date.format('YYYY-MM')}`
    );
  }

  useEffect(() => {
    if (!date) return;
    getUrl<IClassTutor[]>(
      `/class/${date.format('YYYY')}/${date.format('MM')}/monthSummary`,
      ({ data }) => {
        console.log(data);
        setTutors(data);
      }
    );
  }, [getUrl, date]);

  useEffect(() => {
    getUrl<{ minimum: string; maximum: string }>(`/class/limit`, ({ data }) => {
      setLimits({
        minimum: moment(data.minimum),
        maximum: moment(data.maximum),
      });
      setDate(moment(data.maximum));
    });
  }, [getUrl]);

  return (
    <div className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />

      <div className="flex flex-center flex-gap-14">
        <DatePicker
          picker="month"
          type="mainAlt"
          onChange={onChangeDate}
          disabledDate={(current) => {
            if (!limits) return false;
            return (
              current && (current < limits.minimum || current > limits.maximum)
            );
          }}
          style={{ width: 230 }}
          value={date}
          format={'MMMM YYYY'}
          allowClear={false}
        />
        <SearchBar
          className="flex-1"
          buttonVisible={false}
          type="mainAlt"
          onChange={(evt) => setFilter(evt.target.value)}
        />
      </div>

      <Card
        className={`overflow-auto mt-14${data?.length > 0 ? ' flex-1' : ''}`}
        style={{ maxHeight: '100%' }}
      >
        <Table
          alternate
          bordered={false}
          fixedHeaders
          hoverable
          onRowClick={onRowClick}
          filter={tableFilter}
          headers={headers}
          data={data}
        />
        {data?.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay clases registradas." />
          </div>
        )}
      </Card>
    </div>
  );
};
