import React, { useEffect, useState } from 'react';
import {
	Card,
	SectionHeader,
} from '@outlier-spa/component';
import {
	StarFilled,
	FileFilled,
	LightBulbFilled,
} from '@outlier-spa/icon';
import styled from 'styled-components';
import { LoadingKiri } from 'components';
import { useApi } from '@outlier-spa/fetch';
import { IInstitutionStudentViewModel } from 'interfaces/institution';

const ParticipationItem = styled.div`
	width: 200px;
	&:not(:last-child) {
		border-right: 1px solid ${({theme}) => theme.border.main};
	}
`;

export interface IInstitutionStudentSummaryProps {
	userId: string;
};

export const InstitutionStudentSummary: React.FC<IInstitutionStudentSummaryProps> = ({userId}) => {
	const [getUrl, loadingRef] = useApi();
	const [summary, setSummary] = useState<IInstitutionStudentViewModel>();

	useEffect(() => {
		getUrl<IInstitutionStudentViewModel>(`institution/1/student/${userId}/summary`, ({data}) => {
			setSummary(data);
		});
	}, [userId, getUrl]);

	return (
		<div className='h100 overflow-hidden-x line-normal ant-fade-enter ant-fade-enter-active'>
			<LoadingKiri ref={loadingRef} />
			<SectionHeader
				size='small'
				title='Observaciones'
				className='mt-10'
				prefix={<FileFilled className='text-emphasis' />} />
			<Card bordered={false} className='pd-16'>
				<p className='text-secondary'>Sin observaciones</p>
			</Card>
			<div className='pdb-20' />
			<SectionHeader
				size='small'
				title='Evolución Emocional'
				className='mt-20'
				prefix={<LightBulbFilled className='text-emphasis' />} />
			<Card bordered={false} className='pd-16 flex flex-middle' style={{height: '10rem'}}>
				<p className='text-secondary'>Gráfico</p>
			</Card>
			<div className='pdb-20' />
			<SectionHeader
				size='small'
				title='Participación'
				className='mt-20'
				prefix={<StarFilled className='text-emphasis' />} />
			<Card bordered={false} className='pdv-16 flex'>
				{/* <ParticipationItem className='pdh-20'>
					<p className='text-secondary'>Cursos</p>
					<h2 className='text-24 mt-16'>{summary?.courses}</h2>
				</ParticipationItem> */}
				<ParticipationItem className='pdh-20'>
					<p>Incidentes</p>
					<h2 className='text-24 mt-16 text-assertive'>{summary?.incidents}</h2>
				</ParticipationItem>
				<ParticipationItem className='pdh-20'>
					<p>Asistencia</p>
					<h2 className='text-24 mt-16'>{summary?.attendance}</h2>
				</ParticipationItem>
			</Card>
		</div>
	);
};
