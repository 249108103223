import { createGlobalStyle } from 'styled-components';

export const KiriStyles = createGlobalStyle`
	.modal-wrapper .modal-container.section-tertiary {
		background-color: ${({ theme }) => theme.card.main};
		.avatar {
			background-color: ${({ theme }) => theme.background.main};
		}
	}
	.table thead .table-cell {
		font-weight: 600;
	}
	.collapse-title .icon-collapse{
		align-self: center;
	}
	.g2-tooltip{
		background-color: ${({ theme }) => theme.background.main} !important;
		font-family: Cascadia Code, Consolas, Menlo, sans-serif !important;
		border-radius: 2px !important;
		font-size: 0.95rem !important;
		color: ${({ theme }) => theme.colors.main} !important;
		box-shadow: none !important;

		.g2-tooltip-title{
			display: none;
		}
		svg {
			transform: scale(1.7) !important;
		}
		ul {
			font-family: Cascadia Code, Consolas, Menlo, sans-serif !important;
		}
		li[data-value=""]{
			display: none !important;
		}
	}
	.nav-bar-item.disabled{
		pointer-events: none;
		opacity:0.5;
	}
	.input-field .ant-input-affix-wrapper > .ant-input:not(textarea) {
    border: none;
    background: transparent;
	}
	.bg-color-danger {
		background-color: ${({ theme }) => theme.colors.danger};
	}
	.modal.modal-small .modal-container {
		width: 40.5rem;
	}
`;
