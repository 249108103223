import { Confirm, NotFound, SearchBar } from '@outlier-spa/component';
import { axios, useApi } from '@outlier-spa/fetch';
import { RiskFilled } from '@outlier-spa/icon';
import { LoadingKiri } from 'components';
import { EventCard } from 'components/card';
import { EventModal } from 'components/modal';
import { IEvent } from 'interfaces';
import { IEventViewModel } from 'interfaces/event';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StyledEventList } from './style';

export const EventList: React.FC = () => {
  const [getUrl, loadingRef] = useApi();
  const eventModalRef = useRef<React.ElementRef<typeof EventModal>>(null);
  const [events, setEvents] = useState<IEventViewModel[]>();
  const [filter, setFilter] = useState<string>();
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const filteredEvents = useMemo(() => {
    if (!filter || !events) return events;
    const filterStr = filter.toLowerCase();
    return events?.filter(
      (event) =>
        event.name?.toLowerCase().includes(filterStr) ||
        event.address?.toLowerCase().includes(filterStr) ||
        event.code?.toLowerCase().includes(filterStr)
    );
  }, [filter, events]);

  const sortedEvents = useMemo(() => {
    const now = moment();
    return {
      finished: filteredEvents?.filter((event) => moment(event.endDate) < now),
      upcoming: filteredEvents?.filter((event) => moment(event.endDate) >= now),
    };
  }, [filteredEvents]);

  function onRemove(eventId: number) {
    loadingRef.current?.setActive(true);
    axios
      .delete(`event/${eventId}`)
      .then(() => {
        setEvents(events?.filter((evt) => evt.eventId !== eventId));
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function handleRemove(eventId: number, event: IEvent) {
    removeRef.current?.open(
      eventId,
      <p>
        ¿Estás seguro que deseas eliminar el evento{' '}
        <span className="bold">"{event.name}"</span>? <br />
        <br />
        Esto eliminará todos los datos asociados al evento.
      </p>
    );
  }

  function handleUpdate(_: number, event: IEvent) {
    const evt: any = { ...event };
    evt.startDate = moment(`${evt.startDate}`);
    evt.endDate = moment(`${evt.endDate}`);
    evt.institutionIds = event.eventInstitutions?.map(
      (institution) => institution.institutionId
    );
    eventModalRef.current?.open(evt);
  }

  function onSubmitEvent(event: any, prevEvent?: IEvent) {
    // loadingRef.current?.setActive(true);
    // delete event.institutionIds;
    // event.startDate = event.startDate.format("YYYY-MM-DD");
    // event.endDate = event.endDate.format("YYYY-MM-DD");
    // if (prevEvent) {
    //   const index = events?.findIndex(
    //     (evt) => evt.eventId === prevEvent.eventId
    //   );
    //   if (index === -1) {
    //     loadingRef.current?.setActive(false);
    //     return;
    //   }
    //   axios
    //     .put<IEvent>(`event/${prevEvent.eventId}`, event)
    //     .then(({ data }) => {
    //       const newEvents = [...(events ?? [])];
    //       newEvents[index as number] = data;
    //       setEvents(newEvents);
    //       eventModalRef.current?.close();
    //     })
    //     .finally(() => loadingRef.current?.setActive(false));
    // } else {
    //   axios
    //     .post<IEvent>(`event`, event)
    //     .then(({ data }) => {
    //       setEvents([data, ...(events ?? [])]);
    //       eventModalRef.current?.close();
    //     })
    //     .finally(() => loadingRef.current?.setActive(false));
    // }
  }

  function handleAddEvent() {
    eventModalRef.current?.open();
  }

  useEffect(() => {
    getUrl<IEventViewModel[]>(`event`, ({ data }) => {
      console.log(data);
      setEvents(data);
    });
  }, [getUrl]);

  return (
    <StyledEventList className="ant-fade-enter ant-fade-enter-active inner h100 o-hidden flex flex-column">
      <LoadingKiri ref={loadingRef} />
      <EventModal ref={eventModalRef} onSubmit={onSubmitEvent} />
      <Confirm
        ref={removeRef}
        okText="Eliminar Evento"
        className="line-normal"
        onSubmit={onRemove}
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
      />
      <h2 className="text-20 bold mb-16">Eventos</h2>
      <SearchBar
        buttonText="Agregar Evento"
        type="mainAlt"
        onChange={(evt) => setFilter(evt.target.value)}
        onSubmit={handleAddEvent}
      />

      <div
        className={`relative overflow-hidden-x mt-14${
          events && events.length > 0 ? ' flex-1' : ''
        }`}
        style={{ maxHeight: '100%' }}
      >
        {events && events.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay eventos registrados." />
          </div>
        )}
        {events && (
          <>
            <div className="upcoming-events">
              <p className="pdv-14">Próximos</p>
              <div className="event-grid">
                {sortedEvents.upcoming?.map((event) => (
                  <EventCard
                    upcoming
                    key={event.eventId}
                    event={event}
                    onRemove={handleRemove}
                    onUpdate={handleUpdate}
                  />
                ))}
              </div>
            </div>
            <div className="finished-events mt-14">
              <p className="pdv-20">Realizados</p>
              <div className="event-grid">
                {sortedEvents.finished?.map((event) => (
                  <EventCard
                    key={event.eventId}
                    event={event}
                    onRemove={handleRemove}
                    onUpdate={handleUpdate}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </StyledEventList>
  );
};
