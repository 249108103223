import {
  Card,
  Confirm,
  NotFound,
  SearchBar,
  Table,
} from '@outlier-spa/component';
import { useApi } from '@outlier-spa/fetch';
import { RiskFilled } from '@outlier-spa/icon';
import { LoadingKiri, PopoverOptions } from 'components';
import { useInstitution } from 'context/institution';
import { IItem } from 'interfaces';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Popov erOptions } from 'components';

const headers: any = [
  { key: 'discipline', value: 'Disciplina', sortable: true },
  { key: 'supplier', value: 'Proveedor', sortable: true },
  { key: 'code', value: 'Factura', sortable: true },
  { key: 'product', value: 'Producto', sortable: true },
  { key: 'total', value: 'Ítems', sortable: true },
  { key: 'invoiceId', value: '', width: 56, cellComponent: PopoverOptions },
];

export interface IInstitutionProductListProps {}

export const InstitutionProductList: React.FC<
  IInstitutionProductListProps
> = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const { institution } = useInstitution();
  const [getUrl, loadingRef] = useApi();
  const [products, setProducts] = useState<IItem[]>([]);
  const [filter, setFilter] = useState<string>();

  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      return data.filter((d) => {
        const filterStr = filter.toLowerCase();
        const discipline = d[0].discipline?.value?.toLowerCase();
        const product = d[0].product?.value?.toLowerCase();
        const supplier = d[0].supplier?.value?.toLowerCase();
        const code = d[0].code?.value?.toLowerCase();
        return (
          discipline?.includes(filterStr) ||
          product?.includes(filterStr) ||
          supplier?.includes(filterStr) ||
          code?.includes(filterStr)
        );
      });
    },
    [filter]
  );

  const navigate = useNavigate();

  function handleRowClick() {
    navigate('/institution/1/product/52');
  }

  function handleRemove() {
    removeRef.current?.open(
      1,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este insumo?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  useEffect(() => {
    getUrl<IItem[]>(
      `institution/${institution?.institutionId}/item`,
      ({ data }) => {
        console.log({ data });
        setProducts(data);
      }
    );
  }, [institution?.institutionId, getUrl]);

  return (
    <div className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        okText="Eliminar Insumo"
        className="line-normal"
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
      />
      <SearchBar
        type="mainAlt"
        buttonVisible={false}
        onChange={(evt) => setFilter(evt.target.value)}
      />
      <Card
        className={`overflow-auto mt-14${products.length > 0 ? ' flex-1' : ''}`}
        style={{ maxHeight: '100%' }}
      >
        <Table
          fixed
          hoverable
          fixedHeaders
          filter={tableFilter}
          bordered={false}
          onRowClick={handleRowClick}
          headers={headers}
          handlers={{
            onRemove: handleRemove,
          }}
          data={products}
        />
        {products.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay insumos registrados para esta institución." />
          </div>
        )}
      </Card>
    </div>
  );
};
