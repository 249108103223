import { Avatar, Banner, DatePicker, Input, Modal, Select } from '@outlier-spa/component';
import { CameraFilled } from '@outlier-spa/icon';
import { useCity, useStudentByRut } from 'hooks';
import { IUserViewModel } from 'interfaces/user';
import moment from 'moment';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface IStudentModalRef {
  open: (user?: IUserViewModel) => void;
  close: () => void;
}

export interface IStudentModalProps {
  onSubmit: (data: IUserViewModel, user?: IUserViewModel) => void;
  okText: string;
  title: string;
  institutionId: number;
}

export const StudentModal = React.forwardRef<IStudentModalRef, IStudentModalProps>(
  ({ onSubmit, okText, title, institutionId }, ref) => {
    const modalRef = useRef<React.ElementRef<typeof Modal>>(null);
    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm();
    const { onChangeRut } = useStudentByRut(institutionId);
    const { regionProps, provinceProps, cityProps, cityId, onChangeCity } = useCity();
    const [user, setUser] = useState<IUserViewModel>();
    const [prevStudent, setPrevStudent] = useState<{
      isValid: number;
      student: IUserViewModel | null;
    }>();
    console.log(errors);

    function open(user?: IUserViewModel) {
      setUser(user);
      if (user?.dateOfBirth) user.dateOfBirth = moment(user.dateOfBirth) as any;
      if (user?.cityId) onChangeCity(user.cityId);
      reset(user);
      modalRef.current?.open();
    }

    function close() {
      modalRef.current?.close();
    }

    function onSubmitData(data: any) {
      data.dateOfBirth = data.dateOfBirth.format('YYYY-MM-DD');
      if (cityId) data.cityId = cityId;
      onSubmit(data, user);
      close();
    }

    function updateUser(result?: { isValid: number; student: IUserViewModel | null }) {
      setPrevStudent(result);
      if (!result?.student) return;
      if (result.student.dateOfBirth)
        result.student.dateOfBirth = moment(result.student.dateOfBirth) as any;
      if (result.student.cityId) onChangeCity(result.student.cityId);
      reset(result.student);
    }

    useImperativeHandle(ref, () => ({ open, close }));

    return (
      <Modal
        ref={modalRef}
        okText={user?.userId ? 'Actualizar' : okText}
        okProps={{ type: 'assertive', disabled: prevStudent?.isValid === 1 }}
        title={user?.userId ? 'Actualizar' : title}
        size="small"
        overlayClose={false}
        onSubmit={handleSubmit(onSubmitData)}
      >
        {/* <div className="flex flex-middle pdv-20">
          <Avatar
            className="avatar flex flex-middle o-hidden"
            size="80px"
            email={user?.email}
          >
            <CameraFilled className="text-20 text-secondary" />
          </Avatar>
        </div> */}
        <h6 className="bold pdh-14 pdt-14 pdb-20">Búsqueda por RUT</h6>
        <div className="pdh-14 flex flex-column flex-vgap-14 pdb-16">
          <div className="flex flex-center flex-gap-20">
            <Controller
              control={control}
              name="rut"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  disabled={!!(user && user.userId)}
                  type="secondary"
                  placeholder="Ingrese rut"
                  className="flex-1"
                  label="Rut"
                  validation={{
                    message: 'Rut requerido',
                    visible: Boolean(errors.rut),
                  }}
                  {...field}
                  onBlur={evt => {
                    field.onBlur();
                    onChangeRut(evt.target.value, updateUser);
                  }}
                />
              )}
            />
            <div className="flex-1 flex pdt-14">
              {prevStudent && prevStudent.isValid !== 0 && (
                <div className="bg-color-danger rounded pdv-10 pdh-12 mt-10">
                  {prevStudent.isValid === -1 && 'El rut no existe en la base de datos.'}
                  {prevStudent.isValid === 1 && 'Este estudiante ya existe.'}
                </div>
              )}
            </div>
          </div>
          <h6 className="bold pdt-12 pdb-14 border-top-secondary">Detalles</h6>

          <div className="flex flex-center flex-gap-20">
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="flex-1"
                  type="secondary"
                  placeholder="Ingrese nombres"
                  label="Nombres"
                  validation={{
                    message: 'Nombres requerido',
                    visible: Boolean(errors.firstName),
                  }}
                  disabled={!prevStudent}
                  {...field}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="flex-1"
                  label="Apellidos"
                  type="secondary"
                  validation={{
                    message: 'Apellidos requerido',
                    visible: Boolean(errors.lastName),
                  }}
                  placeholder="Ingrese apellidos"
                  disabled={!prevStudent}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex flex-center flex-gap-20">
            <div className="flex-1">
              <label className="flex mb-10">Fecha de nacimiento</label>
              <Controller
                name="dateOfBirth"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    type="secondary"
                    style={{ width: '100%' }}
                    value={field.value}
                    validation={{
                      message: 'Fecha de nacimiento requerida',
                      visible: Boolean(errors.dateOfBirth),
                    }}
                    onChange={value => field.onChange(value)}
                    disabled={!prevStudent}
                  />
                )}
              />
            </div>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <Input
                  className="flex-1"
                  type="secondary"
                  placeholder="Ingrese teléfono"
                  label="Teléfono"
                  style={{ width: '100%' }}
                  disabled={!prevStudent}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex flex-center flex-gap-20">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  // disabled={!!(user && user.userId)}
                  type="secondary"
                  placeholder="Ingrese email"
                  className="flex-1"
                  label="Email"
                  disabled={!prevStudent}
                  {...field}
                />
              )}
            />

            <Controller
              name="region"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  optionFilterProp="label"
                  type="secondary"
                  style={{ width: '100%' }}
                  className="flex-1"
                  validation={{
                    message: 'Región requerida',
                    visible: Boolean(errors.region),
                  }}
                  label="Region"
                  disabled={!prevStudent}
                  {...regionProps}
                  onChange={value => {
                    regionProps.onChange?.(value);
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
          <div className="flex flex-center flex-gap-20">
            <Controller
              name="province"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  optionFilterProp="label"
                  type="secondary"
                  style={{ width: '100%' }}
                  className="flex-1"
                  validation={{
                    message: 'Provincia requerida',
                    visible: Boolean(errors.province),
                  }}
                  label="Provincia"
                  disabled={!prevStudent}
                  {...provinceProps}
                  onChange={value => {
                    provinceProps.onChange?.(value);
                    field.onChange(value);
                  }}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  optionFilterProp="label"
                  type="secondary"
                  style={{ width: '100%' }}
                  className="flex-1"
                  label="Ciudad"
                  validation={{
                    message: 'Ciudad requerida',
                    visible: Boolean(errors.city),
                  }}
                  disabled={!prevStudent}
                  {...cityProps}
                  onChange={value => {
                    cityProps.onChange?.(value);
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
          <div className="flex flex-center flex-gap-20">
            <Controller
              name="address"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="flex-1"
                  type="secondary"
                  placeholder="Ingrese dirección"
                  label="Dirección"
                  validation={{
                    message: 'Dirección requerida',
                    visible: Boolean(errors.address),
                  }}
                  disabled={!prevStudent}
                  {...field}
                />
              )}
            />
          </div>
        </div>
      </Modal>
    );
  }
);
