import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Collapse,
  CollapseSection,
  CollapseValues,
  NavBar,
  NavBarItem,
  SectionHeader,
} from '@outlier-spa/component';
import { DetailsOutlined, LeftOutlined } from '@outlier-spa/icon';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';
import { useApi } from '@outlier-spa/fetch';
import { Header, LoadingKiri } from 'components';
import { IPollAppViewModel } from 'interfaces/poll';
import { PollFrequencySelection, PollContextProvider } from '@outlier-spa/kiri';
import { Link } from 'react-router-dom';
import { useTitle } from 'context';

const Back = ({ url }: { url?: any }) => {
  return (
    <Link
      to={url ? url : '/survey/avaible'}
      className="flex flex-center flex-gap-8 text-16"
    >
      <LeftOutlined className="text-label" />
      <span className="bold">Atrás</span>
    </Link>
  );
};

export interface ISurveySummaryProps {
  poll: IPollAppViewModel;
}

export const SurveySummary: React.FC<ISurveySummaryProps> = ({ poll }) => {
  const parsedPoll = useMemo(
    () => ({
      ...poll,
      sections: poll.sections?.map((s) => ({
        ...s,
        content: JSON.parse(s.content),
      })),
    }),
    [poll]
  );
  return (
    <PollContextProvider
      poll={{ start: {}, end: {}, sections: parsedPoll.sections as any }}
    >
      <div className="inner line-normal pdv-20">
        <Card>
          <Collapse
            type="transparent"
            headerPadding={false}
            transparent
            bordered={false}
            size="large"
          >
            {parsedPoll.sections?.map((section) => (
              <CollapseSection
                key={section.sectionId}
                className="active"
                title={
                  <SectionHeader
                    size="small"
                    className="pdh-6"
                    prefix={
                      <Button
                        bold
                        type="emphasis"
                        className="text-label text-12 code"
                        label={section.sectionId}
                        circle
                        size="xs"
                      />
                    }
                    title={<span className="text-16">{section.name}</span>}
                  />
                }
              >
                <CollapseValues>
                  <div className="pdv-10 pdh-14">
                    {section.content?.map((item: any) => (
                      <PollFrequencySelection
                        key={item.name}
                        poll={{
                          ...item,
                          sectionId: section.sectionId,
                          pollKey: 'aa',
                        }}
                      />
                    ))}
                  </div>
                </CollapseValues>
              </CollapseSection>
            ))}
          </Collapse>
        </Card>
      </div>
    </PollContextProvider>
  );
};

export const SurveyDetail = () => {
  const [getUrl, loadingRef] = useApi();
  const [poll, setPoll] = useState<IPollAppViewModel>();
  const params = useParams();
  const [backUrl, setBackUrl] = useState();
  const back = useMemo(() => {
    return <Back url={backUrl} />;
  }, [backUrl]);
  useTitle(back);

  useEffect(() => {
    console.log({ pollId: params.pollId });
    getUrl<IPollAppViewModel>(`poll/${params.pollId}`, ({ data }) => {
      console.log(data);
      setPoll(data);
    });
  }, [getUrl, params.pollId]);

  return (
    <div className="h100 flex flex-column ant-fade-enter ant-fade-enter-active o-hidden">
      <LoadingKiri ref={loadingRef} />
      <div className="inner w100">
        <Header title={poll?.name ?? '-'} editable={false} canSave={false} />
        <NavBar>
          <NavBarItem
            to="summary"
            className="bold"
            content={
              <>
                <DetailsOutlined className="mr-10 text-16" />
                <span>Detalles</span>
              </>
            }
          />
        </NavBar>
      </div>
      <div className="flex-1 overflow-hidden-x">
        {poll && (
          <Routes>
            <Route path="summary" element={<SurveySummary poll={poll} />} />
            <Route path="*" element={<Navigate replace to="summary" />} />
          </Routes>
        )}
      </div>
    </div>
  );
};
