import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Table,
  Card,
  SearchBar,
  Confirm,
  NotFound,
} from '@outlier-spa/component';
import { useNavigate } from 'react-router-dom';
import { LoadingKiri, PopoverOptions } from 'components';
import { useInstitution } from 'context/institution';
import { RiskFilled, TickOutlined } from '@outlier-spa/icon';
import { axios, useApi } from '@outlier-spa/fetch';
import { DefaultCell } from 'components/table';
import { ICourseListViewModel } from 'interfaces/course';

const headers: any = [
  { key: 'discipline', value: 'Disciplina', sortable: true },
  { key: 'name', value: 'Nombre', sortable: true },
  {
    key: 'students',
    value: 'Alumnos',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'capacity',
    value: 'Capacidad',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'classes',
    value: 'Clases',
    sortable: true,
    cellComponent: DefaultCell,
  },
  // {
  //   key: 'items',
  //   value: 'Insumos',
  //   sortable: true,
  //   cellComponent: DefaultCell,
  // },
  {
    key: 'startDate',
    value: 'Fecha inicio',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'status',
    value: 'Estado',
    sortable: true,
    cellComponent: ({ value }: any) => (
      <span>
        {value === 'Finalizado' && (
          <TickOutlined className="text-emphasis mr-4" />
        )}
        <span className="text-secondary">{value}</span>
      </span>
    ),
  },
  {
    key: 'tutor',
    value: 'Monitor',
    sortable: true,
    cellComponent: DefaultCell,
  },
  { key: 'courseId', value: '', width: 45, cellComponent: PopoverOptions },
];

export interface IInstitutionCourseListProps {}

export const InstitutionCourseList: React.FC<
  IInstitutionCourseListProps
> = () => {
  const navigate = useNavigate();
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const { institution } = useInstitution();
  const [courses, setCourses] = useState<ICourseListViewModel[]>([]);
  const [getUrl, loadingRef] = useApi();

  const [filter, setFilter] = useState<string>();

  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      return data.filter((d) => {
        const filterStr = filter.toLowerCase();
        const name = d[0].name?.value?.toLowerCase();
        const discipline = d[0].discipline?.value?.toLowerCase();
        // const monitor = d[0].monitor?.value?.firstName?.toLowerCase() + d[0].monitor?.value?.lastName?.toLowerCase();
        const monitorName = d[0].monitorName?.value?.toLowerCase();
        return (
          name?.includes(filterStr) ||
          discipline?.includes(filterStr) ||
          monitorName?.includes(filterStr)
        );
      });
    },
    [filter]
  );

  useEffect(() => {
    getUrl<ICourseListViewModel[]>(
      `/institution/${institution?.institutionId}/course`,
      ({ data }) => {
        console.log(data);
        setCourses(data);
      }
    );
  }, [institution?.institutionId, getUrl]);

  const handleRowClick = (row: any) => {
    navigate(
      `/institution/${institution?.institutionId}/course/${row.courseId.value}`
    );
  };

  function onRemoveCourse(courseId: any) {
    loadingRef.current?.setActive(true);
    axios
      .delete(`/course/${courseId}`)
      .then(() => {
        setCourses(courses.filter((c) => c.courseId !== courseId));
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function handleRemoveCourse(courseId: any) {
    removeRef.current?.open(
      courseId,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este taller?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  return (
    <div className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        okText="Eliminar Taller"
        className="line-normal"
        onSubmit={onRemoveCourse}
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
      />
      <SearchBar
        type="mainAlt"
        buttonText="Agregar Taller"
        onChange={(evt) => setFilter(evt.target.value)}
        buttonProps={{
          href: `/institution/${institution?.institutionId}/course/new`,
          as: 'a',
        }}
      />
      <Card
        className={`overflow-auto mt-14${courses.length > 0 ? ' flex-1' : ''}`}
        style={{ maxHeight: '100%' }}
      >
        <Table
          bordered={false}
          className="table-row-large"
          hoverable
          fixedHeaders
          filter={tableFilter}
          headers={headers}
          onRowClick={handleRowClick}
          data={courses}
          handlers={{
            onRemove: handleRemoveCourse,
          }}
        />
        {courses.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay talleres registrados para esta institución." />
          </div>
        )}
      </Card>
    </div>
  );
};
