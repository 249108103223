import { LogLevel } from '@azure/msal-browser';
import axios from 'axios';

export const setAuthorization = (token: string | undefined) => {
  axios.defaults.headers.common['Authorization'] = token;
};

export const apiConfig = {
  b2cScopes: [process.env.REACT_APP_B2C_SCOPE, 'offline_access'],
};
export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_B2C_FLOW,
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_B2C_AUTHORITY,
    },
  },
  authorityDomain: process.env.REACT_APP_B2C_AUTHORITY_DOMAIN!,
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID!, // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_B2C_POST_LOGOUT_REDIRECT_URI,
    navigateToLoginRequestUrl: true, // By default, MSAL.js will navigate to the login request URL. Set this to true to navigate to the login page instead.
    //automaticSilentRenew: true,
    //loadUserInfo: true,
    //autoSignIn: true,
    //responseType: 'code',
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to 'true'.
  },
  system: {
    tokenRenewalOffsetSeconds: 100,
    navigateFrameWait: 1000,
    loggerOptions: {
      loggerCallback: (level: any, message: string, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest: any = {
  scopes: ['openid', ...apiConfig.b2cScopes],
};

export const tokenRequest = {
  scopes: [...apiConfig.b2cScopes], // e.g. ['https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read']
  forceRefresh: false, // Set this to 'true' to skip a cached token and go to the server to get a new token
};
