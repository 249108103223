import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Link,
  Route,
  Routes,
  Navigate,
  useParams,
  useNavigate,
} from "react-router-dom";
import {
  TeamFilled,
  LeftOutlined,
  DetailsOutlined,
  RiskFilled,
} from "@outlier-spa/icon";
import { NavBar, Confirm, NavBarItem } from "@outlier-spa/component";
import { useTitle } from "context";
import { Header, LoadingKiri, UserModal } from "components";
import { InstitutionStudentSummary } from "./summary";
import { InstitutionStudentCaregivers } from "./caregivers";
import { IUserViewModel } from "interfaces/user";
import { useApi, axios } from "@outlier-spa/fetch";
import { useInstitution } from "context/institution";

export interface IInstitutionStudentProps {}

export const InstitutionStudent: React.FC<IInstitutionStudentProps> = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const userModalRef = useRef<React.ElementRef<typeof UserModal>>(null);
  const { institutionId, userId } = useParams();
  const [user, setUser] = useState<IUserViewModel>();
  const [getUrl, loadingRef] = useApi();
  const { institution } = useInstitution();
  const navigate = useNavigate();

  const back = useCallback(() => {
    return (
      <Link
        to={`/institution/${institutionId}/students`}
        className="flex flex-center flex-gap-8 text-16"
      >
        <LeftOutlined className="text-label" />
        <span className="bold">Atrás</span>
      </Link>
    );
  }, [institutionId]);

  useTitle(back);

  function handleRemove() {
    removeRef.current?.open(
      null,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este alumno?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  function handleStudentChange(user: IUserViewModel) {
    console.log(user);
    loadingRef.current?.setActive(true);
    axios
      .put<IUserViewModel>(`/user/${user.userId}`, user)
      .then(({ data }) => {
        setUser(data);
        userModalRef.current?.close();
      })
      .finally(() => loadingRef.current?.setActive(false));
    // setUser(user);
  }

  function onRemove() {
    if (!institution || !user) return;
    loadingRef.current?.setActive(true);
    axios
      .delete(
        `/institution/${institution.institutionId}/student/${user.userId}`
      )
      .then(() => {
        loadingRef.current?.setActive(false);
        navigate(`/institution/${institution.institutionId}/students`);
      })
      .catch(() => {
        loadingRef.current?.setActive(false);
      });
  }

  useEffect(() => {
    if (!userId) return;
    getUrl<IUserViewModel>(`/user/${userId}`, ({ data }) => {
      setUser(data);
    });
  }, [getUrl, userId]);

  return (
    <div className="inner h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <UserModal
        ref={userModalRef}
        title="Actualizar Estudiante"
        okText="Guardar cambios"
        onSubmit={handleStudentChange}
      />
      <Confirm
        okText="Eliminar Alumno"
        className="line-normal"
        onSubmit={onRemove}
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
      />
      <div>
        <Header
          email={user?.email}
          address={user?.address}
          identifier={user?.userId}
          title={`${user?.firstName} ${user?.lastName}`}
          onUpdate={() => userModalRef.current?.open(user)}
          onRemove={handleRemove}
          descriptions={[
            institution?.name,
            user?.rut,
            `${user?.dateOfBirth ?? ""}`.split("T")[0],
          ]}
        />
        <NavBar className="mt-20">
          <NavBarItem
            prefix={<DetailsOutlined className="flex" />}
            to="summary"
            content="Resumen"
          />
          <NavBarItem
            prefix={<TeamFilled className="flex" />}
            to="caregivers"
            content="Cuidadores"
          />
        </NavBar>
      </div>
      <div className="flex-1 o-hidden">
        {userId && (
          <Routes>
            <Route
              path="summary"
              element={<InstitutionStudentSummary userId={userId} />}
            />
            <Route
              path="caregivers"
              element={<InstitutionStudentCaregivers userId={userId} />}
            />
            <Route path="*" element={<Navigate replace to="summary" />} />
          </Routes>
        )}
      </div>
    </div>
  );
};
