import React from 'react';

export const InstitutionCourseStatistics: React.FC = () => {
	return (
		<div className='ant-fade-enter ant-fade-enter-active'>
			<h4>
				Estadísticas!.
			</h4>
		</div>
	);
};
