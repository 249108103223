import { NavBar, NavBarItem } from '@outlier-spa/component';
import {
  ChartFilled,
  PieChartFilled,
  CalendarMonthOutlined,
  CalendarSimpleOutlined,
} from '@outlier-spa/icon';
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ClassesWeek } from './week';
import { ClassesMonth } from './month';
import { ClassAttendance } from './attendance';
import { ClassCapacity } from './capacity';
import { ClassCreate } from './create';

const ClassesNavigator: React.FC = () => {
  return (
    <div className="inner ant-fade-enter ant-fade-enter-active h100">
      <div className="flex flex-column h100 o-hidden ">
        <h2 className="text-20 bold mb-16">Clases</h2>
        <NavBar>
          <NavBarItem
            to="/class/week"
            content={
              <>
                <CalendarSimpleOutlined className="mr-10 text-16" />
                <span>Resumen Semanal</span>
              </>
            }
          />
          <NavBarItem
            to="/class/month"
            content={
              <>
                <CalendarMonthOutlined className="mr-10 text-16" />
                <span>Resumen Mensual</span>
              </>
            }
          />
          <NavBarItem
            to="/class/attendance"
            content={
              <>
                <PieChartFilled className="mr-10 text-16" />
                <span>Asistencia por curso</span>
              </>
            }
          />
          <NavBarItem
            to="/class/capacity"
            content={
              <>
                <ChartFilled className="mr-10 text-16" />
                <span>Capacidad por curso</span>
              </>
            }
          />
        </NavBar>
        <div className="flex-1 o-hidden">
          <Routes>
            <Route path="week" element={<ClassesWeek />} />
            <Route path="month" element={<ClassesMonth />} />
            <Route path="attendance" element={<ClassAttendance />} />
            <Route path="capacity" element={<ClassCapacity />} />
            <Route path="*" element={<Navigate replace to="week" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export const Classes: React.FC = () => {
  return (
    <Routes>
      <Route path=":startDate/:scheduleId" element={<ClassCreate />} />
      <Route path="*" element={<ClassesNavigator />} />
    </Routes>
  );
};
