import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Card,
  Section,
  SectionHeader,
  Select,
} from '@outlier-spa/component';
import { useApi } from '@outlier-spa/fetch';
import { LoadingKiri } from 'components';
import institution from './assets/institution.svg';
import student from './assets/student.svg';
import teacher from './assets/teacher.svg';
import courses from './assets/courses.svg';
import { SchoolIcon } from 'components/icon';
import {
  AlertTriangleFilled,
  CalendarOutlined,
  UserFilled,
} from '@outlier-spa/icon';
import { ChartJson } from '@outlier-spa/chart';
import { LabeledValue } from 'interfaces';
import { ArrowScroll } from 'components/arrow-scroll';

function tooltipFn(name: string, value: number | null | undefined) {
  return {
    name,
    value: typeof value === 'number' ? `${Math.round(value * 100)}%` : '-',
  };
}
const tooltip = ['z*y', tooltipFn];

export const IncidentCard: React.FC<{ incident: any }> = ({ incident }) => {
  return (
    <Card
      className="pointer line-normal"
      style={{ minWidth: 350, maxWidth: 350 }}
    >
      <div className="pd-14 flex flex-center flex-space">
        <div className="flex flex-center flex-gap-8">
          <Avatar className="border-main flex flex-middle" size="26px">
            <AlertTriangleFilled className={'text-emphasis'} />
          </Avatar>
          <h5 className="text-16 bold">{incident.course}</h5>
        </div>
      </div>
      <div className="pdh-14" style={{ marginBottom: 14 }}>
        <div className="text-secondary flex flex-column flex-vgap-6">
          <div className="flex flex-center">
            <SchoolIcon />
            <p className="ml-6">{incident.institution}</p>
          </div>
          <div className="flex flex-center">
            <UserFilled />
            <p className="ml-6">{incident.monitor}</p>
          </div>
          <div className="flex flex-center">
            <CalendarOutlined />
            <p className="ml-6 text-secondary">{incident.dateTime} hrs</p>
          </div>
        </div>
      </div>
    </Card>
  );
};

interface IImpactItemProps {
  label: string;
  counter: number;
  image: string;
}

const ImpactItem: React.FC<IImpactItemProps> = ({ label, counter, image }) => {
  return (
    <>
      <div className="flex impact-item">
        <div
          className=" flex flex-column flex-middle"
          style={{ width: '220px', height: '196px' }}
        >
          <Card
            className="flex flex-middle"
            style={{
              width: '112px',
              height: '112px',
              borderRadius: '100px',
              boxShadow: 'none',
            }}
          >
            <img style={{ width: '50px', height: '50px' }} src={image} alt="" />
          </Card>

          <p className="text-emphasis text-26 bold pdv-6">{counter ?? '-'}</p>
          <p>{label}</p>
        </div>
      </div>
    </>
  );
};

export const Home = () => {
  const [getUrl, loadingRef] = useApi();
  const [dashboard, setDashboard] = useState<any>();
  const [chartData, setChartData] = useState<any>();
  const [communes, setCommunes] = useState<LabeledValue>();

  function onChangeCommune(name: any) {
    const city = dashboard?.attendanceByInstitutionChart.find(
      (item: any) => item.city === name
    );
    setChartData({
      city: city.city,
      chart: city.chart.data.map((item: any) => ({
        x: item.x,
        y: item.y,
        z: item.z,
        group: item.group,
      })),
    });
  }

  useEffect(() => {
    getUrl('home', ({ data }) => {
      console.log(data);
      setCommunes(
        data.attendanceByInstitutionChart.map((item: any, index: number) => ({
          label: item.city,
          value: item.city,
        }))
      );
      const city = data.attendanceByInstitutionChart[0];
      setChartData({
        city: city.city,
        chart: city.chart.data.map((item: any) => ({
          x: item.x,
          y: item.y,
          z: item.z,
          group: item.group,
        })),
      });
      data.attendanceByDisciplineChart = data.attendanceByDisciplineChart.data
        .map((item: any) => ({
          x: item.x,
          y: item.y,
          z: item.z,
          group: item.group,
        }))
        .sort((a: any, b: any) => a.x - b.x);
      setDashboard(data);
    });
  }, [getUrl]);

  return (
    <div className="h100 ant-fade-enter ant-fade-enter-active overflow-hidden-x">
      <div className="inner">
        <LoadingKiri ref={loadingRef} />
        <div>
          <div className="impact-section">
            <p className="text-20 bold">Nuestro Impacto</p>
            <div
              className="stats flex flex-middle flex-wrap mt-20"
              style={{ marginBottom: '50px' }}
            >
              <ImpactItem
                image={institution}
                counter={dashboard?.institutions}
                label="Colegios"
              ></ImpactItem>
              <ImpactItem
                image={teacher}
                counter={dashboard?.tutors}
                label="Docentes"
              ></ImpactItem>
              <ImpactItem
                image={student}
                counter={dashboard?.students}
                label="Estudiantes"
              ></ImpactItem>
              <ImpactItem
                image={courses}
                counter={dashboard?.courses}
                label="Cursos"
              ></ImpactItem>
            </div>
          </div>

          <div className="impact-section flex flex-column">
            <div className="impact-section flex flex-1 flex-column">
              <p className="text-20 bold">Incidentes Recientes</p>
            </div>
            <div
              className="flex flex-1 flex-wrap mt-20"
              style={{ marginBottom: '50px' }}
            >
              <div className="o-hidden">
                <ArrowScroll>
                  <div className="event-grid flex flex-center flex-gap-12">
                    {dashboard?.incidents.map((incident: any) => (
                      <IncidentCard
                        incident={incident}
                        key={incident.incidentId}
                      />
                    ))}
                  </div>
                </ArrowScroll>
              </div>
            </div>
          </div>

          <div className="impact-section flex o-hidden flex-column">
            <p className="text-20 bold">
              Asistencia anual por comuna e institución
            </p>
            <Section
              className="o-hidden flex-column flex-vgap-14 mt-20"
              style={{ marginBottom: 50 }}
            >
              <SectionHeader
                className="flex-space pdh-14 border-bottom-main"
                title={chartData?.city}
                suffix={
                  <div className="flex flex-center flex-gap-8">
                    <span className="bold">Comuna</span>
                    <Select
                      style={{ width: 200 }}
                      value={chartData?.city}
                      options={communes as any}
                      onChange={onChangeCommune}
                    />
                  </div>
                }
              />
              {chartData && (
                <ChartJson
                  padding={[10, 20, 50, 50]}
                  height={250}
                  menuVisible={false}
                  limitInPlot={false}
                  enableZoom={false}
                  animate
                  json={{
                    data: chartData?.chart,
                    axes: [
                      { name: 'x', type: 'linear' },
                      {
                        name: 'y',
                        type: 'linear',
                        formatter: (v: any) => `${v * 100}%`,
                        min: 0,
                        max: 1,
                      },
                    ],
                    geometries: [
                      {
                        name: 'line',
                        type: 'line',
                        size: 2,
                        color: 'group',
                        tooltip,
                      },
                      {
                        name: 'point',
                        shape: 'circle',
                        type: 'point',
                        size: 4,
                        color: 'group',
                      },
                    ],
                  }}
                />
              )}
            </Section>
          </div>

          <div className="impact-section">
            <p className="text-20 bold">Asistencia anual por disciplina</p>
            <Section
              className="o-hidden flex-column flex-vgap-14 mt-20"
              style={{ marginBottom: 50 }}
            >
              {dashboard?.attendanceByDisciplineChart && (
                <ChartJson
                  padding={[20, 20, 50, 50]}
                  height={250}
                  menuVisible={false}
                  limitInPlot={false}
                  enableZoom={false}
                  animate
                  json={{
                    data: dashboard?.attendanceByDisciplineChart,
                    axes: [
                      { name: 'x', type: 'linear' },
                      {
                        name: 'y',
                        type: 'linear',
                        formatter: (v: any) => `${v * 100}%`,
                        min: 0,
                        max: 1,
                      },
                    ],
                    geometries: [
                      {
                        name: 'line',
                        type: 'line',
                        size: 2,
                        color: 'group',
                        tooltip,
                      },
                      {
                        name: 'point',
                        shape: 'circle',
                        type: 'point',
                        size: 4,
                        color: 'group',
                      },
                    ],
                  }}
                />
              )}
            </Section>
          </div>
        </div>
      </div>
    </div>
  );
};
