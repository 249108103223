import React, { useContext } from 'react';
import { IInstitution } from '../interfaces';

export interface IInstitutionContext {
	institution?: IInstitution;
	setInstitution: (institution: IInstitution) => void;
};

const InstitutionContext = React.createContext<IInstitutionContext>({
	institution: undefined,
	setInstitution: () => {},
});

export const useInstitution = () => useContext(InstitutionContext);

export const InstitutionProvider: React.FC<IInstitutionContext> = ({ institution, setInstitution, children }) => {

	return (
		<InstitutionContext.Provider value={{ institution, setInstitution }}>
			{institution && children}
		</InstitutionContext.Provider>
	);
};
