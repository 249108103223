import React, { useEffect, useRef, useState } from 'react';
import { NavBar, NavBarItem, Popover, Confirm } from '@outlier-spa/component';
import {
  LeftOutlined,
  CalendarOutlined,
  DashboardFilled,
  BoxFilled,
  CourseFilled,
  RiskFilled,
  StudenFilled,
  OptionsOutlined,
  TeamUserFilled,
} from '@outlier-spa/icon';
import {
  Navigate,
  Route,
  Routes,
  Link,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { Header, LoadingKiri } from 'components';
import { InstitutionCourse } from '../course';
import { InstitutionStudents } from './students';
import { InstitutionStudent } from './student';
import { InstitutionTeam } from './team';
import { InstitutionDocuments } from './documents';
import { InstitutionEvents } from './events';
import {
  InstitutionProductList,
  InstitutionProductNew,
  InstitutionProductDetail,
} from './product';
import { InstitutionSummary } from './summary';
import {
  InstitutionCourseList,
  InstitutionCourseNew,
  InstitutionCourseUpdate,
} from './courses';
import { useTitle } from 'context';
import { InstitutionProvider, useInstitution } from 'context/institution';
import { useApi, axios } from '@outlier-spa/fetch';
import { IInstitution } from 'interfaces';
import { InstitutionGlobalStyle } from './style';
import { InstitutionModal } from 'components/modal/institution';
import { InstitutionIncidents } from './incidents';
import { FolderIcon, StatisticsIcon } from 'components/icon';

const Back = (
  <Link to="/institution" className="flex flex-center flex-gap-8 text-16">
    <LeftOutlined className="text-label" />
    <span className="bold">Atrás</span>
  </Link>
);

const NavBarItems = () => (
  <>
    <NavBarItem
      className="nav-summary"
      prefix={<DashboardFilled className="flex" />}
      to="summary"
      content="Resumen"
    />
    <NavBarItem
      className="nav-students"
      prefix={<StudenFilled className="flex" />}
      to="students"
      content="Alumnos"
    />
    <NavBarItem
      className="nav-team"
      prefix={<TeamUserFilled className="flex" />}
      to="team"
      content="Equipo"
    />
    {/* <NavBarItem
      className="nav-product"
      prefix={<BoxFilled className="flex" />}
      to="product"
      content="Insumos"
    /> */}
    <NavBarItem
      className="nav-courses"
      prefix={<CourseFilled className="flex" />}
      to="courses"
      content="Talleres"
    />
    <NavBarItem
      className="nav-risk"
      prefix={<RiskFilled className="flex" />}
      to="incidents"
      content="Incidentes"
    />
    <NavBarItem
      className="nav-documents disabled"
      prefix={<FolderIcon className="flex" />}
      to="documents"
      content="Documentos"
    />
    <NavBarItem
      className="nav-events disabled"
      prefix={<CalendarOutlined className="flex" />}
      to="events"
      content="Fechas y eventos"
    />
    <NavBarItem
      className="nav-statistic disabled"
      prefix={<StatisticsIcon className="flex" />}
      to="statistic"
      content="Estadísticas"
    />
  </>
);

export interface IInstitutionProps {}

export const InstitutionMain: React.FC<IInstitutionProps> = () => {
  const loadingRef = useRef<React.ElementRef<typeof LoadingKiri>>(null);
  const updateModalRef =
    useRef<React.ElementRef<typeof InstitutionModal>>(null);
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const { institution, setInstitution } = useInstitution();
  const navigate = useNavigate();
  useTitle(Back);

  function onSubmitInstitution(
    institution: IInstitution,
    prevInstitution?: IInstitution
  ) {
    if (!prevInstitution) return;
    loadingRef.current?.setActive(true);
    axios
      .put<IInstitution>('institution', institution)
      .then(({ data }) => {
        setInstitution(data);
        updateModalRef.current?.close();
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function handleRemove() {
    removeRef.current?.open(
      institution?.institutionId,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar esta institución?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  function onRemove() {
    if (!institution) return;
    loadingRef.current?.setActive(true);
    axios
      .delete(`/institution/${institution.institutionId}`)
      .then(() => {
        navigate('institution');
      })
      .catch(() => {
        loadingRef.current?.setActive(false);
        alert('No se pudo eliminar la institución.');
      });
  }

  return (
    <div className="inner h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        ref={removeRef}
        okText="Eliminar Institución"
        className="line-normal"
        onSubmit={onRemove}
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
      />
      <InstitutionGlobalStyle />
      <InstitutionModal ref={updateModalRef} onSubmit={onSubmitInstitution} />
      <div>
        <Header
          identifier={institution?.institutionId}
          title={institution?.name}
          email={institution?.email}
          phone={institution?.phoneNumber}
          address={institution?.address}
          onUpdate={() => updateModalRef.current?.open(institution)}
          onRemove={handleRemove}
        />
        <NavBar className="mt-20 flex-space institution-nav">
          <div className="flex flex-center">
            <NavBarItems />
          </div>
          <Popover
            trigger="hover"
            content={
              <NavBar className="responsive-institution-nav" mode="vertical">
                <NavBarItems />
              </NavBar>
            }
          >
            <div className="pd-12 hover-emphasis pointer responsive-more-screen">
              <OptionsOutlined className="text-16 flex" />
            </div>
          </Popover>
        </NavBar>
      </div>
      <div className="flex-1 o-hidden">
        <Routes>
          <Route path="summary" element={<InstitutionSummary />} />
          <Route path="product" element={<InstitutionProductList />} />
          <Route path="students" element={<InstitutionStudents />} />
          <Route path="team" element={<InstitutionTeam />} />
          <Route path="courses" element={<InstitutionCourseList />} />
          <Route path="incidents" element={<InstitutionIncidents />} />
          <Route path="documents" element={<InstitutionDocuments />} />
          <Route path="events" element={<InstitutionEvents />} />
          <Route path="*" element={<Navigate replace to="summary" />} />
        </Routes>
      </div>
    </div>
  );
};

export const Institution: React.FC<IInstitutionProps> = () => {
  const { institutionId } = useParams();
  const [getUrl, loadingRef] = useApi();
  const [institution, setInstitution] = useState<IInstitution>();

  useEffect(() => {
    if (institutionId === undefined || institutionId === null) return;
    getUrl<IInstitution>(`/institution/${institutionId}`, (result) => {
      setInstitution(result.data);
    });
  }, [institutionId, getUrl]);

  return (
    <>
      <LoadingKiri ref={loadingRef} />
      <InstitutionProvider
        institution={institution}
        setInstitution={setInstitution}
      >
        <Routes>
          <Route path="student/:userId/*" element={<InstitutionStudent />} />
          <Route path="product/:id/*" element={<InstitutionProductDetail />} />
          <Route path="product/new" element={<InstitutionProductNew />} />
          <Route path="course/new" element={<InstitutionCourseNew />} />
          <Route
            path="course/:courseId/update"
            element={<InstitutionCourseUpdate />}
          />
          <Route path="course/:courseId/*" element={<InstitutionCourse />} />
          <Route path="*" element={<InstitutionMain />} />
        </Routes>
      </InstitutionProvider>
    </>
  );
};
