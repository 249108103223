import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useApi, axios } from '@outlier-spa/fetch';
import {
  Routes,
  Route,
  Link,
  Navigate,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { Confirm, NavBar, NavBarItem } from '@outlier-spa/component';
import {
  DetailsOutlined,
  SchoolFilled,
  RefreshOutlined,
  LeftOutlined,
  RiskFilled,
  UserFilled,
} from '@outlier-spa/icon';
import { Header, LoadingKiri } from '../../../components';
import { InstitutionCourseDetail } from './detail';
import { InstititionCourseClasses } from './classes';
import { InstitutionCourseDocuments } from './documents';
import { InstitutionCourseStatistics } from './statistics';
import { useTitle } from 'context';
import { useInstitution } from 'context/institution';
import {
  ClassIcon,
  disciplineIcons,
  FolderIcon,
  StatisticsIcon,
} from 'components/icon';
import { ICourseDetailViewModel } from 'interfaces/course';

export const InstitutionCourse: React.FC = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const [course, setCourse] = useState<ICourseDetailViewModel>();
  const [getUrl, loadingRef] = useApi();
  const params = useParams();
  const { institution } = useInstitution();
  const navigate = useNavigate();
  const Back = useMemo(
    () => (
      <Link
        to={`/institution/${institution?.institutionId}/courses`}
        className="flex flex-center flex-gap-8 text-16"
      >
        <LeftOutlined className="text-label" />
        <span className="bold">Atrás</span>
      </Link>
    ),
    [institution?.institutionId]
  );
  useTitle(Back);

  function onRemoveCourse() {
    if (!course) return;
    loadingRef.current?.setActive(true);
    axios
      .delete(`/course/${course.courseId}`)
      .then(() => {
        navigate(`/institution/${institution?.institutionId}/courses`);
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function handleRemoveCourse() {
    removeRef.current?.open(
      null,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este taller?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  useEffect(() => {
    getUrl<ICourseDetailViewModel>(`/course/${params.courseId}`, ({ data }) => {
      console.log(data);
      setCourse(data);
    });
  }, [getUrl, params.courseId, params.version]);

  return (
    <div className="h100 flex flex-column ant-fade-enter ant-fade-enter-active o-hidden">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        okText="Eliminar Taller"
        className="line-normal"
        onSubmit={onRemoveCourse}
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
      />
      <div className="inner w100">
        <Header
          editable
          title={course?.name ?? '-'}
          onRemove={handleRemoveCourse}
          onUpdate={() =>
            navigate(
              `/institution/${institution?.institutionId}/course/${course?.courseId}/update`
            )
          }
          descriptions={[
            <div className="flex flex-center">
              <SchoolFilled className="text-12 mr-6" />
              <span>{institution?.name}</span>
            </div>,
            // <div className="flex flex-center">
            //   {disciplineIcons[`${course?.disciplineId}`]}
            //   <span>{course?.discipline}</span>
            // </div>,
            // <div className="flex flex-center">
            //   <UserFilled className="text-12 mr-6" />
            //   <span>{course?.tutor}</span>
            // </div>,
          ]}
        />
        <NavBar className="mt-10 bold">
          <NavBarItem
            prefix={<DetailsOutlined />}
            to="detail"
            content="Detalles"
          />
          <NavBarItem prefix={<ClassIcon />} to="classes" content="Clases" />
          <NavBarItem
            prefix={<FolderIcon />}
            to="documents"
            content="Documentos"
          />
          <NavBarItem
            prefix={<StatisticsIcon />}
            to="statistics"
            content="Estadísticas"
          />
        </NavBar>
      </div>
      <div className="flex-1 overflow-hidden-x">
        <div className="inner">
          <div className="pdv-10" />
          {course && (
            <Routes>
              <Route
                path="detail"
                element={<InstitutionCourseDetail course={course} />}
              />
              <Route
                path="classes"
                element={<InstititionCourseClasses course={course} />}
              />
              <Route
                path="documents"
                element={<InstitutionCourseDocuments />}
              />
              <Route
                path="statistics"
                element={<InstitutionCourseStatistics />}
              />
              <Route path="*" element={<Navigate replace to="detail" />} />
            </Routes>
          )}
        </div>
      </div>
    </div>
  );
};
