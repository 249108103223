import {
  Button,
  Card,
  Confirm,
  NavBar,
  NavBarItem,
  NotFound,
  Select,
  Table,
} from '@outlier-spa/component';
import { axios, useApi } from '@outlier-spa/fetch';
import {
  CalendarMonthOutlined,
  CredentialFilled,
  LeftOutlined,
  RiskFilled,
  TrashcanFilled,
} from '@outlier-spa/icon';
import { Header, LoadingKiri, UserModal } from 'components';
import { IPopoverOptionsProps } from 'components/options';
import { useTitle } from 'context';
import { useDiscipline, useRole } from 'hooks';
import { IDisciplineRole } from 'interfaces';
import { ITeamListRoleViewModel, ITeamListViewModel } from 'interfaces/team';
import { IUserViewModel } from 'interfaces/user';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ClassesWeek } from './classes';

const Remove = ({ handlers, row }: IPopoverOptionsProps) => {
  return (
    <TrashcanFilled
      onClick={() => handlers?.onRemove(row)}
      className="flex text-secondary pointer text-16 hover-danger"
    />
  );
};

const headers: any = [
  {
    key: 'role',
    value: 'Rol',
    sortable: true,
    cellComponent: (obj: any) => obj?.value ?? '-',
  },
  {
    key: 'discipline',
    value: 'Disciplina',
    sortable: true,
    cellComponent: (obj: any) => obj?.value ?? '-',
  },
  { key: 'disciplineUserRoleId', value: '', cellComponent: Remove, width: 50 },
];

interface ITeamUserDetailProps {
  teamUser?: ITeamListViewModel;
}

const TeamUserDetail: React.FC<ITeamUserDetailProps> = ({ teamUser }) => {
  const disciplines = useDiscipline();
  const roles = useRole();
  const [, loadingRef] = useApi();
  const confirmRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);

  const [disciplineRoles, setDisciplineRoles] = useState<ITeamListRoleViewModel[]>(
    teamUser?.roles ?? []
  );
  const [userRole, setUserRole] = useState<IDisciplineRole>({
    userId: teamUser?.userId,
  } as IDisciplineRole);

  const addItemToDisciplineRoles = useCallback(
    (item: IDisciplineRole) => {
      console.log(teamUser);
      console.log(item);
      var exists = disciplineRoles.find(
        x => x.roleId === item.roleId && x.disciplineId === item.disciplineId
      );
      if (!exists)
        setDisciplineRoles(prev => [
          ...prev,
          {
            roleId: item.roleId,
            disciplineId: item.disciplineId,
            role: item.role!.name,
            discipline: item.discipline?.name,
          },
        ]);
      setUserRole({ userId: teamUser?.userId } as IDisciplineRole);
    },
    [disciplineRoles, teamUser]
  );

  useEffect(() => {
    // if (teamUser?.admin) {
    //   var opt = roles.find((role) => role.label === 'Administrador');
    //   if (opt) {
    //     const roleId = parseInt(opt.value.toString());
    //     const name = opt.label;
    //     var temp = {
    //       disciplineUserRoleId: -1,
    //       userId: teamUser.userId,
    //       roleId,
    //       role: { roleId, name },
    //     };
    //     addItemToDisciplineRoles(temp as IDisciplineRole);
    //   }
    // }
  }, [roles, teamUser]);

  function handleRemoveRole(disciplineRole: any) {
    if (!disciplineRole || !userRole?.userId) return;
    loadingRef.current?.setActive(true);
    axios
      .delete(
        `/team/${userRole.userId}/${disciplineRole.roleId.value}/${
          disciplineRole.disciplineId.value ?? ''
        }`
      )
      .then(() => {
        setDisciplineRoles(
          disciplineRoles.filter(
            role =>
              !(
                role.roleId === disciplineRole.roleId.value &&
                role.disciplineId === disciplineRole.disciplineId.value
              )
          )
        );
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function onRemoveRow(row: any) {
    console.log(row);
    const { disciplineId, roleId, discipline, role } = row;
    const disciplineRole = {
      discipline,
      role,
      disciplineId,
      roleId,
    };
    if (!disciplineRole) return;
    // setUserRole(disciplineRole);
    removeRef.current?.open(
      disciplineRole,
      `¿Desea eliminar el rol de ${disciplineRole.role.value}${
        disciplineRole.discipline.value
          ? ` para la disciplina ${disciplineRole.discipline.value}`
          : ''
      }?`
    );
  }

  function handleAddRole() {
    if (!userRole?.userId) return;

    loadingRef.current?.setActive(true);
    axios
      .post(`team/${teamUser?.userId}/${userRole?.roleId}/${userRole?.disciplineId ?? ''}`)
      .then(({ data: disciplineUserRoleId }) => {
        console.log(disciplineUserRoleId, 'data');
        if (disciplineUserRoleId)
          addItemToDisciplineRoles({
            ...userRole,
            disciplineUserRoleId,
          } as IDisciplineRole);
        else confirmRef.current?.open(true, 'No se pudo agregar el rol');
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function handleRoleChange(_: any, opt: any) {
    const roleId = parseInt(opt.value.toString());
    const name = opt.label;
    var temp = { ...userRole, roleId, role: { roleId, name } };

    if (name.toUpperCase() === 'ADMINISTRADOR')
      temp = { ...temp, disciplineId: undefined, discipline: undefined };

    setUserRole(temp as IDisciplineRole);
  }

  function handleDisciplineChange(_: any, opt: any) {
    const disciplineId = parseInt(opt.value.toString());
    const name = opt.label;
    setUserRole(
      prev =>
        ({
          ...prev,
          disciplineId,
          discipline: { disciplineId, name },
        } as IDisciplineRole)
    );
  }

  const CheckAvailableRoles = useCallback(
    (userRole: IDisciplineRole, disciplineRoles: ITeamListRoleViewModel[]) => {
      if (!userRole?.roleId) return true;
      if (userRole?.role?.name?.toUpperCase() === 'ADMINISTRADOR') return true;
      if (!userRole?.disciplineId) return true;

      const exists = disciplineRoles.find(
        x => x.roleId === userRole?.roleId && x.disciplineId === userRole?.disciplineId
      );
      return Boolean(exists);
    },
    []
  );

  return (
    <div className="h100 o-hidden flex flex-column pdt-10">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        ref={confirmRef}
        title="Error de ingreso"
        cancelProps={{ visible: false }}
        okProps={{ type: 'emphasis' }}
        okText="Aceptar"
      />
      <Confirm
        ref={removeRef}
        title={
          <>
            <RiskFilled />
            <span>Aviso</span>
          </>
        }
        onSubmit={handleRemoveRole}
      />

      <div className="flex flex-center flex-gap-14 ant-fade-enter ant-fade-enter-active">
        <Select
          optionFilterProp="label"
          value={userRole?.roleId?.toString() as any}
          options={roles}
          label="Rol"
          type="mainAlt"
          style={{ width: 190 }}
          onChange={handleRoleChange}
        />
        <Select
          optionFilterProp="label"
          disabled={userRole?.role?.name?.toUpperCase() === 'ADMINISTRADOR'}
          value={userRole?.disciplineId?.toString() as any}
          options={disciplines}
          label="Disciplina"
          type="mainAlt"
          style={{ width: 140 }}
          onChange={handleDisciplineChange}
        />
        <div className="pdt-16 mt-6">
          <Button
            disabled={
              !userRole?.userId ||
              !userRole?.roleId ||
              (userRole?.role?.name?.toUpperCase() !== 'ADMINISTRADOR' &&
                !userRole?.disciplineId) ||
              CheckAvailableRoles(userRole, disciplineRoles)
            }
            label="Agregar a la tabla"
            type="assertive"
            className="pdh-20"
            bold
            onClick={handleAddRole}
          />
        </div>
      </div>
      <div className="flex-1 o-hidden mt-14">
        <Card className="relative overflow-hidden-x" style={{ maxHeight: '100%' }}>
          <Table
            bordered={false}
            fixed
            hoverable
            fixedHeaders
            headers={headers}
            handlers={{
              onRemove: onRemoveRow,
            }}
            data={disciplineRoles}
          >
            {disciplineRoles.length === 0 && (
              <tbody>
                <tr>
                  <td colSpan={headers.length} className="text-right">
                    <div className="relative" style={{ height: 400 }}>
                      <NotFound content="Aún no existen roles asociados a este usuario. Utilice la sección superior para añadir nuevos roles." />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </Card>
      </div>
    </div>
  );
};

const Back = (
  <Link to="/team" className="flex flex-center flex-gap-8 text-16">
    <LeftOutlined className="text-label" />
    <span className="bold">Atrás</span>
  </Link>
);

interface ITeamUserProps {}

export const TeamUser: React.FC<ITeamUserProps> = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const userModalRef = useRef<React.ElementRef<typeof UserModal>>(null);
  const { userId } = useParams();
  const [getUrl, loadingRef] = useApi();
  const [teamUser, setTeamUser] = useState<ITeamListViewModel>();
  const navigate = useNavigate();
  useTitle(Back);

  function handleRemove() {
    removeRef.current?.open(
      1,
      <>
        <p className="bold mb-10">¿Está seguro que desea eliminar este integrante?</p>
        <p>El item que desea eliminar contiene datos y no podrán ser recuperados.</p>
      </>
    );
  }

  function handleUpdate() {
    userModalRef?.current?.open(teamUser);
  }

  function onUpdateUser(user: IUserViewModel) {
    if (!teamUser) return;
    loadingRef.current?.setActive(true);
    axios
      .put<IUserViewModel>('/user', user)
      .then(({ data }) => {
        setTeamUser({ ...teamUser, ...data });
        userModalRef.current?.close();
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function onRemove() {
    loadingRef.current?.setActive(true);
    axios
      .delete(`team/${userId}`)
      .then(() => {
        navigate(`/team`);
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  useEffect(() => {
    getUrl<ITeamListViewModel>(`/user/${userId}`, ({ data }) => {
      console.log(data);
      setTeamUser(data);
    });
  }, [userId, getUrl]);

  return (
    <div className="h100 ant-fade-enter ant-fade-enter-active flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <UserModal
        ref={userModalRef}
        title="Editar Usuario"
        okText="Guardar cambios"
        onSubmit={onUpdateUser}
      />
      <Confirm
        okText="Eliminar Integrante"
        className="line-normal"
        onSubmit={onRemove}
        title={
          <div className="flex flex-center flex-gap-6 ">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
      />
      <div className="inner w100">
        <Header
          title={`${teamUser?.firstName} ${teamUser?.lastName}`}
          onRemove={handleRemove}
          onUpdate={handleUpdate}
          email={teamUser?.email}
          phone={(teamUser as any)?.phoneNumber}
          address={teamUser?.address}
          descriptions={[teamUser?.rut, moment(teamUser?.dateOfBirth)?.format('DD-MM-YYYY')]}
        />
        <NavBar className="mt-20">
          <NavBarItem prefix={<CredentialFilled className="flex" />} to="details" content="Roles" />
          <NavBarItem
            prefix={<CalendarMonthOutlined className="flex" />}
            to="classes"
            content="Resumen Clases"
          />
        </NavBar>
      </div>
      <div className="inner w100 overflow-hidden-x pdv-10 flex-1">
        {teamUser && (
          <Routes>
            <Route path="details" element={<TeamUserDetail teamUser={teamUser} />} />
            <Route path="classes">
              <Route index element={<ClassesWeek />} />
              <Route path=":date" element={<ClassesWeek />} />
            </Route>
            <Route path="" element={<Navigate replace to="details" />} />
          </Routes>
        )}
      </div>
    </div>
  );
};
