import styled from "styled-components";

export const StyledLayout = styled.div`
  .user-logged {
    border: 1px solid rgba(255, 255, 255, 0.4);
  }
  .dock-stage {
    border-radius: 6px;

    a {
      border: 1px solid ${({ theme }) => theme.border.secondary};
      &.active {
        border-color: ${({ theme }) => theme.colors.emphasis};
      }
    }

    &:hover {
      & a:not(.active) {
        opacity: 1;
        filter: grayscale(0);
      }
    }
    & a:not(.active) {
      opacity: 0.5;
      filter: grayscale(1);
    }
  }
  .inner {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  header {
    height: 4.286rem;
    background-color: ${({ theme }) => theme.colors.emphasis};
    .ant-input-affix-wrapper {
      border-radius: 18px;
    }
    .sidenav-handler {
      width: 180px;
    }
  }
  .layout-sidenav {
    .nav-bar-item.active {
      background-color: #ffffff2b;
      color: #fff;
      font-weight: 600;
    }
    .nav-bar-item:not(.active):hover {
      &,
      .icon {
        color: #fff;
      }
    }
    .sidenav-content {
      background-color: #f65275;
    }
    .collapse-title {
      padding: 2px 0;
      color: #fff;
      a {
        padding-left: 1rem;
        width: 100%;
      }
      strong {
        padding-left: 1rem;
      }
      &:hover {
        color: #fff;
        background-color: #ffffff1a;
      }
    }
  }
  /* .layout-content {
    margin-left: 200px;
  } */
  .table-row-large {
    td {
      height: 56px;
    }
  }
  &.is-responsive .layout-content {
    margin-left: 0;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }
  .gap-10 {
    align-content: space-between;
    margin-bottom: 10px;
  }
  .gap-14 {
    align-content: space-between;
    margin-bottom: 10px;
  }
  .border-right {
    border-right: 1px solid ${({ theme }) => theme.border.main};
  }
`;
