import { Avatar, DatePicker, Input, Modal, Select } from '@outlier-spa/component';
import { CameraFilled } from '@outlier-spa/icon';
import { useCity, useUserByRut } from 'hooks';
import { IUserViewModel } from 'interfaces/user';
import moment from 'moment';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface IUserModalRef {
  open: (user?: IUserViewModel) => void;
  close: () => void;
}

export interface IUserModalProps {
  onSubmit: (data: IUserViewModel, user?: IUserViewModel) => void;
  okText: string;
  title: string;
}

export const UserModal = React.forwardRef<IUserModalRef, IUserModalProps>(
  ({ onSubmit, okText, title }, ref) => {
    const modalRef = useRef<React.ElementRef<typeof Modal>>(null);
    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm();
    const { onChangeRut } = useUserByRut();
    const { regionProps, provinceProps, cityProps, cityId, onChangeCity } = useCity();
    const [user, setUser] = useState<IUserViewModel>();

    function open(user?: IUserViewModel) {
      setUser(user);
      if (user?.dateOfBirth) user.dateOfBirth = moment(user.dateOfBirth).format('YYYY-MM-DD');
      if (user?.cityId) onChangeCity(user.cityId);
      reset(user);
      modalRef.current?.open();
    }

    function close() {
      modalRef.current?.close();
    }

    function onSubmitData(data: any) {
      if (data?.dateOfBirth) data.dateOfBirth = data.dateOfBirth.format('YYYY-MM-DD');
      if (cityId) data.cityId = cityId;
      onSubmit(data, user);
    }

    function updateUser(user?: IUserViewModel | null) {
      console.log(user);
      if (!user) return;
      if (user.dateOfBirth) user.dateOfBirth = moment(user.dateOfBirth) as any;
      if (user.cityId) onChangeCity(user.cityId);
      reset(user);
    }

    useImperativeHandle(ref, () => ({ open, close }));

    return (
      <Modal
        ref={modalRef}
        okText={user?.userId ? 'Actualizar' : okText}
        okProps={{ type: 'assertive' }}
        title={user?.userId ? 'Actualizar' : title}
        size="small"
        overlayClose={false}
        onSubmit={handleSubmit(onSubmitData)}
      >
        <div className="flex flex-middle pdv-20">
          <Avatar className="avatar flex flex-middle o-hidden" size="80px" email={user?.email}>
            <CameraFilled className="text-20 text-secondary" />
          </Avatar>
        </div>
        <div className="pdh-14 flex flex-column flex-vgap-14 pdb-16">
          <div className="flex flex-center flex-gap-20">
            <Controller
              control={control}
              name="rut"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  disabled={!!(user && user.userId)}
                  type="secondary"
                  placeholder="Ingrese rut"
                  className="flex-1"
                  validation={{
                    message: 'El rut es requerido',
                    visible: Boolean(errors.rut),
                  }}
                  label="Rut"
                  {...field}
                  onBlur={evt => {
                    field.onBlur();
                    onChangeRut(evt.target.value, updateUser);
                  }}
                />
              )}
            />
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="flex-1"
                  type="secondary"
                  placeholder="Ingrese nombres"
                  label="Nombres"
                  validation={{
                    message: 'El nombre es requerido',
                    visible: Boolean(errors.firstName),
                  }}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex flex-center flex-gap-20">
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="flex-1"
                  label="Apellidos"
                  type="secondary"
                  validation={{
                    message: 'El apellido es requerido',
                    visible: Boolean(errors.lastName),
                  }}
                  placeholder="Ingrese apellidos"
                  {...field}
                />
              )}
            />
            <div className="flex-1">
              <label className="flex mb-10">Fecha de nacimiento</label>
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    type="secondary"
                    style={{ width: '100%' }}
                    value={field.value}
                    onChange={value => field.onChange(value)}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-center flex-gap-20">
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <Input
                  className="flex-1"
                  type="secondary"
                  placeholder="Ingrese teléfono"
                  label="Teléfono"
                  style={{ width: '100%' }}
                  {...field}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  disabled={!!(user && user.userId)}
                  type="secondary"
                  placeholder="Ingrese email"
                  className="flex-1"
                  validation={{
                    message: 'El email es requerido',
                    visible: Boolean(errors.email),
                  }}
                  label="Email"
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex flex-center flex-gap-20">
            <Select
              optionFilterProp="label"
              type="secondary"
              style={{ width: '100%' }}
              className="flex-1"
              label="Region"
              {...regionProps}
            />
            <Select
              optionFilterProp="label"
              type="secondary"
              style={{ width: '100%' }}
              className="flex-1"
              label="Provincia"
              {...provinceProps}
            />
          </div>
          <div className="flex flex-center flex-gap-20">
            <Select
              optionFilterProp="label"
              type="secondary"
              style={{ width: '100%' }}
              className="flex-1"
              label="Ciudad"
              {...cityProps}
            />
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <Input
                  className="flex-1"
                  type="secondary"
                  placeholder="Ingrese dirección"
                  label="Dirección"
                  {...field}
                />
              )}
            />
          </div>
        </div>
      </Modal>
    );
  }
);
