export class NumberHelpers {
  /**
   * Number.prototype.format(n, x, s, c)
   *
   * @param integer n: length of decimal
   * @param integer x: length of whole part
   * @param mixed   s: sections delimiter
   * @param mixed   c: decimal delimiter
   */
  static currencyFormat = function ({
    number,
    n,
    wholePart,
    sectionDelimiter = '.',
    decimalDelimiter = ',',
    prefix = '',
  }: {
    number: number;
    n: number;
    wholePart: number;
    sectionDelimiter?: string;
    decimalDelimiter?: string;
    prefix?: string;
  }) {
    var re =
        '\\d(?=(\\d{' + (wholePart || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
      num = number.toFixed(Math.max(0, ~~n));

    return (
      prefix +
      (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(
        new RegExp(re, 'g'),
        '$&' + (sectionDelimiter || ',')
      )
    );
  };
}
