import React, { useEffect, useRef, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate,
} from 'react-router-dom';
import {
  NavBar,
  NavBarItem,
  SectionHeader,
  TextArea,
  Tag,
  Popover,
  List,
  ListItem,
  Confirm,
  Input,
  Section,
} from '@outlier-spa/component';
import {
  EditFilled,
  DetailsOutlined,
  CalendarOutlined,
  LeftOutlined,
  FileFilled,
  RefreshOutlined,
  RiskFilled,
  BoxFilled,
} from '@outlier-spa/icon';
import { Header, LoadingKiri } from '../../../components';
import { useTitle } from 'context';
import { Link } from 'react-router-dom';
import { axios, useApi } from '@outlier-spa/fetch';
import { SessionCard } from 'components';
import { IFile, IProgram } from 'interfaces';
import { ClassIcon, disciplineIcons } from 'components/icon';

interface IProgramDetailProps {
  program: IProgram;
}

const ProgramDetail: React.FC<IProgramDetailProps> = ({ program }) => {
  const sessionCount = program.sessions?.reduce(
    (acc, session) => acc + (session.classes ?? 0),
    0
  );
  return (
    <div className="pdv-10">
      <SectionHeader
        size="small"
        className="mb-10"
        prefix={<DetailsOutlined className="text-emphasis text-18" />}
        title="Datos principales"
      />

      <div className="flex flex-gap-14 pdb-20">
        <Input
          label="Disciplina"
          disabled
          type="mainAlt"
          style={{ width: 160 }}
          value={program.discipline?.name}
        />
        <div>
          <p>Niveles</p>
          <div className="flex flex-center flex-gap-10 flex-wrap">
            {/* <Select optionFilterProp='label' disabled type='mainAlt' style={{width: 160}} /> */}
            {program.programLevels?.length === 0 && '-'}
            {program.programLevels?.map((level) => (
              <Tag
                className="mt-10"
                key={level.levelId}
                label={level.level?.name}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mb-20">
        <TextArea
          label="Descripción"
          value={program.description}
          disabled
          className="mb-20"
          type="mainAlt"
          rows={4}
        />
        <p className="mb-10">Documentos asociados</p>
        <div className="flex flex-center flex-gap-10">
          {program.programFiles?.length === 0 && (
            <p className="text-secondary mt-10">
              Este programa no tiene ningún documento asociado.
            </p>
          )}
          {program.programFiles?.map((programFile) => (
            <Tag
              key={programFile.fileId}
              type="secondary"
              label={
                <>
                  <FileFilled className="text-warning" />
                  <span>{programFile.file?.name}</span>
                </>
              }
            />
          ))}
        </div>
      </div>

      <SectionHeader
        size="small"
        className="mb-10 mt-20 border-top-main"
        prefix={<BoxFilled className="text-emphasis text-18" />}
        title="Insumos"
      />

      <div className="pdb-20 mb-20">
        <p className="mb-10">Productos</p>
        <div className="flex flex-center flex-gap-10">
          {/* <Select optionFilterProp='label' disabled type='mainAlt' style={{width: 160}} /> */}
          {program.programProducts?.length === 0 && '-'}
          {program.programProducts?.map((programProduct) => (
            <Tag
              key={programProduct.productId}
              label={programProduct.product?.name}
            />
          ))}
        </div>
      </div>

      <SectionHeader
        size="small"
        className="mb-10 border-top-main"
        prefix={<ClassIcon className="text-emphasis text-18" />}
        title="Clases"
      />

      <div className="pdb-20 mb-20">
        <Section bordered={false} className="flex flex-gap-14 pdh-20 pdv-16">
          <div style={{ width: 200 }} className="border-right">
            <p className="text-secondary mb-16">Clases con sesiones</p>
            <h5 className="text-20">{sessionCount}</h5>
          </div>
          <div style={{ width: 200 }} className="border-right">
            <p className="text-secondary mb-16">Clases sin sesiones</p>
            <h5 className="text-20">
              {(program.classes ?? 0) - (sessionCount ?? 0)}
            </h5>
          </div>
          <div style={{ width: 200 }}>
            <p className="text-secondary mb-16">Total clases</p>
            <h5 className="text-20 text-emphasis">{program.classes ?? '0'}</h5>
          </div>
        </Section>
      </div>

      <SectionHeader
        size="small"
        className="mb-10 border-top-main"
        prefix={<CalendarOutlined className="text-emphasis text-18" />}
        title="Sesiones"
      />

      <div className="flex flex-column flex-vgap-14">
        {program.sessions?.length === 0 && (
          <div className="text-secondary">
            Este programa no tiene ninguna sesión asociada.
          </div>
        )}
        {program.sessions?.map((session) => (
          <SessionCard
            key={session.sessionId}
            session={session}
            files={
              (session.sessionFiles?.map((sf) => sf.file) as IFile[]) ?? []
            }
          />
        ))}
      </div>
    </div>
  );
};

const Back = (
  <Link to="/program" className="flex flex-center flex-gap-8 text-16">
    <LeftOutlined className="text-label" />
    <span className="bold">Atrás</span>
  </Link>
);

export interface IProgramProps {}

export const Program: React.FC<IProgramProps> = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const popoverRef = useRef<React.ElementRef<typeof Popover>>(null);
  const { programId, version } = useParams();
  const [getUrl, loadingRef] = useApi();
  const [programVersions, setProgramVersions] = useState<{
    program: IProgram;
    versions: number[];
  }>();
  const navigate = useNavigate();
  useTitle(Back);

  function handleRemove() {
    removeRef.current?.open(
      programId,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este programa?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  function onRemove() {
    if (!programId) return;
    loadingRef.current?.setActive(true);
    axios
      .delete(`/program/${programId}`)
      .then(() => {
        navigate('/program');
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function onUpdate() {
    navigate(`/program/${programId}/${version}/edit`);
  }

  function onVersionClick(newVersion: number) {
    if (`${newVersion}` !== version) {
      navigate(`/program/${programId}/${newVersion}`);
    }
    popoverRef.current?.setOpen(false);
  }

  useEffect(() => {
    getUrl<{ program: IProgram; versions: number[] }>(
      `/program/${programId}/${version}`,
      ({ data }) => {
        console.log(data);
        setProgramVersions(data);
      }
    );
  }, [programId, version, getUrl]);

  return (
    <div className="ant-fade-enter ant-fade-enter-active h100 o-hidden flex flex-column">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        okText="Eliminar Programa"
        className="line-normal"
        onSubmit={onRemove}
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
      />
      <div className="inner w100">
        <Header
          title={programVersions?.program?.discipline?.name}
          onUpdate={onUpdate}
          onRemove={handleRemove}
          descriptions={[
            <div className="flex flex-center">
              {
                disciplineIcons[
                  programVersions?.program?.discipline?.disciplineId ?? '1'
                ]
              }
              {programVersions?.program?.discipline?.name}
            </div>,
            <div className="flex flex-center">
              <RefreshOutlined className="mr-8 text-12" />
              <span>{programVersions?.program?.sessions?.length} sesiones</span>
            </div>,
            <Popover
              ref={popoverRef}
              content={
                <List hoverable>
                  {programVersions?.versions?.map((v) => (
                    <ListItem
                      onClick={onVersionClick}
                      key={v}
                      item={v}
                      value={`Versión ${v}`}
                    />
                  ))}
                </List>
              }
            >
              <div className="pointer hover-emphasis t200">
                <RefreshOutlined className="mr-8 text-12" />
                <span>Vesión {programVersions?.program?.version}</span>
              </div>
            </Popover>,
          ]}
        />
        <NavBar className="mt-20">
          <NavBarItem
            prefix={<EditFilled className="flex" />}
            to="details"
            content="Detalles"
          />
        </NavBar>
      </div>
      <div className="inner w100 overflow-hidden-x pdv-10 flex-1">
        {programVersions?.program && (
          <Routes>
            <Route
              path="details"
              element={<ProgramDetail program={programVersions?.program} />}
            />
            <Route path="*" element={<Navigate replace to="details" />} />
          </Routes>
        )}
      </div>
    </div>
  );
};
