import React, { useEffect, useState } from "react";
import { Button } from "@outlier-spa/component";
import { AddOutlined, XOutlined } from "@outlier-spa/icon";
import { ImageHelper } from "./ImageHelper";
import { StyledFilePicker } from "./style";
import { useTheme } from "styled-components";

const PhotoPlaceholderIcon: React.FC = () => {
	const theme = useTheme();
	return (
		<svg width="50" height="36" viewBox="0 0 50 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.76471 0C0.790086 0 0 0.951776 0 2.12585V33.305C0 34.4791 0.790086 35.4308 1.76471 35.4308H48.2353C49.2099 35.4308 50 34.4791 50 33.305V2.12585C50 0.951776 49.2099 0 48.2353 0C29.0289 0 20.0313 0 1.76471 0Z" fill={theme.background.secondary}/>
			<path d="M27.8453 18.7973L23.5723 22.4547V33.288C23.5723 34.4715 24.5464 35.4309 25.7481 35.4309H48.5503C49.3514 35.4309 50.0008 34.7913 50.0008 34.0023V30.8048L36.4862 18.8644C34.0353 16.699 30.3306 16.6702 27.8453 18.7973Z" fill={theme.tag.main}/>
			<path d="M8.55874 15.5609L0 22.9309V33.288C0 34.4715 0.959389 35.4309 2.14286 35.4309H38.9286L17.0648 15.6637C14.6608 13.4901 11.0146 13.4461 8.55874 15.5609Z" fill={theme.border.main}/>
			<circle cx="28.9277" cy="9.72705" r="2.5" fill={theme.border.secondary} />
		</svg>
	)
};

export interface IPhotoProps {
  title?: string;
  enableEdition?: boolean;
  url?: string;
  className?: string;
  style?: React.CSSProperties;
  onChange?: (file: File | undefined) => void;
}

export const ClassPhoto: React.FC<IPhotoProps> = ({ title, url, enableEdition = true, onChange, ...rest }) => {
  const [srcPhoto, setSrcPhoto] = useState<string | undefined>(undefined);

  const handleDeletePhoto = (event: any) => {
    setSrcPhoto(undefined);
    onChange?.(undefined);
    event.preventDefault();
  };

  const handleChangePhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      ImageHelper.resizeImage(file, ImageHelper.Dimensions["640x480"], (blob: Blob, resized: boolean) => {
        var newFile = ImageHelper.toFile(blob, file.name, file.lastModified);
        setSrcPhoto(URL.createObjectURL(newFile));
        onChange?.(newFile);
        event.target.value = "";
      });
    }
  };

  useEffect(() => setSrcPhoto(url ?? undefined), [url]);

  return (
    <StyledFilePicker {...rest}>
      {title && <h1 className="bold mb-14">{title}</h1>}
      <label>
        <div className="photo-picker__inner flex flex-middle pointer">
          {srcPhoto ? <img style={{ maxWidth: "100%", maxHeight: "100%" }} alt="preview" src={srcPhoto} /> : <PhotoPlaceholderIcon />}
          {enableEdition && (
            <>
              <input style={{ display: "none" }} accept="image/.jpg, .jpeg, .png" type="file" onChange={handleChangePhoto} />
              {srcPhoto ? (
                <Button circle type="main" className="text-16 bold" label={<XOutlined className="text-emphasis" />} onClick={handleDeletePhoto} />
              ) : (
                <Button style={{ pointerEvents: "none" }} circle type="emphasis" className="text-16 bold" label={<AddOutlined />} />
              )}
            </>
          )}
        </div>
      </label>
    </StyledFilePicker>
  );
};
