import React, { useRef } from "react";
import {
  SectionHeader,
  Input,
  Tag,
  Table,
  Confirm,
  TextArea,
} from "@outlier-spa/component";
import { Link } from "react-router-dom";
import {
  DetailsOutlined,
  DownloadPackageOutlined,
  LeftOutlined,
  FileFilled,
  RiskFilled,
} from "@outlier-spa/icon";
import { Header } from "components";
import { useTitle } from "context";
import { SchoolIcon } from "components/icon";

const headers: any = [
  { key: "code", value: "Código", sortable: true },
  { key: "buy", value: "Compra", sortable: true },
  { key: "invoice", value: "N° de factura" },
  { key: "condition", value: "Condición", sortable: true },
  { key: "registeredAt", value: "Registro" },
];

const Back = (
  <Link
    to="/institution/1/product"
    className="flex flex-center flex-gap-8 text-16"
  >
    <LeftOutlined className="text-label" />
    <span className="bold">Atrás</span>
  </Link>
);

const data = Array.from({ length: 10 }, () => ({
  code: "JBLT5101",
  buy: "dd/mm/yyyy",
  invoice: "-----",
  condition: "Bueno",
  registeredAt: "dd/mm/yyyy",
}));

export interface IInstitutionProductDetailProps {}

export const InstitutionProductDetail: React.FC<
  IInstitutionProductDetailProps
> = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  useTitle(Back);

  function handleRemove() {
    removeRef.current?.open(
      null,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este producto?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  return (
    <div className="inner ant-fade-enter ant-fade-enter-active line-normal">
      <Confirm
        okText="Eliminar Producto"
        className="line-normal"
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
      />
      <Header
        title="Audífonos JBL"
        onRemove={handleRemove}
        descriptions={[
          <div className="flex flex-center">
            <SchoolIcon className="mr-6" />
            <span>Escuela Pablo de Rocka</span>
          </div>,
        ]}
      />

      <SectionHeader
        size="small"
        className="mv-10 border-top-main"
        prefix={<DetailsOutlined className="text-emphasis" />}
        title="Datos principales"
      />

      <div className="flex flex-center flex-gap-14">
        <Input
          disabled
          defaultValue="Linio Chile"
          label="Proveedor"
          type="mainAlt"
          style={{ width: 300 }}
        />
        <Input
          disabled
          defaultValue="Audífonos JBL"
          label="Nombre"
          type="mainAlt"
          style={{ width: 300 }}
        />
        <div>
          <p className="mb-10">Disciplinas</p>
          <div className="flex flex-center flex-gap-10">
            <Tag label="Tenis" />
            <Tag label="Skate" />
            <Tag label="Coro" />
          </div>
        </div>
      </div>

      <div className="mt-20 mb">
        <TextArea
          defaultValue="Odio eget nulla interdum integer id. Neque cras ullamcorper cras tellus risus aenean mauris sed."
          disabled
          className="mb-20"
          label="Descripción"
          type="mainAlt"
        />
      </div>

      <div className="mt-20 mb-12 pdb-20">
        <p className="mb-10">Documentos asociados</p>
        <Tag
          type="secondary"
          label={
            <>
              <FileFilled className="text-warning" />
              <span style={{ textDecoration: "underline" }}>document.docx</span>
            </>
          }
        />
      </div>

      <SectionHeader
        size="small"
        className="mv-10 border-top-main"
        prefix={<DownloadPackageOutlined className="text-emphasis" />}
        title="Ítems"
      />

      <Table fixed hoverable headers={headers} data={data} />
    </div>
  );
};
