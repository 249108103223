import React, { useEffect, useState } from 'react';
import { useApi } from '@outlier-spa/fetch';
import { Avatar, Card, SectionHeader } from '@outlier-spa/component';
import { BriefcaseFilled, CalendarSimpleOutlined } from '@outlier-spa/icon';
import { LoadingKiri } from 'components';
import styled from 'styled-components';
import { useInstitution } from 'context/institution';
import { ClassIcon } from 'components/icon';
import { EventCard } from 'components/card';
import { IInstitutionSummaryViewModel } from 'interfaces/institution';

const InstitutionUser = styled.div`
  width: 240px;

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.border.main};
  }
`;

const SummaryValue = styled.div`
  width: 180px;

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.border.main};
  }
`;

export interface IInstitutionSummaryProps {}

export const InstitutionSummary: React.FC<IInstitutionSummaryProps> = () => {
  const [getUrl, loadingRef] = useApi();
  const { institution } = useInstitution();
  const [summary, setSummary] = useState<IInstitutionSummaryViewModel>();

  useEffect(() => {
    if (!institution?.institutionId) return;
    getUrl<IInstitutionSummaryViewModel>(
      `/institution/${institution?.institutionId}/summary`,
      ({ data }) => setSummary(data)
    );
  }, [institution?.institutionId, getUrl]);
  return (
    <div className="ant-fade-enter ant-fade-enter-active h100 flex flex-column overflow-hidden-x line-normal">
      <LoadingKiri ref={loadingRef} />
      <SectionHeader
        title="Próximos evento"
        className="mt-14"
        prefix={<CalendarSimpleOutlined className="text-emphasis" />}
      />
      <div className="flex flex-gap-14">
        {summary?.events?.map((event) => (
          <EventCard key={event.eventId} readonly event={event} />
        ))}
      </div>
      {!summary?.events?.length && (
        <Card bordered={false}>
          <div className="flex pdv-20">
            <p className="pdh-14 text-secondary">
              No existen próximos eventos registrados
            </p>
          </div>
        </Card>
      )}

      <div className="pdb-20" />
      <SectionHeader
        title="Directorio"
        className="mt-20"
        prefix={<BriefcaseFilled className="text-emphasis" />}
      />
      <Card bordered={false}>
        <div className="flex pdv-20">
          {summary?.directory?.map((user) => (
            <InstitutionUser
              key={user.userId}
              className="flex flex-column flex-center"
            >
              <Avatar email={user.email} className="o-hidden" size="50px" />
              <p className="bold mt-10 mb-4">
                {user.firstName} {user.lastName}
              </p>
              <p className="text-secondary">{user.role}</p>
              <a
                style={{ textDecoration: 'underline' }}
                href={`mailto:${user.email}`}
                className="text-emphasis bold text-12 mt-6"
              >
                {user.email}
              </a>
            </InstitutionUser>
          ))}
          {(summary?.directory ?? []).length < 0 && (
            <p className="pdh-14 text-secondary">
              Aún no se ha registrado el directorio para esta institución.
            </p>
          )}
        </div>
      </Card>
      <div className="pdb-20" />
      <SectionHeader
        title="Talleres"
        className="mt-20"
        prefix={<ClassIcon className="text-emphasis" />}
      />
      <Card bordered={false} className="flex pdv-16">
        <SummaryValue className="pdh-20">
          <p className="text-secondary">Talleres</p>
          <h4 className="text-24 mt-16">{summary?.courses ?? '-'}</h4>
        </SummaryValue>
        <SummaryValue className="pdh-20">
          <p>Activos</p>
          <h4
            className="text-24 mt-16 text-emphasis"
            style={{ textDecoration: 'underline' }}
          >
            {summary?.activeCourses ?? '-'}
          </h4>
        </SummaryValue>
        <SummaryValue className="pdh-20">
          <p>Asistencia</p>
          <h4 className="text-24 mt-16">{summary?.attendance ?? '-'}%</h4>
        </SummaryValue>
      </Card>
    </div>
  );
};
