import React, { useImperativeHandle, useRef, useState } from 'react';
import {
	Modal,
	Input,
	Select,
	Avatar,
	TextArea,
} from '@outlier-spa/component';
import { CameraFilled } from '@outlier-spa/icon';
import { useCity } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import { ISupplier } from 'interfaces';


interface ISupplierModalRef {
	open: (supplier?: ISupplier) => void;
	close: () => void;
};

export interface ISupplierModalProps {
	onSubmit: (supplier: ISupplier, prevSupplier?: ISupplier) => void;
};

export const SupplierModal = React.forwardRef<ISupplierModalRef, ISupplierModalProps>(({ onSubmit }, ref) => {
	const modalRef = useRef<React.ElementRef<typeof Modal>>(null);
	const { control, handleSubmit, reset } = useForm();
	const { regionProps, provinceProps, cityProps, cityId, onChangeCity } = useCity();
	const [supplier, setSupplier] = useState<ISupplier>();

	function open(supplier?: ISupplier) {
		setSupplier(supplier);
		if (supplier?.cityId) onChangeCity(supplier.cityId);
		reset(supplier);
		modalRef.current?.open();
	}

	function close() {
		modalRef.current?.close();
	}

	function onSubmitData(data: any) {
		data.cityId = cityId;
		if (cityId) data.cityId = cityId;
		onSubmit(data, supplier);
	}

	useImperativeHandle(ref, () => ({ open, close }));

	return (
		<Modal
			ref={modalRef}
			okText={supplier?.supplierId ? 'Guardar Cambios' : 'Crear Proveedor'}
			okProps={{ type: 'assertive' }}
			title={supplier?.supplierId ? 'Editar Proveedor' : 'Nuevo Proveedor'}
			size='small'
			overlayClose={false}
			onSubmit={handleSubmit(onSubmitData)}>
			<div className='flex flex-middle pdv-20'>
				<Avatar className='avatar flex flex-middle' size='80px' email={supplier?.email}>
					<CameraFilled className='text-20 text-secondary' />
				</Avatar>
			</div>
			<div className='pdh-14 flex flex-column flex-vgap-14 pdb-16'>
				<div className='flex flex-center flex-gap-20'>
					<Controller
						name='name'
						control={control}
						render={({ field }) => <Input type='secondary' placeholder='Ingrese nombre' className='flex-1' label='Nombre' {...field} />} />
					<Controller
						name='identifier'
						control={control}
						render={({ field }) => <Input type='secondary' placeholder='Ingrese identificador' className='flex-1' label='Identificador' {...field} />} />
				</div>
				<div className='flex flex-center flex-gap-20'>
					<Controller
						name='email'
						control={control}
						render={({ field }) => <Input type='secondary' placeholder='Ingrese correo' className='flex-1' label='Correo' {...field} />} />
					<Controller
						name='phoneNumber'
						control={control}
						render={({ field }) => <Input type='secondary' placeholder='Ingrese número de teléfono' className='flex-1' label='Teléfono' {...field} />} />
				</div>
				<div className='flex flex-center flex-gap-20'>
					<Select optionFilterProp='label' style={{ width: '100%' }} type='secondary' className='flex-1' label='Region' {...regionProps} />
					<Select optionFilterProp='label' style={{ width: '100%' }} type='secondary' className='flex-1' label='Provincia' {...provinceProps} />
				</div>
				<div className='flex flex-center flex-gap-20'>
					<Select optionFilterProp='label' style={{ width: '100%' }} type='secondary' className='flex-1' label='Ciudad' {...cityProps} />
					<Controller
						name='address'
						control={control}
						render={({ field }) => <Input type='secondary' placeholder='Ingrese dirección' className='flex-1' label='Dirección' {...field} />} />
				</div>
				<Controller
					name='content'
					control={control}
					render={({ field }) => <TextArea type='secondary' placeholder='Ingrese descripción' rows={3} className='w100' label='Descripción' {...field} />} />
			</div>
		</Modal>
	);
});
