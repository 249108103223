import React, { useState, useRef, useEffect } from 'react';
import {
  SectionHeader,
  Input,
  Select,
  Table,
  Button,
  Upload,
  DatePicker,
  InputNumber,
  NotFound,
} from '@outlier-spa/component';
import { axios, useApi } from '@outlier-spa/fetch';
import {
  TrashcanFilled,
  DetailsOutlined,
  DownloadPackageOutlined,
} from '@outlier-spa/icon';
import { useForm, Controller } from 'react-hook-form';
import { Header, LoadingKiri } from 'components';
import { useTitle } from 'context';
import { useSuppliers, useInstitutions } from 'hooks';
import { IInvoice, IItem, IProduct } from 'interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

const Product = ({ value }: { value: IProduct }) => value.name;

const headers: any = [
  { key: 'product', value: 'Producto', cellComponent: Product },
  { key: 'total', value: 'Cantidad' },
  { key: 'identifier', value: 'Código' },
  { key: '', value: '', width: 56 },
];

interface IInventoryInvoiceItemFormProps {
  onSubmit: (items: IItem[]) => void;
}

const InventoryInvoiceItemForm: React.FC<IInventoryInvoiceItemFormProps> = ({
  onSubmit,
}) => {
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    axios.get<IProduct[]>('product').then(({ data }) => setProducts(data));
  }, []);

  const { control, handleSubmit } = useForm({
    defaultValues: { quantity: 1, productId: void 0 },
  });

  function onSubmitItems(data: { quantity?: number; productId?: number }) {
    console.log(data);
    if (!data.quantity || !data.productId) return;
    const product = products.find(
      ({ productId }) => productId.toString() === data.productId?.toString()
    );

    if (!product) return;

    if (product.hasIdentifier) {
      let items: Partial<IItem>[] = Array.from(
        { length: data.quantity },
        (_, index) => ({
          product: {
            productId: product.productId ?? 0,
            name: product?.name ?? '',
          },
          productId: product.productId,
          identifier: '',
          quantityIndex: `${index + 1}/${data.quantity}`,
          requireIdentifier: true,
        })
      );
      onSubmit(items as IItem[]);
    } else {
      let uniqueItem: Partial<IItem> = {
        product: {
          productId: product.productId ?? 0,
          name: product?.name ?? '',
        },
        productId: product.productId,
        quantityIndex: data.quantity.toString(),
        requireIdentifier: false,
      };
      onSubmit([uniqueItem] as IItem[]);
    }
  }

  return (
    <div className="mb-10 flex flex-center flex-gap-10">
      <Controller
        name="productId"
        control={control}
        render={({ field }) => (
          <Select
            optionFilterProp="label"
            options={products.map((d) => ({
              label: d.name,
              value: d.productId.toString(),
            }))}
            value={field.value}
            onChange={field.onChange}
            type="mainAlt"
            label="Producto"
            style={{ width: 300 }}
          />
        )}
      />
      <Controller
        name="quantity"
        control={control}
        render={({ field }) => (
          <InputNumber
            label="Cantidad"
            type="mainAlt"
            style={{ width: 100 }}
            min={1}
            {...field}
          />
        )}
      />
      <Button
        onClick={handleSubmit(onSubmitItems)}
        bold
        type="assertive"
        label="Agregar a la tabla"
        className="pdh-20"
        style={{ alignSelf: 'flex-end' }}
      />
    </div>
  );
};

interface IInvoiceItemRowProps {
  item: IItem;
  index: number;
  onChange: (identifier: string, index: number) => void;
  onRemove: (index: number) => void;
}

const InvoiceItemRow: React.FC<IInvoiceItemRowProps> = ({
  item,
  index,
  onChange,
  onRemove,
}) => {
  function handleIdentifierChange(evt: React.ChangeEvent<HTMLInputElement>) {
    onChange(evt.target.value, index);
  }

  function handleRemove() {
    onRemove(index);
  }

  return (
    <tbody className="t200">
      <tr>
        <td>
          <div className="flex flex-center table-cell h100 t200 pdh-14">
            {item.product?.name}
          </div>
        </td>
        <td>
          <div className="flex flex-center table-cell h100 t200 pdh-14">
            {item.total}
          </div>
        </td>
        <td>
          <div className="flex flex-center table-cell h100 t200 pdh-14">
            {item.product?.hasIdentifier ? (
              <Input
                defaultValue={item.identifier}
                onBlur={handleIdentifierChange}
                style={{ width: 360 }}
                placeholder="Escriba o escanee el código"
              />
            ) : (
              <span>No requiere código</span>
            )}
          </div>
        </td>
        <td>
          <div className="flex flex-center table-cell h100 t200 pdh-14">
            <TrashcanFilled
              onClick={handleRemove}
              className="flex text-secondary pointer text-16 hover-danger"
            />
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export interface IInventoryInvoiceUpdateProps {}

export const InventoryInvoiceUpdate: React.FC<
  IInventoryInvoiceUpdateProps
> = () => {
  const filesRef = useRef<File[]>([]);
  const [invoiceItems, setInvoiceItems] = useState<IItem[]>([]);
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState<IInvoice>();
  const { control, handleSubmit, reset } = useForm();
  const navigate = useNavigate();
  const suppliers = useSuppliers();
  const institutions = useInstitutions();
  const [getUrl, loadingRef] = useApi();
  useTitle('Actualizar Factura');

  function handleFileChange(files: File[]) {
    filesRef.current = files;
  }

  function onSave(data: any) {
    data.purchasedAt = data.purchasedAt?.format('DD-MM-YYYY');
    data.items = invoiceItems;
    loadingRef.current?.setActive(true);
    const formData = new FormData();
    formData.append('invoiceModel', JSON.stringify(data));
    filesRef.current.forEach((file) => formData.append(file.name, file));
    axios
      .put<IInvoice>(`invoice`, formData)
      .then(({ data }) => {
        navigate('/inventory/invoices');
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function handleAddItem(items: IItem[]) {
    console.log(items);
    setInvoiceItems([...items, ...invoiceItems]);
  }

  function onChangeIdentifier(identifier: string, index: number) {
    console.log(identifier);
    const items = [...invoiceItems];
    items[index as number].identifier = identifier;
    setInvoiceItems(items);
  }

  function onRemoveItem(index: number) {
    console.log(index);
    setInvoiceItems(invoiceItems.filter((_, i) => i !== index));
  }

  useEffect(() => {
    getUrl<IInvoice>(`/invoice/${invoiceId}`, ({ data }) => {
      console.log(data);
      reset({
        code: data.code,
        institutionId: `${data.institutionId}`,
        supplierId: `${data.supplierId}`,
        purshasedAt: moment(data.purshasedAt),
      });
      setInvoice(data);
      setInvoiceItems(data.items ?? []);
    });
  }, [getUrl, reset, invoiceId]);

  return (
    <div
      className="overflow-hidden-x h100 pdv-20"
      style={{ height: 'calc(100% + 40px)', marginTop: -20 }}
    >
      <div className="inner ant-fade-enter ant-fade-enter-active line-normal">
        <LoadingKiri ref={loadingRef} />
        <Header
          title={`Factura ${invoice?.code ?? ''}`}
          editable={false}
          onCancel={() => navigate('/inventory/invoices')}
          onSave={handleSubmit(onSave)}
          canSave
        />

        <SectionHeader
          size="small"
          className="mv-10 border-top-main"
          prefix={<DetailsOutlined className="text-emphasis" />}
          title="Datos principales"
        />

        <div className="flex flex-center flex-gap-14">
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <Input
                placeholder="Ingrese n° de Factura"
                type="mainAlt"
                style={{ width: 220 }}
                label="N° de Factura"
                {...field}
              />
            )}
          />
          <Controller
            name="supplierId"
            control={control}
            render={({ field }) => (
              <Select
                optionFilterProp="label"
                options={suppliers}
                type="mainAlt"
                label="Proveedor"
                style={{ width: 200 }}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <div>
            <p className="mb-10">Fecha</p>
            <Controller
              name="purshasedAt"
              control={control}
              render={({ field }) => (
                <DatePicker
                  type="mainAlt"
                  style={{ width: 160 }}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <Controller
            name="institutionId"
            control={control}
            render={({ field }) => (
              <Select
                optionFilterProp="label"
                options={institutions}
                type="mainAlt"
                label="Establecimiento"
                style={{ width: 300 }}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>

        <div className="mt-20 mb-12 pdb-20">
          <p className="mb-10">Documentos asociados</p>
          <Upload type="mainAlt" size="large" onChange={handleFileChange} />
        </div>

        <SectionHeader
          size="small"
          className="mv-10 border-top-main"
          prefix={<DownloadPackageOutlined className="text-emphasis" />}
          title="Tabla de productos"
        />

        <InventoryInvoiceItemForm onSubmit={handleAddItem} />

        <Table hoverable className="table-row-large" headers={headers}>
          {invoiceItems.map((item, index) => (
            <InvoiceItemRow
              key={`${item.identifier}${item.itemId}${index}`}
              index={index}
              item={item}
              onRemove={onRemoveItem}
              onChange={onChangeIdentifier}
            />
          ))}
          {invoiceItems.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={headers.length} className="text-right">
                  <div className="relative" style={{ height: 300 }}>
                    <NotFound content="No existe contenido para mostrar. Seleccione los parámetros de la tabla para visualizar la información" />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
    </div>
  );
};
