import React, {
  useRef,
  useState,
  useImperativeHandle,
  useMemo,
  useEffect,
} from 'react';
import {
  Modal,
  Select,
  Input,
  DatePicker,
  SwitchOptions,
  Table,
  Checkbox,
} from '@outlier-spa/component';
import { SearchOutlined } from '@outlier-spa/icon';
import { axios } from '@outlier-spa/fetch';
import { useForm, Controller } from 'react-hook-form';
import { IInstitution } from 'interfaces';
import { IPollApplyViewModel, IPollViewModel } from 'interfaces/poll';
interface ISurveyModalRef {
  open: (pollApply?: IPollApplyViewModel) => void;
  close: () => void;
}

interface ISurveyModalProps {
  onSubmit: (
    data: IPollApplyViewModel,
    pollApply?: IPollApplyViewModel
  ) => void;
}

const CheckComponent = ({ row }: any) => {
  return (
    <div>
      <Checkbox type="mainAlt" checked={row.checked?.value} />
    </div>
  );
};

const frames = [{ label: 'Mensual', value: 'Mensual' }];

const tableHeaders = [
  { key: 'cityId', value: '', cellComponent: CheckComponent, width: 32 },
  { key: 'name', value: <strong className="bold">Establecimiento</strong> },
  { key: 'city', value: <strong className="bold">Ciudad</strong> },
];

export const SurveyModal = React.forwardRef<ISurveyModalRef, ISurveyModalProps>(
  ({ onSubmit }, ref) => {
    const modalRef = useRef<React.ElementRef<typeof Modal>>(null);
    const [pollApply, setPollApply] = useState<IPollApplyViewModel>();
    const [polls, setPolls] = useState<{ value: string; label: string }[]>();
    const { control, handleSubmit, reset, setValue } = useForm();
    const [filter, setFilter] = useState<string>('');
    const [institutionIds, setInstitutionIds] = useState<number[]>([]);
    const [institutions, setInstitutions] = useState<
      (IInstitution & { checked?: boolean })[]
    >([]);
    const filteredInstitutions = useMemo(() => {
      return institutions.filter(
        (institution: IInstitution & { checked?: boolean }) => {
          return institution.name.toLowerCase().includes(filter.toLowerCase());
        }
      );
    }, [institutions, filter]);

    function handleRowClick(row: any) {
      const institutionId = row.institutionId.value;
      const index = institutionIds.indexOf(institutionId);
      if (index === -1) {
        setInstitutionIds([...institutionIds, institutionId]);
      } else {
        setInstitutionIds(
          institutionIds.filter((id: number) => id !== institutionId)
        );
      }
    }

    function onSubmitData(data: any) {
      data.frameValue = data.frameValue?.format('YYYY-MM');
      data.institutions = data.institutionIds?.map((d: any) => ({
        institutionId: d,
      }));
      data.name = polls?.find((p) => p.value === data.pollId)?.label;
      data.institutionsCount = data.institutionIds?.length;
      delete data.institutionIds;
      console.log(data);
      onSubmit(data, pollApply);
    }

    function open(pollApply?: IPollApplyViewModel) {
      setPollApply(pollApply);
      reset(pollApply);
      modalRef.current?.open();
    }

    function close() {
      modalRef.current?.close();
    }

    useImperativeHandle(ref, () => ({ open, close }));

    useEffect(() => {
      axios
        .get<IInstitution[]>('institution')
        .then(({ data }) => setInstitutions(data));
      axios
        .get<IPollViewModel[]>('poll/list')
        .then(({ data }) =>
          setPolls(data.map((d) => ({ value: `${d.pollId}`, label: d.name })))
        );
    }, []);

    useEffect(() => {
      if (!institutionIds) return;
      setValue('institutionIds', institutionIds);
      setInstitutions((institutions) =>
        institutions.map((i: any) => ({
          ...i,
          checked: institutionIds.includes(i.institutionId),
        }))
      );
    }, [institutionIds, setValue]);

    return (
      <Modal
        ref={modalRef}
        size="small"
        type="tertiary"
        title={pollApply ? 'Editar Encuesta' : 'Agregar Encuesta'}
        okText={pollApply ? 'Actualizar Encuesta' : 'Crear Encuesta'}
        okProps={{ type: 'assertive' }}
        overlayClose={false}
        onSubmit={handleSubmit(onSubmitData)}
      >
        <div className="pdh-14 flex flex-column flex-vgap-14 pdv-16">
          <Controller
            name="pollId"
            control={control}
            render={({ field }) => (
              <Select
                style={{ width: '100%' }}
                type="secondary"
                placeholder="Seleccione encuesta"
                label="Encuesta"
                options={polls}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
              />
            )}
          />

          <div className="flex flex-center flex-gap-20">
            <Controller
              name="frame"
              control={control}
              defaultValue={'Mensual'}
              render={({ field }) => (
                <Select
                  style={{ width: '100%' }}
                  type="secondary"
                  placeholder="Seleccione frame"
                  className="flex-1"
                  label="Frame"
                  options={frames}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                />
              )}
            />
            <div className="flex-1">
              <label className="flex mb-10">Fecha</label>
              <Controller
                name="frameValue"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    type="secondary"
                    className="flex-1"
                    picker="month"
                    placeholder="Seleccione fecha"
                    format={'YYYY-MM'}
                    style={{ width: '100%' }}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-center flex-gap-20">
            <div className="flex-1">
              <label className="flex mb-10">Dirigido a</label>
              <Controller
                name="target"
                control={control}
                defaultValue={'Monitor'}
                render={({ field }) => (
                  <SwitchOptions
                    maxActiveCount={1}
                    disabled
                    multiple
                    options={[
                      { key: 'Monitor', content: 'Monitor' }, // { key: 'Dupla', content: "Dupla",},
                    ]}
                    rounded
                    type="main"
                    onChange={(opt: any) => field.onChange(opt[0]?.key)}
                  />
                )}
              />
            </div>
          </div>
          <div style={{ minHeight: 200 }} className="flex flex-column">
            <p className="mb-10">Establecimientos</p>
            <div className="table-institutions border-main flex-1 flex flex-column ">
              <div className="pd-10 border-bottom-main">
                <Input
                  value={filter}
                  type="secondary"
                  onChange={(evt) => setFilter(`${evt.target.value}`)}
                  prefix={<SearchOutlined />}
                  placeholder="Filtrar establecimientos"
                />
              </div>
              <div
                className="overflow-hidden-x relative"
                style={{ maxHeight: 300 }}
              >
                <Table
                  bordered={false}
                  cellClassName="pdh-8"
                  fixedHeaders
                  hoverable
                  onRowClick={handleRowClick}
                  headers={tableHeaders}
                  data={filteredInstitutions}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);
