import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Card, SearchBar, DatePicker, Popover } from '@outlier-spa/component';
import { SchoolFilled } from '@outlier-spa/icon';
import { Rainbow } from '@outlier-spa/color';
import { LoadingKiri } from 'components';
import { useApi } from '@outlier-spa/fetch';
import { StyledClassAttendance } from './style';
import styled from 'styled-components';
import { NoData } from 'components/no-data';

export interface IClassAttendanceSummaryClass {
  status: string;
  attendance: number;
  date: string;
  attendanceOverTotal: string;
  tutor: string;
  capacity?: number;
}

export interface IClassAttendanceSummaryCourse {
  course: string;
  classesTotal: number;
  classes: IClassAttendanceSummaryClass[];
}

export interface IClassAttendanceInstitution {
  institution: string;
  courses: IClassAttendanceSummaryCourse[];
}

const gradient = new Rainbow({
  min: 0,
  max: 100,
  colours: ['DC294F', 'FFEA29', '00B398'],
});

const TableValue: React.FC<{ items: { label: string; value: any }[] }> = ({ items }) => (
  <table>
    <tbody>
      {items.map(item => (
        <tr key={item.label}>
          <th>{item.label}</th>
          <td className="text-secondary">: {item.value ?? '-'}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const ClassAttendanceDate: React.FC<{ class?: IClassAttendanceSummaryClass }> = ({
  class: classItem,
}) => {
  if (!classItem) {
    return <div className="flex flex-middle code square t200 bordered" />;
  }
  const color =
    classItem.status === 'Cancelada' ? undefined : `#${gradient.colorAt(classItem.attendance)}`;
  return (
    <Popover
      placement="bottom"
      trigger="hover"
      content={
        <StyledPopOverContent className="flex flex-column pd-10">
          <div className="flex flex-1 flex-center pdb-10">
            <div style={{ backgroundColor: color }} className="indicator"></div>
            <p className="text-16 bold">{classItem.attendance}%</p>
          </div>
          <TableValue
            items={[
              { label: 'Fecha', value: classItem.date },
              { label: 'Estado', value: classItem.status },
              { label: 'Tutor', value: classItem.tutor },
              { label: 'Asistencia', value: classItem.attendanceOverTotal },
              { label: 'Cupos', value: classItem.capacity },
              { label: 'Curricular', value: undefined },
            ]}
          />
        </StyledPopOverContent>
      }
    >
      <div
        className="flex flex-middle code square t200 bordered pointer"
        style={{ backgroundColor: color }}
      />
    </Popover>
  );
};

export const StyledPopOverContent = styled.div`
  table th {
    height: 30px;
    padding-right: 30px;
  }
  .indicator {
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.field.secondary};
    border-radius: 3px;
    margin-right: 6px;
  }
`;

interface IInstitutionAttendanceProps {
  institution: IClassAttendanceInstitution;
  classCount: number;
}

const InstitutionAttendance: React.FC<IInstitutionAttendanceProps> = ({
  institution,
  classCount,
}) => {
  return (
    <div className="flex line-normal institution-attendance">
      <div className="flex pdh-14 pdb-14 flex-column flex-vgap-8 institution-courses">
        <h6 style={{ height: 22 }} className="flex flex-center text-secondary">
          <SchoolFilled className="flex mr-6" />
          <span className="mr-8 bold">{institution.institution}</span>
        </h6>
        {institution.courses.map(course => {
          const [discipline, name] = course.course.split(' ');
          return (
            <div key={course.course} style={{ height: 22 }} className="flex flex-center flex-space">
              <p>{discipline}</p>
              <span className="text-secondary code">{name}</span>
            </div>
          );
        })}
      </div>
      <div className="flex flex-column flex-vgap-8 pdb-14">
        <div style={{ height: 22 }} className="flex flex-center">
          <div className="border-top-main w100" />
        </div>
        {institution.courses.map(course => {
          return (
            <div
              style={{ height: 22 }}
              key={course.course}
              className="flex flex-center flex-gap-12 pdr-14"
            >
              {Array.from({ length: classCount }).map((_, index) => (
                <ClassAttendanceDate key={index} class={course.classes[index]} />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const ClassAttendance: React.FC = () => {
  const [date, setDate] = useState(moment());
  const [getUrl, loadingRef] = useApi();
  const [filter, setFilter] = useState('');
  const [institutions, setInstitutions] = useState<IClassAttendanceInstitution[]>([]);
  const [classCount] = useMemo(() => {
    const totals = institutions.map(institution =>
      Math.max(...institution.courses.map(course => course.classesTotal))
    );
    return [Math.max(...totals)];
  }, [institutions]);

  const onChangeDate = (value: any) => {
    setDate(value);
  };

  useEffect(() => {
    getUrl<IClassAttendanceInstitution[]>(
      `/class/${date.year()}/attendanceByCourse`,
      ({ data }) => {
        console.log(data);
        setInstitutions(data);
      }
    );
  }, [getUrl, date]);

  return (
    <StyledClassAttendance className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <div className="flex flex-center flex-gap-14">
        <DatePicker
          picker="year"
          type="mainAlt"
          onChange={onChangeDate}
          style={{ width: 100 }}
          value={date}
          format={`[${date.format('YYYY')}]`}
          allowClear={false}
        />
        <SearchBar
          className="flex-1"
          buttonVisible={false}
          type="mainAlt"
          onChange={evt => setFilter(evt.target.value)}
        />
      </div>
      {institutions.length > 0 ? (
        <Card className={`overflow-auto mt-14 flex-1 relative`}>
          <div style={{ paddingLeft: 200 }} className="pd-14 flex flex-space chart-title">
            <h5 className="text-secondary">Clases</h5>
            <div className="flex flex-center code text-secondary flex-gap-8">
              <span>0%</span>
              <div className="scale-gradient" />
              <span>100%</span>
            </div>
          </div>
          <div className="class-number flex flex-center pdb-4">
            <div className="class-number-left" />
            <div className="flex flex-center flex-gap-12">
              {Array.from({ length: classCount }).map((_, index) => (
                <div key={`${index}`} className="flex flex-middle code square">
                  <span>{index + 1}</span>
                </div>
              ))}
            </div>
          </div>
          <div>
            {institutions.map(institution => (
              <InstitutionAttendance
                key={institution.institution}
                institution={institution}
                classCount={classCount}
              />
            ))}
          </div>
        </Card>
      ) : (
        <div className="flex flex-column flex-middle w100 h100">
          <NoData content="No existe contenido para mostrar. Seleccione los parámetros de la tabla para visualizar la información" />
        </div>
      )}
    </StyledClassAttendance>
  );
};
