import React, { useCallback, useEffect, useRef, useState } from 'react';
import cl from 'classnames';
import {
  Card,
  Collapse,
  CollapseSection,
  CollapseValues,
  List,
  ListItem,
  NotFound,
  Placeholder,
  Section,
  SectionHeader,
  Table,
  Modal,
} from '@outlier-spa/component';
import {
  CommentFilled,
  TickOutlined,
  CameraFilled,
  XOutlined,
  AssistanceFilled,
  BoxFilled,
  RiskFilled,
  RecordFolderFilled,
} from '@outlier-spa/icon';
import { Comment, Incident, LoadingKiri } from 'components';
import { useApi } from '@outlier-spa/fetch';
import { IClass, IClassAttendance, ICourseClass, IUser } from 'interfaces';
import moment from 'moment';
import { DefaultCell } from 'components/table';
import { StyledModal } from './style';
import {
  ICourseClassItemViewModel,
  ICourseDetailViewModel,
} from 'interfaces/course';

const LabelValue = ({ label, value }: { label: string; value: any }) => {
  return (
    <div className="flex flex-center pdh-14 pdv-10">
      <label style={{ width: 75 }}>{label}:</label>
      <span className="text-secondary">{value}</span>
    </div>
  );
};

const Check = ({ row }: any) => {
  const status = row.status.value;
  return (
    <div style={{ textAlign: 'right' }} className="flex-1 pdr-10">
      {status === 'Ausente' ? (
        <XOutlined className="text-16 text-danger" />
      ) : (
        <TickOutlined className="text-assertive text-16" />
      )}
    </div>
  );
};

const ItemCell = ({ row, value }: any) => {
  const hasIdentifier = row.hasIdentifier.value;
  const initial = row.initial.value;
  const final = row.final.value;
  if (initial === 0 && final === 0) return '-';
  if (!hasIdentifier) {
    return (
      <div className="flex-1 pdr-10">
        {value === 0 ? (
          <span className="text-secondary text-12">Extraviado</span>
        ) : (
          <TickOutlined className="text-assertive text-16" />
        )}
      </div>
    );
  }
  return value ?? '-';
};

const statusArray = [
  'Enojado',
  'Triste',
  'Neutro',
  'Alegre',
  'Feliz',
  'Ausente',
  'Presente',
];

const Emotion = ({ value }: any) => (value < 6 ? statusArray[value - 1] : '-');

const attendanceHeaders: any = [
  { key: 'rut', value: 'Rut' },
  { key: 'name', value: 'Nombre Completo' },
  { key: 'status', value: 'Emoción', cellComponent: Emotion },
  { key: 'userId', value: 'Asistencia', width: 90, cellComponent: Check },
];

const inventoryHeaders: any = [
  { key: 'product', value: 'Producto' },
  { key: 'identifier', value: 'Identificador', cellComponent: DefaultCell },
  { key: 'total', value: 'Cantidad', cellComponent: DefaultCell },
  { key: 'initial', value: 'Inicial', width: 75, cellComponent: ItemCell },
  { key: 'final', value: 'Final', width: 100, cellComponent: ItemCell },
];

interface IInstititionCourseClassesProps {
  course: ICourseDetailViewModel;
}

export const InstititionCourseClasses: React.FC<
  IInstititionCourseClassesProps
> = ({ course }) => {
  const [classes, setClasses] = useState<ICourseClassItemViewModel[]>();
  const [currentClass, setCurrentClass] = useState<ICourseClass>();
  const [items, setItems] = useState<any[]>();
  const [getUrl, loadingRef] = useApi();
  const modalRef = useRef<React.ElementRef<typeof Modal>>(null);
  const [imageSrc, setImageSrc] = useState<string | undefined>();

  const handleClassClick = useCallback(
    (classId: number) => {
      getUrl<ICourseClass>(`/class/${classId}`, ({ data }) => {
        console.log(data);
        setCurrentClass(data);
        setItems([]);
        // setItems(
        //   data.classItems?.map(({ item, initial, final }) => ({
        //     product: item?.product?.name,
        //     identifier: item?.identifier,
        //     hasIdentifier: item?.product?.hasIdentifier,
        //     total: item?.total,
        //     initial,
        //     final,
        //   })) ?? []
        // );
      });
    },
    [getUrl]
  );

  useEffect(() => {
    getUrl<ICourseClassItemViewModel[]>(
      `/course/${course.courseId}/classes`,
      ({ data }) => {
        console.log(data);
        setClasses(data);
      }
    );
  }, [getUrl, course.courseId]);

  useEffect(() => {
    classes?.length && handleClassClick(classes[0].classId);
  }, [handleClassClick, classes]);

  // const itemCount =
  //   currentClass?.classItems?.filter((c) => c.initial !== 0 && c.final !== 0)
  //     .length ?? 0;
  const itemCount = 0;
  const attendanceCount =
    currentClass?.attendances?.filter((c) => c.status !== 'Ausente').length ??
    0;
  return (
    <div className="ant-fadde-enter ant-fade-enter-active line-normal">
      <LoadingKiri ref={loadingRef} />
      <Modal bordered={false} ref={modalRef} buttonsVisible={false}>
        <StyledModal className="flex flex-middle relative">
          <XOutlined
            className="pointer"
            onClick={() => modalRef.current?.close()}
          />
          <div
            style={{
              backgroundImage: `url(${imageSrc})`,
              maxHeight: '100%',
            }}
          />
        </StyledModal>
      </Modal>
      {!classes || classes.length === 0 ? (
        <div className="relative" style={{ height: 300 }}>
          <NotFound content="No existe registro de clases" />
        </div>
      ) : (
        <div className="flex flex-start flex-gap-14">
          <div style={{ width: 240 }}>
            <div style={{ position: 'sticky', top: 14 }}>
              <h6 className="mb-10">Clases</h6>
              <Card>
                <List hoverable>
                  {classes?.map((item, i) => {
                    const className = cl({
                      'border-bottom-main': i > 0,
                      'list-item-active':
                        currentClass?.classId === item.classId,
                    });
                    return (
                      <ListItem
                        key={item.classId}
                        onClick={handleClassClick}
                        className={className}
                        item={item.classId}
                        value={
                          <div className="flex flex-1 flex-center flex-space">
                            <span>{item.name}</span>
                            <span className="text-secondary">{item.date}</span>
                          </div>
                        }
                      />
                    );
                  })}
                </List>
              </Card>
            </div>
          </div>
          <div className="flex-1">
            <div className="pdv-14" />
            <Card>
              <Collapse
                type="transparent"
                headerPadding={false}
                transparent
                bordered={false}
                size="large"
              >
                <CollapseSection
                  className="active"
                  title={
                    <SectionHeader
                      size="small"
                      className="pdh-6"
                      prefix={<RecordFolderFilled className="text-emphasis" />}
                      title="Antecedentes"
                    />
                  }
                >
                  <CollapseValues>
                    <div className="mb-20 pdb-20 pdt-10">
                      {currentClass?.status === 'Cancelada' ? (
                        <>
                          <p className="text-danger bold pdh-14 pdv-10">
                            Clase Suspendida
                          </p>
                          <LabelValue label="Por" value={currentClass.tutor} />
                          <LabelValue
                            label="Fecha"
                            value={currentClass.finishedOn ?? '-'}
                          />
                          {/* <LabelValue label="Monitor" value={course.tutor ?? '-'} /> */}
                        </>
                      ) : (
                        <>
                          {/* <LabelValue label="Sesión" value="-" /> */}
                          <LabelValue
                            label="Fecha"
                            value={currentClass?.date ?? '-'}
                          />
                          <LabelValue
                            label="Inicio"
                            value={currentClass?.startedOn ?? '-'}
                          />
                          <LabelValue
                            label="Término"
                            value={currentClass?.finishedOn ?? '-'}
                          />
                          {/* <LabelValue label="Monitor" value={course.tutor ?? '-'} />
                          <LabelValue label="Dupla" value={course.duo ?? '-'} /> */}
                        </>
                      )}
                    </div>
                  </CollapseValues>
                </CollapseSection>
                <CollapseSection
                  className="active border-top-main "
                  title={
                    <SectionHeader
                      size="small"
                      className="pdh-6 "
                      prefix={<CommentFilled className="text-emphasis" />}
                      title="Comentario de la clase"
                    />
                  }
                >
                  <CollapseValues>
                    <div className="pdh-14 mb-20 pdb-20 pdt-10">
                      {currentClass?.status === 'Cancelada' ? (
                        <Comment
                          name={currentClass.tutor}
                          date={
                            currentClass?.finishedOn
                              ? moment(currentClass.finishedOn)
                              : undefined
                          }
                          content={
                            currentClass?.comment || (
                              <span className="text-secondary">
                                Sin comentarios.
                              </span>
                            )
                          }
                        />
                      ) : (
                        <Comment
                          name={course.tutor?.fullName}
                          date={
                            currentClass?.finishedOn
                              ? moment(currentClass.finishedOn)
                              : undefined
                          }
                          content={
                            currentClass?.comment || (
                              <span className="text-secondary">
                                Sin comentarios.
                              </span>
                            )
                          }
                        />
                      )}
                    </div>
                  </CollapseValues>
                </CollapseSection>
                {!(currentClass?.status === 'Cancelada') && (
                  <>
                    <CollapseSection
                      className="active border-top-main"
                      title={
                        <SectionHeader
                          size="small"
                          className="pdh-6 flex-1 pdr-10"
                          prefix={
                            <AssistanceFilled className="text-emphasis" />
                          }
                          title="Asistencia"
                          suffix={
                            <span className="text-secondary">
                              {attendanceCount}/
                              {currentClass?.attendances?.length ?? 0} alumnos
                              registrados
                            </span>
                          }
                        />
                      }
                    >
                      <CollapseValues>
                        <div className="pdh-14 pdb-20 pdt-10 mb-20">
                          <Table
                            fixed
                            hoverable
                            headers={attendanceHeaders}
                            data={currentClass?.attendances}
                          />
                        </div>
                      </CollapseValues>
                    </CollapseSection>

                    <CollapseSection
                      className="active border-top-main "
                      title={
                        <SectionHeader
                          size="small"
                          className="pdh-6 flex-1 pdr-10"
                          prefix={<BoxFilled className="text-emphasis" />}
                          title="Inventario"
                          suffix={
                            <span className="text-secondary">
                              {itemCount}/ 0 productos
                              {/* {currentClass?.classItems?.length ?? 0} productos */}
                              registrados
                            </span>
                          }
                        />
                      }
                    >
                      <CollapseValues>
                        <div className="pdh-14 pdb-20 pdt-10 mb-20">
                          {items?.length === 0 ? (
                            <div className="text-secondary">
                              No existen items asociados a esta clase.
                            </div>
                          ) : (
                            <Table
                              fixed
                              hoverable
                              headers={inventoryHeaders}
                              data={items}
                            />
                          )}
                        </div>
                      </CollapseValues>
                    </CollapseSection>

                    {/* <CollapseSection
                      className="active border-top-main"
                      title={
                        <SectionHeader
                          size="small"
                          className="pdh-6"
                          prefix={<RiskFilled className="text-emphasis" />}
                          title="Incidentes"
                        />
                      }
                    >
                      <CollapseValues>
                        <div className="pdh-14 pdb-20 pdt-10 mb-20 flex flex-column flex-vgap-14">
                          {currentClass?.incidents?.length === 0 && (
                            <div className="text-secondary">
                              No existen incidentes asociados a esta clase.
                            </div>
                          )}
                          {currentClass?.incidents?.map((incident, index) => (
                            <Incident
                              key={incident.incidentId}
                              name={`Incidente ${index + 1}`}
                              incident={incident}
                            />
                          ))}
                        </div>
                      </CollapseValues>
                    </CollapseSection> */}

                    {/* <SectionHeader size='small' className='mb-10 pdh-14 border-top-main' prefix={<DownloadPackageOutlined className='text-emphasis' />} title='Incidentes insumos' />
							<div className='pdh-14 pdb-20 mb-20'>
								<IncidentSupply />
							</div> */}
                    <CollapseSection
                      className="active border-top-main"
                      title={
                        <SectionHeader
                          size="small"
                          className="pdh-6"
                          prefix={<CameraFilled className="text-emphasis" />}
                          title="Registro fotográfico"
                        />
                      }
                    >
                      <CollapseValues>
                        <div
                          style={{ height: 230 }}
                          className="pdb-20 pdt-10 mb-20 flex flex-gap-10 pdh-14 o-hidden pointer"
                        >
                          {Array.from({ length: 3 }, (_, i) => {
                            const file = currentClass?.files?.[i];
                            return (
                              <Section
                                onClick={() => {
                                  if (!file) return;
                                  setImageSrc(
                                    file
                                      ? `${process.env.REACT_APP_API_URL}/file/image/${file?.path}`
                                      : undefined
                                  );
                                  modalRef.current?.open();
                                }}
                                key={`${i}-${file?.fileId}`}
                                type="secondary"
                                className="flex-1 relative o-hidden flex flex-middle"
                              >
                                {!file && (
                                  <Placeholder>No hay registro.</Placeholder>
                                )}
                                {file && (
                                  <img
                                    style={{
                                      maxWidth: '100%',
                                      maxHeight: '100%',
                                    }}
                                    alt="file"
                                    src={`${process.env.REACT_APP_API_URL}/file/image/${file?.path}`}
                                  />
                                )}
                              </Section>
                            );
                          })}
                        </div>
                      </CollapseValues>
                    </CollapseSection>
                  </>
                )}

                {/* <SectionHeader size='small' className='mb-10 pdh-14 border-top-main' prefix={<DownloadPackageOutlined className='text-emphasis' />} title='Encuestas de percepción' />
							<div className='pdh-14 pdb-20 mb-20'>
								<Section type='secondary' className='pdh-14 pdb-10 mb-14'>
									<SectionHeader size='small' title='Encuesta Monitores' />
									<Survey />
								</Section>
								<Section type='secondary' className='pdh-14 pdb-10'>
									<SectionHeader size='small' title='Encuesta Monitor a dupla' />
									<Survey />
								</Section>
							</div> */}
              </Collapse>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};
