import {
  Button,
  Card,
  DatePicker,
  List,
  Modal,
  NotFound,
  Popover,
  SearchBar,
  SwitchOptions,
  TextArea,
} from '@outlier-spa/component';
import { axios, useApi } from '@outlier-spa/fetch';
import { OptionsOutlined } from '@outlier-spa/icon';
import cl from 'classnames';
import { LoadingKiri } from 'components';
import { DefaultCell } from 'components/table';
import { IClassWeekListViewModel } from 'interfaces/class';
import moment from 'moment';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledTable } from './style';

enum Status {
  Pendiente = 0,
  Finalizada = 1,
  Cancelada = 2,
}

const statusColorType: any = {
  Pendiente: 'primary',
  '2': 'secondary',
  Finalizada: 'assertive',
  Cancelada: 'danger',
}; // ["primary", "secondary", "assertive", "danger"];

const StatusRender = ({ value, row, handlers }: { value: string; row: any; handlers: any }) => {
  const popoverRef = useRef<React.ElementRef<typeof Popover>>(null);
  const navigate = useNavigate();

  function handleItemClick(key: string) {
    popoverRef.current?.setOpen(false);
    if (key === 'create') {
      navigate(`/class/${row.startDate.value}/${row.scheduleId.value}`);
    } else if (key === 'cancel') {
      handlers?.onUpdate(row);
    }
  }

  const className = cl('status-cell bold flex-middle flex', 'bold', {
    'pointer hover-emphasis': value !== 'Pendiente',
  });

  return (
    <div className={className}>
      {value === 'Pendiente' ? (
        <Popover
          ref={popoverRef}
          trigger="hover"
          content={
            <List
              hoverable
              onItemClick={handleItemClick}
              keyExtractor={item => item.key}
              renderItem={item => <span className={item.className}>{item.label}</span>}
              items={[
                { key: 'create', label: 'Realizar clase', className: 'bold' },
                {
                  key: 'cancel',
                  label: 'Suspender',
                  className: 'bold text-danger',
                },
              ]}
            />
          }
        >
          <Button
            quiet
            size="small"
            className={`bold border-secondary t200 text-${statusColorType[value]}`}
            // onClick={() => handlers?.onUpdate(row)}
            label={
              <>
                {/* <span className="text-action">Suspender</span> */}
                <span className="mr-6">{value}</span>
                <OptionsOutlined className="text-secondary" />
              </>
            }
          />
        </Popover>
      ) : (
        <Popover
          trigger="hover"
          content={<span className="pd-10">Ir a la clase</span>}
          placement="right"
        >
          <span
            className={`pdh-10 bold t200 text-${statusColorType[value]}`}
            onClick={() => handlers.onClick(row)}
          >
            {value}
          </span>
        </Popover>
      )}
    </div>
  );
};

const headers: any = [
  { key: 'day', value: 'Día', sortable: true },
  {
    key: 'institution',
    value: 'Colegio',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'discipline',
    value: 'Disciplina',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'name',
    value: 'Taller',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'tutor',
    value: 'Monitor',
    sortable: true,
    cellComponent: DefaultCell,
  },
  // { key: 'capacity', value: 'Capacidad', sortable: true, cellComponent: DefaultCell },
  {
    key: 'attendance',
    value: 'Asistencia',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'incident',
    value: 'Incidentes',
    sortable: true,
    cellComponent: ({ value }: { value: any }) => (
      <span className={value && 'bold text-danger'}>{value ?? '-'}</span>
    ),
  },
  {
    key: 'date',
    value: 'Fecha',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'start',
    value: 'Hr. Inicio',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'status',
    value: <span className="pdl-20">Estado</span>,
    sortable: true,
    cellComponent: StatusRender,
    width: 140,
  },
];

interface IClassCancelRef {
  open: (clase: IClassWeekListViewModel) => void;
  close: () => void;
}

interface IClassCancelProps {
  onSubmit: (data: IClassWeekListViewModel, prevClass?: IClassWeekListViewModel) => void;
}

export const ClassCancel = React.forwardRef<IClassCancelRef, IClassCancelProps>(
  ({ onSubmit }, ref) => {
    const modalRef = useRef<React.ElementRef<typeof Modal>>(null);
    const [clase, setClase] = useState<IClassWeekListViewModel>();
    const { control, handleSubmit, formState, watch, setValue, reset } = useForm();
    const canceledBy = watch('canceledBy');
    const prevNotice = watch('prevNotice');

    function onSubmitData(data: any) {
      console.log(data);

      if (formState.errors && Object.keys(formState.errors).length > 0) return;
      data.canceledAt = moment().format('YYYY-MM-DD HH:mm:ss');
      data.canceled = (data.canceledBy[0]?.content || 'Colegio').toLowerCase();
      if (data.prevNotice[0]?.content === 'Si') {
        data.startedOn = moment().format('YYYY-MM-DD HH:mm:ss');
      }
      // onSubmit(
      //   { ...clase, ...data },
      //   clase && clase.classId ? clase : undefined
      // );
    }
    function open(clase: IClassWeekListViewModel) {
      console.log(clase);
      setClase(clase);
      reset(clase);
      // setValue('classId', clase.classId);
      setValue('canceledBy', [{ content: 'Colegio' }]);
      setValue('prevNotice', [{ content: 'Si' }]);
      modalRef.current?.open();
    }
    function close() {
      modalRef.current?.close();
    }

    useImperativeHandle(ref, () => ({ open, close }));

    return (
      <Modal
        okProps={{ type: 'assertive' }}
        title={'Formulario Suspensión'}
        okText={'Suspender'}
        size="small"
        overlayClose={false}
        ref={modalRef}
        onSubmit={handleSubmit(onSubmitData)}
      >
        <div className="pdh-14 flex flex-column flex-vgap-16 pdv-16">
          <div>
            <p className="mb-10">¿Quién suspende la clase?</p>
            <Controller
              name="canceledBy"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <SwitchOptions
                  rounded
                  multiple
                  maxActiveCount={1}
                  index={canceledBy[0]?.content === 'Monitor' ? 0 : 1}
                  options={[{ content: 'Monitor' }, { content: 'Colegio' }]}
                  type="main"
                  {...rest}
                />
              )}
            />
          </div>

          <div>
            <p className="mb-10">¿Existió previo aviso de la suspensión?</p>
            <Controller
              name="prevNotice"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <SwitchOptions
                  rounded
                  multiple
                  maxActiveCount={1}
                  index={prevNotice[0]?.content === 'Si' ? 0 : 1}
                  options={[{ content: 'Si' }, { content: 'No' }]}
                  type="main"
                  {...rest}
                />
              )}
            />
          </div>
          <Controller
            name="canceledComment"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextArea
                label="Explica brevemente el detalle de la suspensión"
                type="secondary"
                placeholder="Escriba algo..."
                validation={{
                  visible: !!formState.errors?.name,
                }}
                className="flex-1"
                {...field}
              />
            )}
          />
        </div>
      </Modal>
    );
  }
);

export const ClassesWeek: React.FC = () => {
  const params = useParams();
  const [date, setDate] = useState<moment.Moment | null>();
  const [limits, setLimits] = useState<{
    minimum: moment.Moment;
    maximum: moment.Moment;
  }>();
  const cancelModalRef = useRef<React.ElementRef<typeof ClassCancel>>(null);
  const navigate = useNavigate();
  const [classes, setClasees] = useState<IClassWeekListViewModel[]>([]);
  const [getUrl, loadingRef] = useApi();

  const [filter, setFilter] = useState<string>();
  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      const filterStr = filter.toLowerCase();
      return data.filter(d => {
        const d0 = d[0];
        console.log(d0);
        const dayName = (d0.classDayString?.value ?? d0.day?.value)?.toLowerCase();
        const monitor = (
          (d0.classTutor?.value ?? d0.courseTutor?.value)?.fullName ?? d0.tutor?.value
        )?.toLowerCase();
        const courseName = (d0.courseName?.value ?? d0.name?.value)?.toLowerCase();
        const institution = (
          d0.institutionViewModel?.value?.name ?? d0.institution?.value
        )?.toLowerCase();
        const discipline = d0.discipline?.value?.toLowerCase();
        const classDate = (d0.classDate?.value ?? d0.start?.value)?.toLowerCase();

        return (
          dayName?.includes(filterStr) ||
          monitor?.includes(filterStr) ||
          courseName?.includes(filterStr) ||
          institution?.includes(filterStr) ||
          discipline?.includes(filterStr) ||
          classDate?.includes(filterStr)
        );
      });
    },
    [filter]
  );

  function handleClassClick({ status, institutionId, courseId }: any) {
    if (status.value === 1) return;
    navigate(`/institution/${institutionId.value}/course/${courseId.value}/classes`);
  }

  function onCancelClass(clase: IClassWeekListViewModel, prevClass?: IClassWeekListViewModel) {
    loadingRef.current?.setActive(true);
    // if (prevClass) {
    //   const index = classes?.findIndex(
    //     (item) => item.classId === prevClass.classId
    //   );
    //   if (index === -1 || !classes) {
    //     loadingRef.current?.setActive(false);
    //     return;
    //   }

    //   axios
    //     .put<IClassWeekListViewModel>(`class/cancel`, clase)
    //     .then(({ data }) => {
    //       const newClasss = [...classes];
    //       newClasss[index] = data;
    //       setClasees(newClasss);
    //       cancelModalRef.current?.close();
    //     })
    //     .finally(() => loadingRef.current?.setActive(false));
    // } else {
    //   const index = classes?.findIndex(
    //     (item) =>
    //       item.startDate === clase.startDate &&
    //       item.institutionId === clase.institutionId &&
    //       item.courseId === clase.courseId &&
    //       item.tutor === clase.tutor &&
    //       item.scheduleId === clase.scheduleId
    //   );
    //   if (index === -1 || !classes) {
    //     loadingRef.current?.setActive(false);
    //     return;
    //   }
    //   axios
    //     .post<IClassWeekListViewModel>(`class/cancel`, clase)
    //     .then(({ data }) => {
    //       const newClasss = [...classes];
    //       newClasss[index] = data;
    //       setClasees(newClasss);
    //       cancelModalRef.current?.close();
    //     })
    //     .finally(() => loadingRef.current?.setActive(false));
    // }
  }
  function handleCancelClass(row: any) {
    cancelModalRef.current?.open({ ...row.__default.value });
  }
  function onChangeDate(date: moment.Moment | null) {
    setDate(date);
  }

  useEffect(() => {
    if (!date) return;
    getUrl<IClassWeekListViewModel[]>(
      `class/range/${date.format('YYYY')}/${date.format('MM')}/${params.userId}`,
      ({ data }) => {
        setClasees(data);
      }
    );
  }, [getUrl, date, params.userId]);

  useEffect(() => {
    getUrl<{ minimum: string; maximum: string }>(`/class/limit/${params.userId}`, ({ data }) => {
      setLimits({
        minimum: moment(data.minimum),
        maximum: moment(data.maximum),
      });
    });
  }, [getUrl, params.userId]);

  useEffect(() => {
    getUrl<IClassWeekListViewModel[]>(
      `/team/classes/2023-05-01/2023-05-31/${params.userId}`,
      ({ data }) => {
        console.log(data);
        setClasees(data);
      }
    );
  }, [getUrl, params.userId]);

  useEffect(() => {
    if (!limits) return;
    setDate(params.date ? moment(params.date, 'YYYY-MM') : limits.maximum.clone());
  }, [params.date, limits]);

  return (
    <div className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <ClassCancel onSubmit={onCancelClass} ref={cancelModalRef} />

      <div className="flex flex-center flex-gap-14">
        <DatePicker
          picker="month"
          type="mainAlt"
          onChange={onChangeDate}
          style={{ width: 230 }}
          disabledDate={current => {
            if (!limits) return false;
            return current && (current < limits.minimum || current > limits.maximum);
          }}
          value={date}
          format={'MMMM YYYY'}
          allowClear={false}
        />
        <SearchBar
          className="flex-1"
          buttonVisible={false}
          type="mainAlt"
          onChange={evt => setFilter(evt.target.value)}
        />
      </div>

      <Card
        className={`overflow-auto mt-14${classes?.length > 0 ? ' flex-1' : ''}`}
        style={{ maxHeight: '100%' }}
      >
        <StyledTable
          alternate
          bordered={false}
          fixedHeaders
          hoverable
          filter={tableFilter}
          handlers={{
            onUpdate: handleCancelClass,
            onClick: handleClassClick,
          }}
          headers={headers}
          data={classes}
        />
        {classes?.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay clases registradas." />
          </div>
        )}
      </Card>
    </div>
  );
};
