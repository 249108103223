import { Dock } from '@outlier-spa/component';
import * as React from 'react';
import events from './assets/events.svg';
import institution from './assets/institution.svg';
import inventory from './assets/inventory.svg';
import kteam from './assets/kteam.svg';
import lessons from './assets/lessons.svg';
import dollar from './assets/dollar.svg';
import programs from './assets/programs.svg';
import survey from './assets/survey.svg';
import { KiriLogo } from 'components/icon';

export const KiriDock = () => {
  return (
    <>
      <div className="flex flex-1 flex-middle pdb-14">
        <Dock
          stages={[
            {
              name: 'Home',
              to: '/home',
              content: (
                <span className="flex flex-1 flex-middle">
                  <KiriLogo style={{ fontSize: 30 }} className="text-emphasis" />
                </span>
              ),
            },
            {
              name: 'Establecimientos',
              to: '/institution',
              content: (
                <span className="flex flex-1 flex-middle">
                  <img alt="kiri-icon" style={{ width: 30, height: 30 }} src={institution} />
                </span>
              ),
            },
            {
              name: 'Clases',
              to: '/class',
              content: (
                <span className="flex flex-1 flex-middle">
                  <img alt="kiri-icon" style={{ width: 30, height: 30 }} src={lessons} />
                </span>
              ),
            },
            {
              name: 'Programas',
              to: '/program',
              disabled: true,
              content: (
                <span className="flex flex-1 flex-middle">
                  <img alt="kiri-icon" style={{ width: 30, height: 30 }} src={programs} />
                </span>
              ),
            },
            {
              name: 'Inventario',
              to: '/inventory',
              disabled: true,
              content: (
                <span className="flex flex-1 flex-middle">
                  <img alt="kiri-icon" style={{ width: 30, height: 30 }} src={inventory} />
                </span>
              ),
            },
            {
              name: 'Equipo',
              to: '/team',
              content: (
                <span className="flex flex-1 flex-middle">
                  <img alt="kiri-icon" style={{ width: 30, height: 30 }} src={kteam} />
                </span>
              ),
            },
            {
              name: 'Eventos',
              to: '/event',
              disabled: true,
              content: (
                <span className="flex flex-1 flex-middle">
                  <img alt="kiri-icon" style={{ width: 30, height: 30 }} src={events} />
                </span>
              ),
            },
            {
              name: 'Encuestas',
              to: '/survey',
              disabled: true,
              content: (
                <span className="flex flex-1 flex-middle">
                  <img alt="kiri-icon" style={{ width: 30, height: 30 }} src={survey} />
                </span>
              ),
            },
            {
              name: 'Pagos',
              to: '/payment/list',
              disabled: true,
              content: (
                <span className="flex flex-1 flex-middle">
                  <img alt="kiri-icon" style={{ width: 30, height: 30 }} src={dollar} />
                </span>
              ),
            },
          ]}
        />
      </div>
    </>
  );
};
