import {
  Avatar,
  Card,
  Confirm,
  DatePicker,
  Input,
  Modal,
  NotFound,
  SearchBar,
  Select,
  Table,
} from '@outlier-spa/component';
import { axios, useApi } from '@outlier-spa/fetch';
import { RiskFilled, TeamUserFilled } from '@outlier-spa/icon';
import { LoadingKiri } from 'components';
import { DefaultCell } from 'components/table';
import { useInstitution } from 'context/institution';
import { useCity, useInstitutionRole, useUserByRut } from 'hooks';
import { IInstitutionRole, IRole, LabeledValue } from 'interfaces';
import { IUserViewModel } from 'interfaces/user';
import { IInstitutionTeamListViewModel } from 'interfaces/institution';
import moment from 'moment';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CustomPopoverOptions } from 'components/custom-options';

const Image = (props: any) => (
  <Avatar email={props.row?.email?.value} className="flex flex-middle o-hidden" size="2.4rem">
    {!props.row?.email?.value && <TeamUserFilled className="text-16 text-secondary" />}
  </Avatar>
);

const headers: any = [
  { key: '', value: '', cellComponent: Image },
  { key: 'firstName', value: 'Nombre', sortable: true },
  { key: 'lastName', value: 'Apellido', sortable: true },
  { key: 'rut', value: 'Rut', sortable: true },
  {
    key: 'dateOfBirth',
    value: 'F. nacimiento',
    sortable: true,
    cellComponent: DefaultCell,
  },
  { key: 'email', value: 'Correo', sortable: true },
  { key: 'address', value: 'Dirección', sortable: true },
  { key: 'city', value: 'Ciudad', sortable: true, cellComponent: DefaultCell },
  { key: 'role', value: 'Rol', sortable: true, cellComponent: DefaultCell },
  { key: 'userId', value: '', sortable: true, cellComponent: CustomPopoverOptions },
];

interface ICustonUserRole extends IUserViewModel {
  role: IRole;
  roleId?: any;
}
interface IInstitutionTeamModalRef {
  open: (user?: ICustonUserRole) => void;
  close: () => void;
}

interface IInstitutionTeamNewProps {
  onSubmit: (data: ICustonUserRole, roleId: number, user?: ICustonUserRole) => void;
}

const InstitutionTeamNew = React.forwardRef<IInstitutionTeamModalRef, IInstitutionTeamNewProps>(
  ({ onSubmit }, ref) => {
    const modalRef = useRef<React.ElementRef<typeof Modal>>(null);
    const [user, setUser] = useState<ICustonUserRole>();
    const [blockForm, setBlockForm] = useState<boolean>(false);
    const [roles, setRoles] = useState<LabeledValue[]>(useInstitutionRole());

    const [getUrl, loadingRef] = useApi();
    const { institution } = useInstitution();
    const { onChangeRut } = useUserByRut();
    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm();
    const { regionProps, provinceProps, cityProps, cityId, onChangeCity } = useCity();
    // const roles = useInstitutionRole();

    function open(user?: ICustonUserRole) {
      setUser({ ...user } as ICustonUserRole);
      if (user?.dateOfBirth) user.dateOfBirth = moment(user.dateOfBirth) as any;
      if (user?.cityId) onChangeCity(user.cityId);
      reset({ ...user, roleId: user?.roleId?.toString() });
      modalRef.current?.open();
    }

    function updateUser(updUser?: IUserViewModel | null) {
      if (!updUser) {
        setBlockForm(false);
        return;
      }
      getUrl<IRole[]>(
        `institution/${institution?.institutionId}/Roles/${updUser.userId}`,
        ({ data }) => {
          console.log(data);
          setRoles(data.map(({ roleId, name }) => ({ label: name, value: roleId.toString() })));
        }
      );
      if (updUser.dateOfBirth) updUser.dateOfBirth = moment(updUser.dateOfBirth) as any;
      if (updUser.cityId) onChangeCity(updUser.cityId);
      setBlockForm(true);
      reset({ ...updUser, roleId: user?.roleId?.toString() });
    }

    function close() {
      modalRef.current?.close();
    }

    function onSubmitData(data: any) {
      data.dateOfBirth = data.dateOfBirth?.format('YYYY-MM-DD');
      data.cityId = cityId;
      onSubmit(data, data.roleId, user);
    }

    useImperativeHandle(ref, () => ({ open, close }));

    return (
      <Modal
        ref={modalRef}
        okText={user && user.userId ? 'Actualizar Funcionario' : 'Crear Funcionario'}
        okProps={{ type: 'assertive' }}
        title={user && user.userId ? 'Actualizar Funcionario' : 'Nuevo Funcionario'}
        size="small"
        overlayClose={false}
        onSubmit={handleSubmit(onSubmitData)}
      >
        <div className="pdh-14 flex flex-column flex-vgap-14 pdv-16">
          <div className="flex flex-center flex-gap-20">
            <Controller
              control={control}
              name="rut"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="flex-1"
                  label="Rut (*)"
                  type="secondary"
                  placeholder="Ingrese rut"
                  validation={{
                    message: 'El rut es requerido',
                    visible: Boolean(errors?.rut),
                  }}
                  {...field}
                  onBlur={evt => {
                    field.onBlur();
                    onChangeRut(evt.target.value, updateUser);
                  }}
                />
              )}
            />
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="flex-1"
                  type="secondary"
                  placeholder="Ingrese nombres"
                  label="Nombres (*)"
                  disabled={blockForm}
                  validation={{
                    message: 'El nombre es requerido',
                    visible: Boolean(errors?.firstName),
                  }}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex flex-center flex-gap-20">
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="flex-1"
                  label="Apellidos (*)"
                  type="secondary"
                  placeholder="Ingrese apellidos"
                  disabled={blockForm}
                  validation={{
                    message: 'El apellido es requerido',
                    visible: Boolean(errors?.lastName),
                  }}
                  {...field}
                />
              )}
            />
            <div className="flex-1">
              <label className="flex mb-10">Fecha de nacimiento</label>
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    type="secondary"
                    style={{ width: '100%' }}
                    value={field.value}
                    onChange={value => field.onChange(value)}
                    disabled={blockForm}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-center flex-gap-20">
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field }) => (
                <Input
                  className="flex-1"
                  label="Teléfono"
                  type="secondary"
                  placeholder="Ingrese teléfono"
                  style={{ width: '100%' }}
                  disabled={blockForm}
                  {...field}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  className="flex-1"
                  label="Correo"
                  type="secondary"
                  placeholder="Ingrese correo"
                  disabled={blockForm}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex flex-center flex-gap-20">
            <Controller
              name="region"
              control={control}
              render={({ field }) => (
                <Select
                  optionFilterProp="label"
                  style={{ width: '100%' }}
                  className="flex-1"
                  type="secondary"
                  label="Region"
                  disabled={blockForm}
                  {...regionProps}
                  onChange={value => {
                    regionProps.onChange?.(value);
                    field.onChange(value);
                  }}
                />
              )}
            />

            <Controller
              name="province"
              control={control}
              render={({ field }) => (
                <Select
                  optionFilterProp="label"
                  style={{ width: '100%' }}
                  className="flex-1"
                  type="secondary"
                  label="Provincia"
                  disabled={blockForm}
                  {...provinceProps}
                  onChange={value => {
                    provinceProps.onChange?.(value);
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
          <div className="flex flex-center flex-gap-20">
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Select
                  optionFilterProp="label"
                  style={{ width: '100%' }}
                  className="flex-1"
                  label="Ciudad"
                  type="secondary"
                  disabled={blockForm}
                  {...cityProps}
                  onChange={value => {
                    cityProps.onChange?.(value);
                    field.onChange(value);
                  }}
                />
              )}
            />
            <Controller
              name="roleId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  optionFilterProp="label"
                  type="secondary"
                  style={{ width: '100%' }}
                  value={field.value}
                  onChange={value => field.onChange(value)}
                  options={roles}
                  className="flex-1"
                  label="Rol (*)"
                  validation={{
                    message: 'El rol es requerido',
                    visible: Boolean(errors?.roleId),
                  }}
                />
              )}
            />
          </div>

          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <Input
                className="w100"
                label="Dirección"
                type="secondary"
                placeholder="Ingrese dirección"
                disabled={blockForm}
                {...field}
              />
            )}
          />
        </div>
      </Modal>
    );
  }
);

export interface IInstitutionTeamProps {}

export const InstitutionTeam: React.FC<IInstitutionTeamProps> = () => {
  const addModalRef = useRef<React.ElementRef<typeof InstitutionTeamNew>>(null);
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const { institution } = useInstitution();
  const [getUrl, loadingRef] = useApi();
  // const [officers, setOfficers] = useState<ICustonUserRole[]>([]);
  const [team, setTeam] = useState<IInstitutionTeamListViewModel[]>([]);
  const [filter, setFilter] = useState<string>();

  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      return data.filter(d => {
        const filterStr = filter.toLowerCase();
        const firstName = d[0].firstName?.value?.toLowerCase();
        const lastName = d[0].lastName?.value?.toLowerCase();
        const rut = d[0].rut?.value?.toLowerCase();
        const email = d[0].email?.value?.toLowerCase();
        const address = d[0].address?.value?.toLowerCase();
        const city = d[0].city?.value?.name?.toLowerCase();
        const role = d[0].role?.value?.name?.toLowerCase();
        return (
          firstName?.includes(filterStr) ||
          lastName?.includes(filterStr) ||
          rut?.includes(filterStr) ||
          email?.includes(filterStr) ||
          address?.includes(filterStr) ||
          city?.includes(filterStr) ||
          role?.includes(filterStr)
        );
      });
    },
    [filter]
  );

  function handleAddOfficer() {
    addModalRef.current?.open();
  }

  function onSubmitOfficer(user: ICustonUserRole, roleId: number, oldUser?: ICustonUserRole) {
    loadingRef.current?.setActive(true);

    if (oldUser?.userId) {
      const index = team.findIndex(item => item.userId === oldUser.userId);
      if (index === -1 || !team) {
        loadingRef.current?.setActive(false);
        return;
      }
      axios
        .put<IInstitutionRole>(
          `institution/${institution?.institutionId}/team/${roleId}/${oldUser.userId}`,
          user
        )
        .then(({ data }) => {
          if (data && data.user && data.role) {
            const newOfficers = [...team];
            newOfficers[index as number] = {
              ...(data.user as any),
              role: data.role,
              roleId: data.role.roleId,
            };
            setTeam(newOfficers);
          }
          addModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    } else {
      axios
        .post<IInstitutionRole>(`institution/${institution?.institutionId}/team`, user)
        .then(({ data }) => {
          console.log(data, 'user data');
          if (data && data.role)
            setTeam([
              {
                ...(data as any),
              },
              ...team,
            ]);
          addModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    }
  }

  function handleRemoveOfficer(row: any) {
    const userId = row.userId.value;
    const roleId = row.roleId.value;
    const officer = team.find(o => o.userId === userId && o.roleId === roleId);
    if (!officer) return;

    removeRef.current?.open(
      { userId, roleId: officer.roleId },
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar a{' '}
          <span className="text-emphasis">
            {officer.firstName} {officer.lastName}
          </span>{' '}
          de la lista de funcionarios?
        </p>
        <p>El item que desea eliminar contiene datos y no podrán ser recuperados.</p>
      </>
    );
  }

  function handleRemoveOfficerConfirm({ userId, roleId }: { userId: any; roleId: number }) {
    loadingRef.current?.setActive(true);
    axios
      .delete(`institution/${institution?.institutionId}/team/${userId}/${roleId}`)
      .then(() => {
        console.log(userId, roleId, team);
        setTeam(team.filter(o => !(o.userId === userId && o.roleId === roleId)));
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function handleUpdateOfficer(officerId: any) {
    const officer = team.find(o => o.userId === officerId);
    if (!officer) return;
    addModalRef.current?.open({ ...officer } as any);
  }

  useEffect(() => {
    getUrl<IInstitutionTeamListViewModel[]>(
      `institution/${institution?.institutionId}/team`,
      ({ data }) => {
        console.log({ data });
        setTeam(data);
      }
    );
  }, [institution?.institutionId, getUrl]);

  return (
    <div className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        okText="Eliminar Funcionario"
        className="line-normal"
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
        onSubmit={handleRemoveOfficerConfirm}
      />
      <InstitutionTeamNew ref={addModalRef} onSubmit={onSubmitOfficer} />
      <SearchBar
        type="mainAlt"
        buttonText="Agregar Funcionario"
        onSubmit={handleAddOfficer}
        onChange={evt => setFilter(evt.target.value)}
      />
      <Card
        className={`overflow-auto mt-14${team.length > 0 ? ' flex-1' : ''}`}
        style={{ maxHeight: '100%' }}
      >
        <Table
          bordered={false}
          className="table-row-large"
          fixedHeaders
          hoverable
          filter={tableFilter}
          handlers={{
            onRemove: handleRemoveOfficer,
            onUpdate: handleUpdateOfficer,
          }}
          headers={headers}
          data={team}
        />
        {team.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay funcionarios registrados para esta institución." />
          </div>
        )}
      </Card>
    </div>
  );
};
