import React from 'react';
import { Avatar, Button } from '@outlier-spa/component';
import { PointSeparator } from '../point-separator';
import {
  AddOutlined,
  BriefcaseFilled,
  CalendarOutlined,
  EditFilled,
  LocationFilled,
  PhoneOutlined,
  SandClockFilled,
  SchoolFilled,
} from '@outlier-spa/icon';

export interface IHeaderProps {
  title?: string;
  identifier?: string | number;
  email?: string;
  date?: string;
  frame?: string;
  institution?: string | number;
  phone?: string | number;
  address?: string;
  canSave?: boolean;
  editable?: boolean;
  descriptions?: React.ReactNode[];
  onUpdate?: () => void;
  onRemove?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
}

export const Header: React.FC<IHeaderProps> = ({
  title,
  editable,
  canSave,
  descriptions,
  identifier,
  email,
  institution,
  date,
  frame,
  phone,
  address,
  onSave,
  onCancel,
  onUpdate,
  onRemove,
}) => {
  return (
    <div className="flex flex-space flex-center line-normal pdb-14">
      <div className="flex flex-center">
        <Avatar
          className="flex  o-hidden flex-middle"
          email={canSave ? undefined : email || `${identifier}`}
          size="50px"
        >
          {canSave && title?.toLowerCase()?.includes('nuevo') ? (
            <AddOutlined className="text-16 text-emphasis" />
          ) : (
            <EditFilled className="text-16 text-emphasis" />
          )}
        </Avatar>
        <div className="ml-12">
          <h4 className="text-20 bold">{title}</h4>
          <PointSeparator
            className="text-secondary flex-wrap"
            items={[
              date && (
                <div className="flex flex-center">
                  <CalendarOutlined className="text-12 mr-6" />
                  <span>{date}</span>
                </div>
              ),
              frame && (
                <div className="flex flex-center">
                  <SandClockFilled className="text-12 mr-6" />
                  <span>{frame}</span>
                </div>
              ),
              email && (
                <div className="flex flex-center">
                  <BriefcaseFilled className="text-12 mr-6" />
                  <span>{email}</span>
                </div>
              ),
              phone && (
                <div className="flex flex-center">
                  <PhoneOutlined className="text-12 mr-6" />
                  <span>+56{phone}</span>
                </div>
              ),
              address && (
                <div className="flex flex-center">
                  <LocationFilled className="text-12 mr-6" />
                  <span>{address}</span>
                </div>
              ),
              institution && (
                <div className="flex flex-center">
                  <SchoolFilled className="text-12 mr-6" />
                  <span>{institution} instituciones</span>
                </div>
              ),
              ...(descriptions || []),
            ].filter((a) => !!a)}
          />
        </div>
      </div>
      {editable && (
        <div className="flex flex-center flex-gap-14">
          <Button
            size="small"
            bold
            className="pdh-20"
            label="Editar"
            onClick={onUpdate}
          />
          <Button
            size="small"
            bold
            type="danger"
            className="pdh-20"
            label="Eliminar"
            onClick={onRemove}
          />
        </div>
      )}
      {canSave && (
        <div className="flex flex-center flex-gap-14">
          <Button
            size="small"
            bold
            className="pdh-20"
            label="Cancelar"
            onClick={onCancel}
          />
          <Button
            size="small"
            bold
            type="assertive"
            className="pdh-20"
            label="Guardar Cambios"
            onClick={onSave}
          />
        </div>
      )}
    </div>
  );
};

Header.defaultProps = {
  editable: true,
};
