import React, { useEffect, useRef } from 'react';
import { LeftOutlined, RightOutlined } from '@outlier-spa/icon'
import cl from 'classnames';
import { StyledArrowScroll } from './style';

export interface IArrowScrollProps {
	className?: string;
	scrollClassName?: string;
};

export const ArrowScroll: React.FC<IArrowScrollProps> = ({className, scrollClassName, children}) => {
	const scrollRef = useRef<HTMLDivElement>(null);
	const [scroll, setScroll] = React.useState({ start: false, end: false });

	function onPrev(){
		if (!scrollRef.current) return;
		const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
		scrollRef.current.scrollTo({
			left: scrollLeft - (clientWidth/2),
			behavior: 'smooth',
		});
		setScroll({
			start: (scrollLeft - (clientWidth/2)) > 0,
			end: scrollLeft - (clientWidth/2) < scrollWidth,
		});
	}

	function onNext(){
		if (!scrollRef.current) return;
		const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
		scrollRef.current.scrollTo({
			left: scrollLeft + clientWidth/2,
			behavior: 'smooth',
		});
		setScroll({
			start: true,
			end: (scrollLeft + clientWidth*1.5) <= scrollWidth,
		});
	}

	useEffect(() => {
		if (!scrollRef.current) return;
		const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
		setScroll({
			start: scrollLeft !== 0,
			end: scrollLeft + clientWidth < scrollWidth,
		});
	}, [children]);

	const classNames = cl('arrow-scroll o-hidden relative', className, {
		'arrow-scroll-start': scroll.start,
		'arrow-scroll-end': scroll.end,
	});
	const scrollClassNames = cl('o-hidden arrow-scroll-content', scrollClassName);
	return (
		<StyledArrowScroll className={classNames}>
			<div onClick={onPrev} className='t200 arrow-left flex flex-middle pointer hover-emphasis text-18'>
				<LeftOutlined />
			</div>
			<div onClick={onNext} className='t200 arrow-right flex flex-middle pointer hover-emphasis text-18'>
				<RightOutlined />
			</div>
			<div ref={scrollRef} className={scrollClassNames}>
				{ children }
			</div>
		</StyledArrowScroll>
	);
};
