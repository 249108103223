import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import { Input, SectionHeader, Table, Tag } from "@outlier-spa/component";
import {
  DownloadPackageOutlined,
  DetailsOutlined,
  OptionsOutlined,
  LeftOutlined,
} from "@outlier-spa/icon";
import { Header } from "components";
import { useTitle } from "context";

export interface IProductProps {}

const headers: any = [
  { key: "code", value: "Código", sortable: true },
  { key: "buy", value: "Compra", sortable: true },
  { key: "registeredAt", value: "Registro" },
  { key: "invoice", value: "N° de factura" },
  { key: "condition", value: "Condición", sortable: true },
  { key: "options", value: "", width: 56 },
];

const Options = <OptionsOutlined className="flex text-secondary pointer" />;

const data = Array.from({ length: 10 }, () => ({
  code: "JBLT5101",
  buy: "dd/mm/yyyy",
  registeredAt: "dd/mm/yyyy",
  invoice: "-----",
  condition: "Bueno",
  options: Options,
}));

const Back = (
  <Link to="/product" className="flex flex-center flex-gap-8 text-16">
    <LeftOutlined className="text-label" />
    <span className="bold">Atrás</span>
  </Link>
);

const ProductDetail: React.FC<IProductProps> = () => {
  useTitle(Back);
  return (
    <div className="inner ant-fade-enter ant-fade-enter-active">
      <Header title="Audífonos JBL" />

      <SectionHeader
        size="small"
        className="mv-10 border-top-main"
        prefix={<DetailsOutlined className="text-emphasis" />}
        title="Datos principales"
      />

      <div className="flex flex-center flex-gap-14">
        <Input
          defaultValue="Audífonos JBL"
          label="Nombre"
          type="mainAlt"
          style={{ width: 300 }}
        />
        <div>
          <p className="mb-10">Disciplinas</p>
          <div className="flex flex-center flex-gap-10">
            <Tag label="Tenis" />
            <Tag label="Skate" />
            <Tag label="Coro" />
          </div>
        </div>
      </div>

      <div className="mt-20 mb-12">
        <Input label="Descripción" type="mainAlt" className="w100" />
      </div>

      <SectionHeader
        size="small"
        className="mv-10 border-top-main"
        prefix={<DownloadPackageOutlined className="text-emphasis" />}
        title="Ítems"
      />

      <Table fixed hoverable headers={headers} data={data} />
    </div>
  );
};

export const Product: React.FC<IProductProps> = () => {
  return (
    <Routes>
      <Route path="*" element={<ProductDetail />} />
    </Routes>
  );
};
