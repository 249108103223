import React from 'react';
import { SwitchOptions } from '@outlier-spa/component';
import styled from 'styled-components';

const StyledSurvey = styled.div`
  .switch-options {
    .switch-option-content {
      min-width: 0;
      padding: 0 0.8rem;
      font-size: 1rem;
    }
  }
`;

export interface ISurveyProps {}

export const Survey: React.FC<ISurveyProps> = () => {
  return (
    <StyledSurvey className="flex flex-column flex-vgap-14">
      <SurveyItem label="Iaculis tincidunt faucibus morbi convallis scelerisque neque. Nisi commodo, in ultricies morbirus" />
      <SurveyItem label="Aliquam proin mi ullamcorper lectus. " />
      <SurveyItem label="Malesuada nisi tortor, interdum purus viverra lorem tristique ultricies. Dui sit." />
      <SurveyItem label="Enim, enim elementum libero massa. Enim tristique non egestas urna, ut integer. Est." />
      <SurveyItem label="Non sociis interdum augue egestas. Velit eu magna arcu duis purus a vel vitae nisi tincidunt." />
    </StyledSurvey>
  );
};

const SurveyItem: React.FC<{ label: string }> = ({ label }) => {
  return (
    <div className="flex flex-center flex-space">
      <p>{label}</p>
      <SwitchOptions
        size="small"
        options={[
          { content: '1' },
          { content: '2' },
          { content: '3' },
          { content: '4' },
          { content: '5' },
        ]}
      />
    </div>
  );
};
