import { Table } from "@outlier-spa/component";
import styled from "styled-components";

export const StyledTable = styled(Table)`
  tbody tr {
    td .status-cell {
      .button:hover {
				border-color: ${({ theme }) => theme.border.secondary};
        /* .text-action {
          display: none;
          + span {
            margin: 0;
          }
        } */
      }
      /* button {
        border-color: transparent;
        background: none;
        box-shadow: none;

        .text-action {
          display: none;
          + span {
            margin: 0;
          }
        }
      } */
    }
    /* &:hover {
      td {
        .status-cell {
          > span:not(.hover-cell) {
            text-decoration: underline;
          }
          .button {
            .text-action {
              display: block;
              + span {
                display: none;
              }
            }
          }
          button {
            background: ${({ theme }) => theme.colors.emphasis};
            border-color: ${({ theme }) => theme.border.main};
            color: white;
            .text-action {
              display: block;
              + span {
                display: none;
              }
            }
          }
        }
      }
    } */
  }
`;
