import {
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Select,
  Table,
} from '@outlier-spa/component';
import { axios } from '@outlier-spa/fetch';
import { SearchOutlined } from '@outlier-spa/icon';
import { useCity } from 'hooks';
import { IEvent, IInstitution } from 'interfaces';
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyledModalEvent } from './style';

interface IEventModalRef {
  open: (event?: IEvent) => void;
  close: () => void;
}

export interface IEventModalProps {
  onSubmit: (event: IEvent, prevEvent?: IEvent) => void;
}

const CheckComponent = ({ row }: any) => {
  return (
    <div>
      <Checkbox type="mainAlt" checked={row.checked?.value} />
    </div>
  );
};

const tableHeaders = [
  { key: 'cityId', value: '', cellComponent: CheckComponent, width: 32 },
  { key: 'name', value: <strong className="bold">Establecimiento</strong> },
  {
    key: 'city',
    value: <strong className="bold">Ciudad</strong>,
    cellComponent: ({ value }: any) => value.name,
  },
];

export const EventModal = React.forwardRef<IEventModalRef, IEventModalProps>(
  ({ onSubmit }, ref) => {
    const modalRef = useRef<React.ElementRef<typeof Modal>>(null);
    const {
      control,
      handleSubmit,
      reset,
      setValue,
      formState: { errors, isValid },
    } = useForm();
    const { regionProps, provinceProps, cityProps, cityId, onChangeCity } =
      useCity();
    const [institutions, setInstitutions] = useState<
      (IInstitution & { checked?: boolean })[]
    >([]);
    const [filter, setFilter] = useState<string>('');
    const filteredInstitutions = useMemo(() => {
      return institutions.filter(
        (institution: IInstitution & { checked?: boolean }) => {
          return institution.name.toLowerCase().includes(filter.toLowerCase());
        }
      );
    }, [institutions, filter]);
    const [institutionIds, setInstitutionIds] = useState<number[]>([]);
    const [event, setEvent] = useState<IEvent>();

    function open(event?: IEvent) {
      setEvent(event);
      if (event?.cityId) onChangeCity(event.cityId);
      reset(event);
      if ((event as any)?.institutionIds) {
        setInstitutionIds((event as any).institutionIds);
      }
      modalRef.current?.open();
    }

    function close() {
      modalRef.current?.close();
    }

    function onSubmitData(data: any) {
      data.cityId = cityId;
      if (cityId) data.cityId = cityId;
      data.eventInstitutions = institutionIds.map((institutionId) => ({
        institutionId,
      }));
      onSubmit(data, event);
    }

    function handleRowClick(row: any) {
      const institutionId = row.institutionId.value;
      const index = institutionIds.indexOf(institutionId);
      if (index === -1) {
        setInstitutionIds([...institutionIds, institutionId]);
      } else {
        setInstitutionIds(
          institutionIds.filter((id: number) => id !== institutionId)
        );
      }
    }

    useImperativeHandle(ref, () => ({ open, close }));

    useEffect(() => {
      axios
        .get<IInstitution[]>('institution')
        .then(({ data }) => setInstitutions(data));
    }, []);

    useEffect(() => {
      if (!institutionIds) return;
      setValue('institutionIds', institutionIds);
      setInstitutions((institutions) =>
        institutions.map((i: any) => ({
          ...i,
          checked: institutionIds.includes(i.institutionId),
        }))
      );
    }, [institutionIds, setValue]);

    return (
      <Modal
        ref={modalRef}
        okText={event?.eventId ? 'Guardar Cambios' : 'Crear Evento'}
        okProps={{ type: 'assertive' }}
        title={event?.eventId ? 'Editar Evento' : 'Nuevo Evento'}
        size="small"
        overlayClose={false}
        onSubmit={handleSubmit(onSubmitData)}
      >
        <StyledModalEvent
          className="pdh-14 flex flex-column flex-vgap-14 pdv-16 flex-1 h100 overflow-auto"
          style={{ maxHeight: '520px' }}
        >
          <div className="flex flex-center flex-gap-20">
            <Controller
              name="name"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Input
                  type="secondary"
                  validation={{
                    message: 'Debe ingresar un nombre',
                    visible: errors?.name !== undefined,
                  }}
                  placeholder="Ingrese nombre"
                  className="flex-1"
                  label="Nombre"
                  {...field}
                />
              )}
            />
          </div>

          <div className="flex flex-center flex-gap-20">
            <div className="flex-1">
              <label className="flex mb-10">Fecha de inicio</label>
              <Controller
                name="startDate"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <DatePicker
                    type="secondary"
                    style={{ width: '100%' }}
                    validation={{
                      message: 'Fecha inválida',
                      visible: errors?.startDate !== undefined,
                    }}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />
            </div>
            <div className="flex-1">
              <label className="flex mb-10">Fecha de término</label>
              <Controller
                name="endDate"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <DatePicker
                    type="secondary"
                    validation={{
                      message: 'Fecha inválida',
                      visible: errors?.endDate !== undefined,
                    }}
                    style={{ width: '100%' }}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex flex-center flex-gap-20">
            <Select
              optionFilterProp="label"
              style={{ width: '100%' }}
              type="secondary"
              className="flex-1"
              label="Region"
              {...regionProps}
            />
            <Select
              optionFilterProp="label"
              style={{ width: '100%' }}
              type="secondary"
              className="flex-1"
              label="Provincia"
              {...provinceProps}
            />
          </div>

          <div className="flex flex-center flex-gap-20">
            <Select
              optionFilterProp="label"
              style={{ width: '100%' }}
              type="secondary"
              className="flex-1"
              label="Ciudad"
              {...cityProps}
            />
            <Controller
              name="address"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Input
                  type="secondary"
                  placeholder="Ingrese dirección"
                  validation={{
                    message: 'Debe ingresar una dirección',
                    visible: errors?.address !== undefined,
                  }}
                  className="flex-1"
                  label="Dirección"
                  {...field}
                />
              )}
            />
          </div>

          <div style={{ minHeight: 200 }} className="flex flex-column">
            <p className="mb-10">Establecimientos</p>
            <div className="table-institutions border-main flex-1 flex flex-column ">
              <div className="pd-10 border-bottom-main">
                <Input
                  value={filter}
                  type="secondary"
                  onChange={(evt) => setFilter(`${evt.target.value}`)}
                  prefix={<SearchOutlined />}
                  placeholder="Filtrar establecimientos"
                />
              </div>
              <div className="overflow-hidden-x relative">
                <Table
                  bordered={false}
                  cellClassName="pdh-8"
                  fixedHeaders
                  hoverable
                  onRowClick={handleRowClick}
                  headers={tableHeaders}
                  data={filteredInstitutions}
                />
              </div>
            </div>
          </div>
        </StyledModalEvent>
      </Modal>
    );
  }
);
