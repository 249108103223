import { Input, NotFound, Table, Tag } from '@outlier-spa/component';
import { useApi } from '@outlier-spa/fetch';
import { SearchOutlined } from '@outlier-spa/icon';
import { LoadingKiri } from 'components';
import { DefaultCell } from 'components/table';
import { IEventViewModel } from 'interfaces/event';
import { IStudentViewModel } from 'interfaces/student';
import React, { useEffect, useMemo, useState } from 'react';

export interface IEventAttendanceProps {
  event: IEventViewModel;
}

const DisciplineRender = ({ disciplines }: { disciplines: any }) => {
  return (
    <span className="flex-gap-6">
      {disciplines.value.length === 0 && '-'}
      {disciplines.value.map((d: string, i: number) => (
        <Tag size="small" rounded key={`${d}-${i}`} value={d} label={d} />
      ))}
    </span>
  );
};

const normalizeText = (value: string) =>
  value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const EventAttendance: React.FC<IEventAttendanceProps> = ({ event }) => {
  const [getUrl, loadingRef] = useApi();
  const [filter, setFilter] = useState<string>('');
  const [users, setUsers] = useState<IStudentViewModel[]>();

  const filterUsers = useMemo(() => {
    return users?.filter((user: IStudentViewModel) => {
      const filterLower = normalizeText(filter.toLowerCase());
      const firstNameNormalized = normalizeText(user.firstName).toLowerCase();
      const lastNameNormalized = normalizeText(user.lastName).toLowerCase();
      const disciplineNormalized = user.disciplines
        ?.map((d) => normalizeText(d).toLowerCase())
        .filter((d: string) => d.includes(filterLower));
      const institutionNormalized = normalizeText(
        `${user?.institution?.name}`
      ).toLowerCase();

      return (
        firstNameNormalized.includes(filterLower) ||
        lastNameNormalized.includes(filterLower) ||
        user.rut.includes(filterLower) ||
        (disciplineNormalized && disciplineNormalized?.length > 0) ||
        institutionNormalized.includes(filterLower)
      );
    });
  }, [filter, users]);

  useEffect(() => {
    getUrl<IStudentViewModel[]>(
      `/event/${event.eventId}/participants`,
      ({ data }) => {
        console.log(data);
        setUsers(data);
      }
    );
  }, [event, getUrl]);

  return (
    <div className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex o-hidden flex-gap-20 relative">
      <LoadingKiri ref={loadingRef} />
      {(users?.length === 0 || !users) && (
        <NotFound content="No existen estudiantes con asistencia para este eventos" />
      )}
      {users && users?.length > 0 && (
        <div className="flex-1 flex flex-column o-hidden">
          <h6 className="bold mb-14 text-secondary">Asistencia</h6>
          <Input
            value={filter}
            onChange={(evt) => setFilter(evt.target.value)}
            placeholder="Escriba algún parámetro para filtrar..."
            type="mainAlt"
            prefix={<SearchOutlined className="text-secondary" />}
          />

          <div className="flex-1 o-hidden mt-14 relative overflow-hidden-x">
            <Table
              className="h100 overflow-hidden-x"
              fixedHeaders
              hoverable
              headers={[
                {
                  key: 'firstName',
                  sortable: true,
                  value: <strong className="bold">Nombre</strong>,
                },
                {
                  key: 'lastName',
                  sortable: true,
                  value: <strong className="bold">Apellido</strong>,
                },
                {
                  key: 'rutWithFormat',
                  sortable: true,
                  value: <strong className="bold">Rut</strong>,
                },
                {
                  key: 'disciplines',
                  value: <strong className="bold">Disciplinas</strong>,
                  cellComponent: (value: any) => (
                    <DisciplineRender disciplines={value} />
                  ),
                },
                {
                  key: 'institution',
                  value: <strong className="bold">Institución</strong>,
                  cellComponent: ({ value }: any) => (
                    <DefaultCell value={value?.name} />
                  ),
                },
              ]}
              data={filterUsers}
            />
          </div>
        </div>
      )}
    </div>
  );
};
