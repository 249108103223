import React from 'react';
import {
	Section,
	Avatar,
} from '@outlier-spa/component';

export interface ICommentProps {
	className?: string;
	name?: string;
	email?: string;
	date?: moment.Moment;
	content?: React.ReactNode;
};

export const Comment: React.FC<ICommentProps> = ({className, name, email, date, content}) => {
	return (
		<Section bordered={false} type='secondary' className={className}>
			<div className='pdh-14 pdv-12'>
				<div className='flex flex-center flex-space'>
					<div className='flex flex-center'>
						<Avatar email={email} className='o-hidden' size='26px' />
						<span className='bold ml-8'>{name} - Monitor</span>
					</div>
					<p className='text-secondary'>{date?.format('DD/MM/YYYY [a las] HH:mm') ?? '-'}</p>
				</div>
			</div>
			<p className='pdh-14 pdb-14'>
				{content}
			</p>
		</Section>
	);
};
