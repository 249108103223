import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Card,
  Confirm,
  NotFound,
  SearchBar,
  Table,
} from '@outlier-spa/component';
import { RiskFilled } from '@outlier-spa/icon';
import { LoadingKiri } from 'components';
import { useApi } from '@outlier-spa/fetch';
import { useInstitution } from 'context/institution';
import { DefaultCell } from 'components/table';

const headers: any = [
  { key: 'courseName', value: 'Taller', cellComponent: DefaultCell },
  { key: 'classOverTotal', value: 'Clases', cellComponent: DefaultCell },
  // { key: 'class', value: 'Clase', sortable: true },
  {
    key: 'incidents',
    value: 'Incidentes',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'studentsInvolved',
    value: 'Alumnos involucrados',
    sortable: true,
    cellComponent: DefaultCell,
  },
  // { key: 'dateOfBirth', value: 'F. nacimiento', sortable: true, cellComponent: (props: { value: any }) => moment(props.value).format("DD-MM-YYYY") ?? '-' },
  // { key: '', value: '', cellComponent: PopoverOptions },
];

interface IClassIncidents {
  courseName: string;
  classOverTotal: string;
  incidents: number;
  studentsInvolved: number;
}

export interface IInstitutionIncidentsProps {}

export const InstitutionIncidents: React.FC<
  IInstitutionIncidentsProps
> = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const { institution } = useInstitution();
  const [getUrl, loadingRef] = useApi();
  const [incidents, setIncidents] = useState<IClassIncidents[]>([]);
  const [filter, setFilter] = useState<string>();

  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      const filterStr = filter.toLowerCase();
      return data.filter((d) => {
        const firstName = d[0].firstName?.value?.toLowerCase();
        return firstName?.includes(filterStr);
      });
    },
    [filter]
  );

  function onRemove() {
    console.log('onremove');
  }

  function handleRemoveIncident(incidentId: any) {
    removeRef.current?.open(
      incidentId,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este incidente?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  useEffect(() => {
    if (!institution?.institutionId) return;
    getUrl<IClassIncidents[]>(
      `/institution/${institution.institutionId}/incidents`,
      ({ data }) => {
        console.log(data);
        setIncidents(data);
      }
    );
  }, [institution?.institutionId, getUrl]);

  return (
    <div className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        okText="Eliminar Incidente"
        className="line-normal"
        title={
          <div className="flex flex-center flex-gap-6 ">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
        onSubmit={onRemove}
      />
      <SearchBar
        type="mainAlt"
        buttonVisible={false}
        onChange={(evt) => setFilter(evt.target.value)}
      />
      <Card
        className={`overflow-auto mt-14${
          incidents.length > 0 ? ' flex-1' : ''
        }`}
        style={{ maxHeight: '100%' }}
      >
        <Table
          bordered={false}
          className="table-row-large"
          filter={tableFilter}
          fixedHeaders
          hoverable
          handlers={{
            onRemove: handleRemoveIncident,
          }}
          headers={headers}
          data={incidents}
        />
        {incidents.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay incidentes registrados para esta institución." />
          </div>
        )}
      </Card>
    </div>
  );
};
