import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, Card, SectionHeader, Tag } from '@outlier-spa/component';
import {
  EditFilled,
  TrashcanFilled,
  FileFilled,
  RefreshOutlined,
  LocationFilled,
  CalendarOutlined,
  QuestionFilled,
  SurveyFilled,
  SandClockFilled,
  UserFilled,
} from '@outlier-spa/icon';
import { PointSeparator } from '../point-separator';
import { IDisciplineProgram, ISession, IFile, IEvent } from 'interfaces';
import { PopoverOptions } from 'components/options';
import {
  ClassIcon,
  disciplineIcons,
  EventIcon,
  SchoolIcon,
} from 'components/icon';
import { IEventViewModel } from 'interfaces/event';

interface ISessionCardProps {
  session: ISession;
  files?: File[] | IFile[];
  editable?: boolean;
}

export const SessionCard: React.FC<ISessionCardProps> = ({
  session,
  files,
  editable,
}) => {
  return (
    <Card className="line-normal">
      <SectionHeader
        className="flex-space pdh-14"
        title={session.name}
        suffix={
          editable && (
            <div className="flex flex-center flex-gap-10 text-secondary">
              <EditFilled className="pointer hover-warning" />
              <TrashcanFilled className="pointer hover-danger" />
            </div>
          )
        }
      />
      <p className="pdb-6 pdh-14">{session.description}</p>
      <PointSeparator
        items={[
          <div className="flex flex-center">
            <ClassIcon className="text-12 mr-6" />
            <span>{session.classes ?? 0} Clase(s)</span>
          </div>,
          ...(files?.map((file, index) => (
            <div key={`${file.name}-${index}`} className="flex flex-center">
              <FileFilled className="text-12 mr-6" />
              <span>{file.name}</span>
            </div>
          )) ?? []),
        ]}
        className="pdv-16 pdh-14 text-secondary"
      />
    </Card>
  );
};

interface IProgramCardProps {
  disciplineId?: string;
  program: IDisciplineProgram;
  onRemove?: (programId: number, program: IDisciplineProgram) => void;
  onUpdate?: (programId: number, program: IDisciplineProgram) => void;
}

export const ProgramCard: React.FC<IProgramCardProps> = ({
  program,
  disciplineId,
  onRemove,
  onUpdate,
}) => {
  const length = program.levels?.length;
  const title = `${program.discipline}${
    program.levels?.length
      ? ` para ${length === 12 ? 'todos' : program.levels?.join(', ')}`
      : ''
  }`;
  return (
    <Link to={`/program/${program.programId}/${program.version}`}>
      <Card className="pointer">
        <SectionHeader
          className="flex-space pdh-12"
          title={title}
          suffix={
            <PopoverOptions
              value={program.programId}
              row={program}
              handlers={{ onRemove, onUpdate } as any}
            />
          }
        />
        <p className="pdh-12 pdv-6">{program.description}</p>
        <PointSeparator
          className="text-secondary pdv-16 pdh-12"
          items={[
            <div className="flex flex-center">
              {disciplineIcons[disciplineId ?? '0']}
              <span>{program.discipline}</span>
            </div>,
            <div className="flex flex-center">
              <ClassIcon className="text-12 mr-6" />
              <span>{program.sessions} Sesiones</span>
            </div>,
            <div className="flex flex-center">
              <RefreshOutlined className="text-12 mr-6" />
              <span>Versión {program.version}</span>
            </div>,
            <div className="flex flex-center">
              <span>{program.classes ?? 0} Clases</span>
            </div>,
          ]}
        />
      </Card>
    </Link>
  );
};

export interface IEventCardProps {
  upcoming?: boolean;
  event: IEventViewModel;
  readonly?: boolean;
  onRemove?: (eventId: number, event: IEvent) => void;
  onUpdate?: (eventId: number, event: IEvent) => void;
}

export const EventCard: React.FC<IEventCardProps> = ({
  upcoming,
  event,
  readonly,
  onRemove,
  onUpdate,
}) => {
  const location = useLocation();
  return (
    <Link to={`/event/${event.eventId}`} state={location}>
      <Card className="pointer line-normal">
        <div className="pd-14 flex flex-center flex-space">
          <div className="flex flex-center flex-gap-8">
            <Avatar className="border-main flex flex-middle" size="26px">
              <EventIcon
                className={upcoming ? 'text-emphasis' : 'text-secondary'}
              />
            </Avatar>
            <h5 className="text-16 bold">{event.name}</h5>
          </div>
          {!readonly && (
            <PopoverOptions
              value={event.eventId}
              row={event}
              handlers={{ onRemove, onUpdate } as any}
            />
          )}
        </div>
        <div className="pdh-14" style={{ marginBottom: 14 }}>
          <div className="text-secondary flex flex-column flex-vgap-6">
            <div className="flex flex-center">
              <LocationFilled />
              <p className="ml-6">{event.address}</p>
            </div>
            {!readonly && (
              <div className="flex flex-center">
                <SchoolIcon />
                <p className="ml-6">
                  {event.institutions?.length ?? 0} establecimientos
                </p>
              </div>
            )}
            {readonly && (
              <div className="flex flex-center">
                <CalendarOutlined />
                <p className="ml-6 text-secondary">
                  {event.duration ?? `${event.startDate} al ${event.endDate}`}
                </p>
              </div>
            )}
          </div>
        </div>
        {!readonly && (
          <div className="pdh-14 pdv-16 flex flex-space text-secondary">
            <div className="flex flex-center">
              <CalendarOutlined />
              <p className="ml-6 text-secondary">
                {event.duration ?? `${event.startDate} al ${event.endDate}`}
              </p>
            </div>
            <Tag
              data-tooltip="Código del evento"
              rounded
              size="small"
              label={<span className="code">{event.code}</span>}
            />
          </div>
        )}
      </Card>
    </Link>
  );
};

// name: nombre de la encuesta
// questions: número de preguntas

export interface ISurveyCardProps {
  name: string;
  questions?: number;
  pollId?: string | number;
}

export const SurveyCard: React.FC<ISurveyCardProps> = ({
  name,
  questions,
  pollId,
}) => {
  const location = useLocation();
  return (
    <Link to={`/survey/available/${pollId}/summary`} state={location}>
      <Card className="pointer line-normal">
        <div className="pd-14 flex flex-center flex-space">
          <div className="flex flex-center flex-gap-8">
            <Avatar className="border-main flex flex-middle" size="26px">
              <SurveyFilled className="text-emphasis" />
            </Avatar>
            <h5 className="text-16 bold">{name}</h5>
          </div>
        </div>
        <div className="pdh-14" style={{ marginBottom: 14 }}>
          <div className="text-secondary flex flex-column flex-vgap-6">
            <div className="flex flex-center">
              <QuestionFilled />
              <p className="ml-6">{questions} preguntas</p>
            </div>
          </div>
        </div>
      </Card>
    </Link>
  );
};
