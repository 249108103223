import React from 'react';

export const InstitutionCourseDocuments: React.FC = () => {
	return (
		<div className='ant-fade-enter ant-fade-enter-active'>
			<h4>
				Documentos!.
			</h4>
		</div>
	);
}
