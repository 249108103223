import React from 'react';
import cl from 'classnames';
import styled from 'styled-components';

const StyledPointSeparator = styled.div`
	.point-separator {
		width: 4px;
		height: 4px;
		border-radius: 4px;
		background: currentColor;
	}
`;

interface IPointSeparatorProps {
	className?: string;
	items?: React.ReactNode[];
};

export const PointSeparator: React.FC<IPointSeparatorProps> = ({className, items}) => {
	const classNames = cl(className, 'flex flex-center flex-gap-8');
	return (
		<StyledPointSeparator className={classNames}>
			{items?.map((item, index) => (
				<div className='flex flex-center flex-gap-8' key={index}>
					{index > 0 && <div className='point-separator' />}
					<div>{item}</div>
				</div>
			))}
		</StyledPointSeparator>
	);
}
