import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axios, useApi } from '@outlier-spa/fetch';
import {
  Avatar,
  SearchBar,
  Table,
  Confirm,
  Card,
  NotFound,
} from '@outlier-spa/component';
import { RiskFilled, SchoolFilled } from '@outlier-spa/icon';
import { IInstitution } from 'interfaces';
import { LoadingKiri, PopoverOptions } from 'components';
import { DefaultCell } from 'components/table';
import { InstitutionModal } from 'components/modal/institution';

const Image = ({ row }: { row: any }) => {
  return (
    <Avatar
      className="flex flex-middle o-hidden"
      size="2.4rem"
      email={row.email?.value}
    >
      {!row.email?.value && <SchoolFilled className="text-16 text-secondary" />}
    </Avatar>
  );
};

const tableHeaders: any = [
  { key: 'image', value: '', width: 70, cellComponent: Image },
  { key: 'name', value: 'Nombre', sortable: true },
  { key: 'email', value: 'Correo', sortable: true, cellComponent: DefaultCell },
  {
    key: 'phoneNumber',
    value: 'Teléfono',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'address',
    value: 'Dirección',
    sortable: true,
    cellComponent: DefaultCell,
  },
  { key: 'city', value: 'Ciudad', sortable: true, cellComponent: DefaultCell },
  { key: 'institutionId', value: '', width: 45, cellComponent: PopoverOptions },
];

export interface IInstitutionListProps {}

export const InstitutionList: React.FC<IInstitutionListProps> = () => {
  const navigate = useNavigate();
  const addModalRef = useRef<React.ElementRef<typeof InstitutionModal>>(null);
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const [institutions, setInstitutions] = useState<IInstitution[]>();
  const [filter, setFilter] = useState<string>();
  const [getUrl, loadingRef] = useApi();
  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      return data.filter((d) => {
        const filterStr = filter.toLowerCase();
        const name = d[0].name?.value?.toLowerCase();
        const email = d[0].email?.value?.toLowerCase();
        const address = d[0].address?.value?.toLowerCase();
        const city = d[0].city?.value?.name?.toLowerCase();
        const phone = d[0].phoneNumber?.value?.toLowerCase();
        return (
          name?.includes(filterStr) ||
          email?.includes(filterStr) ||
          address?.includes(filterStr) ||
          city?.includes(filterStr) ||
          phone?.includes(filterStr)
        );
      });
    },
    [filter]
  );

  function onRowClick(dataItem: any) {
    navigate(`/institution/${dataItem.institutionId.key}`);
  }

  function handleRemove(institutionId: any) {
    removeRef.current?.open(
      institutionId,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar esta institución?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  function onRemove(institutionId: number) {
    loadingRef.current?.setActive(true);
    axios
      .delete(`/institution/${institutionId}`)
      .then(() => {
        setInstitutions(
          institutions?.filter((inst) => inst.institutionId !== institutionId)
        );
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function handleUpdate(institutionId: any) {
    addModalRef.current?.open(
      institutions?.find((inst) => inst.institutionId === institutionId)
    );
  }

  function handleAddInstitution() {
    addModalRef.current?.open();
  }

  function onSubmitInstitution(
    institution: IInstitution,
    prevInstitution?: IInstitution
  ) {
    loadingRef.current?.setActive(true);
    if (prevInstitution) {
      const index = institutions?.indexOf(prevInstitution);
      if (index === -1 || !institutions) {
        loadingRef.current?.setActive(false);
        return;
      }
      axios
        .put<IInstitution>('institution', institution)
        .then(({ data }) => {
          const newInstitutions = [...institutions];
          newInstitutions[index as number] = data;
          setInstitutions(newInstitutions);
          addModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    } else {
      axios
        .post<IInstitution>('institution', institution)
        .then(({ data }) => {
          setInstitutions([data, ...(institutions ?? [])]);
          loadingRef.current?.setActive(false);
          addModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    }
  }

  useEffect(() => {
    getUrl<IInstitution[]>('/institution', ({ data }) => {
      setInstitutions(data);
    });
  }, [getUrl]);

  return (
    <div className="inner ant-fade-enter ant-fade-enter-active h100 o-hidden flex flex-column">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        ref={removeRef}
        okText="Eliminar Institución"
        className="line-normal"
        onSubmit={onRemove}
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
      />
      <InstitutionModal ref={addModalRef} onSubmit={onSubmitInstitution} />
      <h2 className="text-20 bold mb-16">Establecimientos</h2>
      <SearchBar
        onChange={(evt) => setFilter(evt.target.value)}
        buttonText="Agregar Establecimiento"
        type="mainAlt"
        onSubmit={handleAddInstitution}
      />
      <div className="fles-1 mt-14 o-hidden">
        <Card className="overflow-auto relative" style={{ maxHeight: '100%' }}>
          <Table
            hoverable
            fixedHeaders
            filter={tableFilter}
            bordered={false}
            className="table-row-large"
            handlers={{
              onRemove: handleRemove,
              onUpdate: handleUpdate,
            }}
            onRowClick={onRowClick}
            headers={tableHeaders}
            data={institutions}
          />
          {institutions?.length === 0 && (
            <div className="relative" style={{ height: 300 }}>
              <NotFound content="No hay establecimientos registrados." />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};
