import React, { useEffect, useMemo, useRef, useState } from 'react';
import cl from 'classnames';
import { StyledSchedule, StyledCard } from './style';
import { ISchedule } from 'interfaces';
import moment from 'moment';
import { getColours } from '@outlier-spa/color';
import {
  Button,
  DatePicker,
  ISwitchOption,
  Popover,
  SwitchOptions,
} from '@outlier-spa/component';
import { ICourseScheduleViewModel } from 'interfaces/course';

const colors = getColours(20);

export interface IScheduleProps {
  editable?: boolean;
  className?: string;
  schedules?: ICourseScheduleViewModel[];
  onChange?: (schedules: ICourseScheduleViewModel[]) => void;
}

interface IDay {
  name: string;
  selected: boolean;
  color?: string;
}

interface IScheduleItem {
  hour: number;
  minutes: number;
  days: IDay[];
}

const days = [
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
  'Domingo',
];
const start = 8;
const end = 20;
const step = 30;

function getRows() {
  const rows: IScheduleItem[] = [];
  const totalMinutes = (end - start) * 60;
  for (let i = 0; i <= totalMinutes; i += step) {
    rows.push({
      hour: Math.floor(start + i / 60),
      minutes: i % 60,
      days: days.map((name) => ({ name, selected: false })),
    });
  }
  return rows;
}

export const Schedule: React.FC<IScheduleProps> = ({
  schedules,
  editable,
  className,
  onChange,
}) => {
  const [rows, setRows] = React.useState(getRows());
  const select = useRef<boolean | null>(null);

  function update(rowIndex: number, dayIndex: number) {
    if (select.current === null) return;
    const newRows = [...rows];
    newRows[rowIndex].days[dayIndex].selected = select.current;
    if (!select.current) newRows[rowIndex].days[dayIndex].color = undefined;
    setRows(newRows);
  }

  function onDown(rowIndex: number, dayIndex: number) {
    if (!editable) return;
    select.current = !rows[rowIndex].days[dayIndex].selected;
    update(rowIndex, dayIndex);
  }

  function onUp() {
    if (!editable) return;
    select.current = null;

    // grouped row by day
    var rowsByDay: any[] = [];
    days.forEach((_day, index) => {
      rows.forEach((row, rowIndex) => {
        if (row.days[index].selected) {
          rowsByDay.push({
            scheduleId: (row.days[index] as any).scheduleId,
            rowIndex: rowIndex,
            day: index + 1,
            start: moment(`${row.hour}:${row.minutes}`, 'HH:mm').format(
              'HH:mm'
            ),
          });
        }
      });
    });

    // grouped by day and hour if difference between rowindex is less than 2
    const schedules: ICourseScheduleViewModel[] = rowsByDay.reduce(
      (acc, curr, currentIndex) => {
        const prevRowIndex =
          currentIndex > 0 ? rowsByDay[currentIndex - 1].rowIndex : -1;
        const last = acc[acc.length - 1];
        if (
          last &&
          last.day === curr.day &&
          Math.abs(prevRowIndex - curr.rowIndex) < 2
        ) {
          last.duration += 30;
        } else {
          curr.duration = 30;
          acc.push(curr);
        }
        return acc;
      },
      []
    );

    console.log(schedules);
    onChange && onChange(schedules);
  }

  function onChangeSchedule(
    schedule: ICourseScheduleViewModel,
    updated: ICourseScheduleViewModel
  ) {
    const index = schedules?.indexOf(schedule);
    if (index === undefined || index < 0) return;
    const newSchedules = [...(schedules ?? [])];
    newSchedules[index] = updated;
    onChange?.(newSchedules);
  }

  useEffect(() => {
    schedules?.forEach((schedule, i) => {
      var start = moment(schedule.start, 'HH:mm');
      var end = start.clone().add(schedule.duration, 'minutes');
      // rows.forEach((row) => row.days.forEach((day) => (day.color = undefined)));
      var cols = rows.filter((row) => {
        var rowStart = moment(`${row.hour}:${row.minutes}`, 'HH:mm');
        if (rowStart < start) return false;
        var rowEnd = rowStart.clone().add(step, 'minutes');
        return rowEnd <= end || end.isBetween(rowStart, rowEnd, 'minutes');
      });
      cols.forEach((c) => {
        (c.days[schedule.day - 1] as any).scheduleId = schedule.scheduleId;
        c.days[schedule.day - 1].selected = true;
        c.days[schedule.day - 1].color = colors[i];
      });
    });
    setRows([...rows]);
  }, [schedules]);

  const classNames = cl('line-normal', className);
  return (
    <StyledSchedule className={classNames}>
      <table>
        <thead className="bold">
          <tr>
            <th style={{ width: 56 }}>Hora</th>
            {days.map((day) => (
              <th key={day}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <Tr
              onUp={onUp}
              onDown={onDown}
              onEnter={update}
              key={`${row.hour}-${row.minutes}`}
              rowIndex={rowIndex}
              row={row}
            />
          ))}
        </tbody>
      </table>
      <div className="flex flex-1 flex-column mv-16">
        <h1>Resumen Selección</h1>
        <div className="schedule-cards flex-1 inline-flex relative ">
          {schedules?.map((schedule, i) => (
            <ScheduleCard
              key={`${schedule.day}${schedule.start}`}
              editable={editable}
              schedule={schedule}
              color={colors[i]}
              onChange={onChangeSchedule}
            />
          ))}
        </div>
      </div>
    </StyledSchedule>
  );
};

interface IScheduleCardProps {
  schedule: ICourseScheduleViewModel;
  color: string;
  editable?: boolean;
  onChange?: (
    schedule: ICourseScheduleViewModel,
    updated: ICourseScheduleViewModel
  ) => void;
}

const ScheduleCard: React.FC<IScheduleCardProps> = ({
  schedule,
  color,
  editable,
  onChange,
}) => {
  const [value, setValue] = useState<ICourseScheduleViewModel>();
  const start = moment(schedule.start, 'HH:mm:ss');
  const end = moment(schedule.start, 'HH:mm:ss').add(
    schedule.duration,
    'minutes'
  );
  const truncateDays = useMemo(() => {
    return days.map((day, idx) => ({
      key: idx.toString(),
      content: day.substring(0, 3),
    }));
  }, []);

  function handleOnChangeDate(
    option: ISwitchOption | ISwitchOption[],
    index: number,
    indexes?: number[]
  ) {
    console.log(option, index, indexes);
    if (value !== undefined) {
      setValue({ ...value, day: index + 1 });
    }
  }
  function handleVisibleChange(visible: boolean) {
    if (!editable) return;
    if (visible) {
      setValue({ ...schedule });
    } else {
      setValue(undefined);
    }
  }

  function handleStartChange(date: moment.Moment) {
    if (value !== undefined) {
      setValue({ ...value, start: date.format('HH:mm:ss') as any });
    }
  }

  function handleEndChange(date: moment.Moment) {
    if (value !== undefined) {
      const valueStart = moment(value.start, 'HH:mm:ss');
      const duration = date.diff(valueStart, 'minutes');
      setValue({ ...value, duration });
    }
  }

  function onSave() {
    if (!value) return;
    onChange?.(schedule, { ...value });
    setValue(undefined);
  }

  const valueStart = moment(value?.start, 'HH:mm:ss');
  const valueEnd = moment(value?.start, 'HH:mm:ss').add(
    value?.duration,
    'minutes'
  );
  const className = cl('pd-10', {
    'pointer t200': editable,
  });
  return (
    <Popover
      visible={value !== undefined}
      onVisibleChange={handleVisibleChange}
      autoAdjustOverflow={true}
      destroyTooltipOnHide={true}
      overlayInnerStyle={{ padding: 14 }}
      getPopupContainer={(trigger: any) => trigger.parentNode}
      content={
        <div className="relative">
          <div className="days flex flex-column">
            <p>Día</p>
            <SwitchOptions
              index={value ? value.day - 1 : 0}
              className="mt-10"
              onChange={handleOnChangeDate}
              maxActiveCount={1}
              multiple
              options={truncateDays}
              type="main"
            />
          </div>
          <div className="hours flex inline-flex flex-gap-10 mv-20">
            <DatePicker
              style={{ width: 246, marginTop: 10 }}
              title="Hora de inicio"
              picker="time"
              format="HH-mm"
              minuteStep={5}
              value={valueStart}
              onSelect={handleStartChange}
            />
            <DatePicker
              style={{ width: 246, marginTop: 10 }}
              disabledTime={(date) => ({
                disabledHours: () =>
                  Array.from({ length: valueStart.hours() }).map((_, i) => i),
                disabledMinutes: (hour) =>
                  hour === valueStart.hours()
                    ? Array.from({ length: valueStart.minutes() + 1 }).map(
                        (_, i) => i
                      )
                    : [],
              })}
              title="Hora de término"
              picker="time"
              format="HH-mm"
              minuteStep={5}
              value={valueEnd}
              onSelect={handleEndChange}
            />
          </div>
          <div className="bottom-options flex inline-flex flex-end mt-30">
            <Button
              onClick={() => setValue(undefined)}
              className="mh-14 pdh-20"
              bold
              label="Cancelar"
            />
            <Button
              onClick={onSave}
              className="pdh-20"
              bold
              type="emphasis"
              label="Guardar"
            />
          </div>
        </div>
      }
    >
      <div>
        <StyledCard className={className}>
          <div className="header flex flex-gap-8 flex-center">
            <div
              className="color-indicator"
              style={{ backgroundColor: color }}
            ></div>
            <h1 className="bold text-14">{days[schedule.day - 1]}</h1>
          </div>
          <p className="text-secondary text-12">
            {start.format('HH:mm')} a {end.format('HH:mm')} hrs
          </p>
        </StyledCard>
      </div>
    </Popover>
  );
};

interface IMouseEvents
  extends Partial<
    Record<'onDown' | 'onEnter' | 'onUp', (row: number, day: number) => void>
  > {}

interface ITrProps extends IMouseEvents {
  row: IScheduleItem;
  rowIndex: number;
}

const Tr: React.FC<ITrProps> = ({ row, rowIndex, onDown, onEnter, onUp }) => {
  // const onSelect = (day: IDay) => console.log(day);
  return (
    <tr key={`${row.hour}-${row.minutes}`}>
      <td className="text-center code">{`${row.hour}:${
        row.minutes < 10 ? `0${row.minutes}` : row.minutes
      }`}</td>
      {row.days.map((day, dayIndex) => (
        <DayHour
          day={day}
          rowIndex={rowIndex}
          dayIndex={dayIndex}
          key={day.name}
          onDown={onDown}
          onEnter={onEnter}
          onUp={onUp}
        />
      ))}
    </tr>
  );
};

interface IDayHourProps extends IMouseEvents {
  day: IDay;
  dayIndex: number;
  rowIndex: number;
  // onClick: (day: IDay) => void;
}

const DayHour: React.FC<IDayHourProps> = ({
  day,
  rowIndex,
  dayIndex,
  onDown,
  onEnter,
  onUp,
}) => {
  // function handleClick(){
  // 	onClick(day);
  // }

  function handleMouseDown() {
    onDown?.(rowIndex, dayIndex);
  }

  function handleMouseUp() {
    onUp?.(rowIndex, dayIndex);
  }

  function handleMouseEnter() {
    onEnter?.(rowIndex, dayIndex);
  }

  const className = cl({ 'schedule-selected': day.selected });
  return (
    <td
      // onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseEnter={handleMouseEnter}
      className={className}
      style={{ backgroundColor: day.color }}
    ></td>
  );
};
