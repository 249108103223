import { useEffect, useState } from 'react';
import { axios } from '@outlier-spa/fetch';
import { LabeledValue, ISupplier, IInstitution, IDiscipline, IProduct, ILevel, IUser, IRole } from 'interfaces';
import { IUserViewModel } from 'interfaces/user';

export const useDiscipline = () => {
	const [disciplines, setDisciplines] = useState<LabeledValue[]>([]);

	useEffect(() => {
		axios.get<IDiscipline[]>('discipline')
			.then(({ data }) => {
				setDisciplines(data.map(({ disciplineId, name }) => ({ label: name, value: disciplineId.toString() })));
			});
	}, []);

	return disciplines;
};

export const useRole = () => {
	const [roles, setRoles] = useState<LabeledValue[]>([]);

	useEffect(() => {
		axios.get<IRole[]>('team/roles')
			.then(({ data }) => {
				setRoles(data.map(({ roleId, name }) => ({ label: name, value: roleId.toString() })));
			});
	}, []);

	return roles;
};

export const useInstitutionRole = () => {
	const [roles, setRoles] = useState<LabeledValue[]>([]);

	useEffect(() => {
		axios.get<IRole[]>('institution/roles')
			.then(({ data }) => {
				setRoles(data.map(({ roleId, name }) => ({ label: name, value: roleId.toString() })));
			});
	}, []);

	return roles;
};

export const useSuppliers = () => {
	const [suppliers, setSuppliers] = useState<LabeledValue[]>([]);

	useEffect(() => {
		axios.get<ISupplier[]>('supplier')
			.then(({ data }) => {
				setSuppliers(data.map(({ supplierId, name }) => ({ label: name, value: supplierId.toString() })));
			});
	}, []);

	return suppliers;
};

export const useProducts = () => {
	const [products, setProducts] = useState<LabeledValue[]>([]);

	useEffect(() => {
		axios.get<IProduct[]>('product')
			.then(({ data }) => {
				setProducts(data.map(({ productId, name }) => ({ label: name, value: productId.toString() })));
			});
	}, []);

	return products;
};

export const useInstitutions = () => {
	const [institutions, setInstitutions] = useState<LabeledValue[]>([]);

	useEffect(() => {
		axios.get<IInstitution[]>('institution')
			.then(({ data }) => {
				setInstitutions(data.map(({ institutionId, name }) => ({ label: name, value: institutionId.toString() })));
			});
	}, []);

	return institutions;
};

export const useLevels = () => {
	const [levels, setLevels] = useState<LabeledValue[]>([]);

	useEffect(() => {
		axios.get<ILevel[]>('level')
			.then(({ data }) => {
				setLevels(data.map(({ levelId, name }) => ({ label: name, value: levelId.toString() })));
			});
	}, []);

	return levels;
};

export const useUserByRut = () => {

	function onChangeRut(rut: string, callback?: (user?: IUserViewModel | null) => void) {
		if (rut?.length < 8) return;
		axios.get<IUserViewModel | null>(`user?rut=${rut}`)
			.then(({ data }) => callback?.(data));
	};

	return { onChangeRut };
};

export const useStudentByRut = (institutionId: number) => {

	function onChangeRut(rut: string, callback?: (user?: { isValid: number; student: IUserViewModel | null }) => void) {
		if (rut?.length < 8) return;
		axios.get<{ isValid: number; student: IUserViewModel | null }>(`institution/${institutionId}/student?rut=${rut}`)
			.then(({ data }) => {
				console.log(data);
				callback?.(data)
			});
	};

	return { onChangeRut };
};
