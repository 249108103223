import { Card, Checkbox, Input, NotFound, Table } from '@outlier-spa/component';
import { SearchOutlined } from '@outlier-spa/icon';
import { IUser } from 'interfaces';
import React, { useCallback, useEffect, useState } from 'react';

const Check = ({ value, handlers }: any) => (
  <Checkbox
    type="secondary"
    onChange={() => handlers.onChange(!value.checked, value.index)}
    checked={value.checked}
  />
);

const headers: any = [
  { key: 'checked', value: '', width: 50, cellComponent: Check },
  { key: 'firstName', value: 'Nombre' },
  { key: 'lastName', value: 'Apellido' },
  { key: 'rut', value: 'Rut', width: 200 },
];

export interface IUserCheck extends IUser {
  checked?: {
    index: number;
    checked: boolean;
  };
}

export interface IUserSelectProps {
  options?: IUserCheck[];
  onChange?: (selected: string[]) => void;
}

export const UserSelect: React.FC<IUserSelectProps> = ({
  options,
  onChange,
}) => {
  const [users, setUsers] = useState<IUserCheck[]>([]);
  const [filter, setFilter] = useState<string>();

  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      const filterStr = filter.toLowerCase();
      return data.filter((d) => {
        const firstName = d[0].firstName?.value?.toLowerCase();
        const lastName = d[0].lastName?.value?.toLowerCase();
        const rut = d[0].rut?.value?.toLowerCase();
        return (
          firstName?.includes(filterStr) ||
          lastName?.includes(filterStr) ||
          rut?.includes(filterStr)
        );
      });
    },
    [filter]
  );

  useEffect(() => {
    setUsers(
      options?.map((user, index) => ({
        ...user,
        checked: { index, checked: user.checked?.checked || false },
      })) ?? []
    );
  }, [options, onChange]);

  function handleChange(checked: any, index: any) {
    const newUsers = users.map((user: any, i: number) =>
      i === index ? { ...user, checked: { checked, index } } : user
    );
    setUsers(newUsers);
    onChange &&
      onChange(
        newUsers.filter((user) => !!user?.checked?.checked).map((d) => d.userId)
      );
  }

  return (
    <div>
      <Card className="mb-20">
        <div className="pd-10 border-bottom-main">
          <Input
            type="secondary"
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
            prefix={<SearchOutlined className="text-secondary mr-4" />}
            placeholder="Buscar alumno"
          />
        </div>
        <div className="relative overflow-hidden-x" style={{ height: 500 }}>
          <Table
            bordered={false}
            hoverable
            headers={headers}
            handlers={{
              onChange: handleChange as any,
            }}
            data={users}
            filter={tableFilter}
            fixedHeaders
          />
          {users.length === 0 && (
            <div className="relative" style={{ height: 500 }}>
              <NotFound content="No hay estudiantes registrados para esta institución." />
            </div>
          )}
        </div>
      </Card>
      {/* <Pagination className='flex-end' pageInputVisible={false} count={100} pageCount={10} /> */}
    </div>
  );
};
