import styled from 'styled-components';

export const StyledClassAttendance = styled.div`
  .square {
    width: 18px;
    height: 18px;
    border-radius: 3px;
    &.bordered {
      background-color: ${({ theme }) => theme.field.secondary};
      border: 1px solid ${({ theme }) => theme.border.main};
			&:not(.pointer) {
				opacity: 0.4;
			}
      &.pointer:hover {
        border-color: ${({ theme }) => theme.colors.emphasis};
        box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.emphasis}2B;
      }
    }
  }
	.institution-courses, .class-number-left {
		width: 200px;
		min-width: 200px;
	}
  .institution-courses {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.card.main};
    h6 {
      position: sticky;
      top: 44px;
      background-color: ${({ theme }) => theme.card.main};
    }
  }
  .chart-title {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: ${({ theme }) => theme.card.main};
  }

  .class-number {
    background-color: ${({ theme }) => theme.card.main};
    position: sticky;
    top: 44px;
		z-index: 1;

    .class-number-left {
      height: 22px;
      position: sticky;
      left: 0;
      z-index: 2;
      background-color: ${({ theme }) => theme.card.main};
    }
  }
  .scale-gradient {
    width: 124px;
    height: 16px;
    border-radius: 3px;
    background: linear-gradient(
      270deg,
      #00b398 0%,
      #ffea29 50.52%,
      #dc294f 100%
    );
  }
`;
