import React from "react";
import { NavBar, NavBarItem } from "@outlier-spa/component";
import { BoxFilled, SupplierFilled, InvoiceFilled } from "@outlier-spa/icon";
import { Route, Routes, Navigate } from "react-router-dom";
import { InventorySupplierList } from "./suppliers";
import { InventoryProductList } from "./products";
import { InventoryProductDetail } from "./products/detail";
import { InventoryInvoiceList } from "./invoices";
import { InventoryInvoiceNew } from "./invoices/new";
import { InventoryInvoiceDetail } from "./invoices/detail";
import { InventoryInvoiceUpdate } from "./invoices/update";

const InventoryNavigator: React.FC = () => {
  return (
    <div className="inner ant-fade-enter ant-fade-enter-active h100">
      <div className="flex flex-column h100 o-hidden ">
        <h2 className="text-20 bold mb-16">Inventario</h2>
        <NavBar>
          <NavBarItem
            to="/inventory/suppliers"
            className="bold"
            content={
              <>
                <SupplierFilled className="mr-10 text-16" />
                <span>Proveedores </span>
              </>
            }
          />
          <NavBarItem
            to="/inventory/products"
            className="bold"
            content={
              <>
                <BoxFilled className="mr-10 text-16" />
                <span>Productos</span>
              </>
            }
          />
          <NavBarItem
            to="/inventory/invoices"
            className="bold"
            content={
              <>
                <InvoiceFilled className="mr-10 text-16" />
                <span>Facturas</span>
              </>
            }
          />
        </NavBar>
        <div className="flex-1 o-hidden">
          <Routes>
            <Route path="suppliers" element={<InventorySupplierList />} />
            <Route path="products" element={<InventoryProductList />} />
            <Route path="invoices" element={<InventoryInvoiceList />} />
            <Route path="*" element={<Navigate replace to="suppliers" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export interface IInventoryProps {}

export const Inventory: React.FC<IInventoryProps> = () => {
  return (
    <Routes>
      <Route path="products/:productId" element={<InventoryProductDetail />} />
      <Route
        path="invoices/:invoiceId/update"
        element={<InventoryInvoiceUpdate />}
      />
      <Route path="invoices/:invoiceId" element={<InventoryInvoiceDetail />} />
      <Route path="invoices/new" element={<InventoryInvoiceNew />} />
      <Route path="*" element={<InventoryNavigator />} />
    </Routes>
  );
};
