import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  Routes,
  useParams,
  Link,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useApi } from "@outlier-spa/fetch";
import { Header, LoadingKiri } from "components";
import { NavBar, NavBarItem } from "@outlier-spa/component";
import { useTitle } from "context";
import {
  AssistanceFilled,
  CalendarOutlined,
  LeftOutlined,
  TickOutlined,
} from "@outlier-spa/icon";
import { EventAuthorization } from "./authorization";
import { EventAttendance } from "./attendance";
import { SchoolIcon } from "components/icon";
import { IEventViewModel } from "interfaces/event";

const Back = ({ url }: { url?: any }) => {
  return (
    <Link
      to={url ? url : "/event"}
      className="flex flex-center flex-gap-8 text-16"
    >
      <LeftOutlined className="text-label" />
      <span className="bold">Atrás</span>
    </Link>
  );
};

const NavBarItems = () => (
  <>
    <NavBarItem
      className="nav-summary"
      prefix={<TickOutlined className="flex" />}
      to="authorization"
      content="Autorización"
    />
    <NavBarItem
      className="nav-students"
      prefix={<AssistanceFilled className="flex" />}
      to="attendance"
      content="Asistencia"
    />
  </>
);

export interface IEventsProps {}

export const EventRoutes: React.FC<IEventsProps> = () => {
  const [getUrl, loadingRef] = useApi();
  const { eventId } = useParams();
  const [event, setEvent] = useState<IEventViewModel>();
  const location = useLocation();
  const [backUrl, setBackUrl] = useState();
  const back = useMemo(() => {
    return <Back url={backUrl} />;
  }, [backUrl]);
  useTitle(back);

  useEffect(() => {
    if (!location.state) return;
    setBackUrl((location.state as any)?.pathname);
  }, [location.state]);

  useEffect(() => {
    if (eventId) {
      getUrl<IEventViewModel>(`/event/${eventId}`, ({ data }) => {
        setEvent(data);
      });
    }
  }, [eventId, getUrl]);

  return (
    <div className="inner h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      {event && (
        <>
          <div>
            <Header
              identifier={event.eventId}
              title={event.name}
              editable={false}
              address={event.fullAddress}
              descriptions={[
                <div className="flex flex-center flex-gap-4">
                  <CalendarOutlined />{" "}
                  <span>
                    {event.duration}
                  </span>
                </div>,
                <div className="flex flex-center flex-gap-4">
                  <SchoolIcon />{" "}
                  <span>
                    {event.institutions?.length ?? 0} establecimientos
                  </span>
                </div>,
              ]}
            />
            <NavBar className="mt-20 flex-space institution-nav">
              <div className="flex flex-center">
                <NavBarItems />
              </div>
            </NavBar>
          </div>
          <div className="flex-1 o-hidden">
            <Routes>
              <Route
                path="authorization"
                element={<EventAuthorization event={event} />}
              />
              <Route
                path="attendance"
                element={<EventAttendance event={event} />}
              />
              <Route path="*" element={<Navigate to="authorization" />} />
            </Routes>
          </div>
        </>
      )}
    </div>
  );
};
