import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Classes } from './classes';
import { CourseList, CourseNew } from './course';
import { Institution, InstitutionList, InstitutionNew } from './institution';
import { Inventory } from './inventory';
import { Product, ProductList, ProductNew } from './product';
import { Program, ProgramList, ProgramNew, ProgramUpdate } from './program';
import { Team } from './team';
import { Event } from './event';
import { SurveyRoutes } from './survey';
import { Home } from './home/index';
import { PaymentRoutes } from './payment';

const Logout = () => {
  const { instance } = useMsal();
  useEffect(() => {
    console.log(instance);
    instance.logout();
  }, [instance]);
  return <></>;
};

export const KiriRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="course/new" element={<CourseNew />} />
      <Route path="course/*" element={<CourseList />} />
      <Route path="institution/new" element={<InstitutionNew />} />
      <Route path="institution" element={<InstitutionList />} />
      <Route path="class/*" element={<Classes />} />
      <Route path="institution/:institutionId/*" element={<Institution />} />
      <Route path="inventory/*" element={<Inventory />} />
      <Route path="product" element={<ProductList />} />
      <Route path="product/new" element={<ProductNew />} />
      <Route path="product/:id/*" element={<Product />} />
      <Route path="program/list/*" element={<ProgramList />} />
      <Route path="program/new" element={<ProgramNew />} />
      <Route
        path="program/:programId/:version/edit/*"
        element={<ProgramUpdate />}
      />
      <Route path="program/:programId/:version/*" element={<Program />} />
      <Route path="program" element={<Navigate replace to="/program/list" />} />
      <Route path="team/*" element={<Team />} />
      <Route path="event/*" element={<Event />} />
      <Route path="logout" element={<Logout />} />
      <Route path="survey/*" element={<SurveyRoutes />} />
      <Route path="payment/*" element={<PaymentRoutes />} />
      <Route path="home" element={<Home />} />
      <Route path="*" element={<Navigate replace to="home" />} />
    </Routes>
  );
};
