import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Card, SearchBar, DatePicker, Popover, NotFound } from '@outlier-spa/component';
import { SchoolFilled } from '@outlier-spa/icon';
import { LoadingKiri } from 'components';
import { useApi } from '@outlier-spa/fetch';
import { StyledClassCapacity } from './style';
import styled from 'styled-components';
import { NoData } from 'components/no-data';

export interface IClassCapacitySummaryCourse {
  course: string;
  attendance: number;
  capacity: number;
  enrolleds: number;
}

export interface IClassCapacityInstitution {
  institution: string;
  courses: IClassCapacitySummaryCourse[];
}

const TableValue: React.FC<{
  items: { label: string; value: any; color?: string }[];
}> = ({ items }) => (
  <table className="mh-10">
    <tbody>
      {items.map(item => (
        <tr key={item.label}>
          <th className="flex flex-center pdv-10 ">
            <div style={{ background: item.color }} className="indicator" />
            <p>{item.label}</p>
          </th>
          <td className="text-secondary">: {item.value ?? '-'}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const InstitutionBars: React.FC<{
  course: IClassCapacitySummaryCourse;
  max: number;
}> = ({ course, max }) => {
  const attendance = (course.attendance / max) * 95;
  const capacity = (course.capacity / max) * 95;
  const enrolleds = (course.enrolleds / max) * 95;
  const courseMax = Math.max(attendance, capacity, enrolleds);
  return (
    <div style={{ height: 22 }} className="relative bar-container mr-14">
      <div
        className="bar bar-capacity rounded border-main pointer-none"
        style={{ width: `${capacity}%` }}
      />
      <div className="bar bar-inscribed pointer-none" style={{ width: `${enrolleds}%` }} />
      <div className="bar bar-attendance pointer-none" style={{ width: `${attendance}%` }} />
      <Popover
        destroyTooltipOnHide
        trigger={'hover'}
        placement="bottom"
        content={
          <StyledPopOverContent className="flex flex-column flex-vgap-8">
            <TableValue
              items={[
                {
                  label: 'Asistencia',
                  value: course.attendance,
                  color: '#00B398',
                },
                {
                  label: 'Inscritos',
                  value: course.enrolleds,
                  color: '#00B3983C',
                },
                { label: 'Capacidad', value: course.capacity },
              ]}
            />
          </StyledPopOverContent>
        }
      >
        <div className="bar pointer rounded t200" style={{ width: `${courseMax}%` }} />
      </Popover>
    </div>
  );
};

export const StyledPopOverContent = styled.div`
  table th {
    height: 30px;
    padding-right: 30px;
  }
  table .indicator {
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.field.secondary};
    border-radius: 3px;
    margin-right: 6px;
  }
`;

interface IInstitutionAttendanceProps {
  institution: IClassCapacityInstitution;
  max: number;
}

const InstitutionCapacity: React.FC<IInstitutionAttendanceProps> = ({ institution, max }) => {
  return (
    <div className="flex line-normal">
      <div className="flex pdh-14 pdb-14 flex-column flex-vgap-8 institution-courses">
        <h6 style={{ height: 22 }} className="flex flex-center text-secondary">
          <SchoolFilled className="flex mr-6" />
          <span className="mr-8 bold">{institution.institution}</span>
        </h6>
        {institution.courses.map(course => {
          const [discipline, name] = course.course.split(' ');
          return (
            <div key={course.course} style={{ height: 22 }} className="flex flex-center flex-space">
              <p>{discipline}</p>
              <span className="text-secondary code">{name}</span>
            </div>
          );
        })}
      </div>
      <div className="flex flex-column flex-vgap-8 pdb-14 flex-1">
        <div style={{ height: 22 }} className="flex flex-center institution-line">
          <div className="border-top-main w100" />
        </div>
        {institution.courses.map(course => (
          <InstitutionBars course={course} max={max} key={course.course} />
        ))}
      </div>
    </div>
  );
};

export const ClassCapacity: React.FC = () => {
  const [date, setDate] = useState(moment());
  const [getUrl, loadingRef] = useApi();
  const [filter, setFilter] = useState('');
  const [institutions, setInstitutions] = useState<IClassCapacityInstitution[]>([]);
  const max = useMemo(() => {
    const numbers = institutions.reduce(
      (acc, cur) => [
        ...acc,
        ...cur.courses.reduce(
          (acc, cur) => [...acc, cur.attendance, cur.capacity, cur.enrolleds],
          [] as any
        ),
      ],
      [] as number[]
    );
    return Math.max(...numbers);
  }, [institutions]);

  const onChangeDate = (value: any) => {
    console.log(value);
    setDate(value);
  };

  useEffect(() => {
    getUrl<IClassCapacityInstitution[]>(`/class/${date.year()}/capacityByCourse`, ({ data }) => {
      console.log(data);
      setInstitutions(data);
    });
  }, [date, getUrl]);

  return (
    <StyledClassCapacity className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <div className="flex flex-center flex-gap-14">
        <DatePicker
          picker="year"
          type="mainAlt"
          onChange={onChangeDate}
          style={{ width: 100 }}
          value={date}
          format={`[${date.format('YYYY')}]`}
          allowClear={false}
        />
        <SearchBar
          className="flex-1"
          buttonVisible={false}
          type="mainAlt"
          onChange={evt => setFilter(evt.target.value)}
        />
      </div>
      {institutions.length > 0 ? (
        <Card className="overflow-hidden-x mt-14 flex-1 relative">
          <div style={{ paddingLeft: 200 }} className="pd-14 flex flex-space chart-title">
            <h5 className="text-secondary">Cupos</h5>
            <div className="flex flex-center text-secondary flex-gap-16">
              <div className="flex flex-center flex-gap-8 text-secondary">
                <span>Asistencia promedio</span>
                <div style={{ background: '#00B398' }} className="square bordered" />
              </div>
              <div className="flex flex-center flex-gap-8 text-secondary">
                <span>Inscritos</span>
                <div style={{ background: '#00B3983C' }} className="square bordered" />
              </div>
              <div className="flex flex-center flex-gap-8 text-secondary">
                <span>Capacidad</span>
                <div className="square bordered" />
              </div>
            </div>
          </div>
          <div>
            {institutions.map(institution => (
              <InstitutionCapacity
                key={institution.institution}
                max={max}
                institution={institution}
              />
            ))}
          </div>
        </Card>
      ) : (
        <div className="flex flex-column flex-middle w100 h100">
          <NoData content="No existe contenido para mostrar. Seleccione los parámetros de la tabla para visualizar la información" />
        </div>
      )}
    </StyledClassCapacity>
  );
};
