import { NotFound } from '@outlier-spa/component';
import { useApi } from '@outlier-spa/fetch';
import { LoadingKiri } from 'components';
import { SurveyCard } from 'components/card';
import { IPollApplyViewModel, IPollAppViewModel } from 'interfaces/poll';
import React, { useEffect, useState } from 'react';
import { StyledEventList } from './style';

export const AvailableSurvey: React.FC = () => {
  const [getUrl, loadingRef] = useApi();
  const [surveys, setSurveys] = useState<IPollAppViewModel[]>();

  useEffect(() => {
    getUrl<IPollAppViewModel[]>('/poll', ({ data }) => {
      console.log(data);
      setSurveys(data);
    });
  }, [getUrl]);

  return (
    <StyledEventList className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <div
        className={`relative overflow-hidden-x ${
          surveys && surveys.length > 0 ? ' flex-1' : ''
        }`}
        style={{ maxHeight: '100%' }}
      >
        {surveys && surveys.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay eventos registrados." />
          </div>
        )}
        {surveys && (
          <>
            <div className="upcoming-events">
              <div className="event-grid">
                {surveys.map((survey, idx) => (
                  <SurveyCard
                    key={idx}
                    name={survey.name}
                    questions={survey.sections?.reduce(
                      (prev, curr) => prev + JSON.parse(curr.content).length,
                      0
                    )}
                    pollId={survey.pollId}
                  ></SurveyCard>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </StyledEventList>
  );
};
