import { Route, Routes } from 'react-router-dom';
import { PaymentList } from './list';

export const PaymentRoutes = () => {
  return (
    <Routes>
      <Route path="list" element={<PaymentList />}></Route>
    </Routes>
  );
};
