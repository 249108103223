import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { NavBar, NavBarItem, NotFound, Confirm } from '@outlier-spa/component';
import { RiskFilled } from '@outlier-spa/icon';
import { axios, useApi } from '@outlier-spa/fetch';
import { LoadingKiri, ProgramCard } from 'components';
import { useDiscipline } from 'hooks';
import { IDisciplineProgram } from 'interfaces';
import { disciplineIcons } from 'components/icon';
import { SearchBar } from 'components/search-bar';

const ProgramDiscipline: React.FC = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const [programs, setPrograms] = React.useState<IDisciplineProgram[]>();
  const { disciplineId } = useParams();
  const [getUrl, loadingRef] = useApi();
  const [filter, setFilter] = useState<string>('');
  const navigate = useNavigate();

  const filteredPrograms = useMemo(() => {
    if (filter.length < 2) return programs;
    const filterStr = filter.toLowerCase();
    return programs?.filter((program) => {
      return program.description?.toLowerCase().includes(filterStr);
    });
  }, [filter, programs]);

  function handleRemove(_programId: number, program: IDisciplineProgram) {
    removeRef.current?.open(
      program,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este programa?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  function handleUpdate(_programId: number, program: IDisciplineProgram) {
    navigate(`/program/${program.programId}/${program.version}/edit`);
  }

  function onRemoveProgram(program: IDisciplineProgram) {
    console.log(program);
    loadingRef.current?.setActive(true);
    axios
      .delete(`/program/${program.programId}`)
      .then(() => {
        setPrograms(programs?.filter((p) => p.programId !== program.programId));
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  useEffect(() => {
    getUrl<IDisciplineProgram[]>(`program/${disciplineId}`, ({ data }) => {
      console.log(data);
      setPrograms(data);
    });
  }, [disciplineId, getUrl]);

  return (
    <>
      <LoadingKiri ref={loadingRef} />
      <Confirm
        okText="Eliminar Programa"
        className="line-normal"
        title={
          <div className="flex flex-center flex-gap-6 ">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
        onSubmit={onRemoveProgram}
      />
      <div className="inner w100">
        <SearchBar
          buttonText="Agregar Programa"
          onChange={(evt) => setFilter(evt.target.value)}
          buttonProps={{ as: 'a', href: '/program/new' }}
        />
      </div>
      <div className="inner w100 flex-1 overflow-hidden-x relative">
        <div className="flex flex-column flex-vgap-14 line-normal">
          {filteredPrograms?.map((program) => (
            <ProgramCard
              key={`${program.programId}-${program.version}`}
              onRemove={handleRemove}
              onUpdate={handleUpdate}
              disciplineId={disciplineId}
              program={program}
            />
          ))}
          {programs?.length === 0 && (
            <div className="relative" style={{ height: '50vh' }}>
              <NotFound content="No hay programas registrados para esta disciplina." />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export interface IProgramListProps {}

export const ProgramList: React.FC<IProgramListProps> = () => {
  const discipline = useDiscipline();
  return (
    <div className="ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <div className="inner w100">
        <h2 className="text-20 bold mb-16">Programas</h2>
        <NavBar className="mb-20">
          {discipline.map((discipline) => (
            <NavBarItem
              key={discipline.value}
              className="bold"
              to={`/program/list/${discipline.value}`}
              content={
                <>
                  {disciplineIcons[discipline.value]}
                  <span>{discipline.label}</span>
                </>
              }
            />
          ))}
        </NavBar>
      </div>
      <Routes>
        <Route path=":disciplineId" element={<ProgramDiscipline />} />
        {discipline.length > 0 && (
          <Route
            path=""
            element={<Navigate replace to={discipline[0].value} />}
          />
        )}
      </Routes>
    </div>
  );
};
