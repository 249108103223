import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { EventList } from './list';
import { EventRoutes } from './partials';

export interface IEventProps {

};

export const Event: React.FC<IEventProps> = () => {
	return (
		<Routes>
			<Route path=':eventId/*' element={<EventRoutes />} />
			<Route path='' element={<EventList />} />
		</Routes>
	);
};
