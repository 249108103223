import React, { useCallback, useRef, useState } from 'react';
import { SearchBar, Table, Confirm, Card } from '@outlier-spa/component';
import { PopoverOptions } from 'components';
import { RiskFilled } from '@outlier-spa/icon';

const headers: any = [
  { key: 'name', value: 'Nombre', sortable: true },
  { key: 'size', value: 'Tamaño', sortable: true },
  { key: 'options', value: '', width: 45, cellComponent: PopoverOptions },
];

const data = Array.from({ length: 30 }, (_, k) => ({
  name: `Reglamento de Seguridad Interno_${k}.pdf`,
  size: '320 kb',
}));

export const InstitutionDocuments: React.FC = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const [filter, setFilter] = useState<string>();

  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      const filterStr = filter.toLowerCase();
      return data.filter((d) => {
        const firstName = d[0].name?.value?.toLowerCase();
        return firstName?.includes(filterStr);
      });
    },
    [filter]
  );

  function handleRemove() {
    removeRef.current?.open(
      1,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este documento?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  return (
    <div className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <Confirm
        okText="Eliminar Documento"
        className="line-normal"
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
      />
      <SearchBar
        type="mainAlt"
        buttonText="Agregar Documento"
        buttonProps={{ href: '#', as: 'a' }}
        onChange={(evt) => setFilter(evt.target.value)}
      />
      <Card className="flex-1 overflow-auto mt-14">
        <Table
          hoverable
          fixedHeaders
          filter={tableFilter}
          bordered={false}
          headers={headers}
          data={data}
          handlers={{
            onRemove: handleRemove,
          }}
        />
      </Card>
    </div>
  );
};
