import styled from 'styled-components';

export const StyledArrowScroll = styled.div`
  .arrow-left,
  .arrow-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    z-index: 3;
  }
  .arrow-left,
  .arrow-right {
    opacity: 0;
    pointer-events: none;
  }
  &.arrow-scroll-start .arrow-left,
  &.arrow-scroll-end .arrow-right {
    opacity: 1;
    pointer-events: all;
  }
  .arrow-left {
    left: 0;
    background: linear-gradient(
      to left,
      ${({ theme }) => `${theme.background.main}1A, ${theme.background.main}`}
    );
  }
  .arrow-right {
    right: 0;
    background: linear-gradient(
      to right,
      ${({ theme }) => `${theme.background.main}1A, ${theme.background.main}`}
    );
  }
`;
