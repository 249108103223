import React, { useEffect, useMemo, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useApi } from '@outlier-spa/fetch';
import { Header, LoadingKiri } from 'components';
import { NavBar, NavBarItem } from '@outlier-spa/component';
import { useTitle } from 'context';
import { ChartFilled, DetailsOutlined, LeftOutlined } from '@outlier-spa/icon';
import { IPollApplyViewModel } from 'interfaces/poll';

const Back = ({ url }: { url?: any }) => {
  return (
    <Link
      to={url ? url : '/survey/list'}
      className="flex flex-center flex-gap-8 text-16"
    >
      <LeftOutlined className="text-label" />
      <span className="bold">Atrás</span>
    </Link>
  );
};

const NavBarItems = () => (
  <>
    <NavBarItem
      className="nav-summary"
      prefix={<DetailsOutlined className="flex" />}
      to="participation"
      content="Detalles"
    />
    <NavBarItem
      className="nav-students"
      prefix={<ChartFilled className="flex" />}
      to="statistics"
      content="Estadísticas"
    />
  </>
);

export interface IEventsProps {}

export const SurveyParticipation: React.FC<IEventsProps> = () => {
  const [getUrl, loadingRef] = useApi();
  const [backUrl] = useState();
  const [surveys, setSurveys] = useState<IPollApplyViewModel[]>();
  const back = useMemo(() => {
    return <Back url={backUrl} />;
  }, [backUrl]);
  useTitle(back);

  useEffect(() => {
    getUrl<IPollApplyViewModel[]>('/poll', ({ data }) => {
      setSurveys(data);
    });
  }, [getUrl]);

  return (
    <div className="inner h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <div>
        {surveys?.map((survey, idx) => (
          <Header
            key={idx}
            identifier={1}
            title={survey?.name}
            editable={false}
            date="dd-mm-yyyy"
            frame={survey?.frame}
            institution={survey?.institutionsCount}
          />
        ))}
        <NavBar className="mt-20 flex-space institution-nav">
          <div className="flex flex-center">
            <NavBarItems />
          </div>
        </NavBar>
      </div>
      <div className="flex-1 o-hidden">
        <Outlet></Outlet>
      </div>
    </div>
  );
};
