import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axios, useApi } from '@outlier-spa/fetch';
import {
  Avatar,
  SearchBar,
  Table,
  Confirm,
  Card,
  NotFound,
  Popover,
  List,
  Button,
} from '@outlier-spa/component';
import { OptionsOutlined, RiskFilled, SurveyFilled } from '@outlier-spa/icon';
import { LoadingKiri, PopoverOptions } from 'components';
import { DefaultCell } from 'components/table';
import { SurveyModal } from 'components/modal/survey';
import { IPollApplyViewModel } from 'interfaces/poll';
import cl from 'classnames';

const Image = ({ row }: { row: any }) => {
  return (
    <Avatar
      className="flex flex-middle o-hidden"
      size="2.4rem"
      email={row.email?.value}
    >
      {!row.email?.value && <SurveyFilled className="text-16 text-secondary" />}
    </Avatar>
  );
};

const statusColorType: any = {
  enCurso: 'primary',
  '2': 'secondary',
  Finalizada: 'assertive',
  Cancelada: 'danger',
}; // ["primary", "secondary", "assertive", "danger"];

const StatusRender = ({
  value,
  row,
  handlers,
}: {
  value: string;
  row: any;
  handlers: any;
}) => {
  const popoverRef = useRef<React.ElementRef<typeof Popover>>(null);
  const navigate = useNavigate();

  // function handleItemClick(key: string) {
  //   popoverRef.current?.setVisible(false);
  //   if (key === 'create') {
  //     navigate(`/class/${row.startDate.value}/${row.scheduleId.value}`);
  //   } else if (key === 'cancel') {
  //     handlers?.onUpdate(row);
  //   }
  // }

  const className = cl('status-cell bold flex-middle flex', 'bold', {
    'pointer hover-emphasis': value !== 'En curso',
  });

  return (
    <div className={className}>
      {value === 'En curso' ? (
        <Popover
          ref={popoverRef}
          trigger="hover"
          content={
            <List
              hoverable
              // onItemClick={handleItemClick}
              keyExtractor={(item) => item.key}
              renderItem={(item) => (
                <span className={item.className}>{item.label}</span>
              )}
              items={[
                { key: 'create', label: 'Finalizar', className: 'bold' },
                {
                  key: 'cancel',
                  label: 'Eliminar',
                  className: 'bold text-danger',
                },
              ]}
            />
          }
        >
          <Button
            quiet
            size="small"
            className={`bold border-secondary t200 text-${statusColorType[value]}`}
            // onClick={() => handlers?.onUpdate(row)}
            label={
              <>
                {/* <span className="text-action">Suspender</span> */}
                <span className="mr-6" style={{ width: 'max-content' }}>
                  {value}
                </span>
                <OptionsOutlined className="text-secondary" />
              </>
            }
          />
        </Popover>
      ) : (
        <Popover
          trigger="hover"
          content={<span className="pd-10">Ir a la clase</span>}
          placement="right"
        >
          <span
            className={`pdh-10 bold t200 text-${statusColorType[value]}`}
            onClick={() => handlers.onClick(row)}
          >
            {value}
          </span>
        </Popover>
      )}
    </div>
  );
};

const tableHeaders: any = [
  { key: 'image', value: '', width: 70, cellComponent: Image },
  { key: 'name', value: 'Nombre', sortable: true },
  {
    key: 'target',
    value: 'Usuario',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'frame',
    value: 'Frame',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'frameValue',
    value: 'Fecha',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'institutionsCount',
    value: 'Establecimientos',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'participation',
    value: 'Participacion',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'status',
    value: 'Estado',
    sortable: true,
    cellComponent: StatusRender,
  },
  // { key: 'pollId', value: '', width: 45, cellComponent: PopoverOptions },
];

export interface ISurveyListProps {}

export const SurveyList: React.FC<ISurveyListProps> = () => {
  const navigate = useNavigate();
  const addModalRef = useRef<React.ElementRef<typeof SurveyModal>>(null);
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const [surveys, setSurveys] = useState<IPollApplyViewModel[]>();
  const [filter, setFilter] = useState<string>();
  const [getUrl, loadingRef] = useApi();
  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      const filterStr = filter.trim().toLowerCase();
      return data.filter((d) => {
        const name = d[0].name?.value?.toLowerCase();
        const targer = d[0].targer?.value?.toLowerCase();
        const frame = d[0].frame?.value?.toLowerCase();
        const frameValue = d[0].frameValue?.value?.name?.toLowerCase();
        const status = d[0].status?.value?.toLowerCase();
        return (
          name?.includes(filterStr) ||
          targer?.includes(filterStr) ||
          frame?.includes(filterStr) ||
          frameValue?.includes(filterStr) ||
          status?.includes(filterStr)
        );
      });
    },
    [filter]
  );

  function onRowClick(dataItem: any) {
    navigate(`/survey/list/${dataItem.pollId.key}/details/participation`);
  }

  function handleRemove(institutionId: any) {
    removeRef.current?.open(
      institutionId,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar esta institución?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  function onRemove(institutionId: number) {}

  function handleUpdate(institutionId: any) {}

  function handleAddInstitution() {
    addModalRef.current?.open();
  }

  function onSubmitPollApply(
    pollApply: IPollApplyViewModel,
    prevPollApply?: IPollApplyViewModel
  ) {
    loadingRef.current?.setActive(true);
    if (prevPollApply) {
      const index = surveys?.indexOf(prevPollApply);
      if (index === -1 || !surveys) {
        loadingRef.current?.setActive(false);
        return;
      }
      axios
        .put<IPollApplyViewModel>('poll', pollApply)
        .then(({ data }) => {
          const newSurveys = [...surveys];
          newSurveys[index as number] = data;
          setSurveys(newSurveys);
          addModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    } else {
      axios
        .post<IPollApplyViewModel>('poll', pollApply)
        .then(({ data }) => {
          setSurveys([data, ...(surveys ?? [])]);
          loadingRef.current?.setActive(false);
          addModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    }
  }

  useEffect(() => {
    getUrl<IPollApplyViewModel[]>('/poll/apply', ({ data }) => {
      console.log(data);
      setSurveys(data);
    });
  }, [getUrl]);

  return (
    <div className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        ref={removeRef}
        okText="Eliminar Institución"
        className="line-normal"
        onSubmit={onRemove}
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
      />
      <SurveyModal ref={addModalRef} onSubmit={onSubmitPollApply} />
      <SearchBar
        onChange={(evt) => setFilter(evt.target.value)}
        buttonText="Agregar Encuesta"
        type="mainAlt"
        onSubmit={handleAddInstitution}
      />
      <div className="fles-1 mt-14 o-hidden">
        <Card className="overflow-auto relative" style={{ maxHeight: '100%' }}>
          <Table
            hoverable
            fixedHeaders
            filter={tableFilter}
            bordered={false}
            className="table-row-large"
            handlers={{
              onRemove: handleRemove,
              onUpdate: handleUpdate,
            }}
            onRowClick={onRowClick}
            headers={tableHeaders}
            data={surveys}
          />
          {surveys?.length === 0 && (
            <div className="relative" style={{ height: 300 }}>
              <NotFound content="No hay encuestas registradas." />
            </div>
          )}
        </Card>
        {/* <SurveyDetail></SurveyDetail> */}
      </div>
    </div>
  );
};
