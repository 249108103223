import {
	Confirm,
  DatePicker,
  Input,
  InputNumber,
  SectionHeader,
  Select,
} from '@outlier-spa/component';
import { axios, useApi } from '@outlier-spa/fetch';
import {
  DetailsOutlined,
  InfoOutlined,
  SchoolFilled,
  UserFilled,
} from '@outlier-spa/icon';
import {
  Header,
  LoadingKiri,
  Schedule,
  SupplySelect,
  UserSelect,
} from 'components';
import { useInstitution } from 'context/institution';
import { useDiscipline } from 'hooks';
import {
  IInstitutionRole,
  IDisciplineItem,
  IProgram,
  IRole,
  IUser,
  ICourse,
} from 'interfaces';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { ICourseViewModel } from 'interfaces/course';
import { IUserViewModel } from 'interfaces/user';

interface ICustonUserRole extends IUser {
  role: IRole;
  roleId?: any;
}

export interface IInstitutionCourseUpdateProps {}

export const InstitutionCourseUpdate: React.FC<
  IInstitutionCourseUpdateProps
> = () => {
	const confirmRef = React.useRef<React.ElementRef<typeof Confirm>>(null);
  const { control, handleSubmit, setValue } = useForm();
  const discipline = useDiscipline();
  const { institution } = useInstitution();
  const [programs, setPrograms] = useState<IProgram[]>([]);
	const [course, setCourse] = useState<ICourseViewModel>();
  const navigate = useNavigate();
  const [getUrl, loadingRef] = useApi();
  const params = useParams();

  const [officers, setOfficers] = useState<IUser[]>([]);
  const [students, setStudents] = useState<IUser[]>([]);
	const [monitors, setMonitors] = useState<IUserViewModel[]>([]);
  // const [items, setItems] = useState<IDisciplineItem[]>([]);

	function onSubmit({ startDate, programId, schedules, students, monitorId, duplaId, disciplineId, ...data }: any) {
		data.startDate = startDate?.format("YYYY-MM-DD");

		if (!disciplineId) {
			confirmRef.current?.open(null, 'Debe seleccionar una disciplina.');
			return;
		}
		if (!programId) {
			confirmRef.current?.open(null, 'Debe seleccionar un programa.');
			return;
		}
		data.color = '#00498d';
		data.courseId = params.courseId;
		data.institutionViewModel = institution;
		data.programViewModel = { programId };
		data.scheduleViewModels = schedules;
		data.students = students.map((s: any) => ({ userId: s }));
		data.disciplineId = parseInt(disciplineId);
		data.tutor = monitorId ? { userId: monitorId } : null;
		data.duo = duplaId ? { userId: duplaId } : null;
		console.log(data);

		loadingRef.current?.setActive(true);
    axios
      .put<any>(`/course`, data)
      .then(({ data }) => {
        console.log(data);
        navigate(
          `/institution/${institution?.institutionId}/course/${data.courseId}`
        );
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  useEffect(() => {
    getUrl<ICourseViewModel>(
      `/course/${params.courseId}`,
      ({ data: course }) => {
        const studentsId = course.students?.map((c) => c.userId) ?? [];
        // const itemsId = course.courseItems?.map((c) => c.itemId) ?? [];
        const disciplineId = course.programViewModel?.disciplineId;
        setValue("disciplineId", course.programViewModel?.disciplineId?.toString());
        setValue("name", course.name);
        setValue("startDate", moment(course.startDate));
        setValue("planning", course.planning);
        setValue("capacity", course.capacity);
        // setValue(
        //   "supplies",
        //   course.courseItems?.map((c) => ({ itemId: c.itemId, total: 0 })) ?? []
        // );
        setValue("students", studentsId);
        setValue("schedules", course.scheduleViewModels);
        setValue('duplaId', course.duo?.userId);
        console.log(course);

				getUrl<IUserViewModel[]>(`/discipline/${disciplineId}/tutor`, ({ data }) => {
          setValue('monitorId', course.tutor?.userId);
          setMonitors(data);
        });

        // getUrl<IDisciplineItem[]>(
        //   `/institution/${institution?.institutionId}/item/${disciplineId}`,
        //   ({ data }) => {
        //     setItems(
        //       data.map((i) => ({ ...i, checked: itemsId.includes(i.itemId) }))
        //     );
        //   }
        // );

        getUrl<IProgram[]>(`/program/${disciplineId}`, ({ data }) => {
          setPrograms(data);
          setValue('programId', course.programViewModel?.programId);
        });

        getUrl<any>(
          `/institution/${institution?.institutionId}/user`,
          ({ data }) => {
            const {
              officers,
              students,
            }: { officers: IUser[]; students: IUser[] } = data;
            setStudents(
              students.map((s) => ({
                ...s,
                checked: { checked: studentsId.indexOf(s.userId) > -1 },
              }))
            );

            setOfficers(officers);
          }
        );
        setCourse(course);
      }
    );
  }, [
    institution?.institutionId,
    getUrl,
    params.courseId,
    params.version,
    setValue,
  ]);

  function onChangeProgram(programId: any) {
    const program = programs.find(({ programId: id }) => id === programId);
    if (!program) return;

    setValue('programId', programId);
  }

  return (
    <div className='ant-fade-enter ant-fade-enter-active line-normal h100 o-hidden flex flex-column'>
			<Confirm
				ref={confirmRef}
				title={<><InfoOutlined className='text-danger' /><span>Datos Faltantes</span></>}
				okText='Aceptar'
				cancelProps={{ visible: false }} />
      <LoadingKiri ref={loadingRef} />
      <div className='inner w100'>
        <Header
          canSave
          editable={false}
          title={`Actualizar Taller ${course?.name}`}
          onCancel={() =>
            navigate(
              `/institution/${institution?.institutionId}/course/${course?.courseId}`
            )
          }
          onSave={handleSubmit(onSubmit)}
          descriptions={[
            <div className='text-secondary flex flex-center flex-gap-6'>
              <SchoolFilled />
              <span>{institution?.name}</span>
            </div>,
          ]}
        />
      </div>

      <div className='flex-1 overflow-hidden-x'>
        <div className='inner'>
          <SectionHeader
            size='small'
            className='mb-10'
            prefix={<DetailsOutlined className='text-emphasis' />}
            title='Datos principales'
          />

          <div className='mb-20 flex flex-center flex-gap-14'>
            <Controller
              name='disciplineId'
              control={control}
              render={({ field }) => (
                <Select
                  optionFilterProp='label'
                  disabled
                  label='Disciplina'
                  options={discipline}
                  type='mainAlt'
                  style={{ width: 160 }}
                  value={field.value}
                />
              )}
            />
            <Controller
              name='programId'
              control={control}
              render={({ field }) => (
                <Select
                  optionFilterProp='label'
                  label='Programa'
                  type='mainAlt'
                  options={programs.map((d) => ({
                    value: d.programId.toString(),
                    label: d.description,
                  }))}
                  style={{ width: 300 }}
                  value={field.value}
                  onChange={onChangeProgram}
                />
              )}
            />
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <Input
                  label='Nombre'
                  placeholder='Ingrese nombre'
                  type='mainAlt'
                  style={{ width: 300 }}
                  {...field}
                />
              )}
            />
            <div>
              <label className='mb-10 flex'>Fecha de inicio</label>
              <Controller
                name='startDate'
                control={control}
                render={({ field }) => (
                  <DatePicker
                    type='mainAlt'
                    style={{ width: 140 }}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>
          <div className='mb-20 flex flex-center flex-gap-14'>
            <Controller
              name='planning'
              control={control}
              render={({ field }) => (
                <Input
                  label='Link de planificación'
                  placeholder='Ingrese url de planificación'
                  type='mainAlt'
                  style={{ width: 474 }}
                  {...field}
                />
              )}
            />
            <Controller
              name='capacity'
              control={control}
              render={({ field }) => (
                <InputNumber
                  min={0}
                  label='Capacidad'
                  placeholder='...'
                  type='mainAlt'
                  style={{ width: 140 }}
                  {...field}
                />
              )}
            />
          </div>

          <div>
            <div className='flex flex-center flex-space mb-10'>
              <p>Horario</p>
              <div className='flex flex-center text-secondary'>
                <span>
                  Seleccione las horas y los días en los que se imparte este
                  taller
                </span>
                <InfoOutlined className='ml-4' />
              </div>
            </div>
            <Controller
              name='schedules'
              control={control}
              render={({ field }) => (
                <Schedule
                  editable
                  className='pdb-20 mb-20'
                  schedules={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <SectionHeader
            size='small'
            className='mb-10 border-top-main'
            prefix={<UserFilled className='text-emphasis' />}
            title='Participantes'
          />
          <div className='flex flex-center flex-gap-14 mb-20'>
            <Controller
              name='monitorId'
              control={control}
              render={({ field }) => (
                <Select
                  optionFilterProp='label'
                  label='Monitor'
                  type='mainAlt'
                  options={monitors.map((d) => ({
                    value: d.userId.toString(),
                    label: `${d.firstName} ${d.lastName}`,
                  }))}
                  style={{ width: 260 }}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              name='duplaId'
              control={control}
              render={({ field }) => (
                <Select
                  optionFilterProp='label'
                  label='Dupla'
                  type='mainAlt'
                  options={officers.map((d) => ({
                    value: d.userId.toString(),
                    label: `${d.firstName} ${d.lastName}`,
                  }))}
                  style={{ width: 260 }}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className='pdb-20 mb-20'>
            <p className='mb-10'>Alumnos</p>
            <Controller
              name='students'
              control={control}
              render={({ field }) => (
                <UserSelect options={students} onChange={field.onChange} />
              )}
            />
          </div>

          {/* <SectionHeader
            size='small'
            className='mb-10 border-top-main'
            prefix={<UserFilled className='text-emphasis' />}
            title='Insumos'
          />
          <div className='pdb-20 mb-10'>
            <Controller
              name='supplies'
              control={control}
              render={({ field }) => (
                <SupplySelect options={items} onChange={field.onChange} />
              )}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};
