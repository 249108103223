import { Button, Card, Input, List, Table, Tag } from '@outlier-spa/component';
import { axios, useApi } from '@outlier-spa/fetch';
import { SearchOutlined, TickOutlined } from '@outlier-spa/icon';
import { LoadingKiri } from 'components';
import { IEventAuthorization } from 'interfaces';
import { IEventStudentViewModel, IEventViewModel } from 'interfaces/event';
import React, { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

export const StyledSurveyParticipation = styled.div`
  .participation-cell {
    > p {
      display: block;
    }
    button {
      display: none;
    }
  }
  .table {
    tbody {
      cursor: pointer;
      &:hover {
        .participation-cell {
          > p {
            display: none;
          }
          button {
            display: block;
          }
        }
      }
    }
  }
  .list {
    .list-item-active {
      font-weight: 600;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: ${({ theme }) => theme.colors.emphasis};
      }
    }
  }
`;

const ParticipationCell = ({ value, row, handlers }: any) => {
  // const handleClick = () => handlers.participationUser?.(row.userId.value);

  if (value) {
    return (
      <div className="participation-cell">
        <p className="flex flex-center flex-gap-6">
          <TickOutlined className="text-emphasis" /> <span>Respondida</span>
        </p>
      </div>
    );
  }
  return (
    <div className="participation-cell">
      <p
        className="text-emphasis bold t200 text-center"
        style={{ textDecoration: 'underline' }}
      >
        Pendiente
      </p>
    </div>
  );
};

const DisciplineCell = ({ value }: any) => {
  return (
    <div className="flex flex-gap-4 flex-center">
      {value?.map((discipline: string, id: number) => (
        <Tag
          size="small"
          rounded
          key={`${discipline}-${id}`}
          label={discipline}
        />
      ))}
    </div>
  );
};

export interface IEventAuthorizationProps {
  event?: IEventViewModel;
}

const normalizeText = (value: string) =>
  value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const AppliedSurveyDetails: React.FC<IEventAuthorizationProps> = ({
  event,
}) => {
  const [getUrl, loadingRef] = useApi();
  const [institutionId, setInstitutionId] = useState<number>();
  const [filter, setFilter] = useState<string>('');
  const [users, setUsers] = useState<IEventStudentViewModel[]>();
  const filteredUsers = useMemo(() => {
    if (!filter || !users) return users;
    return users.filter(({ firstName, lastName, rut, email, disciplines }) => {
      const disciplineName = disciplines?.map((name) =>
        normalizeText(name.toLowerCase())
      );
      return (
        normalizeText(firstName?.toLowerCase() || '').includes(
          normalizeText(filter.toLowerCase())
        ) ||
        normalizeText(lastName?.toLowerCase() || '').includes(
          normalizeText(filter.toLowerCase())
        ) ||
        normalizeText(rut?.toLowerCase() || '').includes(
          normalizeText(filter.toLowerCase())
        ) ||
        normalizeText(email?.toLowerCase() || '').includes(
          normalizeText(filter.toLowerCase())
        ) ||
        disciplineName?.some((name) =>
          normalizeText(name).includes(normalizeText(filter.toLowerCase()))
        )
      );
    });
  }, [users, filter]);

  function handleItemClick(institutionId: number) {
    setInstitutionId(institutionId);
    setFilter('');
  }

  function authorizeUser(userId: any) {
    const user = users?.find((u) => u.userId === userId);
    if (!event || !institutionId || !users || !user) return;
    loadingRef.current?.setActive(true);
    // const postData = { institutionId, eventId: event?.eventId, userId };
    // console.log(postData);
    axios
      .post<IEventStudentViewModel>(
        `/event/${event.eventId}/${institutionId}/authorize`,
        { ...user, authorization: !user.authorization }
      )
      .then(({ data }) => {
        const newUsers = users.map((u) =>
          u.userId === data.userId ? data : u
        );
        setUsers(newUsers);
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  useEffect(() => {
    if (event?.institutions?.length) {
      setInstitutionId(event?.institutions[0].institutionId);
    }
  }, [event]);

  useEffect(() => {
    if (!institutionId) return;
    getUrl<IEventStudentViewModel[]>(
      `/event/${event?.eventId}/${institutionId}/students`,
      ({ data }) => {
        console.log(data);
        setUsers(data);
      }
    );
  }, [institutionId, getUrl, event]);

  return (
    <StyledSurveyParticipation className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex o-hidden flex-gap-20">
      <LoadingKiri ref={loadingRef} />
      <div className="flex flex-column" style={{ width: 240 }}>
        <h6 className="bold mb-14 text-secondary">Establecimientos</h6>
        <Card className="flex-1">
          {event && (
            <List
              hoverable
              activeKeys={institutionId !== undefined ? [institutionId] : []}
              items={event?.institutions}
              onItemClick={handleItemClick}
              keyExtractor={(item) => item.institutionId}
              renderItem={(item) => item.name}
            />
          )}
        </Card>
      </div>
      <div className="flex-1 flex flex-column o-hidden">
        <h6 className="bold mb-14 text-secondary">Monitores</h6>
        {/* <Input
          value={filter}
          onChange={(evt) => setFilter(evt.target.value)}
          placeholder="Escriba algún parámetro para filtrar..."
          type="mainAlt"
          prefix={<SearchOutlined className="text-secondary" />}
        /> */}
        <div className="flex-1 o-hidden relative overflow-hidden-x">
          <Table
            className="h100 overflow-hidden-x"
            fixedHeaders
            hoverable
            headers={[
              {
                key: 'firstName',
                sortable: true,
                value: <strong className="bold">Nombre</strong>,
              },
              {
                key: 'lastName',
                sortable: true,
                value: <strong className="bold">Apellido</strong>,
              },
              {
                key: 'rutWithFormat',
                sortable: true,
                value: <strong className="bold">Rut</strong>,
              },
              {
                key: 'disciplines',
                value: <strong className="bold">Disciplina</strong>,
                cellComponent: DisciplineCell,
              },
              {
                key: 'participation',
                value: 'Participación',
                width: 120,
                cellComponent: ParticipationCell,
              },
            ]}
            data={filteredUsers}
            handlers={{
              authorizeUser,
            }}
          />
        </div>
      </div>
    </StyledSurveyParticipation>
  );
};
