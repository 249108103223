import styled from "styled-components";

export const StyledEventList = styled.div`
  .event-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    display: grid;
  }
`;
