import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Card,
  Avatar,
  Table,
  SearchBar,
  Confirm,
  NotFound,
} from '@outlier-spa/component';
import { RiskFilled, SupplierFilled } from '@outlier-spa/icon';
import { LoadingKiri, PopoverOptions } from 'components';
import { useApi, axios } from '@outlier-spa/fetch';
import { ISupplier, ICity } from 'interfaces';
import { DefaultCell } from 'components/table';
import { SupplierModal } from 'components/modal';

const Image = (props: any) => (
  <Avatar
    email={props.row?.email?.value}
    className="flex flex-middle o-hidden"
    size="2.4rem"
  >
    {!props.row?.email?.value && (
      <SupplierFilled className="text-16 text-secondary" />
    )}
  </Avatar>
);

const City = (props: { value?: ICity }) => {
  return props?.value?.name ?? '-';
};

const headers: any = [
  { key: 'image', value: '', cellComponent: Image },
  { key: 'name', value: 'Nombre', sortable: true },
  { key: 'identifier', value: 'Identificador', sortable: true },
  { key: 'email', value: 'Correo', sortable: true, cellComponent: DefaultCell },
  {
    key: 'phoneNumber',
    value: 'Teléfono',
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'address',
    value: 'Dirección',
    sortable: true,
    cellComponent: DefaultCell,
  },
  { key: 'city', value: 'Ciudad', sortable: true, cellComponent: City },
  { key: 'supplierId', value: '', cellComponent: PopoverOptions },
];

export interface IInventorySupplierListProps {}

export const InventorySupplierList: React.FC<
  IInventorySupplierListProps
> = () => {
  const supplierModalRef = useRef<React.ElementRef<typeof SupplierModal>>(null);
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [getUrl, loadingRef] = useApi();

  const [filter, setFilter] = useState<string>();
  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      const filterStr = filter.toLowerCase();
      return data.filter((d) => {
        const name = d[0].name?.value?.toLowerCase();
        const identifier = d[0].identifier?.value?.toLowerCase();
        const phoneNumber = d[0].phoneNumber?.value?.toLowerCase();
        const email = d[0].email?.value?.toLowerCase();
        const address = d[0].address?.value?.toLowerCase();
        const city = d[0].city?.value?.name?.toLowerCase();
        return (
          name?.includes(filterStr) ||
          email?.includes(filterStr) ||
          identifier?.includes(filterStr) ||
          phoneNumber?.includes(filterStr) ||
          address?.includes(filterStr) ||
          city?.includes(filterStr)
        );
      });
    },
    [filter]
  );

  function handleAddSupplier() {
    supplierModalRef.current?.open();
  }

  function handleRemoveSupplier(supplierId: any) {
    removeRef.current?.open(
      supplierId,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este proveedor?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  function handleUpdateSupplier(supplierId: any) {
    const supplier = suppliers?.find((u) => u.supplierId === supplierId);
    supplierModalRef.current?.open(supplier);
  }

  function onRemoveSupplier(supplierId: number) {
    loadingRef.current?.setActive(true);
    axios
      .delete(`supplier/${supplierId}`)
      .then(() => {
        setSuppliers(suppliers.filter((u) => u.supplierId !== supplierId));
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function onSubmitSupplier(supplier: ISupplier, prevSupplier?: ISupplier) {
    loadingRef.current?.setActive(true);
    if (prevSupplier) {
      const updateSupplier = suppliers?.find(
        (u) => u.supplierId === prevSupplier.supplierId
      );
      if (!updateSupplier || !suppliers) {
        loadingRef.current?.setActive(false);
        return;
      }
      const index = suppliers?.indexOf(updateSupplier);
      axios
        .put<ISupplier>(`supplier/${updateSupplier?.supplierId}`, supplier)
        .then(({ data }) => {
          const newSuppliers = [...suppliers];
          newSuppliers[index as number] = { ...updateSupplier, ...data };
          setSuppliers(newSuppliers);
          supplierModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    } else {
      axios
        .post<ISupplier>(`supplier`, supplier)
        .then(({ data }) => {
          console.log({ data });
          setSuppliers([data, ...suppliers]);
          supplierModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    }
  }

  useEffect(() => {
    getUrl<ISupplier[]>(`supplier`, ({ data }) => {
      console.log(data);
      setSuppliers(data);
    });
  }, [getUrl]);

  return (
    <div className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        okText="Eliminar Proveedor"
        className="line-normal"
        onSubmit={onRemoveSupplier}
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
      />
      <SupplierModal ref={supplierModalRef} onSubmit={onSubmitSupplier} />
      <SearchBar
        type="mainAlt"
        buttonText="Agregar Proveedor"
        onChange={(evt) => setFilter(evt.target.value)}
        onSubmit={handleAddSupplier}
      />
      <Card
        className={`overflow-auto mt-14${
          suppliers?.length > 0 ? ' flex-1' : ''
        }`}
        style={{ maxHeight: '100%' }}
      >
        <Table
          bordered={false}
          className="table-row-large"
          fixedHeaders
          hoverable
          filter={tableFilter}
          handlers={{
            onRemove: handleRemoveSupplier,
            onUpdate: handleUpdateSupplier,
          }}
          headers={headers}
          data={suppliers}
        />
        {suppliers?.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay proveedores registrados." />
          </div>
        )}
      </Card>
    </div>
  );
};
