import {
  RobotUnionFilled,
  TennisFilled,
  MicFilled,
  SkateBoardFilled,
  ProgrammingFilled,
  CircusFilled,
  SchoolFilled,
  ClassFilled,
  FolderFilled,
  CourseFilled,
  PieChartFilled,
  KiriLogoFilled,
} from "@outlier-spa/icon";
import { TicketFilled } from "@outlier-spa/icon";

export const disciplineIcons: Record<string, React.ReactNode> = {
  "1": <RobotUnionFilled className="mr-8 text-12" />,
  "2": <TennisFilled className="mr-8 text-12" />,
  "3": <MicFilled className="mr-8 text-12" />,
  "4": <SkateBoardFilled className="mr-8 text-12" />,
  "5": <ProgrammingFilled className="mr-8 text-12" />,
  "6": <CircusFilled className="mr-8 text-12" />,
};

export const EventIcon = (props: any) => <TicketFilled {...props} />;
export const SchoolIcon = (props: any) => <SchoolFilled {...props} />;
export const ClassIcon = (props: any) => <ClassFilled {...props} />;
export const FolderIcon = (props: any) => <FolderFilled {...props} />;
export const CourseIcon = (props: any) => <CourseFilled {...props} />;
export const StatisticsIcon = (props: any) => <PieChartFilled {...props} />;
export const KiriLogo = (props: any) => <KiriLogoFilled {...props} />;
