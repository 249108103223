import React, { useContext, useEffect } from 'react';
import {
  useIsAuthenticated,
  useMsal,
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
} from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from 'auth';

export interface IAppContext {
  title?: React.ReactNode;
  token: string | null;
  setTitle: (title: React.ReactNode) => void;
}

const AppContext = React.createContext<IAppContext>({
  setTitle: () => console.log('setTitle is not implemented'),
  token: null,
});

export const useApp = () => useContext(AppContext);

export function useTitle(title?: React.ReactNode) {
  const { setTitle } = useApp();
  useEffect(() => {
    setTitle(title);
    return () => setTitle('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);
}

export const AppProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [title, setTitle] = React.useState<React.ReactNode>('');
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();
  const [token, setToken] = React.useState<string | null>(null);

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None && accounts.length > 0) {
      const request = { ...loginRequest, account: accounts[0] };
      instance
        .acquireTokenSilent(request)
        .then(response => {
          setToken(response.accessToken);
          localStorage.setItem('session', response.accessToken);
        })
        .catch(e => {
          instance.loginRedirect(loginRequest);
        });
    }

    if (inProgress === InteractionStatus.None && !isAuthenticated)
      instance.loginRedirect(loginRequest);
  }, [instance, inProgress, isAuthenticated, accounts]);

  if (!token) return null;
  return (
    <AppContext.Provider value={{ title, token, setTitle }}>
      <UnauthenticatedTemplate>
        {inProgress === InteractionStatus.None && <span>Redirigiendo a home...</span>}
        {inProgress === InteractionStatus.Login && <span>Redirigiendo a login...</span>}
        {inProgress === InteractionStatus.Logout && <span>Cerrando sesión...</span>}
        {inProgress === InteractionStatus.HandleRedirect && <span>Redirigiendo...</span>}
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate children={children} />
    </AppContext.Provider>
  );
};
