import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  Card,
  Table,
  Avatar,
  Confirm,
  SearchBar,
  NotFound,
} from '@outlier-spa/component';
import { RiskFilled } from '@outlier-spa/icon';
import { LoadingKiri, PopoverOptions, UserModal } from 'components';
import { axios, useApi } from '@outlier-spa/fetch';
import { IUserParentRole } from 'interfaces';
import { IUserViewModel } from 'interfaces/user';
import { IInstitutionStudentViewModel } from 'interfaces/institution';
import { DefaultCell } from 'components/table';

const Image = (props: { value?: string }) => (
  <Avatar email={props.value} className="flex o-hidden" size="2.4rem" />
);

const headers: any = [
  { key: 'email', value: '', cellComponent: Image },
  { key: 'firstName', value: 'Nombres', sortable: true },
  { key: 'lastName', value: 'Apellidos', sortable: true },
  { key: 'rut', value: 'Rut', sortable: true },
  {
    key: 'dateOfBirth',
    value: 'F. nacimiento',
    sortable: true,
    cellComponent: DefaultCell,
  },
  { key: 'city', value: 'Ciudad', sortable: true, cellComponent: DefaultCell },
  { key: 'address', value: 'Dirección', sortable: true, DefaultCell },
  { key: 'userId', value: '', cellComponent: PopoverOptions },
];

export interface IInstitutionStudentCaregiversProps {
  userId: string;
}

export const InstitutionStudentCaregivers: React.FC<
  IInstitutionStudentCaregiversProps
> = ({ userId }) => {
  const addModalRef = useRef<React.ElementRef<typeof UserModal>>(null);
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const [getUrl, loadingRef] = useApi();
  const [parents, setParents] = useState<IUserViewModel[]>([]);
  const [filter, setFilter] = useState<string>();

  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      const filterStr = filter.toLowerCase();
      return data.filter((d) => {
        const firstName = d[0].firstName?.value?.toLowerCase();
        const lastName = d[0].lastName?.value?.toLowerCase();
        const rut = d[0].rut?.value?.toLowerCase();
        const email = d[0].email?.value?.toLowerCase();
        return (
          firstName?.includes(filterStr) ||
          lastName?.includes(filterStr) ||
          rut?.includes(filterStr) ||
          email?.includes(filterStr)
        );
      });
    },
    [filter]
  );

  function handleAddParent() {
    addModalRef.current?.open();
  }

  function onSubmitParent(parent: IUserViewModel, prevParent?: IUserViewModel) {
    loadingRef.current?.setActive(true);

    if (prevParent) {
      const index = parents?.indexOf(prevParent);
      if (index === -1 || !parents) {
        loadingRef.current?.setActive(false);
        return;
      }
      axios
        .put<IUserViewModel>(`user/${parent?.userId}`, parent)
        .then(({ data }) => {
          const newParents = [...parents];
          newParents[index as number] = data;
          setParents(newParents);
          addModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    } else {
      axios
        .post<Required<IUserParentRole>>(`user/${userId}/parent`, parent)
        .then(({ data }) => {
          console.log({ data });
          setParents([data.parent as any, ...parents]);
          addModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    }
  }

  function handleRemoveParent(parentId: any) {
    removeRef.current?.open(
      parentId,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este cuidador?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  function onRemoveParent(parentId: any) {
    loadingRef.current?.setActive(true);
    axios
      .delete(`user/${userId}/parent/${parentId}`)
      .then(() => {
        setParents(parents.filter((parent) => parent.userId !== parentId));
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function handleUpdateParent(parentId: any) {
    addModalRef.current?.open(parents?.find((p) => p.userId === parentId));
  }

  // useEffect(() => {
  //   if (!userId) return;

  //   getUrl<IUserViewModel[]>(`user/${userId}/parents`, ({ data }) => {
  //     console.log({ data });
  //     setParents(data);
  //   });
  // }, [userId, getUrl]);

  useEffect(() => {
    if (!userId) return;
    getUrl<IInstitutionStudentViewModel>(
      `institution/1/student/${userId}/summary`,
      ({ data }) => {
        setParents(data.carers ?? []);
      }
    );
  }, [userId, getUrl]);

  return (
    <div className="h100 flex flex-column pdt-14 o-hidden line-normal ant-fade-enter ant-fade-enter-active">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        okText="Eliminar Cuidador"
        className="line-normal"
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
        onSubmit={onRemoveParent}
      />
      <UserModal
        ref={addModalRef}
        onSubmit={onSubmitParent}
        okText="Agregar Cuidador"
        title="Agregar Cuidador"
      />
      <SearchBar
        type="tertiary"
        buttonText="Agregar Cuidador"
        onSubmit={handleAddParent}
        onChange={(evt) => setFilter(evt.target.value)}
      />
      <Card
        className={`overflow-auto mt-14${parents.length > 0 ? ' flex-1' : ''}`}
        style={{ maxHeight: '100%' }}
      >
        <Table
          bordered={false}
          className="table-row-large"
          fixedHeaders
          hoverable
          filter={tableFilter}
          handlers={{
            onRemove: handleRemoveParent,
            onUpdate: handleUpdateParent,
          }}
          headers={headers}
          data={parents}
        />
        {parents.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay cuidadores registrados para este usuario." />
          </div>
        )}
      </Card>
    </div>
  );
};
