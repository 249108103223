import React, { useRef } from 'react';
import { Popover } from '@outlier-spa/component';
import { Emotion1, Emotion2, Emotion3, Emotion4, Emotion5 } from 'icon/emotion';

export const emotionDict: any = {
  1: { name: 'Enojado', icon: Emotion1 },
  2: { name: 'Triste', icon: Emotion2 },
  3: { name: 'Neutro', icon: Emotion3 },
  4: { name: 'Alegre', icon: Emotion4 },
  5: { name: 'Feliz', icon: Emotion5 },
  6: { name: 'Ausente', icon: null },
  7: { name: 'Presente', icon: null },
};
const popoverEmotions = [1, 2, 3, 4, 5];

export interface IEmotionPopoverProps {
  onChange?: (value: number) => void;
}

export const EmotionPopover: React.FC<IEmotionPopoverProps> = ({ children, onChange }) => {
  const popoverRef = useRef<React.ElementRef<typeof Popover>>(null);

  function onEmotionClick(value: number) {
    onChange?.(value);
    popoverRef.current?.setOpen(false);
  }

  return (
    <Popover
      ref={popoverRef}
      content={
        <div className="flex flex-center flex-gap-14 pdv-14 pdh-12">
          {popoverEmotions.map(emotion => (
            <EmotionIcon key={emotion} emotion={emotion} onClick={onEmotionClick} />
          ))}
        </div>
      }
    >
      {children}
    </Popover>
  );
};

const EmotionIcon: React.FC<{
  emotion: number;
  onClick?: (emotion: number) => void;
}> = ({ emotion, onClick }) => {
  const Emotion = emotionDict[emotion].icon;
  return (
    <div
      onClick={() => onClick?.(emotion)}
      key={emotion}
      className="flex flex-center flex-column pointer scale-active t200 text-secondary hover-emphasis"
    >
      <Emotion style={{ fontSize: 40 }} />
      <p className="mt-10 text-12">{emotionDict[emotion].name}</p>
    </div>
  );
};
