import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Card,
  Confirm,
  Input,
  Modal,
  NotFound,
  SearchBar,
  Select,
  SwitchOptions,
  Table,
  Tag,
  TextArea,
  Upload,
} from '@outlier-spa/component';
import { axios, useApi } from '@outlier-spa/fetch';
import { RiskFilled } from '@outlier-spa/icon';
import { LoadingKiri, PopoverOptions } from 'components';
import { DefaultCell } from 'components/table';
import { useDiscipline } from 'hooks';
import { IProduct, IProductDiscipline } from 'interfaces';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const Disciplines = (props: { value?: any[] }) =>
  props.value && props.value?.length
    ? props?.value
        ?.map((productDiscipline: any) => productDiscipline.discipline?.name)
        .join(', ')
    : '-';

const headers: any = [
  // { key: 'provider', value: 'Proveedor', sortable: true },
  { key: 'name', value: 'Nombre', sortable: true },
  { key: 'description', value: 'Descripción', cellComponent: DefaultCell },
  {
    key: 'hasIdentifier',
    value: 'Código de barras',
    cellComponent: (props: { value: any }) => (props.value ? 'Si' : 'No'),
  },
  {
    key: 'productDisciplines',
    value: 'Disciplinas',
    sortable: true,
    cellComponent: Disciplines,
  },
  { key: 'productId', value: '', cellComponent: PopoverOptions },
];

interface IProductNewRef {
  open: (product?: IProduct) => void;
  close: () => void;
}

interface IInventoryProductNewProps {
  onSubmit: (data: IProduct, files: File[], prevProduct?: IProduct) => void;
}

export const InventoryProductNew = React.forwardRef<
  IProductNewRef,
  IInventoryProductNewProps
>(({ onSubmit }, ref) => {
  const modalRef = useRef<React.ElementRef<typeof Modal>>(null);
  const [product, setProduct] = useState<IProduct>();
  const disciplineOptions = useDiscipline();
  // const suppliers = useSuppliers();
  const { control, handleSubmit, formState, watch, setValue, reset } =
    useForm();
  const filesRef = useRef<File[]>([]);
  const disciplines = watch('disciplines');
  const hasIdentifier = watch('hasIdentifier');

  function onSubmitData(data: any) {
    if (formState.errors && Object.keys(formState.errors).length > 0) return;

    data.productDisciplines =
      data.disciplines?.map((disciplineId: string) => ({
        disciplineId,
        discipline: {
          disciplineId,
          name: disciplineOptions.find((d) => d.value === disciplineId)?.label,
        },
      })) ?? [];
    // data.productDisciplines = data.disciplines?.map((disciplineId: string) => ({ disciplineId })) ?? [];
    data.hasIdentifier =
      !data.hasIdentifier ||
      data.hasIdentifier?.content?.toUpperCase() === 'SI';
    onSubmit(data, filesRef.current, product);
  }

  function handleFileChange(files: File[]) {
    filesRef.current = files;
  }

  function handleRemoveDiscipline(_: any, disciplineId: string) {
    setValue(
      'disciplines',
      disciplines.filter((discipline: string) => discipline !== disciplineId)
    );
  }

  function open(product?: IProduct) {
    console.log(product);
    setProduct(product);
    reset(product);
    setValue(
      'disciplines',
      product?.productDisciplines?.map((discipline: any) =>
        discipline.disciplineId.toString()
      ) ?? []
    );
    setValue('hasIdentifier', {
      content: product?.hasIdentifier ? 'SI' : 'NO',
    });
    modalRef.current?.open();
  }

  function close() {
    modalRef.current?.close();
  }

  useImperativeHandle(ref, () => ({ open, close }));

  return (
    <Modal
      okProps={{ type: 'assertive' }}
      title={product ? 'Editar Producto' : 'Nuevo Producto'}
      okText={product ? 'Actualizar Producto' : 'Agregar Producto'}
      size="small"
      overlayClose={false}
      ref={modalRef}
      onSubmit={handleSubmit(onSubmitData)}
    >
      <div className="pdh-14 flex flex-column flex-vgap-16 pdv-16">
        <div
          className="flex flex-wrap gap-10"
          style={{ flexWrap: 'wrap', gap: 10, alignContent: 'space-between' }}
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                placeholder="Ingrese nombre"
                type="secondary"
                validation={{
                  message: 'Ingrese nombre del producto',
                  visible: !!formState.errors?.name,
                }}
                className="flex-1"
                label="Nombre"
                {...field}
              />
            )}
          />

          <div>
            <p className="mb-10">Código de barras</p>
            <Controller
              name="hasIdentifier"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <SwitchOptions
                  index={hasIdentifier?.content === 'SI' ? 0 : 1}
                  options={[
                    {
                      content: 'Si',
                    },
                    {
                      content: 'No',
                    },
                  ]}
                  type="main"
                  {...rest}
                />
              )}
            />
          </div>
        </div>

        <div>
          <p className="mb-10">Disciplinas</p>
          <div className="flex flex-wrap gap-10">
            <Controller
              name="disciplines"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  optionFilterProp="label"
                  type="secondary"
                  mode="tags"
                  tagRender={() => <></>}
                  validation={{
                    message: 'Seleccione al menos una disciplina',
                    visible: !!formState.errors?.disciplines,
                  }}
                  options={disciplineOptions}
                  className="w100"
                  style={{ width: '100%' }}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </div>
        </div>
        <div>
          <div
            className="flex flex-center"
            style={{ flexWrap: 'wrap', gap: 10, alignContent: 'space-between' }}
          >
            {disciplineOptions.map(
              (opt: any) =>
                disciplines?.includes(opt.value.toString()) && (
                  <Tag
                    key={opt.value}
                    value={opt.value}
                    closable
                    label={opt.label}
                    onClose={handleRemoveDiscipline}
                  />
                )
            )}
          </div>
        </div>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextArea
              type="secondary"
              placeholder="Ingrese detalle"
              style={{ width: '100%' }}
              rows={4}
              label="Detalle"
              {...field}
            />
          )}
        />
        <div>
          <p className="mb-10">Documento asociado (opcional)</p>
          <Upload
            type="secondary"
            onChange={handleFileChange}
            multiple
            size="large"
          />
        </div>
      </div>
    </Modal>
  );
});

export interface IInventoryProductListProps {}

export const InventoryProductList: React.FC<
  IInventoryProductListProps
> = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const addModalRef =
    useRef<React.ElementRef<typeof InventoryProductNew>>(null);
  const navigate = useNavigate();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [getUrl, loadingRef] = useApi();

  const [filter, setFilter] = useState<string>();
  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      const filterStr = filter.toLowerCase();
      return data.filter((d) => {
        const name = d[0].name?.value?.toLowerCase();
        const description = d[0].description?.value?.toLowerCase();
        const disciplines = d[0].productDisciplines?.value
          ?.map((d: IProductDiscipline) => d?.discipline?.name?.toLowerCase())
          .join(' ');
        const hasIdentifier = d[0].hasIdentifier?.value ? 'si' : 'no';

        return (
          name?.includes(filterStr) ||
          description?.includes(filterStr) ||
          disciplines?.includes(filterStr) ||
          hasIdentifier?.includes(filterStr)
        );
      });
    },
    [filter]
  );

  function handleProductClick({ productId }: any) {
    navigate(`/inventory/products/${productId.value}`);
  }

  function handleAddProduct() {
    addModalRef.current?.open();
  }

  function onAddProduct(
    product: IProduct,
    files: File[],
    prevProduct?: IProduct
  ) {
    loadingRef.current?.setActive(true);
    if (prevProduct) {
      const index = products?.findIndex(
        (item) => item.productId === prevProduct.productId
      );
      if (index === -1 || !products) {
        loadingRef.current?.setActive(false);
        return;
      }
      const formData = new FormData();
      formData.append('productModel', JSON.stringify(product));
      files.forEach((file) => formData.append(file.name, file));
      axios
        .put<IProduct>(`product`, formData)
        .then(({ data }) => {
          console.log(data);
          const newProducts = [...products];
          newProducts[index] = data;
          setProducts(newProducts);
          addModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    } else {
      const formData = new FormData();
      formData.append('productModel', JSON.stringify(product));
      files.forEach((file) => formData.append(file.name, file));
      axios
        .post<IProduct>(`product`, formData)
        .then(({ data }) => {
          setProducts([data, ...products]);
          addModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    }
  }

  function handleRemoveProduct(productId: any) {
    removeRef.current?.open(
      productId,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este Producto?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  function handleUpdateProduct(productId: any) {
    const product = products.find((p) => p.productId === productId);
    if (product) addModalRef.current?.open(product);
  }

  function handleRemoveProductConfirm(productId: any) {
    loadingRef.current?.setActive(true);
    axios
      .delete(`product/${productId}`)
      .then(() => {
        setProducts(
          products.filter((product) => product.productId !== productId)
        );
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  useEffect(() => {
    getUrl<IProduct[]>(`product/inventory`, ({ data }) => {
      console.log(data);
      setProducts(data);
    });
  }, [getUrl]);

  return (
    <div className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <InventoryProductNew onSubmit={onAddProduct} ref={addModalRef} />
      <Confirm
        okText="Eliminar Producto"
        className="line-normal"
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
        onSubmit={handleRemoveProductConfirm}
      />

      <SearchBar
        type="mainAlt"
        buttonText="Agregar Producto"
        onChange={(evt) => setFilter(evt.target.value)}
        onSubmit={handleAddProduct}
      />
      <Card
        className={`overflow-auto mt-14${
          products?.length > 0 ? ' flex-1' : ''
        }`}
        style={{ maxHeight: '100%' }}
      >
        <Table
          bordered={false}
          fixedHeaders
          hoverable
          filter={tableFilter}
          onRowClick={handleProductClick}
          handlers={{
            onRemove: handleRemoveProduct,
            onUpdate: handleUpdateProduct,
          }}
          headers={headers}
          data={products}
        />
        {products?.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay productos registrados." />
          </div>
        )}
      </Card>
    </div>
  );
};
