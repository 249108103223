import styled from 'styled-components';

export const StyledClassCapacity = styled.div`
  .square {
    width: 18px;
    height: 18px;
    border-radius: 3px;
    &.bordered {
      background-color: ${({ theme }) => theme.field.secondary};
      border: 1px solid ${({ theme }) => theme.border.main};
    }
  }
	.institution-courses {
		width: 200px;
		min-width: 200px;
	}
  .institution-courses h6,
  .institution-line {
    position: sticky;
    top: 44px;
    background-color: ${({ theme }) => theme.card.main};
  }
  .institution-courses,
  .chart-title {
    position: sticky;
    background-color: ${({ theme }) => theme.card.main};
  }
  .institution-courses {
    left: 0;
    z-index: 1;
  }
  .chart-title {
    top: 0;
    left: 0;
    z-index: 2;
  }
  .institution-line {
    z-index: 1;
  }
  .bar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 18px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    &.pointer {
      border: 1px solid transparent;
      box-shadow: 0 0 0 0 ${({ theme }) => theme.colors.emphasis};
      &:hover {
        border-color: ${({ theme }) => theme.colors.emphasis};
        box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.emphasis}2B;
      }
    }
    &.bar-attendance {
      background-color: #00b398;
    }
    &.bar-inscribed {
      background-color: #00b3983c;
    }
    &.bar-capacity {
      background-color: ${({ theme }) => theme.field.secondary};
    }
  }
`;
