import {
  Card,
  Confirm,
  Input,
  NotFound,
  SectionHeader,
  Table,
  Tag,
} from '@outlier-spa/component';
import { axios, useApi } from '@outlier-spa/fetch';
import {
  BoxFilled,
  DetailsOutlined,
  FileFilled,
  LeftOutlined,
  RiskFilled,
} from '@outlier-spa/icon';
import { Header, LoadingKiri } from 'components';
import { DefaultCell } from 'components/table';
import { useTitle } from 'context';
import { IInvoice, IProduct } from 'interfaces';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';

const Product = ({ value }: { value: IProduct }) => value.name;

const Back = (
  <Link
    to="/inventory/invoices"
    className="flex flex-center flex-gap-8 text-16"
  >
    <LeftOutlined className="text-label" />
    <span className="bold">Atrás</span>
  </Link>
);

const headers: any = [
  { key: 'product', value: 'Producto', sortable: true, cellComponent: Product },
  {
    key: 'total',
    value: 'Cantidad',
    sortable: true,
    cellComponent: DefaultCell,
  },
  { key: 'identifier', value: 'Código', sortable: true },
];

export interface IInventoryInvoiceDetailProps {}

export const InventoryInvoiceDetail: React.FC<
  IInventoryInvoiceDetailProps
> = () => {
  const [getUrl, loadingRef] = useApi();
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState<IInvoice>();
  const navigate = useNavigate();
  useTitle(Back);

  function handleRemoveInvoice() {
    removeRef.current?.open(
      invoiceId,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar esta factura?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  function handleUpdate() {
    navigate(`/inventory/invoices/${invoiceId}/update`);
  }

  function removeInvoice() {
    console.log(invoiceId);
    loadingRef.current?.setActive(true);
    axios
      .delete(`/invoice/${invoiceId}`)
      .then(() => {
        navigate('/inventory/invoices');
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  useEffect(() => {
    getUrl<IInvoice>(`/invoice/${invoiceId}`, ({ data }) => {
      setInvoice(data);
    });
  }, [getUrl, invoiceId]);

  return (
    <div
      className="overflow-hidden-x h100 pdv-20"
      style={{ height: 'calc(100% + 40px)', marginTop: -20 }}
    >
      <Confirm
        okText="Eliminar Factura"
        className="line-normal"
        onSubmit={removeInvoice}
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
      />

      <div className="inner ant-fade-enter ant-fade-enter-active line-normal">
        <LoadingKiri ref={loadingRef} />
        <Header
          title={`Factura ${invoice?.code}`}
          onRemove={handleRemoveInvoice}
          onUpdate={handleUpdate}
          onCancel={() => navigate('/inventory/invoices')}
        />

        <SectionHeader
          size="small"
          className="mv-10 border-top-main"
          prefix={<DetailsOutlined className="text-emphasis" />}
          title="Datos principales"
        />

        <div className="flex flex-center flex-gap-14">
          <Input
            disabled
            type="mainAlt"
            style={{ width: 220 }}
            label="N° de Factura"
            value={invoice?.code}
          />
          <Input
            disabled
            type="mainAlt"
            label="Proveedor"
            style={{ width: 200 }}
            value={invoice?.supplier?.name}
          />
          <div>
            <p className="mb-10">Fecha</p>
            <Input
              disabled
              type="mainAlt"
              style={{ width: 160 }}
              value={invoice?.purshasedAt?.split('T')[0]}
            />
          </div>
          <Input
            disabled
            type="mainAlt"
            label="Establecimiento"
            style={{ width: 300 }}
            value={invoice?.institution?.name}
          />
        </div>

        <div className="mt-20 mb-12 pdb-20">
          <p className="mb-10">Documentos asociados</p>
          {invoice?.invoiceFiles?.length === 0 && (
            <p className="text-secondary">No hay documentos asociados.</p>
          )}
          {invoice?.invoiceFiles?.map((file) => (
            <Tag
              key={file.fileId}
              type="secondary"
              label={
                <>
                  <FileFilled className="text-warning" />
                  <span style={{ textDecoration: 'underline' }}>
                    {file.file?.name}
                  </span>
                </>
              }
            />
          ))}
        </div>

        <SectionHeader
          size="small"
          className="mv-10 border-top-main"
          prefix={<BoxFilled className="text-emphasis" />}
          title="Tabla de productos"
        />

        <Card>
          <Table
            bordered={false}
            hoverable
            headers={headers}
            data={invoice?.items}
          />
          {invoice?.items?.length === 0 && (
            <div className="relative" style={{ height: 300 }}>
              <NotFound content="No hay items registrados para esta factura." />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};
