import React, { useRef, useState } from 'react';
import {
  Button,
  Select,
  SectionHeader,
  InputNumber,
  Tag,
  Modal,
  TextArea,
  Upload,
  Section,
  NotFound,
} from '@outlier-spa/component';
import {
  DetailsOutlined,
  InfoOutlined,
  BoxFilled,
  CalendarOutlined,
} from '@outlier-spa/icon';
import axios from 'axios';
import { SessionCard, Header, LoadingKiri } from 'components';
import { useDiscipline, useLevels, useProducts } from 'hooks';
import { useTitle } from 'context';
import { IProgram, ISession, LabeledValue } from 'interfaces';
import { useNavigate } from 'react-router-dom';
import { SessionModal } from 'components/modal';
import { ClassIcon } from 'components/icon';

export interface IProgramNewProps {}

export const ProgramNew: React.FC<IProgramNewProps> = () => {
  const addSessionRef = useRef<React.ElementRef<typeof Modal>>(null);
  const loadingRef = useRef<React.ElementRef<typeof LoadingKiri>>(null);
  const [state, setState] = useState<Partial<IProgram>>({});
  const [sessions, setSessions] = useState<
    { session: ISession; files: File[] }[]
  >([]);
  const [levels, setLevels] = useState<LabeledValue[]>([]);
  const [products, setProducts] = useState<LabeledValue[]>([]);
  const [classes, setClasses] = useState<number>(0);
  const filesRef = useRef<File[]>([]);
  const navigate = useNavigate();
  const discipline = useDiscipline();
  const productOptions = useProducts();
  const levelOptions = useLevels();
  useTitle('Nuevo Programa');
  const sessionCount = sessions.reduce(
    (acc, cur) => acc + (cur.session.classes ?? 0),
    0
  );

  function handleAddSession() {
    addSessionRef.current?.open();
  }

  function onAddSession(session: ISession, files: File[]) {
    console.log(session);
    addSessionRef.current?.close();
    setSessions([...sessions, { session, files }]);
  }

  function handleLevelClose(_: any, value: string) {
    setLevels(levels.filter((l) => l.value !== value));
  }

  function handleProductClose(_: any, value: string) {
    setProducts(products.filter((l) => l.value !== value));
  }

  function handleFilesChange(files: File[]) {
    filesRef.current = files;
  }

  function handleLevelsChange(_levelIds: any, levels: any) {
    setLevels(levels);
  }

  function handleProductsChange(_productIds: any, products: any) {
    setProducts(products);
  }

  function onSubmit() {
    const program: Partial<IProgram> = {
      ...state,
      classes,
      sessions: sessions.map((s, index) => ({ ...s.session, index })),
      programLevels: levels.map((l) => ({ levelId: l.value })) as any,
      programProducts: products.map((p) => ({ productId: p.value })) as any,
    };
    loadingRef.current?.setActive(true);
    const formData = new FormData();
    formData.append('programModel', JSON.stringify(program));
    filesRef.current.forEach((file) => formData.append('program', file));
    sessions.forEach(({ files }, sessionIndex) =>
      files.forEach((file) => formData.append(`session-${sessionIndex}`, file))
    );
    axios
      .post<IProgram>(`program`, formData)
      .then(({ data }) => {
        console.log({ data });
        navigate(`/program/${data.programId}/${data.version}`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  return (
    <div className="ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <SessionModal ref={addSessionRef} onSubmit={onAddSession} />

      <div className="inner w100">
        <Header
          title="Nuevo Programa"
          editable={false}
          onCancel={() => navigate('/program')}
          onSave={onSubmit}
          canSave
        />
        <div className="border-bottom-main" />
      </div>

      <div className="flex-1 overflow-hidden-x">
        <div className="w100 inner">
          <SectionHeader
            size="small"
            className="mv-10"
            prefix={<DetailsOutlined className="text-emphasis text-18" />}
            title="Datos principales"
          />

          <div className="flex flex-center flex-gap-14 pdb-20">
            <Select
              optionFilterProp="label"
              options={discipline}
              type="mainAlt"
              label="Disciplina"
              style={{ width: 160 }}
              value={state.disciplineId as any}
              onChange={(disciplineId: any) =>
                setState({ ...state, disciplineId })
              }
            />
            <div>
              <p className="mb-10">Niveles</p>
              <div className="flex flex-center flex-gap-10">
                <Select
                  optionFilterProp="label"
                  type="mainAlt"
                  mode="tags"
                  tagRender={() => <></>}
                  value={levels as any}
                  options={levelOptions}
                  onChange={handleLevelsChange}
                  style={{ width: 160 }}
                />
                {levels.map((level) => (
                  <Tag
                    key={level.value}
                    closable
                    value={level.value}
                    label={level.label}
                    onClose={handleLevelClose}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="mb-20">
            <TextArea
              value={state.content}
              onChange={(e) =>
                setState({ ...state, description: e.target.value })
              }
              className="mb-20"
              type="mainAlt"
              label="Descripción"
              placeholder="Ingrese una descripción"
              rows={4}
            />
            <p className="mb-10">Documentos asociados</p>
            <Upload size="large" type="mainAlt" onChange={handleFilesChange} />
          </div>

          <SectionHeader
            size="small"
            className="mb-10 mt-20 border-top-main"
            prefix={<BoxFilled className="text-emphasis text-18" />}
            suffix={
              <div className="flex flex-center text-secondary">
                <span>
                  Seleccione que equipos serán ocupados en el programa de manera
                  general
                </span>
                <InfoOutlined className="ml-4" />
              </div>
            }
            title="Insumos"
          />

          <div className="pdb-20 mb-20">
            <p className="mb-10">Productos</p>
            <div className="flex flex-center flex-gap-10">
              <Select
                optionFilterProp="label"
                mode="tags"
                tagRender={() => <></>}
                options={productOptions}
                onChange={handleProductsChange}
                type="mainAlt"
                style={{ width: 160 }}
              />
              {products.map((product) => (
                <Tag
                  key={product.value}
                  closable
                  value={product.value}
                  label={product.label}
                  onClose={handleProductClose}
                />
              ))}
            </div>
          </div>

          <SectionHeader
            size="small"
            className="mb-10 border-top-main"
            prefix={<ClassIcon className="text-emphasis text-18" />}
            title="Clases"
          />

          <div className="pdb-20 mb-20">
            <Section
              bordered={false}
              className="flex flex-gap-14 pdh-20 pdv-16"
            >
              <div style={{ width: 200 }} className="border-right">
                <p className="text-secondary mb-16">Clases con sesiones</p>
                <h5 className="text-20">{sessionCount}</h5>
              </div>
              <div style={{ width: 200 }} className="border-right">
                <p className="text-secondary mb-16">Clases sin sesiones</p>
                <InputNumber
                  min={0}
                  value={classes}
                  onChange={(value) => setClasses(value as number)}
                />
              </div>
              <div style={{ width: 200 }}>
                <p className="text-secondary mb-16">Total clases</p>
                <h5 className="text-20 text-emphasis">
                  {sessionCount + classes}
                </h5>
              </div>
            </Section>
          </div>

          <SectionHeader
            size="small"
            className="mb-10 border-top-main"
            prefix={<CalendarOutlined className="text-emphasis text-18" />}
            suffix={
              <Button
                onClick={handleAddSession}
                bold
                type="assertive"
                size="small"
                className="pdh-20"
                label="Nueva sesión"
              />
            }
            title="Sesiones"
          />

          <div className="flex flex-column flex-vgap-14">
            {sessions.map(({ session, files }, index) => (
              <SessionCard
                key={`${session.name}-${index}`}
                editable
                session={session}
                files={files}
              />
            ))}
            {sessions.length === 0 && (
              <Section className="relative" style={{ height: 300 }}>
                <NotFound content='Aún no hay sesiones agregadas a este programa. Para añadir, haz click sobre el botón "Nueva sesión".' />
              </Section>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
