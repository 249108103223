import {
  Avatar,
  Button,
  Card,
  IModalRef,
  Select,
  Table,
} from '@outlier-spa/component';
import { useApi } from '@outlier-spa/fetch';
import { RiskFilled, SchoolFilled, TickOutlined } from '@outlier-spa/icon';
import { LoadingKiri } from 'components';
import { SearchBar } from 'components/search-bar';
import { DefaultCell } from 'components/table';
import { NumberHelpers } from 'helpers';
import moment from 'moment';
import { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ApprovePayment } from '../approve';

const fakeData = Array.from({ length: 100 }, (_, i) => ({
  name: 'Johanna Maria',
  lastName: 'Gonzalez',
  rut: '12345678-9',
  amount: 1000000,
  email: 'mail@mnail.com',
  isPayment: Math.random() > 0.5,
}));

const PaymentButton = ({
  handlers,
  value,
  row,
}: {
  handlers?: Record<string, (value: any, row?: any) => void>;
  value?: any;
  row: any;
}) => {
  return (
    <div className="flex flex-1 flex-end">
      {value && (
        <Button suffix={<TickOutlined className="text-emphasis" />}>
          Pago Aprobado
        </Button>
      )}
      {!value && (
        <Button
          type="emphasis"
          onClick={() => handlers?.onPayment?.(value, row)}
        >
          Aprobar pago
        </Button>
      )}
    </div>
  );
};

const Image = ({ row }: { row: any }) => {
  return (
    <Avatar
      className="flex flex-middle o-hidden"
      size="2.4rem"
      email={row.email?.value}
    >
      {!row.email?.value && <SchoolFilled className="text-16 text-secondary" />}
    </Avatar>
  );
};

const tableHeaders: any = [
  { key: 'image', value: '', width: 70, cellComponent: Image },
  {
    key: 'name',
    value: 'Nombre',
    width: 150,
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'lastName',
    value: 'Apellido',
    width: 140,
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'rut',
    value: 'Rut',
    width: 120,
    sortable: true,
    cellComponent: DefaultCell,
  },
  {
    key: 'amount',
    value: 'Monto',
    width: 100,
    sortable: true,
    cellComponent: ({ value }: { value?: any }) => (
      <span>
        {(value &&
          NumberHelpers.currencyFormat({
            number: Number(value),
            n: 0,
            wholePart: 3,
            prefix: '$',
          })) ||
          '-'}
      </span>
    ),
  },
  {
    key: 'isPayment',
    value: '',
    cellComponent: PaymentButton,
  },
];

export const PaymentList = () => {
  const approveRef = useRef<IModalRef>(null);
  const [dateSelected, setDateSelected] = useState<moment.Moment>(moment());
  const [getUrl, loadingRef] = useApi();
  const [filter, setFilter] = useState<string>();
  const tableFilter = useCallback(
    (data: any[]) => {
      return data;
    },
    [filter]
  );
  const yearOptions = useMemo(() => {
    const initialYear = 2021;
    const currentYear = new Date().getFullYear();

    return Array.from(
      { length: currentYear - initialYear + 1 },
      (value, i) => ({ label: initialYear + i, value: initialYear + i })
    );
  }, []);
  const monthOptions = useMemo(
    () =>
      Array.apply(0, Array(12)).map((_, i) => ({
        label: moment().month(i).format('MMMM'),
        value: i + 1,
      })),
    []
  );

  function handlePayment(row: any) {
    console.log(row);
    approveRef.current?.open();
  }

  function handleSubmit() {
    approveRef.current?.close();
  }

  console.log(dateSelected.format('MMMM YYYY'));
  return (
    <div className="inner ant-fade-enter ant-fade-enter-active h100 o-hidden flex flex-column">
      <LoadingKiri ref={loadingRef} />
      <ApprovePayment ref={approveRef} onSubmit={handleSubmit} />
      {/* <Confirm
        ref={removeRef}
        okText="Eliminar Institución"
        className="line-normal"
        onSubmit={onRemove}
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
      /> */}
      {/* <InstitutionModal ref={addModalRef} onSubmit={onSubmitInstitution} /> */}
      <h2 className="text-20 bold mb-16">Pagos</h2>
      <div className="flex flex-gap-10">
        <Select
          type="mainAlt"
          options={yearOptions}
          style={{ width: 90 }}
          value={dateSelected.format('YYYY') as any}
          onChange={(value) => setDateSelected(moment().year(Number(value)))}
        />
        <Select
          type="mainAlt"
          options={monthOptions}
          style={{ width: 120 }}
          value={dateSelected.format('MMMM') as any}
          onChange={(value) =>
            setDateSelected(moment().month(Number(value) - 1))
          }
        />
        <SearchBar
          className="flex flex-1"
          buttonVisible={false}
          onChange={(evt) => setFilter(evt.target.value)}
          buttonText="Agregar Establecimiento"
          type="mainAlt"
        />
      </div>
      <div className="fles-1 mt-14 o-hidden">
        <Card className="overflow-auto relative" style={{ maxHeight: '100%' }}>
          <Table
            hoverable
            fixedHeaders
            filter={tableFilter}
            bordered={false}
            className="table-row-large"
            handlers={{
              onPayment: handlePayment,
            }}
            // onRowClick={onRowClick}
            headers={tableHeaders}
            data={fakeData}
          />
          {/* {institutions?.length === 0 && (
            <div className="relative" style={{ height: 300 }}>
              <NotFound content="No hay establecimientos registrados." />
            </div>
          )} */}
        </Card>
      </div>
    </div>
  );
};
