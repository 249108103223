import cl from 'classnames';

export const DefaultCell = ({ value }: { value: any }) => {
  return <span>{value ?? '-'}</span>;
};

export const AttendanceCell = ({ value, row }: { value: any; row: any }) => {
  const { attendancePct } = row;
  const percentage = attendancePct?.value
    ? parseFloat(attendancePct?.value)
    : '-';

  const className = cl('status-cell', {
    'flex flex-middle  pointer text-emphasis bold': percentage < 50,
  });
  return (
    <span className={className}>
      <div className="button flex flex-middle ">
        <span className="text-action">
          {percentage} {percentage !== '-' && '%'}
        </span>
        <span>{value || '-'}</span>
      </div>
    </span>
  );
};
