import React from "react";
import { Table, Input, SectionHeader } from "@outlier-spa/component";
import {
  DetailsOutlined,
  UserFilled,
  // DownloadPackageOutlined,
} from "@outlier-spa/icon";
import { Schedule } from "components";
import { ICourseDetailViewModel } from "interfaces/course";

const userHeaders: any = [
  { key: "firstName", value: "Nombres", sortable: true },
  { key: "lastName", value: "Apellidos", sortable: true },
  { key: "rut", value: "Rut" },
];
// const supplyHeaders: any = [
//   { key: "product", value: "Producto" },
//   { key: "item", value: "Item" },
// ];

interface IInstitutionCourseDetailProps {
  course: ICourseDetailViewModel;
}

export const InstitutionCourseDetail: React.FC<IInstitutionCourseDetailProps> = ({ course }) => {
  return (
    <div className="ant-fade-enter ant-fade-enter-active">
      <SectionHeader
        size="small"
        className="mb-10"
        title="Datos principales"
        prefix={<DetailsOutlined className="text-emphasis" />}
      />
      <div className="mb-20 flex flex-center flex-gap-14">
        <Input
          disabled
          type="mainAlt"
          style={{ width: 160 }}
          label="Disciplina"
          value={course.programViewModel?.discipline}
        />
        <Input
          disabled
          type="mainAlt"
          style={{ width: 300 }}
          label="Programa"
          value={course.programViewModel?.name}
        />
        <Input
          disabled
          type="mainAlt"
          style={{ width: 300 }}
          label="Nombre"
          value={course.name}
        />
        <Input
          disabled
          type="mainAlt"
          style={{ width: 140 }}
          label="Fecha de inicio"
          value={course.startDate}
        />
      </div>
      <div className="mb-20 flex flex-center flex-gap-14">
        <Input
          disabled
          type="mainAlt"
          style={{ width: 474 }}
          label="Link de planificación"
          value={course.planning}
        />
        <Input
          disabled
          type="mainAlt"
          style={{ width: 140 }}
          label="Capacidad"
          value={course.capacity}
        />
      </div>

      <div>
        <div className="flex flex-center flex-space mb-10">
          <p>Horario</p>
          <p className="text-secondary">
            Seleccione las horas y los días en los que se imparte este taller
          </p>
        </div>
        <Schedule
          editable={false}
          schedules={course.scheduleViewModels}
          className="pdb-20 mb-20"
        />
      </div>

      <SectionHeader
        size="small"
        className="mb-10 border-top-main"
        prefix={<UserFilled className="text-emphasis" />}
        title="Participantes"
      />
      <div className="flex flex-center flex-gap-14 mb-20">
        <Input
          disabled
          type="mainAlt"
          label="Monitor"
          style={{ width: 260 }}
          value={course.tutor?.fullName}
        />
        <Input
          disabled
          type="mainAlt"
          label="Dupla"
          style={{ width: 260 }}
          value={course.duo?.fullName}
        />
      </div>

      <div className="pdb-20 mb-20">
        <p className="mb-10">Alumnos</p>
				<div className='relative overflow-hidden-x' style={{maxHeight: '600px'}}>
					<Table
						hoverable
						fixedHeaders
						headers={userHeaders}
						data={course.students ?? []} />
				</div>
      </div>

      {/* <SectionHeader
        size="small"
        className="mb-10 border-top-main"
        prefix={<DownloadPackageOutlined className="text-emphasis" />}
        title="Insumos"
      />
      <div className="pdb-20 mb-10">
        <Table headers={supplyHeaders} data={supplies} hoverable />
      </div> */}
    </div>
  );
};
