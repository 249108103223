import React, { useRef } from 'react';
import { List, Popover, ListItem } from '@outlier-spa/component';
import { OptionsOutlined } from '@outlier-spa/icon';

export interface IPopoverOptionsProps {
  handlers?: Record<string, (value: any, row?: any) => void>;
  value?: any;
  row?: any;
}

export const CustomPopoverOptions: React.FC<IPopoverOptionsProps> = ({ handlers, value, row }) => {
  const popoverRef = useRef<React.ElementRef<typeof Popover>>(null);

  function handleClick(evt: React.MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  function handleRemove(_item: any, evt: React.MouseEvent) {
    handleClick(evt);
    console.log(_item, value, row);
    handlers?.onRemove?.(row);
    popoverRef.current?.setOpen(false);
  }

  function handleUpdate(_item: any, evt: React.MouseEvent) {
    handleClick(evt);
    handlers?.onUpdate?.(row);
    popoverRef.current?.setOpen(false);
  }

  return (
    <Popover
      ref={popoverRef}
      trigger="hover"
      content={
        <List hoverable>
          <ListItem
            item={1}
            onClick={handleUpdate}
            value={<span className="pdh-10">Editar</span>}
          />
          <ListItem
            item={2}
            onClick={handleRemove}
            value={<span className="pdh-10">Eliminar</span>}
            className="text-danger"
          />
        </List>
      }
    >
      <OptionsOutlined
        onClick={handleClick}
        className="flex text-secondary pointer hover-emphasis"
      />
    </Popover>
  );
};
