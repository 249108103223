import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { TeamList } from './list';
import { TeamUser } from './user';

export interface ITeamProps {

};

export const Team: React.FC<ITeamProps> = () => {
	return (
		<Routes>
			<Route path='user/:userId/*' element={<TeamUser />} />
			<Route path='' element={<TeamList />} />
		</Routes>
	);
};
