import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { IRegion, IProvince, ICity, LabeledValue } from 'interfaces';

export interface SelectProps {
	options: LabeledValue[];
	value?: any;
	onChange?: (data: any) => void;
};

interface ICityProvince {
	provinces: IProvince[];
	cities: ICity[];
	provinceId: number;
	regionId: number;
	cityId: number;
};

export const useCity = () => {
	const [regionProps, setRegionProps] = useState<SelectProps>({ options: [] });
	const [provinceProps, setProvinceProps] = useState<SelectProps>({ options: [] });
	const [cityProps, setCityProps] = useState<SelectProps>({ options: [] });
	const [cityId, setCityId] = useState<string>();

	const onProvinceChange = useCallback((provinceId: string) => {
		setProvinceProps(provinceProps => ({ ...provinceProps, value: provinceId }));
		axios.get<ICity[]>(`country/city/${provinceId}`).then(({ data }) => {
			setCityProps({
				options: data.map(({ cityId, name }) => ({ label: name, value: cityId.toString() })),
				onChange: setCityId,
			});
		});
	}, []);

	const onRegionChange = useCallback((regionId: string) => {
		setRegionProps(regionProps => ({ ...regionProps, value: regionId }));
		axios.get<IProvince[]>(`country/province/${regionId}`).then(({ data }) => {
			setProvinceProps({
				options: data.map(({ provinceId, name }) => ({ label: name, value: provinceId.toString() })),
				onChange: onProvinceChange,
			});
		});
	}, [onProvinceChange]);

	useEffect(() => {
		axios.get<IRegion[]>('country/region/1').then(({ data }) => {
			setRegionProps({
				options: data.map(({ regionId, name }) => ({ label: name, value: regionId.toString() })),
				onChange: onRegionChange,
			});
		});
	}, [onRegionChange]);

	function onChangeCity(cityId: number) {
		setCityId(`${cityId}`);
		axios.get<ICityProvince>(`country/${cityId}`).then(({ data }) => {
			setRegionProps({ ...regionProps, value: data.regionId.toString() });
			setProvinceProps({
				options: data.provinces.map(({ provinceId, name }) => ({ label: name, value: provinceId.toString() })),
				onChange: onProvinceChange,
				value: data.provinceId.toString(),
			});
			setCityProps({
				options: data.cities.map(({ cityId, name }) => ({ label: name, value: cityId.toString() })),
				onChange: setCityId,
				value: data.cityId.toString(),
			});
		});
	}

	return { regionProps, provinceProps, cityProps, cityId, onChangeCity };
};
