import { createGlobalStyle } from 'styled-components';

export const InstitutionGlobalStyle = createGlobalStyle`
	.institution-nav .responsive-more-screen,
	.responsive-institution-nav .nav-bar-item {
		display: none;
	}

	.responsive-institution-nav {

	}

	@media only screen and (max-width: 1450px) {
		.institution-nav .responsive-more-screen {
			display: block;
		}
		.institution-nav .nav-statistic { display: none; }
		.responsive-institution-nav .nav-statistic { display: flex; }
	}
	@media only screen and (max-width: 1340px) {
		.institution-nav .nav-events { display: none; }
		.responsive-institution-nav .nav-events { display: flex; }
	}
	@media only screen and (max-width: 1160px) {
		.institution-nav .nav-documents { display: none; }
		.responsive-institution-nav .nav-documents { display: flex; }
	}
	@media only screen and (max-width: 1015px) {
		.institution-nav .nav-risk { display: none; }
		.responsive-institution-nav .nav-risk { display: flex; }
	}
	@media only screen and (max-width: 880px) {
		.institution-nav .nav-courses { display: none; }
		.responsive-institution-nav .nav-courses { display: flex; }
	}
	@media only screen and (max-width: 760px) {
		.institution-nav .nav-product { display: none; }
		.responsive-institution-nav .nav-product { display: flex; }
	}
`;
