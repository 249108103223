import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  SearchBar,
  Table,
  Pagination,
  Card,
  Confirm,
} from "@outlier-spa/component";
import { PopoverOptions } from "components";
import { RiskFilled } from "@outlier-spa/icon";

const headers: any = [
  { key: "discipline", value: "Disciplina", sortable: true },
  { key: "product", value: "Productos", sortable: true },
  { key: "items", value: "Ítems", sortable: true },
  { key: "options", value: "", width: 56, cellComponent: PopoverOptions },
];

const data = Array.from({ length: 30 }, (_, k) => ({
  discipline: `Tenis`,
  product: `Pelota de tenis ${k}`,
  items: Math.round(Math.random() * 100),
}));

export interface IProductListProps {}

export const ProductList: React.FC<IProductListProps> = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const navigate = useNavigate();

  function handleRowClick() {
    navigate("/product/52");
  }

  function handleRemove() {
    removeRef.current?.open(
      1,
      <>
        <p className="bold mb-10">¿Está seguro que desea eliminar este item?</p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  return (
    <div className="inner ant-fade-enter ant-fade-enter-active h100 flex flex-column">
      <Confirm
        okText="Eliminar Insumo"
        className="line-normal"
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
      />
      <div>
        <h2 className="text-20 bold mb-16">Insumos</h2>
        <SearchBar
          type="mainAlt"
          className="mb-14"
          buttonText="Agregar Producto"
          buttonProps={{ as: "a", href: "/product/new" }}
        />
      </div>
      <Card className="flex-1 overflow-hidden-x">
        <Table
          fixed
          hoverable
          fixedHeaders
          bordered={false}
          onRowClick={handleRowClick}
          headers={headers}
          handlers={{
            onRemove: handleRemove,
          }}
          data={data}
        />
      </Card>
      <Pagination
        count={100}
        pageCount={10}
        pageInputVisible={false}
        className="mt-14 flex-end"
      />
    </div>
  );
};
