import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/es-mx';
import { lightTheme, darkTheme } from '@outlier-spa/theme';
import { Layout } from './components';
import { KiriRoutes } from './routes';
import { Privacy } from './routes/privacy';
import { AppProvider } from './context';
import { KiriStyles } from './styles';
import './services';
import '@outlier-spa/component/lib/esm/style/reset.scss';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth';
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/es_ES';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

(lightTheme as any).colors.emphasis = '#F65275';
(lightTheme as any).colors.assertive = '#00B398';
(lightTheme as any).background.main = '#FAFAFA';
(darkTheme as any).colors.emphasis = '#F65275';
(darkTheme as any).colors.assertive = '#00B398';

moment.locale('es');
const months =
  'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
    '_'
  );

const monthsShort = 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split(
  '_'
);

const weekdays = 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split(
  '_'
);
const weekdaysShort = 'Dom_Lun_Mar_Mie_Jue_Vie_Sab'.split('_');
moment.updateLocale('es', {
  months,
  monthsShort,
  weekdays,
  weekdaysShort,
  week: { dow: 1 },
});

const msalInstance = new PublicClientApplication(msalConfig);

const App: React.FC = () => {
  return (
		<ConfigProvider locale={locale}>
			<MsalProvider instance={msalInstance}>
        <AppProvider>
          <Layout>
            <KiriStyles />
            <div style={{ paddingTop: '36px' }} className='pdv-20 h100'>
              <KiriRoutes />
            </div>
          </Layout>
        </AppProvider>
			</MsalProvider>
		</ConfigProvider>
  );
};

const GlobalApp: React.FC = () => {
	return (
		<ConfigProvider locale={locale}>
			<BrowserRouter>
				<Routes>
					<Route path='/privacy' element={<Privacy />} />
					<Route path='*' element={<App />} />
				</Routes>
			</BrowserRouter>
		</ConfigProvider>
	);
};

ReactDOM.render(<GlobalApp />, document.getElementById('root'));
