import {
	Confirm,
  DatePicker,
  Input,
  InputNumber,
  SectionHeader,
  Select,
} from '@outlier-spa/component';
import { axios, useApi } from '@outlier-spa/fetch';
import {
  DetailsOutlined,
  InfoOutlined,
  SchoolFilled,
  UserFilled,
} from '@outlier-spa/icon';
import {
  Header,
  LoadingKiri,
  Schedule,
  SupplySelect,
  UserSelect,
} from 'components';
import { useInstitution } from 'context/institution';
import { useDiscipline } from 'hooks';
import {
  IInstitutionRole,
  IDisciplineItem,
  IProgram,
  IRole,
  IUser,
  ISchedule,
} from 'interfaces';
import { ICourseScheduleViewModel } from 'interfaces/course';
import { IUserViewModel } from 'interfaces/user';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// interface ICustonUserRole extends IUser {
//   role: IRole;
//   roleId?: any;
// }

export interface IInstitutionCourseNewProps {}

export const InstitutionCourseNew: React.FC<
  IInstitutionCourseNewProps
> = () => {
	const confirmRef = React.useRef<React.ElementRef<typeof Confirm>>(null);
  const { control, handleSubmit, setValue } = useForm();
  const discipline = useDiscipline();
  const { institution } = useInstitution();
  const [programs, setPrograms] = useState<IProgram[]>([]);
  const navigate = useNavigate();
  const [getUrl, loadingRef] = useApi();

  const [officers, setOfficers] = useState<IUser[]>([]);
  const [students, setStudents] = useState<IUser[]>([]);
	const [monitors, setMonitors] = useState<IUserViewModel[]>([]);
  // const [items, setItems] = useState<IDisciplineItem[]>([]);
  const [schedules, setSchedules] = useState<ICourseScheduleViewModel[]>([]);

  useEffect(() => {
    // get officers from institution
    loadingRef.current?.setActive(true);
    axios
      .get<any>(`/institution/${institution?.institutionId}/user`)
      .then(({ data }) => {
        const { officers, students }: { officers: IUser[]; students: IUser[] } =
          data;
        setStudents(students);
        setOfficers(officers);
      })
      .finally(() => loadingRef.current?.setActive(false));
  }, [institution?.institutionId, loadingRef]);

	function onSubmit({ startDate, programId, schedules, students, monitorId, duplaId, disciplineId, ...data }: any) {
    data.startDate = startDate?.format('YYYY-MM-DD');
		if (!disciplineId) {
			confirmRef.current?.open(null, 'Debe seleccionar una disciplina.');
			return;
		}
		if (!programId) {
			confirmRef.current?.open(null, 'Debe seleccionar un programa.');
			return;
		}

		data.color = '#00498d';
		data.institutionViewModel = institution;
		data.programViewModel = { programId };
		data.scheduleViewModels = schedules;
		data.students = students.map((s: any) => ({ userId: s }));
		data.disciplineId = parseInt(disciplineId);
		data.tutor = monitorId ? { userId: monitorId } : null;
		data.duo = duplaId ? { userId: duplaId } : null;
		console.log(data);
		loadingRef.current?.setActive(true);
    axios
      .post<any>(`/course`, data)
      .then(({ data }) => {
        console.log(data);
        navigate(
          `/institution/${institution?.institutionId}/course/${data.courseId}`
        );
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function onChangeDiscipline(disciplineId: any, option: any) {
    setValue('disciplineId', disciplineId);
    setValue('monitorId', '');
    setValue('programId', '');

    // get progrmas by discipline
    getUrl<IProgram[]>(`/program/${disciplineId}`, ({ data }) => {
      setPrograms(data);
    });

    console.log({ disciplineId });
    // getUrl<IDisciplineItem[]>(
    //   `/institution/${institution?.institutionId}/item/${disciplineId}`,
    //   ({ data }) => {
    //     console.log(data);
    //     setItems(data);
    //   }
    // );

		getUrl<IUserViewModel[]>(`/discipline/${disciplineId}/tutor`, ({ data }) => {
      setMonitors(data);
    });
  }

  function onChangeProgram(programId: any) {
    const program = programs.find(({ programId: id }) => id === programId);
    if (!program) return;

    setValue('programId', programId);
  }

  return (
    <div className='ant-fade-enter ant-fade-enter-active line-normal h100 o-hidden flex flex-column'>
      <LoadingKiri ref={loadingRef} />`
			<Confirm
				ref={confirmRef}
				title={<><InfoOutlined className='text-danger' /><span>Datos Faltantes</span></>}
				okText='Aceptar'
				cancelProps={{visible: false}} />
      <div className='inner w100'>
        <Header
          canSave
          editable={false}
          title='Nuevo Taller'
          onCancel={() =>
            navigate(`/institution/${institution?.institutionId}/courses`)
          }
          onSave={handleSubmit(onSubmit)}
          descriptions={[
            <div className='text-secondary flex flex-center flex-gap-6'>
              <SchoolFilled />
              <span>{institution?.name}</span>
            </div>,
          ]}
        />
      </div>

      <div className='flex-1 overflow-hidden-x'>
        <div className='inner'>
          <SectionHeader
            size='small'
            className='mb-10'
            prefix={<DetailsOutlined className='text-emphasis' />}
            title='Datos principales'
          />

          <div className='mb-20 flex flex-center flex-gap-14'>
            <Controller
              name='disciplineId'
              control={control}
              render={({ field }) => (
                <Select
                  optionFilterProp='label'
                  label='Disciplina'
                  options={discipline}
                  type='mainAlt'
                  style={{ width: 160 }}
                  value={field.value}
                  onChange={onChangeDiscipline}
                />
              )}
            />
            <Controller
              name='programId'
              control={control}
              render={({ field }) => (
                <Select
                  optionFilterProp='label'
                  label='Programa'
                  type='mainAlt'
                  options={programs.map((d) => ({
                    value: d.programId.toString(),
                    label: d.description,
                  }))}
                  style={{ width: 300 }}
                  value={field.value}
                  onChange={onChangeProgram}
                />
              )}
            />
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <Input
                  label='Nombre'
                  placeholder='Ingrese nombre'
                  type='mainAlt'
                  style={{ width: 300 }}
                  {...field}
                />
              )}
            />
            <div>
              <label className='mb-10 flex'>Fecha de inicio</label>
              <Controller
                name='startDate'
                control={control}
                render={({ field }) => (
                  <DatePicker
                    type='mainAlt'
                    style={{ width: 140 }}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>
          <div className='mb-20 flex flex-center flex-gap-14'>
            <Controller
              name='planning'
              control={control}
              render={({ field }) => (
                <Input
                  label='Link de planificación'
                  placeholder='Ingrese url de planificación'
                  type='mainAlt'
                  style={{ width: 474 }}
                  {...field}
                />
              )}
            />

            <Controller
              name='capacity'
              control={control}
              render={({ field }) => (
                <InputNumber
                  min={0}
                  label='Capacidad'
                  placeholder='...'
                  type='mainAlt'
                  style={{ width: 140 }}
                  {...field}
                />
              )}
            />
          </div>

          <div>
            <div className='flex flex-center flex-space mb-10'>
              <p>Horario</p>
              <div className='flex flex-center text-secondary'>
                <span>
                  Seleccione las horas y los días en los que se imparte este
                  taller
                </span>
                <InfoOutlined className='ml-4' />
              </div>
            </div>
            <Controller
              name='schedules'
              control={control}
              render={({ field }) => (
                <Schedule
                  editable
                  className='pdb-20 mb-20'
                  schedules={schedules}
                  onChange={(schedules) => {
                    field.onChange(schedules);
                    setSchedules(schedules);
                  }}
                />
              )}
            />
          </div>

          <SectionHeader
            size='small'
            className='mb-10 border-top-main'
            prefix={<UserFilled className='text-emphasis' />}
            title='Participantes'
          />
          <div className='flex flex-center flex-gap-14 mb-20'>
            <Controller
              name='monitorId'
              control={control}
              render={({ field }) => (
                <Select
                  optionFilterProp='label'
                  label='Monitor'
                  type='mainAlt'
                  options={monitors.map((d) => ({
                    value: d.userId.toString(),
                    label: `${d.firstName} ${d.lastName}`,
                  }))}
                  style={{ width: 260 }}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              name='duplaId'
              control={control}
              render={({ field }) => (
                <Select
                  optionFilterProp='label'
                  label='Dupla'
                  type='mainAlt'
                  options={officers.map((d) => ({
                    value: d.userId.toString(),
                    label: `${d.firstName} ${d.lastName}`,
                  }))}
                  style={{ width: 260 }}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className='pdb-20 mb-20'>
            <p className='mb-10'>Alumnos</p>
            <Controller
              name='students'
              control={control}
              render={({ field }) => (
                <UserSelect options={students} onChange={field.onChange} />
              )}
            />
          </div>

          {/* <SectionHeader
            size='small'
            className='mb-10 border-top-main'
            prefix={<UserFilled className='text-emphasis' />}
            title='Insumos'
          />
          <div className='pdb-20 mb-10'>
            <Controller
              name='supplies'
              control={control}
              render={({ field }) => (
                <SupplySelect options={items} onChange={field.onChange} />
              )}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};
