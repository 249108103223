import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import {
  NavBar,
  NavBarItem,
  SectionHeader,
  TextArea,
  Tag,
  Section,
  InputNumber,
  Select,
  Upload,
  Button,
  Modal,
} from "@outlier-spa/component";
import {
  EditFilled,
  DetailsOutlined,
  CalendarOutlined,
  LeftOutlined,
  RefreshOutlined,
  BoxFilled,
  InfoOutlined,
} from "@outlier-spa/icon";
import { Header, LoadingKiri } from "../../../components";
import { useTitle } from "context";
import { Link } from "react-router-dom";
import { axios, useApi } from "@outlier-spa/fetch";
import { SessionCard } from "components";
import { IProgram, ISession, LabeledValue } from "interfaces";
import { useDiscipline, useLevels, useProducts } from "hooks";
import { SessionModal } from "components/modal";
import { disciplineIcons } from "components/icon";

interface IProgramDetailRef {
  onSubmit: () => void;
}

interface IProgramDetailProps {
  program: IProgram;
}

const ProgramDetail = React.forwardRef<IProgramDetailRef, IProgramDetailProps>(
  ({ program: currentProgram }, ref) => {
    const loadingRef = useRef<React.ElementRef<typeof LoadingKiri>>(null);
    const addSessionRef = useRef<React.ElementRef<typeof Modal>>(null);
    const [levels, setLevels] = useState<LabeledValue[]>([]);
    const [products, setProducts] = useState<LabeledValue[]>([]);
    const [sessions, setSessions] = useState<
      { session: ISession; files: File[] }[]
    >([]);
    const [program, setProgram] = useState<IProgram>({} as IProgram);
    const discipline = useDiscipline();
    const productOptions = useProducts();
    const levelOptions = useLevels();
    const filesRef = useRef<File[]>([]);
    const navigate = useNavigate();

    const sessionCount =
      program.sessions?.reduce(
        (acc, session) => acc + (session.classes ?? 0),
        0
      ) ?? 0;

    function handleLevelsChange(_levelIds: any, levels: any) {
      setLevels(levels);
    }

    function handleAddSession() {
      addSessionRef.current?.open();
    }

    function handleLevelClose(_: any, value: string) {
      setLevels(levels.filter((l) => l.value !== value));
    }

    function handleProductClose(_: any, value: string) {
      // setProducts(products.filter(l => l.value !== value));
    }

    function handleFilesChange(files: File[]) {
      filesRef.current = files;
    }

    function handleProductsChange(_productIds: any, products: any) {
      setProducts(products);
    }

    function onAddSession(session: ISession, files: File[]) {
      console.log(session);
      addSessionRef.current?.close();
      setSessions([...sessions, { session, files }]);
    }

    function onSubmit() {
      const newProgram: Partial<IProgram> = {
        ...program,
        sessions: sessions.map((s, index) => ({ ...s.session, index })),
        programLevels: levels.map((l) => ({ levelId: l.value })) as any,
        programProducts: products.map((p) => ({ productId: p.value })) as any,
      };
      loadingRef.current?.setActive(true);
      const formData = new FormData();
      formData.append("programModel", JSON.stringify(newProgram));
      filesRef.current.forEach((file) => formData.append("program", file));
      sessions.forEach(({ files }, sessionIndex) =>
        files.forEach((file) =>
          formData.append(`session-${sessionIndex}`, file)
        )
      );
      axios
        .post<IProgram>(`program`, formData)
        .then(({ data }) => {
          console.log({ data });
          navigate(`/program/${data.programId}/${data.version}`);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => loadingRef.current?.setActive(false));
    }

    useEffect(() => {
      setProgram(currentProgram);
      setLevels(
        currentProgram.programLevels?.map((l) => ({
          value: `${l.levelId}`,
          label: `${l.level?.name}`,
        })) ?? []
      );
      setProducts(
        currentProgram.programProducts?.map((p) => ({
          value: `${p.productId}`,
          label: `${p.product?.name}`,
        })) ?? []
      );
      setSessions(
        currentProgram.sessions?.map((s) => ({ session: s, files: [] })) ?? []
      );
    }, [currentProgram]);

    useImperativeHandle(ref, () => ({ onSubmit }));

    return (
      <div className="pdv-10">
        <LoadingKiri ref={loadingRef} />
        <SessionModal ref={addSessionRef} onSubmit={onAddSession} />
        <SectionHeader
          size="small"
          className="mb-10"
          prefix={<DetailsOutlined className="text-emphasis text-18" />}
          title="Datos principales"
        />

        <div className="flex flex-gap-14 pdb-20">
          <Select
            optionFilterProp="label"
            options={discipline}
            type="mainAlt"
            label="Disciplina"
            style={{ width: 160 }}
            value={`${program.disciplineId}` as any}
            onChange={(disciplineId: any) =>
              setProgram({ ...program, disciplineId })
            }
          />
          <div>
            <p className="mb-10">Niveles</p>
            <div className="flex flex-center flex-gap-10">
              <Select
                optionFilterProp="label"
                type="mainAlt"
                mode="tags"
                tagRender={() => <></>}
                value={levels as any}
                options={levelOptions}
                onChange={handleLevelsChange}
                style={{ width: 160 }}
              />
            </div>
          </div>
          <div>
            <div className="flex flex-center flex-gap-10 pdt-14 flex-wrap">
              {levels.map((level) => (
                <Tag
                  className="mt-10"
                  key={level.value}
                  closable
                  value={level.value}
                  label={level.label}
                  onClose={handleLevelClose}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="mb-20">
          <TextArea
            value={program.description}
            onChange={(e) =>
              setProgram({ ...program, description: e.target.value })
            }
            className="mb-20"
            type="mainAlt"
            label="Descripción"
            placeholder="Ingrese descripción"
            rows={4}
          />
          <p className="mb-10">Documentos asociados</p>
          <Upload size="large" type="mainAlt" onChange={handleFilesChange} />
        </div>

        <SectionHeader
          size="small"
          className="mb-10 mt-20 border-top-main"
          prefix={<BoxFilled className="text-emphasis text-18" />}
          suffix={
            <div className="flex flex-center text-secondary">
              <span>
                Seleccione que equipos serán ocupados en el programa de manera
                general
              </span>
              <InfoOutlined className="ml-4" />
            </div>
          }
          title="Insumos"
        />

        <div className="pdb-20 mb-20">
          <p className="mb-10">Productos</p>
          <div className="flex flex-center flex-gap-10">
            <Select
              optionFilterProp="label"
              mode="tags"
              tagRender={() => <></>}
              options={productOptions}
              onChange={handleProductsChange}
              type="mainAlt"
              style={{ width: 160 }}
            />
            {products.map((product) => (
              <Tag
                key={product.value}
                closable
                value={product.value}
                label={product.label}
                onClose={handleProductClose}
              />
            ))}
          </div>
        </div>

        <SectionHeader
          size="small"
          className="mb-10 border-top-main"
          prefix={<CalendarOutlined className="text-emphasis text-18" />}
          title="Clases"
        />

        <div className="pdb-20 mb-20">
          <Section bordered={false} className="flex flex-gap-14 pdh-20 pdv-16">
            <div style={{ width: 200 }} className="border-right">
              <p className="text-secondary mb-16">Clases con sesiones</p>
              <h5 className="text-20">{sessionCount}</h5>
            </div>
            <div style={{ width: 200 }} className="border-right">
              <p className="text-secondary mb-16">Clases sin sesiones</p>
              <InputNumber
                min={0}
                value={(program.classes ?? 0) - sessionCount}
                onChange={(value) =>
                  setProgram({ ...program, classes: value as number })
                }
              />
            </div>
            <div style={{ width: 200 }}>
              <p className="text-secondary mb-16">Total clases</p>
              <h5 className="text-20 text-emphasis">
                {program.classes ?? "0"}
              </h5>
            </div>
          </Section>
        </div>

        <SectionHeader
          size="small"
          className="mb-10 border-top-main"
          prefix={<CalendarOutlined className="text-emphasis text-18" />}
          suffix={
            <Button
              onClick={handleAddSession}
              bold
              type="assertive"
              size="small"
              className="pdh-20"
              label="Nueva sesión"
            />
          }
          title="Sesiones"
        />

        <div className="flex flex-column flex-vgap-14">
          {sessions.map(({ session, files }, index) => (
            <SessionCard
              key={`${session.name}-${index}`}
              editable
              session={session}
              files={files}
            />
          ))}
        </div>
      </div>
    );
  }
);

const Back = (
  <Link to="/program" className="flex flex-center flex-gap-8 text-16">
    <LeftOutlined className="text-label" />
    <span className="bold">Atrás</span>
  </Link>
);

export interface IProgramUpdateProps {}

export const ProgramUpdate: React.FC<IProgramUpdateProps> = () => {
  const { programId, version } = useParams();
  const [getUrl, loadingRef] = useApi();
  const [program, setProgram] = useState<IProgram>();
  const detailRef = useRef<IProgramDetailRef>(null);
  const navigate = useNavigate();
  useTitle(Back);

  function onUpdate() {
    detailRef.current?.onSubmit();
  }

  function onCancel() {
    navigate(`/program/${programId}/${version}/details`);
  }

  useEffect(() => {
    getUrl<IProgram>(`/program/${programId}/${version}`, ({ data }) => {
      setProgram(data);
    });
  }, [programId, version, getUrl]);

  return (
    <div className="ant-fade-enter ant-fade-enter-active h100 o-hidden flex flex-column">
      <LoadingKiri ref={loadingRef} />
      <div className="inner w100">
        <Header
          canSave
          title={program?.discipline?.name}
          editable={false}
          onSave={onUpdate}
          onCancel={onCancel}
          descriptions={[
            <div className="flex flex-center">
              {disciplineIcons[program?.discipline?.disciplineId ?? "1"]}
              {program?.discipline?.name}
            </div>,
            <div className="flex flex-center">
              <RefreshOutlined className="mr-8 text-12" />
              <span>{program?.sessions?.length} sesiones</span>
            </div>,
            <div className="flex flex-center t200">
              <RefreshOutlined className="mr-8 text-12" />
              <span>Vesión {program?.version}</span>
            </div>,
          ]}
        />
        <NavBar className="mt-20">
          <NavBarItem
            prefix={<EditFilled className="flex" />}
            to=""
            content="Detalles"
          />
        </NavBar>
      </div>
      <div className="inner w100 overflow-hidden-x pdv-10 flex-1">
        {program && (
          <Routes>
            <Route
              path="*"
              element={<ProgramDetail ref={detailRef} program={program} />}
            />
          </Routes>
        )}
      </div>
    </div>
  );
};
