import React from 'react';

export interface ICourseListProps {

};

export const CourseList: React.FC<ICourseListProps> = () => {
	return (
		<div>
			CourseList
		</div>
	);
};
