import {
  Check,
  Checkbox,
  Collapse,
  CollapseSection,
  CollapseValues,
  IModalProps,
  IModalRef,
  InputNumber,
  Modal,
} from '@outlier-spa/component';
import React from 'react';
import styled from 'styled-components';
import cl from 'classnames';
import moment from 'moment';
import { NumberHelpers } from 'helpers';

export const ApprovePayment = React.forwardRef<IModalRef, IModalProps>(
  (props, ref) => {
    return (
      <Modal
        ref={ref}
        {...props}
        size="small"
        title="Aprobar pago"
        okText="Aprobar pago"
        okProps={{ type: 'assertive' }}
      >
        <div className="pdh-16 pdt-14 pdb-10">
          <h4>Detalle</h4>
        </div>
        <div className="overflow-hidden-x" style={{ maxHeight: 460 }}>
          <StyledCollapse multiple={false} bordered={false}>
            <CollapseSection
              showArrow
              className="text-center "
              title={
                <div className="flex flex-1 flex-space flex-center mh-8 border-bottom-main">
                  <div>
                    <p>Clases</p>
                    <p className="text-left">20/23</p>
                  </div>
                  <div>
                    <p>$320.000</p>
                  </div>
                </div>
              }
            >
              <SectionValues
                isPayment
                date={moment()}
                title="Patineta Clase 1/5"
                description="Pablo de Rocka"
              />
              <SectionValues
                isPayment={false}
                date={moment()}
                title="Patineta Clase 1/5"
                description="Pablo de Rocka"
              />
              <SectionValues
                isPayment
                date={moment()}
                title="Patineta Clase 1/5"
                description="Pablo de Rocka"
              />
              <SectionValues
                isPayment={false}
                date={moment()}
                title="Patineta Clase 1/5"
                description="Pablo de Rocka"
              />
            </CollapseSection>
            <CollapseSection
              showArrow
              className="text-center "
              title={
                <div className="flex flex-1 flex-space flex-center mh-8 border-bottom-main">
                  <div className="flex flex-column flex-left">
                    <p>Cap. Socioemocionales</p>
                    <p className="text-left">20/23</p>
                  </div>
                  <div>
                    <p className="text-left">$32.000</p>
                  </div>
                </div>
              }
            >
              <SectionValues
                isPayment={false}
                date={moment()}
                title="Patineta Clase 1/5"
                description="Pablo de Rocka"
              />
            </CollapseSection>

            <SimpleValue
              title="Cap. Pedagógica"
              description="1/1"
              amount={26000}
            />
            <SimpleValue
              title="Feedback Individual"
              description="1/1"
              amount={26000}
            />
            <SimpleValue title="Festivales" description="1/1" amount={26000} />
            <SimpleValue title="Bono Locomoción" amount={26000} />
            <SimpleValue title="Extra" amount={26000} />
          </StyledCollapse>
        </div>
        <div className="flex flex-1 flex-space border-bottom-main mh-16">
          <div className="flex-gap-8 pdv-16">
            <Checkbox /> <span>Agregar un extra</span>
          </div>
          <div className="pdv-10">
            <InputNumber
              min={0}
              max={100000000}
              size="small"
              value={120000}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
              }
              parser={(value) => value!.replace(/\$\s?|(.*)/g, '')}
              placeholder="0"
              style={{ width: 120 }}
            />
          </div>
        </div>
        <div className="flex flex-1 flex-space border-bottom-main pdv-16 mh-16">
          <div>Total a aprobar</div>
          <div className="bold">$528.000</div>
        </div>
      </Modal>
    );
  }
);

export interface ISectionValues {
  isPayment?: boolean;
  date?: moment.Moment;
  title?: React.ReactNode;
  description?: React.ReactNode;
}

const SectionValues: React.FC<ISectionValues> = ({
  title,
  description,
  isPayment,
  date = moment(),
}) => {
  const detailClassName = cl({ 'text-line-through': !isPayment });
  const paymentStatus = isPayment ? 'Por pagar' : 'Sin pago';
  const paymentStatusClassName = cl('text-secondary', {
    'text-assertive': isPayment,
  });
  return (
    <CollapseValues>
      <div className="flex bg-color-secondary flex-center pd-16">
        <div className=" flex flex-1 flex-space border-bottom-main">
          <div className="flex flex-column flex-justify-center">
            <p className={detailClassName}>{title}</p>
            <p className="text-left text-secondary text-12">{description}</p>
          </div>
          <div className="flex flex-column flex-justify-center">
            <p className={paymentStatusClassName}>{paymentStatus}</p>
            <p className="text-secondary text-12">
              {date.format('DD/MM/yyyy')}
            </p>
          </div>
        </div>
      </div>
    </CollapseValues>
  );
};

interface ISimpleValue {
  title: React.ReactNode;
  amount: number;
  description?: React.ReactNode;
  important?: boolean;
}
const SimpleValue: React.FC<ISimpleValue> = ({
  title,
  description,
  important,
  amount = 0,
}) => {
  return (
    <CollapseValues>
      <div
        className={`flex flex-1 flex-space bg-color-secondary flex-center pdv-16 mh-16 border-bottom-main ${
          important ? 'bold' : ''
        }`}
      >
        <div className="flex flex-center">
          <p>{title}</p>
          {description && <p>{description}</p>}
        </div>
        <div className="flex flex-center">
          <p className="text-secondary">
            {NumberHelpers.currencyFormat({
              number: amount,
              n: 0,
              wholePart: 3,
              prefix: '$',
            })}
          </p>
        </div>
      </div>
    </CollapseValues>
  );
};

const StyledCollapse = styled(Collapse)`
  .collapse-section {
    height: 60px;
  }

  .collapse-values,
  ul div {
    height: 60px;
  }

  ul {
    height: 100%;
  }

  .collapse-title {
    &-inner {
      height: 60px !important;
    }
    height: 60px !important;

    h4 {
      align-items: center;
      svg {
        margin-left: 8px;
      }
    }
  }
`;
