const hasBlobConstructor =
  typeof Blob !== "undefined" &&
  (function () {
    try {
      return Boolean(new Blob());
    } catch (e) {
      return false;
    }
  })();

const hasArrayBufferViewSupport =
  hasBlobConstructor &&
  typeof Uint8Array !== "undefined" &&
  (function () {
    try {
      return new Blob([new Uint8Array(100)]).size === 100;
    } catch (e) {
      return false;
    }
  })();

const hasToBlobSupport = typeof HTMLCanvasElement !== "undefined" ? HTMLCanvasElement.prototype.toBlob : false;

const hasBlobSupport = hasToBlobSupport || (typeof Uint8Array !== "undefined" && typeof ArrayBuffer !== "undefined" && typeof atob !== "undefined");

const hasReaderSupport = typeof FileReader !== "undefined" || typeof URL !== "undefined";

type resolutionType = "1920x1080" | "1280x720" | "1024x768" | "800x600" | "640x480";
type resolutionList = Record<resolutionType, { width: number; height: number }>;

export class ImageHelper {
  static Dimensions: resolutionList = {
    "1920x1080": { width: 1920, height: 1080 },
    "1280x720": { width: 1280, height: 720 },
    "1024x768": { width: 1024, height: 768 },
    "800x600": { width: 800, height: 600 },
    "640x480": { width: 640, height: 480 },
  };
  static isSupported() {
    return typeof HTMLCanvasElement !== "undefined" && hasBlobSupport && hasReaderSupport;
  }

  static canvasToBlob(canvas: HTMLCanvasElement, type: string) {
    const dataURI = canvas.toDataURL(type);
    const dataURIParts = dataURI.split(",");
    let byteString = undefined;

    if (dataURIParts[0].indexOf("base64") >= 0) {
      // Convert base64 to raw binary data held in a string:
      byteString = atob(dataURIParts[1]);
    } else {
      // Convert base64/URLEncoded data component to raw binary data:
      byteString = decodeURIComponent(dataURIParts[1]);
    }
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i += 1) {
      intArray[i] = byteString.charCodeAt(i);
    }

    const mimeString = dataURIParts[0].split(":")[1].split(";")[0];
    let blob = null;

    if (hasBlobConstructor) {
      blob = new Blob([hasArrayBufferViewSupport ? intArray : arrayBuffer], { type: mimeString });
    } else {
      blob = new Blob([arrayBuffer]);
    }

    return blob;
  }

  static loadImage = (image: any, file: File, callback?: any) => {
    if (typeof URL === "undefined") {
      const reader = new FileReader();
      reader.onload = function (evt) {
        image.src = (evt.target as any).result;
        callback?.();
      };
      reader.readAsDataURL(file);
    } else {
      image.src = URL.createObjectURL(file);
      callback?.();
    }
  };

  static toFile = (theBlob: Blob, fileName: string, lastModified: number): File => new File([theBlob], fileName, { lastModified });

  static resizeImage = (file: File, maxDimensions: { width: number; height: number }, callback: any) => {
    if (typeof maxDimensions === "function") {
      callback = maxDimensions;
      maxDimensions = ImageHelper.Dimensions["640x480"];
    }

    if (!ImageHelper.isSupported() || !file.type.match(/image.*/)) {
      callback(file, false);
      return false;
    }

    var image = document.createElement("img");
    image.onload = function (imgEvt) {
      var width = image.width;
      var height = image.height;
      var isTooLarge = false;

      if (width >= height && width > maxDimensions.width) {
        // width is the largest dimension, and it's too big.
        // height *= maxDimensions.width / width;
        // width = maxDimensions.width;
        isTooLarge = true;
      } else if (height > maxDimensions.height) {
        // either width wasn't over-size or height is the largest dimension
        // and the height is over-size
        // width *= maxDimensions.height / height;
        // height = maxDimensions.height;
        isTooLarge = true;
      }

      if (!isTooLarge) {
        // early exit; no need to resize
        callback(file, false);
        return;
      }

      const scaleRatio = maxDimensions.width / width;

      width *= scaleRatio;
      height *= scaleRatio;

      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      var ctx = canvas.getContext("2d");
      ctx?.drawImage(image, 0, 0, width, height);

      if (hasToBlobSupport) {
        canvas.toBlob(function (blob) {
          callback(blob, true);
        }, file.type);
      } else {
        var blob = ImageHelper.canvasToBlob(canvas, file.type);
        callback(blob, true);
      }
    };

    ImageHelper.loadImage(image, file);
    return true;
  };
}
