import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider, UserPopover, Button } from '@outlier-spa/component';
// import {
//   TeamFilled,
//   PuzzleFilled,
//   MenuOutlined,
//   InventoryFilled,
//   SchoolFilled,
//   SurveyFilled,
//   PieChartFilled,
//   KiriLogoFilled,
// } from "@outlier-spa/icon";
import { BrowserRouter } from 'react-router-dom';
import { StyledLayout } from './style';
import { useApp } from '../../context';
import { useMsal } from '@azure/msal-react';
import { useApi } from '@outlier-spa/fetch';
import { LoadingKiri } from 'components/loading';
import { KiriLogo } from 'components/icon';
import { KiriDock } from 'components/dock';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

export interface ILayoutProps {}

export const Layout: React.FC<ILayoutProps> = ({ children }) => {
  const [getUrl, loadingRef] = useApi();
  const { title } = useApp();
  const [isAdmin, setIsAdmin] = useState();
  const { accounts, instance } = useMsal();
  const account = useMemo(() => {
    if (accounts.length > 0) return accounts[0];
    return null;
  }, [accounts]);

  function logout() {
    instance.logout();
  }

  useEffect(() => {
    if (!account) return;
    getUrl(`/user/${account.localAccountId}/roles`, ({ data }) => {
      setIsAdmin(data.isAdmin);
    });
  }, [account, getUrl]);

  useEffect(() => {
    if (isAdmin === false) {
      setTimeout(() => {
        instance.logout();
      }, 5000);
    }
  }, [isAdmin, instance]);

  return (
    <ThemeProvider defaultTheme="light">
      <LoadingKiri ref={loadingRef} />
      {isAdmin && (
        <>
          <StyledLayout className="h100 o-hidden flex flex-column">
            <header className="flex flex-middle border-bottom-main text-label pdh-20">
              <div
                style={{ width: '100%', maxWidth: '2200px' }}
                className="flex flex-center flex-space h100  flex-middle"
              >
                <div className="flex flex-center flex-1">
                  <Breadcrumb></Breadcrumb>
                  <Link to="/">
                    <KiriLogo style={{ width: 40, height: 40 }} />
                  </Link>
                  {/* <LayoutSidenav /> */}
                  <div className="ml-20 text-16 bold">{title}</div>
                </div>
                {account && (
                  <UserPopover
                    user={{
                      name: account.name,
                      email: account.username,
                      descriptions: [<span className="text-label">Administrador</span>],
                    }}
                  />
                )}
              </div>
            </header>
            <div className="layout-content flex-1 overflow-hidden-x t200">{children}</div>
            <div>
              <KiriDock />
            </div>
          </StyledLayout>
        </>
      )}
      {isAdmin === false && (
        <StyledLayout className="h100 o-hidden flex flex-column line-normal">
          <div className="cover flex flex-column flex-middle">
            <p className="text-center text-16 bold">
              Este usuario no tiene acceso a este módulo de la aplicación
            </p>
            <p className="text-center text-16 bold">Redirigiendo al inicio de sesión.</p>
            <Button
              onClick={logout}
              className="mt-10"
              bold
              type="emphasis"
              size="large"
              label="Cerrar Sesión"
            />
          </div>
        </StyledLayout>
      )}
    </ThemeProvider>
  );
};

// interface ISectionItem {
//   name: string;
//   to: string;
//   items?: ISectionItem[];
//   icon?: React.ReactNode;
// }

// const routeItems: ISectionItem[] = [
//   {
//     name: "Establecimientos",
//     to: "institution",
//     icon: <SchoolFilled className="flex" />,
//   },
//   { name: "Clases", to: "class", icon: <ClassIcon className="flex" /> },
//   { name: "Programas", to: "program", icon: <PuzzleFilled className="flex" /> },
//   {
//     name: "Inventario",
//     to: "inventory",
//     icon: <InventoryFilled className="flex" />,
//   },
//   { name: "Equipo", to: "team", icon: <TeamFilled className="flex" /> },
//   { name: "Eventos", to: "event", icon: <EventIcon className="flex" /> },
//   {
//     name: "Indicadores",
//     to: "indicators",
//     icon: <PieChartFilled className="flex" />,
//   },
//   { name: "Encuestas", to: "survey", icon: <SurveyFilled className="flex" /> },
//   // { name: 'Insumos', to: 'product', icon: <DownloadPackageOutlined className='flex' /> },
//   // { name: 'Circuitos', to: 'preparation-batch', icon: <CalendarOutlined className='flex' /> },
// ];

// interface ILayoutSidenavProps {}

// const LayoutSidenav: React.FC<ILayoutSidenavProps> = () => {
//   const navRef = useRef<React.ElementRef<typeof Sidenav>>(null);

//   return (
//     <>
//       <Sidenav
//         ref={navRef}
//         closeHandler={null}
//         showOverlay={false}
//         handler={() => (
//           <div className="sidenav-handler">
//             <MenuOutlined className="text-18 pointer" />
//             <span className="code">Kiri</span>
//           </div>
//         )}
//         dark
//         width={200}
//         className="layout-sidenav"
//         visible
//         position="fixed"
//         placement="left"
//       >
//         <div className="flex flex-column h100">
//           <div className="flex flex-middle" style={{ height: 120 }}>
//             {/* <img alt="logo" src="/logo.png" /> */}
//             <KiriLogo style={{ width: 68, height: 68 }}></KiriLogo>
//           </div>
//           <Collapse
//             className="flex-1 overflow-hidden-x"
//             rounded
//             size="large"
//             transparent
//             bordered={false}
//             type="transparent"
//             headerPadding={true}
//             multiple={false}
//           >
//             <NavBar mode="vertical">
//               <LayoutSection items={routeItems} />
//             </NavBar>
//           </Collapse>
//         </div>
//       </Sidenav>
//       <KiriDock></KiriDock>
//     </>
//   );
// };

// interface ILayoutNavBarProps extends Omit<INavBarItemProps, "onClick" | "to"> {
//   onClick?: (evt: React.MouseEvent<HTMLAnchorElement>, to: string) => void;
//   to: string;
// }

// const LayoutNavBar: React.FC<ILayoutNavBarProps> = ({
//   content,
//   to,
//   prefix,
//   onClick,
// }) => {
//   const handleClick = (evt: React.MouseEvent<HTMLAnchorElement>) =>
//     onClick?.(evt, to);
//   return (
//     <NavBarItem
//       prefix={prefix}
//       content={content}
//       to={to}
//       onClick={handleClick}
//     />
//   );
// };

// interface ILayoutSectionProps {
//   items: ISectionItem[];
// }

// const LayoutSection: React.FC<ILayoutSectionProps> = ({ items }) => {
//   return (
//     <>
//       {items?.map((item) => (
//         <CollapseSection
//           key={item.name}
//           showArrow={!item.to}
//           title={
//             item.to ? (
//               <LayoutNavBar
//                 prefix={item.icon}
//                 content={item.name}
//                 to={item.to}
//               />
//             ) : (
//               <strong>{item.name}</strong>
//             )
//           }
//         >
//           {item.items && (
//             <CollapseValues>
//               <LayoutSection items={item.items} />
//             </CollapseValues>
//           )}
//         </CollapseSection>
//       ))}
//     </>
//   );
// };
