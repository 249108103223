import styled from "styled-components";

export const StyledFilePicker = styled.div`
  .photo-picker__inner {
    position: relative;
    border: 1px dashed ${({ theme }) => theme.border.main};
    background-color: ${({ theme }) => theme.section.secondary};
    border-radius: 6px;
    height: 240px;

    label {
      display: inline-block;
    }
    button {
      position: absolute;
      bottom: -6px;
      right: -6px;
    }
  }
`;
