import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Card,
  Table,
  SearchBar,
  Confirm,
  NotFound,
} from '@outlier-spa/component';
import { axios, useApi } from '@outlier-spa/fetch';
import { LoadingKiri, PopoverOptions } from 'components';
import { IInvoice } from 'interfaces';
import { RiskFilled } from '@outlier-spa/icon';
import { DefaultCell } from 'components/table';
import { useNavigate } from 'react-router-dom';

const Items = (props: { value?: any[] }): string => {
  var groupedItemsByProduct = props.value?.reduce((prev: any, curr: any) => {
    let searched = prev.find((item: any) => item.productId === curr.productId);
    if (!searched)
      prev.push({
        productId: curr.productId,
        name: curr.product?.name,
        quantity: 1,
      });
    else searched.quantity++;

    return prev;
  }, []);
  if (!groupedItemsByProduct.length) return '-';
  return groupedItemsByProduct
    .map((d: any) => `${d.quantity} ${d.name}`)
    .join(', ');
};

const headers: any = [
  {
    key: 'supplier',
    value: 'Proveedor',
    sortable: true,
    cellComponent: (props: { value?: any }) => props?.value?.name,
  },
  {
    key: 'code',
    value: 'N° Factura',
    sortable: true,
    cellComponent: DefaultCell,
    width: 130,
  },
  {
    key: 'purshasedAt',
    value: 'Fecha',
    sortable: true,
    width: 110,
    cellComponent: (props: any) => props.value?.split('T')[0] ?? '-',
  },
  { key: 'items', value: 'Productos', sortable: true, cellComponent: Items },
  {
    key: 'institution',
    value: 'Establecimiento',
    sortable: true,
    cellComponent: (props: { value?: any }) => props?.value?.name,
  },
  { key: 'invoiceId', value: '', cellComponent: PopoverOptions },
];

// const data = Array.from({length: 40}, () => ({
// 	provider: 'Linio Chile',
// 	id: '-----',
// 	date: 'dd/mm/yyyy',
// 	products: '12',
// 	institution: 'Escuela Pablo de Rocka',
// }));

export interface IInventoryInvoiceListProps {}

export const InventoryInvoiceList: React.FC<
  IInventoryInvoiceListProps
> = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [getUrl, loadingRef] = useApi();
  const navigate = useNavigate();

  const [filter, setFilter] = useState<string>();
  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      const filterStr = filter.toLowerCase();
      return data.filter((d) => {
        const code = d[0].code?.value?.toLowerCase();
        const supplier = d[0].supplier?.value?.name.toLowerCase();
        const institution = d[0].institution?.value?.name.toLowerCase();
        const purshasedAt = d[0].purshasedAt?.value
          ?.split('T')[0]
          .toLowerCase();
        const items = Items(d[0].items)?.toLowerCase();
        return (
          code?.includes(filterStr) ||
          supplier?.includes(filterStr) ||
          institution?.includes(filterStr) ||
          purshasedAt?.includes(filterStr) ||
          items?.includes(filterStr)
        );
      });
    },
    [filter]
  );

  function handleAddInvoice() {}

  function handleRemoveInvoice(invoiceId: any) {
    removeRef.current?.open(
      invoiceId,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar esta Factura?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  function handleRowClick(data: any) {
    navigate(`/inventory/invoices/${data.invoiceId.value}`);
  }

  function removeInvoice(invoiceId: any) {
    console.log(invoiceId);
    loadingRef.current?.setActive(true);
    axios
      .delete(`/invoice/${invoiceId}`)
      .then(() => {
        setInvoices(invoices.filter((d) => d.invoiceId !== invoiceId));
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  useEffect(() => {
    getUrl<IInvoice[]>(`invoice`, ({ data }) => {
      console.log(data);
      setInvoices(data);
    });
  }, [getUrl]);

  return (
    <div className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        okText="Eliminar Factura"
        className="line-normal"
        onSubmit={removeInvoice}
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
      />
      <SearchBar
        type="mainAlt"
        buttonText="Agregar Factura"
        onChange={(evt) => setFilter(evt.target.value)}
        onSubmit={handleAddInvoice}
        buttonProps={{ as: 'a', href: '/inventory/invoices/new' }}
      />
      <Card
        className={`overflow-auto mt-14${
          invoices?.length > 0 ? ' flex-1' : ''
        }`}
        style={{ maxHeight: '100%' }}
      >
        <Table
          bordered={false}
          fixedHeaders
          hoverable
          filter={tableFilter}
          onRowClick={handleRowClick}
          handlers={{
            onRemove: handleRemoveInvoice,
          }}
          cellClassName="pdh-14 pdv-6"
          headers={headers}
          data={invoices}
        />
        {invoices?.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay facturas registrados." />
          </div>
        )}
      </Card>
    </div>
  );
};
