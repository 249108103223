import { Routes, Route, Navigate } from 'react-router-dom';
import { SurveyDetail } from './available/detail';
import { SurveyParticipation } from './applied/partials';
import { AppliedSurveyDetails } from './applied/partials/details';
import { SurveyNavigator } from './list';

//   return (
//     <div className="inner ant-fade-enter ant-fade-enter-active h100">
//       <div className="flex flex-column h100 o-hidden ">
//         <h2 className="text-20 bold mb-16">Encuestas</h2>
//         <NavBar>
//           <NavBarItem
//             to="/survey/available"
//             content={
//               <>
//                 <SurveyFilled className="mr-10 text-16" />
//                 <span>Disponibles</span>
//               </>
//             }
//           />
//           <NavBarItem
//             to="/survey/list"
//             content={
//               <>
//                 <ArrowLongOutlined className="mr-10 text-16" />
//                 <span>Aplicadas</span>
//               </>
//             }
//           />
//         </NavBar>
//         <div className="flex-1 o-hidden">
//           <Routes>
//             <Route path="available" element={<AvailableSurvey />} />
//             <Route path="list" element={<SurveyList />} />
//             <Route path="*" element={<Navigate replace to="available" />} />
//           </Routes>
//         </div>
//       </div>
//     </div>
//   );
// };
export interface ISurveyRoutesProps {}

export const SurveyRoutes: React.FC<ISurveyRoutesProps> = () => {
  return (
    <Routes>
      <Route path="available/:pollId/*" element={<SurveyDetail />} />
      <Route path="list/:pollId/details/*" element={<SurveyParticipation />}>
        <Route path="participation" element={<AppliedSurveyDetails />} />
        <Route path="statistics" element={<>Estadísticas!</>} />
      </Route>
      <Route path="*" element={<SurveyNavigator />} />
    </Routes>
  );
};
