import React from "react";
import {
  SectionHeader,
  Input,
  Select,
  TextArea,
  Tag,
  Table,
  Button,
  Upload,
} from "@outlier-spa/component";
import {
  TrashcanFilled,
  DetailsOutlined,
  DownloadPackageOutlined,
} from "@outlier-spa/icon";
import { Header } from "components";
import { useTitle } from "context";
import { SchoolIcon } from "components/icon";

const headers: any = [
  { key: "code", value: "Código", sortable: true },
  { key: "buy", value: "Compra", sortable: true },
  { key: "invoice", value: "N° de factura" },
  { key: "condition", value: "Condición", sortable: true },
  { key: "registeredAt", value: "Registro" },
  { key: "options", value: "", width: 56 },
];

const Delete = (
  <TrashcanFilled className="flex text-secondary pointer text-16 hover-danger" />
);

const data = Array.from({ length: 10 }, () => ({
  code: "JBLT5101",
  buy: "dd/mm/yyyy",
  registeredAt: "dd/mm/yyyy",
  invoice: "-----",
  condition: "Bueno",
  options: Delete,
}));

export interface IInstitutionProductNewProps {}

export const InstitutionProductNew: React.FC<
  IInstitutionProductNewProps
> = () => {
  useTitle("Nuevo Poducto");
  return (
    <div className="inner ant-fade-enter ant-fade-enter-active line-normal">
      <Header
        title="Nuevo Producto"
        descriptions={[
          <div className="flex flex-center">
            <SchoolIcon className="mr-6" />
            <span>Escuela Pablo de Rocka</span>
          </div>,
        ]}
        editable={false}
        canSave
      />

      <SectionHeader
        size="small"
        className="mv-10 border-top-main"
        prefix={<DetailsOutlined className="text-emphasis" />}
        title="Datos principales"
      />

      <div className="flex flex-center flex-gap-14">
        <Input
          defaultValue="Linio Chile"
          label="Proveedor"
          type="mainAlt"
          style={{ width: 300 }}
        />
        <Input
          defaultValue="Audífonos JBL"
          label="Nombre"
          type="mainAlt"
          style={{ width: 300 }}
        />
        <div>
          <p className="mb-10">Disciplinas</p>
          <div className="flex flex-center flex-gap-10">
            <Select
              optionFilterProp="label"
              type="mainAlt"
              style={{ width: 200 }}
            />
            <Tag closable label="Tenis" />
            <Tag closable label="Skate" />
            <Tag closable label="Coro" />
          </div>
        </div>
      </div>

      <div className="mt-20 mb">
        <TextArea className="mb-20" label="Descripción" type="mainAlt" />
      </div>
      <div className="mt-20 mb-12">
        <p className="mb-10">Documentos asociados</p>
        <Upload type="mainAlt" size="large" />
      </div>

      <SectionHeader
        size="small"
        className="mv-10 border-top-main"
        prefix={<DownloadPackageOutlined className="text-emphasis" />}
        title="Ítems"
      />

      <div className="mb-10 flex flex-center flex-gap-10">
        <Input type="mainAlt" className="flex-1" label="Código" />
        <Input label="Compra" type="mainAlt" style={{ width: 260 }} />
        <Input label="N° de factura" type="mainAlt" style={{ width: 260 }} />
        <Select
          optionFilterProp="label"
          label="Condición"
          type="mainAlt"
          style={{ width: 180 }}
        />
        <Button
          type="assertive"
          label="Agregar"
          className="pdh-20"
          style={{ alignSelf: "flex-end" }}
        />
      </div>

      <Table fixed hoverable headers={headers} data={data} />
    </div>
  );
};
