import React, { useCallback, useEffect, useRef, useState } from 'react';
import { axios, useApi } from '@outlier-spa/fetch';
import {
  Avatar,
  Card,
  Confirm,
  NotFound,
  Popover,
  SearchBar,
  Table,
  Tag,
} from '@outlier-spa/component';
import { LoadingKiri, PopoverOptions, UserModal } from 'components';
import { RiskFilled, TeamUserFilled } from '@outlier-spa/icon';
import { useNavigate } from 'react-router-dom';
import { IDisciplineRole, IKiriTeam } from 'interfaces';
import { IUserViewModel } from 'interfaces/user';
import { DefaultCell } from 'components/table';
import { ITeamListViewModel } from 'interfaces/team';

const Image = (props: { value?: string }) => {
  return (
    <Avatar email={props.value} className="flex flex-middle o-hidden" size="2.4rem">
      {!props.value && <TeamUserFilled className="text-16 text-secondary" />}
    </Avatar>
  );
};

const Roles = (props: { value?: any[] }) => {
  if (!props?.value?.length) return '-';
  return (
    <Popover
      trigger="hover"
      content={
        <div className="pd-10">
          {props?.value &&
            props?.value?.map((role: string) => (
              <p key={role} className="text-secondary text-14">
                {role}
              </p>
            ))}
        </div>
      }
    >
      <span className="hover-emphasis">
        {props?.value?.length === 1 && <Tag size="small" rounded label={props?.value[0]} />}
        {props?.value?.length > 1 && (
          <Tag bold size="small" rounded label={`${props?.value.length} roles`} />
        )}
      </span>
    </Popover>
  );
};

const headers: any = [
  { key: 'email', value: '', cellComponent: Image },
  { key: 'firstName', value: 'Nombres', sortable: true },
  { key: 'lastName', value: 'Apellidos', sortable: true },
  { key: 'rut', value: 'Rut', sortable: true },
  {
    key: 'dateOfBirth',
    value: 'F. nacimiento',
    sortable: true,
    cellComponent: DefaultCell,
  },
  { key: 'email', value: 'Email', sortable: true, cellComponent: DefaultCell },
  { key: 'city', value: 'Ciudad', sortable: true, cellComponent: DefaultCell },
  {
    key: 'address',
    value: 'Dirección',
    sortable: true,
    cellComponent: DefaultCell,
  },
  { key: 'roles', value: 'Roles', cellComponent: Roles },
  { key: 'userId', value: '', cellComponent: PopoverOptions },
];

export interface ITeamUser extends Omit<IKiriTeam, 'user'>, IUserViewModel {
  disciplineUserRoles: IDisciplineRole[];
  admin?: boolean;
}

export const TeamList: React.FC = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const userModalRef = useRef<React.ElementRef<typeof UserModal>>(null);
  const [team, setTeam] = useState<ITeamListViewModel[]>([]);
  const [getUrl, loadingRef] = useApi();
  const navigate = useNavigate();

  const [filter, setFilter] = useState<string>();
  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      const filterStr = filter.toLowerCase();
      return data.filter(d => {
        const firstName = d[0].firstName?.value?.toLowerCase();
        const lastName = d[0].lastName?.value?.toLowerCase();
        const rut = d[0].rut?.value?.toLowerCase();
        const email = d[0].email?.value?.toLowerCase();
        const city = d[0].city?.value?.name?.toLowerCase();
        const address = d[0].address?.value?.toLowerCase();
        const dateOfBirth = d[0].dateOfBirth?.value?.toLowerCase();
        const roles = d[0].roles?.value
          ?.map((r: IDisciplineRole) => `${r?.role} ${r?.discipline}`.toLowerCase())
          .join(' ');

        return (
          firstName?.includes(filterStr) ||
          lastName?.includes(filterStr) ||
          rut?.includes(filterStr) ||
          email?.includes(filterStr) ||
          city?.includes(filterStr) ||
          address?.includes(filterStr) ||
          dateOfBirth?.includes(filterStr) ||
          roles?.includes(filterStr)
        );
      });
    },
    [filter]
  );

  function handleRemoveUser(userId: any) {
    removeRef.current?.open(
      userId,
      <>
        <p className="bold mb-10">¿Está seguro que desea eliminar este integrante?</p>
        <p>El item que desea eliminar contiene datos y no podrán ser recuperados.</p>
      </>
    );
  }

  function handleAddUser() {
    userModalRef.current?.open();
  }

  function handleUpdateUser(userId: any) {
    const user = team?.find(u => u.userId === userId);
    userModalRef.current?.open(user);
  }

  function onSubmitUser(data: IUserViewModel, prevUser?: IUserViewModel) {
    loadingRef.current?.setActive(true);
    if (prevUser) {
      const updateUser = team?.find(u => u.userId === prevUser.userId);
      if (!updateUser || !team) {
        loadingRef.current?.setActive(false);
        return;
      }
      const index = team?.indexOf(updateUser);
      axios
        .put<IUserViewModel>(`user/${updateUser?.userId}`, data)
        .then(({ data }) => {
          const newTeam = [...team];
          newTeam[index as number] = { ...updateUser, ...data };
          setTeam(newTeam);
          userModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    } else {
      axios
        .post<IKiriTeam>(`team`, data)
        .then(({ data }) => {
          setTeam([{ ...(data.user as any), ...data } as ITeamUser, ...(team ?? [])]);
          userModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    }
  }

  function onRemove(userId: string) {
    loadingRef.current?.setActive(true);
    axios
      .delete(`team/${userId}`)
      .then(() => {
        setTeam(team?.filter(team => team.userId !== userId));
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function handleRowClick({ userId }: any) {
    navigate(`/team/user/${userId.value}`);
  }

  useEffect(() => {
    getUrl<ITeamListViewModel[]>(`team`, ({ data }) => {
      console.log(data);
      setTeam(data);
    });
  }, [getUrl]);

  return (
    <div className="ant-fade-enter ant-fade-enter-active inner h100 o-hidden flex flex-column">
      <LoadingKiri ref={loadingRef} />
      <UserModal
        ref={userModalRef}
        onSubmit={onSubmitUser}
        okText="Crear Usuario"
        title="Nuevo Usuario"
      />
      <Confirm
        ref={removeRef}
        okText="Eliminar Usuario"
        className="line-normal"
        onSubmit={onRemove}
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
      />
      <h2 className="text-20 bold mb-16">Equipo Kiri</h2>
      <SearchBar
        buttonText="Agregar Integrante"
        type="mainAlt"
        onChange={evt => setFilter(evt.target.value)}
        onSubmit={handleAddUser}
      />
      <Card
        className={`relative overflow-hidden-x mt-14${team.length > 0 ? ' flex-1' : ''}`}
        style={{ maxHeight: '100%' }}
      >
        <Table
          bordered={false}
          className="table-row-large"
          fixedHeaders
          hoverable
          filter={tableFilter}
          handlers={{
            onRemove: handleRemoveUser,
            onUpdate: handleUpdateUser,
          }}
          onRowClick={handleRowClick}
          headers={headers}
          data={team}
        />
        {team.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay integrantes registrados." />
          </div>
        )}
      </Card>
    </div>
  );
};
