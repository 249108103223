import React from 'react';

export interface IInstitutionNewProps {

};

export const InstitutionNew: React.FC<IInstitutionNewProps> = () => {
	return (
		<div>
			NewInstitution
		</div>
	);
};
