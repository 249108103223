import {
  Confirm,
  Input,
  SectionHeader,
  SwitchOptions,
  Tag,
  TextArea,
} from '@outlier-spa/component';
import { axios, useApi } from '@outlier-spa/fetch';
import {
  DetailsOutlined,
  FileFilled,
  LeftOutlined,
  RiskFilled,
} from '@outlier-spa/icon';
import { Header, LoadingKiri } from 'components';
import { useTitle } from 'context';
import { IProduct } from 'interfaces';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { InventoryProductNew } from '..';

const Back = (
  <Link
    to="/inventory/products"
    className="flex flex-center flex-gap-8 text-16"
  >
    <LeftOutlined className="text-label" />
    <span className="bold">Atrás</span>
  </Link>
);

export interface IInventoryProductDetailProps {}

export const InventoryProductDetail: React.FC<
  IInventoryProductDetailProps
> = () => {
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const editModalRef =
    useRef<React.ElementRef<typeof InventoryProductNew>>(null);
  useTitle(Back);

  const { productId } = useParams();
  const [getUrl, loadingRef] = useApi();
  const navigate = useNavigate();

  const [product, setProduct] = useState<IProduct>();

  useEffect(() => {
    getUrl<IProduct>(`product/${productId}`, ({ data }) => {
      console.log(data);
      setProduct(data as IProduct);
    });
  }, [productId, getUrl]);

  function handleRemove() {
    removeRef.current?.open(
      null,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este producto?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  function handleRemoveConfirm() {
    loadingRef.current?.setActive(true);
    axios
      .delete(`product/${productId}`)
      .then(() => {
        // setProduct(undefined);
        navigate('/inventory/products');
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function onUpdateProduct(product: IProduct, files: File[]) {
    loadingRef.current?.setActive(true);
    const formData = new FormData();
    formData.append('productModel', JSON.stringify(product));
    files.forEach((file) => formData.append(file.name, file));
    axios
      .put<IProduct>(`product`, formData)
      .then(({ data }) => {
        console.log(data);
        setProduct(data as IProduct);
        editModalRef.current?.close();
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  return (
    <div className="inner ant-fade-enter ant-fade-enter-active line-normal">
      <LoadingKiri ref={loadingRef} />
      <InventoryProductNew onSubmit={onUpdateProduct} ref={editModalRef} />

      <Confirm
        okText="Eliminar Producto"
        className="line-normal"
        title={
          <div className="flex flex-center flex-gap-6">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
        onSubmit={handleRemoveConfirm}
      />
      <Header
        title={product?.name}
        onRemove={handleRemove}
        onUpdate={() => editModalRef.current?.open(product)}
        // descriptions={[
        // 	<div className='flex flex-center'><BussinesFilled className='mr-6' /><span>Escuela Pablo de Rocka</span></div>
        // ]}
      />

      <SectionHeader
        size="small"
        className="mv-10 border-top-main"
        prefix={<DetailsOutlined className="text-emphasis" />}
        title="Datos principales"
      />

      <div className="flex flex-center flex-gap-14">
        <Input
          value={product?.name}
          label="Nombre"
          type="mainAlt"
          style={{ width: 300, pointerEvents: 'none' }}
        />
        <div>
          <p className="mb-10">Código de barras</p>
          <div style={{ pointerEvents: 'none' }}>
            <SwitchOptions
              index={product?.hasIdentifier ? 0 : 1}
              options={[
                {
                  content: 'Si',
                },
                {
                  content: 'No',
                },
              ]}
              type="main"
            />
          </div>
        </div>
        <div>
          <p className="mb-10">Disciplinas</p>
          <div className="flex flex-center flex-gap-10">
            {product?.productDisciplines &&
            product?.productDisciplines?.length > 0 ? (
              product?.productDisciplines?.map(({ discipline }, k) => (
                <Tag key={k} label={discipline?.name} />
              ))
            ) : (
              <span style={{ height: 36, lineHeight: '36px' }}>
                No existe registro de disciplinas
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="mt-20 mb">
        <TextArea
          style={{ pointerEvents: 'none' }}
          value={product?.description}
          className="mb-20"
          label="Descripción"
          type="mainAlt"
        />
      </div>

      <div className="mt-20 mb-12">
        <p className="mb-10">Documentos asociados</p>
        <Tag
          type="secondary"
          label={
            <>
              <FileFilled className="text-warning" />
              <span style={{ textDecoration: 'underline' }}>document.docx</span>
            </>
          }
        />
      </div>
    </div>
  );
};
