import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  async (config) => {
    const session = localStorage.getItem("session");
    if (session) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${session}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);
