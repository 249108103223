import React, { useRef } from 'react';
import { Button, Card, Modal, Input, Checkbox, DatePicker } from '@outlier-spa/component';
import { CalendarSimpleOutlined } from '@outlier-spa/icon';
import { PointSeparator } from 'components';

export const InstitutionEvents: React.FC = () => {
	const addModalRef = useRef<React.ElementRef<typeof Modal>>(null);

	function handleAddEvent(){
		addModalRef.current?.open();
	}

	return (
		<div className='pdt-14 ant-fade-enter ant-fade-enter-active'>
			<Modal
				okText='Agregar Fecha'
				okProps={{ type: 'assertive' }}
				title='Agregar Fecha' size='small' overlayClose={false} ref={addModalRef}>
				<div className='pdh-14 flex flex-column flex-vgap-14 pdv-16'>
					<Input label='Nombre del evento' />
					<div>
						<div className='flex flex-center flex-space mb-10'>
							<span>Comienzo</span>
							<div className='text-secondary flex flex-center'>
								<div className='mr-6'>Todo el día</div>
								<div style={{width: 18}}><Checkbox className='flex' /></div>
							</div>
						</div>
						<div className='flex flex-center flex-gap-10'>
							<DatePicker className='flex-1' />
							<DatePicker format='HH-mm' use12Hours picker='time' style={{width: 180}} />
						</div>
					</div>
					<div>
						<div className='flex flex-center flex-space mb-10'>Término</div>
						<div className='flex flex-center flex-gap-10'>
							<DatePicker className='flex-1' />
							<DatePicker format='HH-mm' use12Hours picker='time' style={{width: 180}} />
						</div>
					</div>
				</div>
			</Modal>
			<div className='flex flex-center flex-space'>
				<h5 className='bold text-16'>Año 2022</h5>
				<Button bold type='assertive' label='Agregar fecha' className='pdh-14' onClick={handleAddEvent} />
			</div>
			<Card className='mt-10'>
				{Array.from({length: 10}).map((_, i) => (
					<div className='pdh-14' key={i}>
						<div className='pdv-14 border-bottom-main'>
							<div className='flex flex-center mb-10'>
								<CalendarSimpleOutlined className='text-emphasis' />
								<PointSeparator className='text-secondary ml-8' items={['Lunes 15 de Abril - Viernes 19 de Abril', '09:00 a 16:00 hrs']} />
							</div>
							<p className='text-16 bold'>Aniversario Escuela</p>
						</div>
					</div>
				))}
			</Card>
		</div>
	);
}
