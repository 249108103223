import React, { useEffect, useState } from 'react';
import { Header, LoadingKiri, PointSeparator } from 'components';
import { useApi, axios } from '@outlier-spa/fetch';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { AssistanceFilled, CameraFilled, CommentFilled, RecordFolderFilled, RiskFilled, SchoolFilled, UserFilled } from '@outlier-spa/icon';
import { disciplineIcons } from 'components/icon';
import { Avatar, Card, Checkbox, Collapse, CollapseSection, CollapseValues, Confirm, SectionHeader, Table, TextArea } from '@outlier-spa/component';
import { DefaultCell } from 'components/table';
import { IClassUserViewModel, IClassCreateViewModel } from 'interfaces/class';
import { ClassPhoto } from 'components/photo';
import { useForm, Controller } from 'react-hook-form';
import { EmotionPopover, emotionDict } from 'components/emotion-popover';

const LabelValue = ({ label, value }: { label: string; value: any }) => {
  return (
    <div className='flex flex-center pdh-14 pdv-10'>
      <label style={{ width: 75 }}>{label}:</label>
      <span className='text-secondary'>{value}</span>
    </div>
  );
};

const AttendanceCell: React.FC = ({row, handlers}: any) => {
	const checked = row.status.value >0 && row.status.value !== 6;
	return (
		<div className='flex flex-middle' style={{marginLeft: 24}}>
			<Checkbox
				onChange={() => handlers.onChange({userId: row.userId.value, status: checked ? 6 : 7})}
				checked={checked} />
		</div>
	);
}

const EmotionCell: React.FC = ({value, row, handlers}: any) => {
	const hasEmotion = value > 0 && value < 6;
	return (
		<EmotionPopover onChange={(status) => handlers.onChange({ userId: row.userId.value, status })}>
			<div>
				{hasEmotion ? (
					<label className='pointer bold'>{emotionDict[value].name}</label>
				): (<label style={{textDecoration: 'underline'}} className='bold text-emphasis pointer'>Seleccione</label>)}
			</div>
		</EmotionPopover>
	);
};

export const ClassCreate: React.FC = () => {
	const [getUrl, loadingRef] = useApi();
	const confirmRef = React.useRef<React.ElementRef<typeof Confirm>>(null);
	const [viewModel, setViewModel] = useState<IClassCreateViewModel>();
	const params = useParams();
	const navigate = useNavigate();
	const [users, setUsers] = useState<IClassUserViewModel[]>([]);
	const { reset, control, handleSubmit } = useForm();

	function onSave(data: IClassCreateViewModel){
		if (!data.comment) {
			confirmRef.current?.open(null, 'Debe ingresar un comentario.');
			return;
		}
		if (data.files.filter((f: any) => !!f).length === 0) {
			confirmRef.current?.open(null, 'Debe ingresar al menos un registro fotográfico.');
			return;
		}
		const duo = users.find(u => u.userId === viewModel?.duo?.userId);
		const students = users.filter(u => u.userId !== viewModel?.duo?.userId);
		data.duo = duo ?? viewModel?.duo;
		data.students = students;
		console.log(data);
		data.finishedOn = moment().format('YYYY-MM-DDTHH:mm:ss');
		loadingRef.current?.setActive(true);
		const formData = new FormData();
		data.files.filter(f => !!f).forEach((file: any) => {
			formData.append('files', file);
		});
		data.files = [];
		formData.append('viewModel', JSON.stringify(data));
		axios.post(`class`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(({data}) => {
			console.log(data);
			navigate('/class');
		}).catch((err) => {
			confirmRef.current?.open(null, err.message);
		}).finally(() => loadingRef.current?.setActive(false));
	}

	function handleAssistanceChange({userId, status}: any){
		const index = users.findIndex(x => x.userId === userId);
		if(index > -1){
			users[index].status = status;
			setUsers([...users]);
		}
	}

	useEffect(() => {
		getUrl<IClassCreateViewModel>(`/class/${params.startDate}/${params.scheduleId}`, ({data}) => {
			console.log(data);
			data.startedOn = moment().format('YYYY-MM-DDTHH:mm:ss');
			data.files = [ undefined, undefined, undefined ];
			data.incidents = [];
			setViewModel(data);
			// const users = (data.duo ? [data.duo, ...data.students] : data.students).map(u => ({...u, status: u.status || 6}));
			const users = (data.duo ? [data.duo, ...data.students] : data.students).map(u => ({...u, status: u.status || 6}));
			setUsers(users);
			reset(data);
		});
	}, [getUrl, params, reset]);

	return (
		<div className='h100 flex flex-column ant-fade-enter ant-fade-enter-active o-hidden'>
			<LoadingKiri ref={loadingRef} />
			<Confirm
				title={<div className='flex flex-center'><RiskFilled className='text-danger mr-6' /><span>Advertencia</span></div>}
				cancelProps={{visible: false}}
				okText='Aceptar'
				okProps={{type: 'assertive'}}
				ref={confirmRef} />
			<div className='inner w100'>
				<Header
          title={viewModel?.discipline}
					editable={false}
          canSave
					onSave={handleSubmit(onSave as any)}
					onCancel={() => navigate('/class')}
          descriptions={[
            <div className='flex flex-center'>
              <SchoolFilled className='text-12 mr-6' />
              <span>{viewModel?.institution}</span>
            </div>,
            <div className='flex flex-center'>
              {viewModel && disciplineIcons[`${viewModel.disciplineId}`]}
              <span>{viewModel?.discipline}</span>
            </div>,
            <div className='flex flex-center'>
              <UserFilled className='text-12 mr-6' />
              <span>{'-'}</span>
            </div>,
          ]}
        />
				<div className='border-bottom-main mb-14' />
			</div>
			<div className='flex-1 overflow-hidden-x'>
				<div className='inner'>
					<Card>
						<Collapse
							type='transparent'
							headerPadding={false}
							transparent
							bordered={false}
							size='large'>
								<CollapseSection
                  className='active'
                  title={
                    <SectionHeader
                      size='small'
                      className='pdh-6'
                      prefix={<RecordFolderFilled className='text-emphasis text-16' />}
                      title={<span className='text-16'>Antecedentes</span>}
                    />
                  }>
								<CollapseValues>
									<div className='pdb-20 pdt-10 border-bottom-main'>
										<LabelValue
											label='Fecha'
											value={viewModel?.classDate.split('T')[0] ?? '-'}
										/>
										<LabelValue
											label='Inicio'
											value={`${viewModel?.start} hrs`}
										/>
										<LabelValue
											label='Término'
											value={`${moment(viewModel?.start, 'HH:mm').add(viewModel?.duration, 'minutes').format('HH:mm:ss')} hrs`}
										/>
										<LabelValue label='Monitor' value={viewModel?.tutor?.fullName ?? '-'} />
										<LabelValue label='Dupla' value={(viewModel as any)?.duo?.fullName ?? '-'} />
										<div className='mb-20'></div>
									</div>
								</CollapseValues>
							</CollapseSection>
							<CollapseSection
								className='active'
								title={
									<SectionHeader
										size='small'
										className='pdh-6'
										prefix={<CommentFilled className='text-emphasis text-16' />}
										title={<span className='text-16'>Comentario de la clase/suspensión</span>}
									/>
								}>
								<CollapseValues>
									<div className="pdh-14 pdb-20 pdt-10 border-bottom-main">
										<Card>
											<div className="pdh-14 pdv-10">
												<PointSeparator
													items={[
														<div className='flex flex-center flex-gap-8'>
															<Avatar size={`26px`} />
															<p className='bold'>{viewModel?.tutor?.fullName ?? '-'}</p>
														</div>,
														<span className='text-secondary'>{'Monitor'}</span>
													]}
												/>
											</div>
											<div className='pdh-14 pdb-14'>
												<Controller
													name='comment'
													control={control}
													defaultValue={viewModel?.comment ?? ''}
													render={({ field }) => (
														<TextArea
															type='secondary'
															placeholder='Escriba los objetivos de esta clase...'
															rows={6}
															{...field} />
															)} />
											</div>
										</Card>
										<div className='mb-20' />
									</div>
								</CollapseValues>
							</CollapseSection>
							<CollapseSection
								className='active'
								title={
									<SectionHeader
										size='small'
										className='pdh-6'
										prefix={<AssistanceFilled className='text-emphasis text-16' />}
										title={<span className='text-16'>Asistencia</span>}
									/>
								}>
								<CollapseValues>
									<div className="pdh-14 pdb-20 pdt-10 border-bottom-main">
										<div style={{maxHeight: 500}} className='overflow-hidden-x border-main'>
											<Table
												bordered={false}
												fixedHeaders
												headers={[
													{ key: 'fullName', value: 'Nombres', cellComponent: DefaultCell },
													{ key: 'rut', value: 'Rut', cellComponent: DefaultCell },
													{ key: 'status', value: 'Emoción', cellComponent: EmotionCell },
													{ key: 'assistance', value: 'Asistencia', width: 140, cellComponent: AttendanceCell },
												] as any}
												handlers={{
													onChange: handleAssistanceChange
												}}
												data={users} />
										</div>
										<div className='mb-20' />
									</div>
								</CollapseValues>
							</CollapseSection>
							<CollapseSection
								className='active'
								title={
									<SectionHeader
										size='small'
										className='pdh-6'
										prefix={<CameraFilled className='text-emphasis text-16' />}
										title={<span className='text-16'>Registro Fotográfico</span>}
									/>
								}>
								<CollapseValues>
									<div className="pdh-14 pdb-20 mb-20 pdt-10 flex flex-center flex-gap-14">
										{Array.from({length: 3}).map((_, index) => (
											<Controller
												key={index}
												name={`files[${index}]`}
												control={control}
												render={({ field }) => (
													<ClassPhoto onChange={field.onChange} style={{width: 300}} />
												)} />
										))}
									</div>
								</CollapseValues>
							</CollapseSection>
						</Collapse>
					</Card>
				</div>
			</div>
		</div>
	);
};
