import {
  Avatar,
  Card,
  Confirm,
  NotFound,
  SearchBar,
  Table,
  Tag,
} from '@outlier-spa/component';
import { useApi } from '@outlier-spa/fetch';
import { RiskFilled, StudenFilled } from '@outlier-spa/icon';
import axios from 'axios';
import { LoadingKiri, PopoverOptions, StudentModal } from 'components';
import { DefaultCell } from 'components/table';
import { useInstitution } from 'context/institution';
import { ICourseUserRole } from 'interfaces';
import { IInstitutionStudentListViewModel } from 'interfaces/institution';
import { IUserViewModel } from 'interfaces/user';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Courses = ({ value }: any) => (
  <div className="flex flex-center flex-gap-4">
    {value?.map((v: any, k: number) => (
      <Tag key={k} size="xxs" rounded label={v} />
    ))}
  </div>
);

const Image = ({ value }: { value?: string }) => {
  return (
    <Avatar email={value} className="flex flex-middle o-hidden" size="2.4rem">
      {!value && <StudenFilled className="text-16 text-secondary" />}
    </Avatar>
  );
};

const headers: any = [
  { key: 'email', value: '', cellComponent: Image },
  { key: 'firstName', value: 'Nombres', sortable: true },
  { key: 'lastName', value: 'Apellidos', sortable: true },
  { key: 'rut', value: 'Rut', sortable: true },
  {
    key: 'dateOfBirth',
    value: 'F. nacimiento',
    sortable: true,
    cellComponent: DefaultCell,
  },
  // { key: 'ingressDate', value: 'F. ingreso inst.', sortable: true },
  // {
  //   key: 'apoderado',
  //   value: 'Cuidadores',
  //   sortable: true,
  //   cellComponent: DefaultCell,
  // },
  {
    key: 'city',
    value: 'Ciudad',
    sortable: true,
    cellComponent: DefaultCell,
  },
  // {
  //   key: 'address',
  //   value: 'Dirección',
  //   sortable: true,
  //   cellComponent: DefaultCell,
  // },
  { key: 'disciplines', value: 'Cursos', cellComponent: Courses },
  { key: 'userId', value: '', cellComponent: PopoverOptions },
];

export interface IInstitutionStudentsProps {}

export const InstitutionStudents: React.FC<IInstitutionStudentsProps> = () => {
  const addModalRef = useRef<React.ElementRef<typeof StudentModal>>(null);
  const removeRef = useRef<React.ElementRef<typeof Confirm>>(null);
  const navigate = useNavigate();
  const { institution } = useInstitution();
  const [getUrl, loadingRef] = useApi();
  const [students, setStudents] = useState<IInstitutionStudentListViewModel[]>(
    []
  );
  const [filter, setFilter] = useState<string>();

  const tableFilter = useCallback(
    (data: any[]) => {
      if (!filter || filter?.length < 2) return data;
      const filterStr = filter.toLowerCase();
      return data.filter((d) => {
        const firstName = d[0].firstName?.value?.toLowerCase();
        const lastName = d[0].lastName?.value?.toLowerCase();
        const rut = d[0].rut?.value?.toLowerCase();
        // const email = d[0].email?.value?.toLowerCase();
        const city = d[0].city?.value?.name?.toLowerCase();
        const courses = d[0].courseUserRoles?.value
          ?.map((v: ICourseUserRole) =>
            v?.course?.program?.discipline?.name?.toLowerCase()
          )
          ?.join(' ');
        const address = d[0].address?.value?.toLowerCase();
        const dateOfBirth = moment(d[0].dateOfBirth?.value).format(
          'DD-MM-YYYY'
        );
        return (
          firstName?.includes(filterStr) ||
          lastName?.includes(filterStr) ||
          rut?.includes(filterStr) ||
          city?.includes(filterStr) ||
          courses?.includes(filterStr || address?.includes(filterStr)) ||
          dateOfBirth?.includes(filterStr)
        );
      });
    },
    [filter]
  );

  function handleAddStudent() {
    addModalRef.current?.open();
  }

  function onSubmitStudent(
    student: IUserViewModel,
    prevStudent?: IUserViewModel
  ) {
    loadingRef.current?.setActive(true);
    console.log(1);
    if (prevStudent) {
      console.log(2);
      const index = students?.findIndex((i) => prevStudent.rut === i.rut);
      if (index === -1 || !students) {
        loadingRef.current?.setActive(false);
        return;
      }
      axios
        .put<IInstitutionStudentListViewModel>(
          `user/${student?.userId}`,
          student
        )
        .then(({ data }) => {
          const newStudents = [...students];
          newStudents[index as number] = data;
          setStudents(newStudents);
          addModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    } else {
      console.log(3);
      axios
        .post<IInstitutionStudentListViewModel>(
          `institution/${institution?.institutionId}/student`,
          student
        )
        .then(({ data }) => {
          console.log(data);
          setStudents([data, ...students]);
          addModalRef.current?.close();
        })
        .finally(() => loadingRef.current?.setActive(false));
    }
  }

  function handleRowClick(row: any) {
    navigate(
      `/institution/${institution?.institutionId}/student/${row.userId.value}`
    );
  }

  function handleRemoveStudent(userId: any) {
    removeRef.current?.open(
      userId,
      <>
        <p className="bold mb-10">
          ¿Está seguro que desea eliminar este alumno?
        </p>
        <p>
          El item que desea eliminar contiene datos y no podrán ser recuperados.
        </p>
      </>
    );
  }

  function handleRemoveStudentConfirm(userId: any) {
    console.log('remove student', userId);
    loadingRef.current?.setActive(true);
    axios
      .delete(`institution/${institution?.institutionId}/student/${userId}`)
      .then(() => {
        setStudents(students.filter((student) => student.userId !== userId));
      })
      .finally(() => loadingRef.current?.setActive(false));
  }

  function handleUpdateStudent(studentId: any) {
    addModalRef.current?.open(
      students?.find((stu) => stu.userId === studentId)
    );
  }

  useEffect(() => {
    getUrl<IInstitutionStudentListViewModel[]>(
      `institution/${institution?.institutionId}/students`,
      ({ data }) => {
        console.log(data);
        setStudents(data);
      }
    );
  }, [institution?.institutionId, getUrl]);

  return (
    <div className="pdt-20 ant-fade-enter ant-fade-enter-active h100 flex flex-column o-hidden">
      <LoadingKiri ref={loadingRef} />
      <Confirm
        okText="Eliminar Alumno"
        className="line-normal"
        title={
          <div className="flex flex-center flex-gap-6 ">
            <RiskFilled className="text-danger" />
            <span>Advertencia</span>
          </div>
        }
        ref={removeRef}
        onSubmit={handleRemoveStudentConfirm}
      />
      <StudentModal
        ref={addModalRef}
        onSubmit={onSubmitStudent}
        institutionId={institution?.institutionId ?? -1}
        okText="Agregar Alumno"
        title="Nuevo Alumno"
      />
      <SearchBar
        type="mainAlt"
        buttonText="Agregar Alumno"
        onSubmit={handleAddStudent}
        onChange={(evt) => setFilter(evt.target.value)}
      />
      <Card
        className={`overflow-auto mt-14${students.length > 0 ? ' flex-1' : ''}`}
        style={{ maxHeight: '100%' }}
      >
        <Table
          bordered={false}
          className="table-row-large"
          filter={tableFilter}
          fixedHeaders
          hoverable
          handlers={{
            onRemove: handleRemoveStudent,
            onUpdate: handleUpdateStudent,
          }}
          onRowClick={handleRowClick}
          headers={headers}
          data={students}
        />
        {students.length === 0 && (
          <div className="relative" style={{ height: 300 }}>
            <NotFound content="No hay estudiantes registrados para esta institución." />
          </div>
        )}
      </Card>
    </div>
  );
};
