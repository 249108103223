import styled from "styled-components";

export const StyledModal = styled.div`
  width: 700px;
  height: 700px;
  background-color: #24262d;
  border-radius: 3px;
  .icon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #d1d1d1;
    font-size: 16px;

    &:hover {
      color: #ff6060;
    }
  }

  div {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
`;
