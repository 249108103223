import React, { useRef, useState, useImperativeHandle } from 'react';
import { Modal, Avatar, Select, Input, InputNumber } from '@outlier-spa/component';
import { CameraFilled } from '@outlier-spa/icon';
import { useForm, Controller } from 'react-hook-form';
import { IInstitution } from 'interfaces';
import { useCity } from 'hooks';

interface IInstitutionModalRef {
  open: (institution?: IInstitution) => void;
  close: () => void;
}

interface IInstitutionModalProps {
  onSubmit: (data: IInstitution, institution?: IInstitution) => void;
}

export const InstitutionModal = React.forwardRef<IInstitutionModalRef, IInstitutionModalProps>(
  ({ onSubmit }, ref) => {
    const modalRef = useRef<React.ElementRef<typeof Modal>>(null);
    const [institution, setInstitution] = useState<IInstitution>();
    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm();
    const { regionProps, provinceProps, cityProps, cityId, onChangeCity } = useCity();

    function onSubmitData(data: any) {
      if (cityId) data.cityId = cityId;
      onSubmit(data as IInstitution, institution);
    }

    function open(institution?: IInstitution) {
      setInstitution(institution);
      if (institution?.cityId) onChangeCity(institution.cityId);
      reset(institution ?? {});
      modalRef.current?.open();
    }

    function close() {
      modalRef.current?.close();
    }

    useImperativeHandle(ref, () => ({ open, close }));

    return (
      <Modal
        ref={modalRef}
        size="small"
        type="tertiary"
        title={institution ? 'Editar Establecimiento' : 'Nuevo Establecimiento'}
        okText={institution ? 'Actualizar Establecimiento' : 'Crear Establecimiento'}
        okProps={{ type: 'assertive' }}
        overlayClose={false}
        onSubmit={handleSubmit(onSubmitData)}
      >
        <div className="pdh-14 flex flex-column flex-vgap-14 pdv-16">
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                type="secondary"
                placeholder="Ingrese nombre"
                label="Nombre"
                validation={{
                  message: 'El nombre es requerido',
                  visible: Boolean(errors?.name),
                }}
                {...field}
              />
            )}
          />

          <div className="flex flex-center flex-gap-20">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  type="secondary"
                  placeholder="Ingrese email"
                  className="flex-1"
                  label="Email"
                  {...field}
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <InputNumber
                  type="secondary"
                  className="flex-1"
                  placeholder="Ingrese teléfono"
                  label="Teléfono"
                  style={{ width: '100%' }}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex flex-center flex-gap-20">
            <Input
              label="Pais"
              placeholder="Ingrese pais"
              type="secondary"
              className="flex-1"
              defaultValue={'Chile'}
              disabled
            />
            <Controller
              name="region"
              control={control}
              render={({ field }) => (
                <Select
                  optionFilterProp="label"
                  style={{ width: '100%' }}
                  className="flex-1"
                  label="Region"
                  type="secondary"
                  {...regionProps}
                  onChange={value => {
                    regionProps.onChange?.(value);
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
          <div className="flex flex-center flex-gap-20">
            <Controller
              name="province"
              control={control}
              render={({ field }) => (
                <Select
                  optionFilterProp="label"
                  style={{ width: '100%' }}
                  className="flex-1"
                  label="Provincia"
                  type="secondary"
                  {...provinceProps}
                  onChange={value => {
                    provinceProps.onChange?.(value);
                    field.onChange(value);
                  }}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  optionFilterProp="label"
                  label="Ciudad"
                  style={{ width: '100%' }}
                  validation={{
                    message: 'Ciudad requerida',
                    visible: Boolean(errors.city),
                  }}
                  className="flex-1"
                  type="secondary"
                  {...cityProps}
                  onChange={value => {
                    cityProps.onChange?.(value);
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
          <div className="flex flex-center flex-gap-20">
            <Controller
              name="address"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  label="Dirección"
                  placeholder="Ingrese dirección"
                  type="secondary"
                  className="flex-1"
                  validation={{
                    message: 'Dirección requerida',
                    visible: Boolean(errors.address),
                  }}
                  {...field}
                />
              )}
            />
            <Controller
              name="identifier"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  label="Identificador establecimiento"
                  placeholder="Ingrese identificador"
                  validation={{
                    message: 'Identificador requerido',
                    visible: Boolean(errors.identifier),
                  }}
                  type="secondary"
                  className="flex-1"
                  {...field}
                />
              )}
            />
          </div>
        </div>
      </Modal>
    );
  }
);
