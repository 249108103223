import styled from 'styled-components';

export const StyledEventAuthorization = styled.div`
	.authorize-cell {
		> p { display: block; }
		button { display: none; }
	}
	.table {
		tbody {
			cursor: pointer;
			&:hover {
				.authorize-cell {
					> p { display: none; }
					button { display: block; }
				}
			}
		}
	}
	.list {
		.list-item-active {
			font-weight: 600;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 3px;
				background-color: ${({theme}) => theme.colors.emphasis};
			}
		}
	}
`;
