import React, { useRef } from 'react';
import { Modal, Input, TextArea, InputNumber, Upload, IModalRef } from '@outlier-spa/component';
import { useForm, Controller } from 'react-hook-form';
import { ISession } from 'interfaces';

export interface ISessionModalProps {
	onSubmit: (session: ISession, files: File[]) => void;
};

export const SessionModal = React.forwardRef<IModalRef, ISessionModalProps>(({onSubmit}, ref) => {
	const filesRef = useRef<File[]>([]);
	const { handleSubmit, control } = useForm({ defaultValues: { classes: 1, name: '', description: '' } });

	function handleFilesChange(files: File[]) {
		console.log(files);
		filesRef.current = files;
	}

	function onSubmitData(data: any){
		onSubmit(data, filesRef.current);
	}

	return (
		<Modal
			ref={ref}
			size='small'
			overlayClose={false}
			onSubmit={handleSubmit(onSubmitData)}
			okProps={{type: 'assertive'}}
			title='Nueva Sesión'
			okText='Crear Sesión'>
			<div className='pdh-16 flex flex-center flex-gap-10'>
				<Controller
					name='name'
					control={control}
					render={({field}) => <Input className='flex-1' type='secondary' label='Nombre' placeholder='Ingrese nombre' {...field} />} />
				<Controller
					name='classes'
					control={control}
					render={({field}) => <InputNumber label='Clases' type='secondary' style={{width: 120}} min={1} {...field} />} />
			</div>
			<div className='pdh-16 mv-16'>
				<Controller
					name='description'
					control={control}
					render={({field}) => <TextArea label='Detalle' placeholder='Ingrese detalle' type='secondary' rows={6} {...field} />} />
			</div>
			<div className='pdh-16 mv-16'>
				<p className='mb-10'>Archivos asociados</p>
				<Upload type='secondary' size='large' onChange={handleFilesChange} />
			</div>
		</Modal>
	);
});
