import React from 'react';
import { Input, Button } from '@outlier-spa/component';
import { Schedule } from '../../../components';

export interface ICourseNewProps {

}

export const CourseNew: React.FC<ICourseNewProps> = () => {
	return (
		<div className='inner'>
			<div className='flex flex-column flex-vgap-20'>
				<Input label='Nombre del curso' size='large' />
				<div className="flex flex-gap-20">
					<div className='flex-1'><Input label='Tutor' size='large' /></div>
					<div className='flex-1'><Input label='Disciplina' size='large' /></div>
				</div>
				<div className="flex flex-gap-20">
					<div className='flex-1'><Input label='Institución' size='large' /></div>
					<div className='flex-1'><Input label='Versión' size='large' /></div>
				</div>
				<Schedule />

				<div className='flex flex-center flex-end'>
					<Button className='pdh-20' size='large' type='assertive' bold label='Guardar Curso' />
				</div>
			</div>
		</div>
	);
};
