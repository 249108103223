import styled from "styled-components";
import { Card, Popover } from "@outlier-spa/component";
export const StyledSchedule = styled.div`
  th {
    background-color: ${({ theme }) => theme.tableCell.header};
    padding: 8px 0;
  }
  td {
    background-color: ${({ theme }) => theme.table.secondary};
    padding: 4px 0;
  }
  table {
    user-select: none;
    width: 100%;
    table-layout: fixed;
  }
  td,
  th {
    border: 1px solid ${({ theme }) => theme.border.main};
  }
  .schedule-selected {
    background: #f652754d;
    border-color: #d5dce3;
  }
  .schedule-cards {
    flex-flow: wrap;
  }
`;
export const StyledCard = styled(Card)`
  :first-child {
    margin-left: 0;
  }
  margin-top: 10px;
  margin-right: 10px;
  &.pointer:hover {
    transform: translateY(-2px);
  }
  .header {
    margin-bottom: 2px;
  }
  .color-indicator {
    height: 10px;
    width: 10px;
    border-radius: 10px;
  }
`;
